import {axiosInstance} from "./base"

export function getEmergenciesQry(params) {
    return axiosInstance.get("api/emergencies/", {params})
}

export function getAlertsQry(params) {
    return axiosInstance.get("api/alerts/", {params})
}

export function patchEmergenciesQry(id, data) {
    return axiosInstance.patch(`api/emergencies/${id}/`, data, {})
}

export function optionsEmergenciesQry() {
    return axiosInstance.options("api/emergencies/", {})
}
