<template>
  <div class="full-height contracts">
    <!-- HEADER -->
    <Row>
      <i-col :md="12" :xs="14">
        <i-col :md="15" :xs="15">
          <router-link :to="{ name: 'accounts' }">
            <span class="history-account mr-3">{{ $t("Account") }}</span>
          </router-link>
          <Icon type="ios-arrow-forward" size="20" class="arrow" />
          <span class="history-account-actual" v-if="!isLoading"> {{ client.client_name }}</span>
            <span class="history-account-actual" v-else> {{ $t("Client") }}</span>
        </i-col>
      </i-col>
      <i-col v-if="isOperatorOrAbove()" :md="12" :xs="10" align="right">
        <button class="action-button" @click="triggerShowCreateModal">
          {{ $t("Add contract") }}
        </button>
        <ContractModalForm v-model="modalData" :modalOnSubmit="modalOnSubmit" @hide="triggerHideModal" />
      </i-col>
    </Row>
    <!-- TABS -->
    <Tabs class="marginTopHeader">
      <TabPane :label="$t('Users')" name="Users"> 
        <Users :contracts="contracts.results"></Users>
      </TabPane>
      <TabPane :label="$t('Contracts')" name="Contracts">
        <Row v-if="isOperatorOrAbove()" class="mb-5">
          <i-col :md="12" :xs="24" class="mt-3">
            <i-col :md="8" :xs="12" class="mr-4">
              <p class="text-filter">{{ $t("Search") }}</p>
              <Input
                search
                type="text"
                v-model="contracts.filter.name"
                @on-search="triggerFetchContracts({ contract_name: contracts.filter.name })"
                clearable
                @on-clear="fetchContracts"
                :placeholder="$t('Search by name')"
              />
            </i-col>
            <i-col :md="8" :xs="8" class="mr-3">
              <p class="text-filter">{{ $t("Status") }}</p>
              <i-select
                type="text"
                :placeholder="$t('Status')"
                v-model="contracts.filter.status"
                clearable
                multiple
                @on-change="triggerFetchContracts({ contract_status: contracts.filter.status })"
              >
                <i-option value="in_progress"> {{ $t("In progress") }} </i-option>
                <i-option value="suspended"> {{ $t("Suspended") }} </i-option>
                <i-option value="incoming"> {{ $t("Incoming") }} </i-option>
                <i-option value="completed"> {{ $t("Completed") }} </i-option>
              </i-select>
            </i-col>
          </i-col>
        </Row>
        <transition appear name="slide-left-centered">
          <AppTable
            :columns="columns"
            table-class="s2ec-table"
            :data-table="contracts.results"
            ref="table"
            @on-row-click="open"
            :row-class-name="getAccountsTableRowClassName"
            :no-data-text="$t('No data')"
            :loading="isLoading"
          />
        </transition>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import ContractModalForm from "@/views/accounts/modals/ContractModalForm";
import { getContractsQry, getClientQry, getClientsQry } from "@/services/accounts.service";
import { getClientContractsQry } from "@/services/contract.service";
import {
  postClientContractQry,
  deleteClientContractQry,
  patchContractQry,
  postDomainOrEmailQry,
  putClientContractQry,
  deleteDomainOrEmailQry,
} from "@/services/contract.service";
import AppModal from "@/components/common/AppModal";
import moment from "moment";
import DateFormat from "@/mixins/dateFormat";
import AppTable from "@/components/common/AppTable";
import Users from "./users/Users";

export default {
  name: "ClientContract",
  components: {
    ContractModalForm,
    getContractsQry,
    deleteClientContractQry,
    getClientContractsQry,
    AppModal,
    patchContractQry,
    AppTable,
    Users
  },
  mixins: [DateFormat],
  data() {
    return {
      isLoading: false,
      client: {
        client_name: "",
      },
      contracts: {
        filter: {
          status: [],
          name: "",
        },
        count: 0,
        results: [],
      },
      modalData: {
        showModal: false,
        form: {},
      },
      modalOnSubmit: null,
      columns: [
        {
          title: this.$t("Name"),
          key: "contract_name",
        },
        {
          title: this.$t("Id"),
          key: "contract_identifier",
        },
        {
          title: this.$t("Opening date"),
          key: "contract_start_date",
          sortable: true,
          render: (h, params) => {
            return h("div", [h("p", this.dateFormat(params.row.contract_start_date))]);
          },
        },
        {
          title: this.$t("Expiration date"),
          key: "contract_end_date",
          sortable: true,
          render: (h, params) => {
            if (moment(moment().format()).isAfter(moment(params.row.contract_end_date))) {
              return h("div", [
                h(
                  "p",
                  {
                    style: {
                      color: "red",
                    },
                  },
                  this.dateFormat(params.row.contract_end_date)
                ),
              ]);
            } else {
              return h("div", [h("p", this.dateFormat(params.row.contract_end_date))]);
            }
          },
        },
        {
          title: this.$t("State"),
          sortable: true,
          align: "center",
          render: (h, params) => {
            if (params.row.contract_is_active === false) {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      paddingLeft: "16px",
                      paddingTop: "7px",
                      paddingRight: "16px",
                      border: "none",
                      textAlign: "center",
                      width: "100px",
                      margin: "auto",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                      backgroundColor: "#CC0000",
                    },
                  },
                  this.$t("Suspended")
                ),
              ]);
            } else if (
              moment(moment().format()).isAfter(moment(params.row.contract_start_date)) &&
              moment(moment().format()).isBefore(moment(params.row.contract_end_date))
            ) {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      paddingLeft: "16px",
                      paddingTop: "7px",
                      paddingRight: "16px",
                      border: "none",
                      textAlign: "center",
                      width: "100px",
                      margin: "auto",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                      backgroundColor: "#268044",
                    },
                  },
                  this.$t("In progress")
                ),
              ]);
            } else if (moment(moment().format()).isBefore(moment(params.row.contract_start_date))) {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      paddingLeft: "16px",
                      paddingTop: "7px",
                      paddingRight: "16px",
                      border: "none",
                      textAlign: "center",
                      width: "90px",
                      margin: "auto",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                      backgroundColor: "#A66811",
                    },
                  },
                  this.$t("Incoming")
                ),
              ]);
            } else {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      paddingLeft: "16px",
                      paddingTop: "7px",
                      paddingRight: "16px",
                      border: "none",
                      textAlign: "center",
                      width: "100px",
                      margin: "auto",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                      backgroundColor: "#808080",
                    },
                  },
                  this.$t("Expired")
                ),
              ]);
            }
          },
        },
        {
          title: this.$t("Access"),
          key: "contract_user_limit",
          sortable: true,
          render: (h, params) => {
            if (params.row.contract_user_limit === 999999) {
              return h("div", [h("p", this.$t("Unlimited"))]);
            } else {
              return h("div", [h("p", params.row.contract_user_limit)]);
            }
          },
        },
        {
          title: this.$t("Actions"),
          key: "actions",
          align: "right",
          render: (h, params) => {
            if (this.isOperatorOrAbove()) {
              return [
                h("Button", {
                  props: {
                    icon: "md-create",
                  },
                  attrs: {
                    title: this.$t("Edit"),
                  },
                  class: "table-button",
                  on: {
                    click: (evt) => {
                      evt.stopPropagation(); // "click.stop" is not supported here
                      this.triggerShowUpdateModal(params.row);
                    },
                  },
                }),
                h("Button", {
                  props: {
                    icon: params.row.contract_is_active ? "md-close" : "md-done-all",
                  },
                  attrs: {
                    title: params.row.contract_is_active ? this.$t("Disable") : this.$t("Enable"),
                  },
                  class: "table-button",
                  on: {
                    click: (evt) => {
                      evt.stopPropagation(); // "click.stop" is not supported here
                      this.changeStateContract(params.row.client, params.row.id, params.row.contract_is_active);
                    },
                  },
                }),
                h("Button", {
                  props: {
                    icon: "md-trash",
                  },
                  attrs: {
                    title: this.$t("Delete"),
                  },
                  class: "table-button",
                  on: {
                    click: (evt) => {
                      evt.stopPropagation(); // "click.stop" is not supported here
                      this.removeContract(params.row.id, params.index, params.row.client);
                    },
                  },
                }),
              ];
            } else {
              return [];
            }
          },
        },
      ],
    };
  },
  computed: {
    emptyContractForm() {
      return {
        contract_start_date: "",
        contract_end_date: "",
        contract_user_limit: "",
        contract_is_active: true,
        domains_or_emails: null,
      };
    },
  },
  created() {
    this.modalData.form = { ...this.emptyContractForm };
    this.modalOnSubmit = this.triggerCreateContract;

    this.fetchClient();
    this.fetchContracts();
  },
  methods: {
    triggerFetchContracts(filter_value) {
      this.isLoading = true;
      getClientContractsQry(this.client.id, filter_value)
        .then((res) => {
          this.contracts.results = res.data.results;
          this.contracts.count = res.data.count;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred retrieving contracts"),
            duration: 5,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getAccountsTableRowClassName() {
      return "account-table__row";
    },
    fetchClient() {
      this.isLoading = true;
      getClientQry(this.$route.params.id)
        .then((res) => {
          this.client = res.data;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred retrieving client"),
            duration: 5,
          });
        });
    },
    fetchContracts() {
      getContractsQry(this.$route.params.id)
        .then((res) => {
          this.contracts.results = res.data.results;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred retrieving contracts"),
            duration: 5,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    open(row) {
      this.$router.push({
        name: "users",
        params: {
          contractId: row.id,
          id: row.client,
        },
      });
    },
    removeContract(id, idx, idClient) {
      this.$AppModal.AppConfirm({
        extraTitle: this.$t("Delete"),
        message: this.$t("You are about to delete this item. Are you sure to continue"),
        loading: true,
        cancelText: this.$t("Cancel"),
        onConfirm: () => {
          deleteClientContractQry(idClient, id)
            .then(() => {
              this.contracts.count -= 1;
              this.contracts.results.splice(idx, 1);
              this.$AppModal.remove();
              this.$Message.success(this.$t("Successfully deleted contract"));
            })
            .catch(() => {
              this.$AppModal.remove();
              this.$Message.error({
                content: this.$t("Error occurred deleting contract"),
                duration: 5,
              });
            });
        },
      });
    },
    changeStateContract(client_id, contract_id, state) {
      patchContractQry(client_id, contract_id, { contract_is_active: !state })
        .then(() => {
          this.$Message.success(this.$t("Successfully updated a contract"));
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred"),
            duration: 5,
          });
        });
    },
    triggerShowCreateModal() {
      this.modalData.form = {
        ...this.emptyContractForm,
        domains_or_emails: [],
      };
      this.modalData.showModal = true;
      this.modalOnSubmit = this.triggerCreateContract;
    },
    triggerShowUpdateModal(contractData) {
      this.modalData.form = {
        ...contractData,
        domains_or_emails: contractData.contract_access_restrictions.map((e) => e.domain_or_email),
      };
      this.modalData.showModal = true;
      this.modalOnSubmit = this.triggerUpdateContract;
    },
    triggerHideModal() {
      this.modalData.form = {
        ...this.emptyContractForm,
        domains_or_emails: [],
      };
      this.modalData.showModal = false;
      this.modalOnSubmit = this.triggerCreateContract;
    },
    triggerCreateContract() {
      const clientId = this.$route.params.id;
      const contractData = this.modalData.form;

      postClientContractQry(clientId, contractData)
        .then((res) => {
          this.createDomainsOrEmails(res.data.id, this.modalData.form.domains_or_emails);
          this.$Message.success(this.$t("Successfully created a contract"));
          this.fetchContracts();
          this.triggerHideModal();
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error when adding a contract"),
            duration: 5,
          });
        });
    },
    triggerUpdateContract() {
      const clientId = this.$route.params.id;
      const contractId = this.modalData.form.id;
      const contractData = this.modalData.form;

      putClientContractQry(clientId, contractId, contractData)
        .then(() => {
          this.updateDomainsOrEmails(contractId);
          this.$Message.success(this.$t("Successfully updated a contract"));
          this.fetchContracts();
          this.triggerHideModal();
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error when updating a contract"),
            duration: 5,
          });
        });
    },
    updateDomainsOrEmails(contract_id) {
      console.log(this.modalData.form);
      const contractAccessRestrictions = this.modalData.form.contract_access_restrictions;
      const domainsOrEmails = this.modalData.form.domains_or_emails;

      const newDomainsOrEmails = domainsOrEmails.filter((domain_or_email) => {
        return !contractAccessRestrictions.map((e) => e.domain_or_email).includes(domain_or_email);
      });

      const removedDomainsOrEmails = contractAccessRestrictions.filter((contractAccessRestriction) => {
        return !domainsOrEmails.includes(contractAccessRestriction.domain_or_email);
      });

      this.createDomainsOrEmails(contract_id, newDomainsOrEmails);
      this.deleteContractAccessRestrictions(contract_id, removedDomainsOrEmails);
    },
    createDomainsOrEmails(contract_id, domains_or_emails) {
      const clientId = this.$route.params.id;

      domains_or_emails.forEach((domain_or_email) => {
        postDomainOrEmailQry(clientId, contract_id, {
          domain_or_email: domain_or_email,
        });
      });
    },
    deleteContractAccessRestrictions(contract_id, contract_access_restrictions) {
      const clientId = this.$route.params.id;

      contract_access_restrictions.forEach((contract_access_restriction) => {
        deleteDomainOrEmailQry(clientId, contract_id, contract_access_restriction.id);
      });
    },
  },
};
</script>

<style scoped>
.contract-list-action > .ivu-btn:first-of-type {
  margin-right: 20px;
}

.ivu-table-title {
  margin-bottom: 186px !important;
}
.ivu-input-icon {
  line-height: 40px;
}
</style>
