<template>
  <Poptip
    confirm
    transfer
    :ok-text="$t('Yes')"
    :cancel-text="$t('Cancel')"
    :title="message"
    @on-ok="broadCast"
  >
    <Button class="table-button" style="margin: 5px -4px 0 0">
      <Icon
        v-if="val"
        type="ios-megaphone"
        :color="color"
      />
      <Icon
        v-else
        type="ios-megaphone"
        color="#2d8cf0"
      />
    </Button>
  </Poptip>
</template>

<script>
    import {postIncidentBroadcast, patchIncidentQry} from "../../services/incidents.service";

    export default {
        name: "PoptipBroadcast",
        props: ['id', 'val', 'message', 'broadcast', 'color'],
        methods: {
            broadCast() {
                postIncidentBroadcast({incident_id: this.id, broadcast_status: this.val})
                    .then(res => {
                        this.$Message.success(this.$t('Broadcasting status set'));
                        this.$emit('broadcasting', this.val);
                    })
                    .catch(err => {
                        this.$Message.error("Error setting broadcast status")
                    })
            }
        }
    }
</script>

<style scoped>

</style>
