<template>
  <div>
    <div
      v-if="success"
      class="auth-success-message text-center"
    >
      <p>{{ $t('Congratulation') }}!</p>
      <p>{{ $t('You successfully set your password') }}</p>
      <p>{{ $t('You can now use the application') }}</p>
      <Button
        type="default"
        :to="{name: 'login'}"
      >
        {{ $t('Go to login') }}
      </Button>
      <p>
        <img 
          :src="require('@/assets/images/ico/Google_Play.svg')" 
          style="cursor: pointer" width="200" 
          @click="openGooglePlay"/>
      </p>
    </div>

    <PasswordForm ref="passwordFormRef"
      v-else
      :password-submit="triggerSetPassword"
      :message="message"
      :includeFullname="true"
    />
  </div>
</template>

<script>
  import {getInvitationPasswordQry, postInvitationPasswordQry} from "../../services/auth.service";
  import {logout} from "../../helpers/auth.helper";
  import PasswordForm from "./PasswordForm"

  export default {
    name: "Invitation",
    components: {
      PasswordForm
    },
    data() {
      return {
        success: false,
        message: this.$t('Set your password to activate your account') // FIXME: Edit the message
      }
    },
    computed: {
      invitationKey() {
        return this.$route.params.key
      }
    },

    mounted() {
      getInvitationPasswordQry(this.invitationKey)
        .then(() => {
          logout();
        })
        .catch(() => {
          this.$Message.error({content: this.$t('Confirmation link is invalid or expired'), duration: 5});
          this.$router.push({name: 'login'});
        })
    },
    methods: {
      openGooglePlay() {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.s2ec.ilios&hl=en'
      },
      triggerSetPassword(data, load) {
        load(true);
        postInvitationPasswordQry(this.invitationKey, data)
          .then(() => {
            load(false);
            this.success = true;
            this.$Message.success(this.$t('You successfully set password'));
          })
          .catch((err) => {
            let treated = this.$refs.passwordFormRef.handleSubmissionErrors(err)
            if (!treated)
              this.$Message.error({content: this.$t('Error occurred, your key is probably invalid or expired'), duration: 5});
            load(false);
          })
      },
    }
  }
</script>

<style scoped>

</style>
