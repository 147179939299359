import {axiosInstance, uncachedAxiosInstance} from "./base"
import {jsonToFormData} from "@/helpers/common.helper";
export function patcherUserQry(id, data, params) {
  return axiosInstance.patch(`api/users/${id}/`, data, {params})
}

export function patchUser(id, data) {
  return uncachedAxiosInstance.patch(`api/users/${id}/`, jsonToFormData(data), {})
}

export function deleteUserQry(id, params) {
  return axiosInstance.delete(`api/users/${id}/`, {params})
}

export function sendSms(params) { // Fixme naming
  return axiosInstance.post(`api/users/send-sms/`, params)
}

export function postValidateUserQry(id) {
  return axiosInstance.post(`/api/users/${id}/validate-user-registration/`)
}
