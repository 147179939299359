<template>
  <div>
    <div
      v-if="success"
      class="auth-success-message text-center"
    >
      <p>{{ $t('You successfully reset your password') }}!</p>
      <Button
        type="default"
        :to="{name: 'login'}"
      >
        {{ $t('Back') }}
      </Button>
    </div>

    <PasswordForm
      v-else
      :message="message"
      :password-submit="formSubmit"
      :include_tac="false"
    />
  </div>
</template>

<script>
    import PasswordForm from "./PasswordForm";
    import {logout} from "../../helpers/auth.helper";
    import {getUserPasswordResetQry, putUserPasswordResetQry} from "../../services/auth.service";

    export default {
        name: "LostPasswordConfirmation",
        components: {
            PasswordForm
        },
        data() {
            return {
                success: false,
                message: this.$t('Set your password')
            }
        },
        mounted() {
            getUserPasswordResetQry(this.$route.params.key)
                .then(() => {
                    logout();
                })
                .catch(() => {
                    this.$Message.error({content: this.$t('Confirmation link is invalid or expired'), duration: 5});
                    this.$router.push({name: 'login'});
                })
        },
        methods: {
            formSubmit(data, load) {
                load(true);
                putUserPasswordResetQry(this.$route.params.key, data)
                    .then(res => {
                        this.success = true;
                        load(false);

                    })
                    .catch(() => {
                        this.$Message.error({content: this.$t('Error occurred on submit'), duration: 5});
                        load(false);
                    })
            }
        }
    }
</script>

<style scoped>

</style>
