import {getAuthSession} from "../helpers/auth.helper";
import i18n from '@/i18n/i18n'

export const userTypeMixin = {
    data() {

        let userTypes = { // Keep this in sync with BACKEND!
            superuser: 0,
            traveler: 1,
            head_of_site: 2,
            manager: 3,
            admin: 4
        };

        const userRoles = {
            traveler: {
                code: 1,
                label: i18n.t('Traveler')
            },
            head_of_site: {
                code: 2,
                label: i18n.t('Head of site')
            },
            manager: {
                code: 3,
                label: i18n.t('Manager')
            },
            operator: {
                code: 4,
                label: i18n.t('Operator')
            },
        }

        let userStatuses = { // Keep this in sync with BACKEND!
            self_signup: 1,
            invited: 2,
            registered: 3,
        };

        const authSession = getAuthSession()

        return {
            s2ec: {
                userTypes: userTypes,
                userRoles: userRoles,
                userStatuses: userStatuses,
                user: {
                    is_superuser: authSession.is_superuser,
                    is_traveler: authSession.user_role === userTypes.traveler,
                    is_head_of_site: authSession.user_role === userTypes.head_of_site,
                    is_manager: authSession.user_role === userTypes.manager,
                    is_admin: authSession.user_role === userTypes.admin,
                },
                user_role: authSession.user_role
            }
        }
    },
    methods: {
        resetDataName(vm, data_name) {
            vm[data_name] = vm.$options.data.apply(vm)[data_name]
        },
        canEditTranslatableContent() {
            return i18n.locale === "fr"  // Database content is FR by default for now
        },
        getNonEditableLocaleMessageIfRelevant() {
            if (this.canEditTranslatableContent())
                return "";
            return "This translatable content can only be edited in French locale";  // For now it's only in English, by construction...
        },
        getTranslatableContentAttributes() {
            let isDisabled = !this.canEditTranslatableContent();
            return {"class": isDisabled ? "icon-disabled" : "",
                    "title": this.getNonEditableLocaleMessageIfRelevant(),
                    "disabled": isDisabled}
        },
        getUserTypes() {
            var userTypes = [
                {value: 1, display_name: i18n.t('Traveler')},
                { value: 2, display_name: i18n.t('Head of site')},
            ];
            if (this.isManagerOrAbove()) {
                userTypes.push({value: 3, display_name: i18n.t('Manager')});
            }
            if (this.isOperatorOrAbove()) {
                userTypes.push({value: 4, display_name: i18n.t('Operator')})
            }
            return userTypes
        },
        getInvitableUserRoles() {
            return Object.values(this.s2ec.userRoles).filter(e => {
                if (
                    this.s2ec.user.is_superuser ||
                    (this.s2ec.user_role >= this.s2ec.userRoles.manager.code
                    && this.s2ec.user_role > e.code)
                )
                    return true
                return false
            })
        },
        isOperatorOrAbove() {
            return this.s2ec.user_role == this.s2ec.userRoles.operator.code || this.s2ec.user.is_superuser
        },
        isManagerOrAbove() {
            return this.s2ec.user_role >= this.s2ec.userRoles.manager.code || this.s2ec.user.is_superuser
        },
        isIdenticalRoleOrObove(user_role) {
            if (this.s2ec.user_role >= user_role || this.s2ec.user.is_superuser) {
                return false
            }
            return true
        }
    }
};
