import Vue from "vue";
import i18n from "@/i18n/i18n";
import IncidentTypesTags from "../../../incidents/IncidentTypesTags";

export { FeaturesPopup };
import { ImagesOrientation } from "./ImagesOrientation.js";

class FeaturesPopup {

  static #lockPopupOpening = false

  constructor(object) {
    this.viewer = object.viewer;
    this.maplibregl = this.viewer.maplibregl;
    this.layers = object.layers;
    this.map = this.viewer.map;
    this.popupIsDisplay = false;
    this.zoomLevel = object.zoomLevel;
    this.popup = null;

    this.build();
  }

  build() {
    this.layers.forEach((element) => {
      this.setPopup(element);
    });

    this.viewer._eventConstructor("featurespopup-load", null);
  }

  static lockPopupOpening() {
    this.#lockPopupOpening = true
  }

  static unlockPopupOpening() {
    this.#lockPopupOpening = false
  }

  static isPopupOpeningLocked() {
    return this.#lockPopupOpening === true
  }

  setPopup(element) {
    var layerId = element.layerId;

    let timeout;
    let clickCount = 0;
    let delay = 250;

    this.map.on("click", layerId, (e) => {
      if (FeaturesPopup.isPopupOpeningLocked())
        return
      FeaturesPopup.lockPopupOpening()

      var feature = e.features[0];
      ++clickCount;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        let needZoom = clickCount == 2 ? true : false
        this.addPopupToMap(feature, layerId, needZoom);
        clickCount = 0;
        FeaturesPopup.unlockPopupOpening()
      }, delay);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    this.map.on("mouseenter", element.layerId, () => {
      this.map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    this.map.on("mouseleave", element.layerId, () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  addPopupToMap(feature, layerId, needToZoom, zoomLevel=15) {
    
    if (this.popupIsDisplay == true)
      return

    const coordinates = this.getCoordinatesFromFeature(feature)
    const popupContainer = `<div id="unique-feature-popup" style="height:${this.viewer.mapHeight / 2 - 70}px"></div>`;
    const markerHeight = 25;
    const popupOffsets = {
      top: [0, markerHeight],
      bottom: [0, -markerHeight],
    };

    this.popup = new this.maplibregl.Popup({ offset: popupOffsets });
    this.popup.setHTML(popupContainer);

    if (needToZoom == true) {
      if (this.map.getZoom() < zoomLevel) {
        this.map.once("moveend", () => {this.createPopup(layerId, feature, coordinates)});
        this.map.flyTo({
          center: coordinates,
          zoom: zoomLevel,
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      }
    }
    else {
      this.createPopup(layerId, feature, coordinates)
    }
  }

  getCoordinatesFromFeature(feature) {
    const coordinates = [feature.properties.lng, feature.properties.lat];
    while (Math.abs(feature.properties.lng - coordinates[0]) > 180)
      coordinates[0] += feature.properties.lng > coordinates[0] ? 360 : -360;
    return coordinates
  }

  createPopup(layerId, feature, coordinates) {
    this.popup.setLngLat(coordinates);

    this.popup.addTo(this.map);
    document.getElementById("unique-feature-popup").style.height = "unset";
    this.popupIsDisplay = true;

    this.popup.on("close", () => {
      this.viewer._eventConstructor("popup-close", null);
      this.popupIsDisplay = false;
    });

    const featurePopupContainer = document.getElementById("unique-feature-popup")
    const popupContent = this.getDefaultPopupContent(layerId, feature.properties)
    
    featurePopupContainer.replaceChildren(popupContent);

    if (layerId == "devices") {
      this.fixAvatarSize();
    }

    this.viewer._eventConstructor("popup-open", null);
  }
  
  fixAvatarSize() {
    var orientation = new ImagesOrientation().getOrientation(
      document.getElementById("avatar-src")
    );

    orientation.then((value) => {
      if (value == "landscape") {
        document.getElementById("avatar-src").classList.add("landscape");
      } else if (value == "portrait") {
        document.getElementById("avatar-src").classList.add("portrait");
      } else {
        document.getElementById("avatar-src").classList.add("square");
      }
    });
  }

  getDefaultPopupContent(layerId, properties) {
    var container = document.createElement("div");

    var template;
    var title;
    var unclusteredLayers = [
      "main-infracluster-unclustered",
      "main-cluster-unclustered",
    ];

    if (unclusteredLayers.includes(layerId)) {
      title = properties.title;
    } else {
      title = layerId;
    }
    const popupMaxHeight = `${this.viewer.mapHeight / 2 - 110}px`

    if (title === "sites") {
      template = this.createSitePopupContent(popupMaxHeight, properties)
    }
    else if (title === "devices") {
      template = this.createDevicePopupContent(popupMaxHeight, properties)
    }
    else if (title === "incidents") {
      template = this.createIncidentPopupContent(popupMaxHeight, properties)
    }
    else if (title === "poi") {
      template = this.createPoiPopupContent(popupMaxHeight, properties)
    }

    container.innerHTML = template;
    return container;
  }

  createSitePopupContent(popupMaxHeight, properties) {
    const baseSiteUrl = `${this.viewer.apiLocation}/images/symbologie/base/base-sites.svg`
    const template = `
      <div>
        <div class="nav-popup-header" >
          <div style="display:flex">
            <img
              style="height:20px; margin-left:15px;border:none;"
              src="${baseSiteUrl}"
            >
            <span style="padding-left:0px" class="ellipse-220">${properties["name"]}</span>
          </div>
        </div>
        <div class="popup-content" style="max-height:${popupMaxHeight}">
          <div class="btn flex margin">
            <span class="key-title">Client</span>
            <span class="value-title">${properties["client_name"]}</span>
          </div>
          <div class="btn flex margin">
            <span class="key-title">${i18n.t("Address")}</span>
            <span class="value-title">${properties["place_query_search"]}</span>
          </div>
          <div class="btn flex margin">
            <span class="key-title">${i18n.t("Location")}</span>
            <span class="value-title">${properties["city"]}</span>
          </div>
        </div>
      </div>
    `;

    return template
  }

  createDevicePopupContent(popupMaxHeight, properties) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const readableDate = new Date(properties["latest_device_track.updated_at"]).toLocaleDateString(undefined, options);
    const avatarUrl = `${this.viewer.apiLocation}/images/avatar.svg`
    const baseSiteUrl = `${this.viewer.apiLocation}/images/symbologie/base/base-devices.svg`

    const template = `
      <div>
        <div class="nav-popup-header">
          <div style="display:flex">
            <img
              style="height:20px; margin-left:15px;border:none;"
              src="${baseSiteUrl}"></img>
            <span class="ellipse-220">${properties["user_value.full_name"]} </span>
          </div>
        </div>
        <div style="display: flex;" >
          <div class="avatar-container">
            <div class="avatar-overflow" style="background-image: url('${avatarUrl}')">
              <img class="user-avatar" id="avatar-src" src="${properties["user_value.avatar"]}">
            </div>
            <!--<span class="btn">Send sms</span>-->
          </div>
          <div class="popup-content" style="max-height:${popupMaxHeight}">
            <div class="btn flex margin">
              <span class="key-title" style="width : 124px;">${i18n.t("Client")}</span>
              <span class="value-title" >${properties["user_value.client_name"]}</span>
            </div>
            <div class="btn flex margin">
              <span class="key-title">${i18n.t("Date")}</span>
              <span class="value-title" >${readableDate}</span>
            </div>
            <div class="btn flex margin">
              <span class="key-title">${i18n.t("Location")}</span>
              <span class="value-title" >${properties["latest_device_track.city"]}</span>
            </div>
            <div class="btn flex margin">
              <span class="key-title">${i18n.t("Phone")}</span>
              <span class="value-title">${properties["user_value.cellphones[0].number"]}</span>
            </div>
            <div class="btn flex margin">
              <span class="key-title">${i18n.t("Latitude")}</span>
              <span class="value-title">${properties["latest_device_track.latitude"]}</span>
            </div>
            <div class="btn flex margin">
              <span class="key-title">${i18n.t("Longitude")}</span>
              <span class="value-title">${properties["latest_device_track.longitude"]}</span>
            </div>
          </div>
        </div>
      </div>
    `;

    return template
  }

  createIncidentPopupContent(popupMaxHeight, properties) {
    const incidentTypesValues = [];
    for (let i = 0; i < 3; i++) {
      if (properties.hasOwnProperty(`incident_types_values[${i}].id`)) {
        incidentTypesValues.push({
          id: properties[`incident_types_values[${i}].id`],
          name: properties[`incident_types_values[${i}].name`],
          hex_color: properties[`incident_types_values[${i}].hex_color`],
        });
      }
    }
    const incidentTypesTags = new Vue({
      methods: IncidentTypesTags.methods,
      render: (h) =>
        h(IncidentTypesTags, { props: { incidentTypesValues } }),
    });
    const incidentTagsHTML = incidentTypesTags.generateHTML();
    const flagUrl = `${this.viewer.apiLocation}/images/flags/4x3/${properties["incident_locations[0].country"].toLowerCase()}.svg`
    const template = `
      <div>
        <div class="nav-popup-header" >
          <div style="margin: auto; padding: 15px;">
            <img src="${flagUrl}">
            <span>${properties["country_name"]}</span>
          </div>
        </div>
        <div class="popup-content" style="max-height:${popupMaxHeight}">
            <div style="display:flex">
                <span class="btn tag">${properties["incident_date"]}</span>
            </div>
            <div class="incidentTags">${incidentTagsHTML}</div>
            <div class="bold">
              <p>${properties["short_description"]}</p>
            </div>
            <div>
              <p>${properties["long_description"]}</p>
            </div>
            <div class="bold">
              <p>${properties["recommendations"]}</p>
            </div>
        </div>
      </div>
    `;
    return template
  }

  createPoiPopupContent(popupMaxHeight, properties) {
     const baseSiteUrl = `${this.viewer.apiLocation}/images/symbologie/base/base-poi.svg`
    const template = `
      <div>
        <div class="nav-popup-header">
          <div style="display:flex">
            <img
              style="height:20px; margin-left:15px;border:none;"
              src="${baseSiteUrl}"></img>
            <span class="ellipse-220">${properties["name"]}</span>
          </div>
        </div>
        <div class="popup-content" style="max-height:${popupMaxHeight}">
          <div class="btn flex margin">
            <span class="key-title">${i18n.t("Address")}</span>
            <span class="value-title" >${properties["place_query_search"]}</span>
          </div>
          <div class="btn flex margin">
            <span class="key-title">${i18n.t("Location")}</span>
            <span class="value-title" >${properties["city"]}</span>
          </div>
          <div class="btn flex margin">
            <span class="key-title">${i18n.t("Phone")}</span>
            <span class="value-title" >${properties["landline"]}</span>
          </div>
          <div class="btn flex margin">
            <span class="key-title">${i18n.t("Emergencies")}</span>
            <span class="value-title" >${properties["emergency_contact"]}</span>
          </div>
        </div>
      </div>
    `;
    return template
  }
}
