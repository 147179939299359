import qs from 'qs';
import axios from "axios"
import defaults from "axios/lib/defaults"
const cachios = require('cachios');

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'XCSRF-TOKEN';


function getLocalAuthSession() {
    const auth_data = localStorage.getItem('auth_data');
    if (auth_data) {
        return JSON.parse(auth_data);
    } else {
        return {}
    }
}

export const clientId = process.env.VUE_APP_AUTH2_CLIENT_ID;
export const clientSecret = process.env.VUE_APP_AUTH2_CLIENT_SECRET;
export const clientGrant = process.env.VUE_APP_AUTH2_GRANT_TYPE;


let realAxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 30000,  // HTTP request timeout
    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'}),
    transformRequest: [function (data, headers) {
        // console.log("CALLING transformRequest", data, headers);
        if (!headers["Authorization"]) {
            /* Set default Authorization token if available */
            let access_token = getLocalAuthSession().access_token;
            if (access_token) {
                headers["Authorization"] = `Bearer ${access_token}`;
            }
        }
      headers["Accept-Language"] = localStorage.getItem('selected_language') || "en";
      return defaults["transformRequest"][0](data, headers);
    }],
});

// Only this one works with Formdata objects
export const uncachedAxiosInstance = realAxiosInstance;

// We set a default caching on Axios instance, to prevent duplicate queries on page load!
export const axiosInstance = cachios.create(realAxiosInstance, {
  // We assume that all requests for a page loading fire in this interval, Do not allow too much caching,
  // else incoherences will happen with fast "read -> update -> read"
  stdTTL: 3,
  checkperiod: 120,  // Periodic checkup of cached data
});

export const TILE_TTL = 300;  // Specific TTL for map tiles in seconds
