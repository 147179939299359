<template>
  <div
    class="confirmation-display"
    :class="[extraClass]"
  >
    <p
      v-if="iconType"
      class="text-center"
    >
      <Icon
        :type="iconType"
        size="50"
        class="confirm-warning-icon"
        :color="iconColor"
      />
    </p>
    <h3 :class="[method!=='delete'?'medium-text':'','text-center']">
      {{ extraTitle }}
    </h3>
    <div
      class="text-center confirmation-message"
      v-html="message"
    />
    <div class="confirmation-action">
      <Row :gutter="20">
        <i-col :span="method==='delete'? 24:12">
          <Button
            long
            type="success"
            size="large"
            class="confirm-button"
            @click="triggerDelete"
          >
            {{ okText ? okText: extraTitle }}
          </Button>
        </i-col>
        <i-col :span="method==='delete'? 24:12">
          <Button
            long
            :type="method==='delete'? 'error':'info'"
            :ghost="method!=='delete'"
            size="large"
            @click="triggerCancel"
          >
            {{ cancelText }}
          </Button>
        </i-col>
      </Row>
    </div>
    <Spin
      v-if="loading"
      fix
    />
  </div>
</template>

<script>

    import i18n from '@/i18n/i18n'

    export default {
        name: "AppConfirm",
        props: {
            'loadingEnabled': {default: true},
            'method': {default: 'delete'},
            "message": {default: i18n.t('You are about to delete this item. Are you sure you want to continue?')},
            "extraTitle": {default: i18n.t('Delete item')},
            "iconType": {default: "md-warning"},
            "iconColor": {default: "#E53935"},
            okText: {default: null},
            cancelText: {default: i18n.t('Cancel')},
          extraClass: {default: null}
        },
        data() {
            return {
                loading: false
            }
        },
        computed: {},
        methods: {
            triggerDelete() {
                if (this.loadingEnabled) {
                    this.loading = true;
                }
                this.$emit('onConfirm');
            },
            triggerCancel() {
                this.$emit('onCancel');
                this.$AppModal.remove()
            }
        }
    }
</script>

<style>
    .confirmation-display > * {
        margin-bottom: 14px;
    }

    .confirmation-display {
        padding: 20px 40px 0 40px;
    }

    .confirmation-display .confirmation-message {
        font-size: 14px;
        padding: 0 40px;
    }


    .confirmation-display .confirmation-action {
        margin-top: 20px;
    }

    .confirmation-display .confirmation-action button:first-of-type {
        margin-bottom: 10px;
    }
    .medium-text{
        font-weight: 500;
        font-size: 24px;
    }
</style>
