<template>
  <i-Select
    :placeholder="$t('Select client')"
    transfer
    clearable
    filterable
    size="large"
    @on-change="$emit('clientSelected')"
    v-model="value.client"
  >
    <Option
      v-for="client in clients"
      :key="client.id"
      :value="client.id"
      :label="client.client_name"
    />
  </i-Select>
</template>

<script>
/* FIXME MOVE THIS FILE TO PROPER PLACE */

export default {
  name: "ClientSelector",
  props: {
    clients: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
    },
  },
  created() {},
  methods: {},
};
</script>

<style scoped></style>
