<template>
  <AppModal
    v-model="value"
    :header-name="$t('Invite user')"
    :footer-hide="false"
    @click:submit="submitForm"
    @click:cancel="cancel"
    width="571"
    class="invite-modal"
  >
    <Row class="mt-3">
      <p class="mt-3">{{ $t(`Enter the email address of the user you wish to invite`) }}</p>
      <i-col :md="17" class="mt-3">
        <Form ref="emailForm" :model="form" :rules="infoRules" @submit.native.prevent="addTempUsers()">
          <FormItem prop="email" :error="infoErrors.email">
            <Input type="text" :placeholder="$t('Email')" v-model="form.email"></Input>
          </FormItem>
        </Form>
      </i-col>
      <i-col :md="4">
        <button class="action-button-secondary ml-3 mt-3" @click="addTempUsers()">{{ $t("Add") }}</button>
      </i-col>
    </Row>
    <Row class="mt-5 mb-5">
      <div v-for="(user, idx) in form.users" :key="user.id">
        <Row class="mt-3">
          <i-col :md="10">
            <p class="mt-3">{{ user.email }}</p>
          </i-col>
          <i-col :md="5">
            <Input
              type="text"
              style="border: none !important"
              :placeholder="$t('Ref UID')"
              v-model="user.referenceUid"
            ></Input
          ></i-col>
          <i-col :md="5">
            <Select v-model="user.userRole" class="ml-4">
              <i-option v-for="invitableRole in userRoles" :key="invitableRole.code" :value="invitableRole.code">
                {{ invitableRole.label }}
              </i-option>
            </Select>
          </i-col>
          <i-col :md="4">
            <Icon
              type="md-trash"
              class="ml-5 mt-3 icon-click"
              color="#959FB3"
              size="20"
              @click="removeUser(idx)"
              :title="$t('Remove user')"
            />
          </i-col>
        </Row>
      </div>
    </Row>
    <Form @submit.native.prevent="submitForm">
      <input type="submit" hidden />
      <!-- FIXME: make clean Press Enter submit handling -->
      <Spin v-if="loading" fix />
    </Form>
  </AppModal>
</template>

<script>
import AppModal from "@/components/common/AppModal";
import { postInviteUserQry } from "@/services/auth.service";
import { buildRequiredRules } from "@/helpers/common.helper";

export default {
  name: "InviteUser",

  components: {
    AppModal,
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    client: {
      required: true,
      type: Object,
    },
    contract: {
      required: true,
      type: Object,
    },
  },
  data() {
    const checkEmail = (rule, value, callback) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //Basic email regex pattern.
      if (emailRegex.test(value)) {
        const domain = value.split("@")[1];
        this.contract.contract_access_restrictions.forEach((access) => {
          if (domain.endsWith(access.domain_or_email)) {
            callback();
          } else {
            let domains = this.getDomains();
            let domainesStr = domains.join(", ");
            callback(this.$t("The domain name must be ") + domainesStr);
          }
        });
        callback()
      } else {
        callback(this.$t("Email format is not valid"));
      }
    };
    return {
      form: {
        users: [],
        email: "",
        referenceUid: "",
        userRole: 1,
      },
      infoErrors: {
        email: "",
      },
      infoRules: {
        email: [{ validator: checkEmail, trigger: "blur" }],
      },
      loading: false,
      formRules: {}, // Autofilled in created()
    };
  },
  computed: {
    userRoles() {
      return this.getInvitableUserRoles();
    },
    defaultRole() {
      return this.s2ec.userRoles.traveler;
    },
  },
  created() {
    this.form.userRole = this.defaultRole.code;
    this.formRules = buildRequiredRules(this.form);
    delete this.formRules["referenceUid"]; // Keep this one optional
  },
  methods: {
    getDomains() {
      let tempDomain = [];
      this.contract.contract_access_restrictions.forEach((access) => {
        tempDomain.push(access.domain_or_email);
      });
      return tempDomain;
    },
    addTempUsers() {
      let user = {
        email: this.form.email,
        referenceUid: "",
        userRole: 1,
      };
      this.$refs["emailForm"].validate((valid) => {
        if (valid) {
          let exist = false;
          this.form.users.forEach((userList) => {
            if (userList.email.includes(user.email)) {
              exist = true;
            }
          });
          if (!exist) {
            this.form.users.push(user);
          }
          this.form.email = "";
        }
      });
    },
    removeUser(idx) {
      this.form.users.splice(idx, 1);
    },
    cancel() {
      this.$emit("input", false);
      this.$refs["emailForm"].resetFields();
      this.form.users = [];
      this.loading = false;
    },
    submitForm() {
      if (this.form.users.length > 0) {
        this.triggerInviteUser();
      }
    },
    triggerInviteUser() {
      this.loading = true;
      this.form.users.forEach((user) => {
        const data = {
          message: "invite",
          email: user.email,
          client: this.client.id,
          contract: this.contract.id,
          user_role: user.userRole,
          reference_uid: user.referenceUid,
        };
        postInviteUserQry(data)
          .then(() => {
            this.$Message.success(this.$t("Successfully invited") + ` ${data.email}`);
            this.cancel();
            this.$emit("refreshUsers");
          })
          .catch((err) => {
            this.loading = false;
            const error = err.response;
            if (error.data.non_field_errors && error.data.non_field_errors[0]) {
              this.$Message.error({ content: error.data.non_field_errors[0], duration: 5 });
            } else {
              this.$Message.error({ content: this.$t("User already exist") + ` ${data.email}`, duration: 5 });
            }
          });
      });
    },
  },
};
</script>

<style scoped>
.ivu-input {
  border: #000000 !important;
}

.ivu-input,
.ivu-input-number,
.ivu-select-selection {
  border: none !important;
}

.divider {
  height: 1px;
  width: 100%;
  background: #bab7cc;
  margin-top: 47px;
  border-radius: 50px;
}

.icon-click {
  cursor: pointer;
  padding: 5px;
  transition: all 0.2s ease-in-out;
  margin: 5px;
  margin-left: 5px;
  margin-left: 20px;
}

.icon-click:hover {
  background-color: var(--hover-icon);
  padding: 5px;
  border-radius: 5px;
}
</style>
