<template>
  <div id="map-viewer-container" class="full-height general-overview-map">
    <Devices
      v-if="s2ec.user.is_superuser || s2ec.user.is_admin || s2ec.user.is_manager"
      v-model="devices"
      @deviceClick="goToDevice"
    />
    <div
      id="geotics-viewer"
      style="height: 100%; width: 100%;"
    >
      <plugin id="risk-plugins" v-pre />
      <ControlButtons :mapViewer="mapViewer"/>
    </div>
  </div>
</template>

<script>
import Devices from "./devices/Devices"
import ControlButtons from "./layersControl/ControlButtons"
import { Viewer } from './lib/class/Viewer.js'
import { DataGetter } from './lib/class/DataGetter.js'

export default {
  name: "MapViewer",
  components: {
    Devices,
    ControlButtons
  },
  data() {
    return {
      mapViewer: {},
      dataGetter: {},
      devices: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      incidents: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      pois: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      sites: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      zonesOfInterest: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
    }
  },
  created() {
    this.dataGetter = new DataGetter()
  },
  mounted() {
    console.log("--------------------------");
    console.log(this.s2ec.user);
    this.mapViewer = new Viewer('geotics-viewer');
    var generalOverviewContent = document.getElementsByClassName('general-overview-content')[0]
    var deviceContent = document.getElementsByClassName('general-overview-device-content')[0]

    // We dynamically override the .general-overview-device-content class height
    if(deviceContent != undefined) {
      deviceContent.style.height = generalOverviewContent.clientHeight - 300 + "px";
    } 

    this.mapViewer.events.addEventListener("map-load", () => {this.triggerLoadMap()})
  },
  methods: {
    triggerLoadMap() {
      /*
       * The function set dirty events for features loading (sites, risks, incidents, etc..)
       */
      if (this.dataGetter.geoJsonCollection['sites']) {
        this.mapViewer.addSites(this.dataGetter.geoJsonCollection['sites'])
      } else {
        this.dataGetter.events.addEventListener("sites-load", (e) => {
          var geoJson = e.detail
          this.mapViewer.addSites(geoJson)
        })
      }

      if (this.dataGetter.geoJsonCollection['risks']) {
        this.mapViewer.addRisk(this.dataGetter.geoJsonCollection['risks'])
      } else {
        this.dataGetter.events.addEventListener("risks-load", (e) => {
          var geoJson = e.detail
          this.mapViewer.addRisk(geoJson)
        })
      }

      if (this.dataGetter.geoJsonCollection['incidents']) {
        this.mapViewer.addIncidents(this.dataGetter.geoJsonCollection['incidents'])
        this.mapViewer.addIncidentsGeofencing(this.getGeofencingIncidentGeojson(this.dataGetter.geoJsonCollection['incidents']))
      } else {
        this.dataGetter.events.addEventListener("incidents-load", (e) => {
          var geoJson = e.detail
          this.mapViewer.addIncidents(geoJson)
          this.mapViewer.addIncidentsGeofencing(this.getGeofencingIncidentGeojson(geoJson))
        })
      }

      if (this.dataGetter.geoJsonCollection['devices']) {
        this.mapViewer.addDevices(this.dataGetter.geoJsonCollection['devices'])
      } else {
        this.dataGetter.events.addEventListener("devices-load", (e) => {
          var geoJson = e.detail
          this.mapViewer.addDevices(geoJson)
        })
      }

      if (this.dataGetter.geoJsonCollection['points-of-interest']) {
        this.mapViewer.addPoi(this.dataGetter.geoJsonCollection['points-of-interest'])
      } else {
        this.dataGetter.events.addEventListener("points-of-interest-load", (e) => {
          var geoJson = e.detail
          this.mapViewer.addPoi(geoJson)
        })
      }

      if (this.dataGetter.geoJsonCollection['danger-zones']) {
        this.mapViewer.addZonesOfInterest(this.dataGetter.geoJsonCollection['danger-zones'])
      } else {
        this.dataGetter.events.addEventListener("danger-zones-load", (e) => {
          var geoJson = e.detail
          this.mapViewer.addZonesOfInterest(geoJson)
        })
      }
      // check if query come from notification
      if (this.$route.query.id && this.$route.query.location) {
        let device = {
          id: this.$route.query.id,
        };
        this.goToDevice(device);
      }
    },

    getGeofencingIncidentGeojson(incidentGeojson) {
      /*
       * Util function for incidents geofencing rendering
       */
      var geofencingFeatures = []
      incidentGeojson.features.forEach(feature => {
        if (feature.properties['incident_locations[0].affected_radius']) {
          var radiusInKm = feature.properties['incident_locations[0].affected_radius'] / 1000
          geofencingFeatures.push(this.getGeofencingFeature(feature.geometry.coordinates, radiusInKm, 30))
        }
      });

      return {
        "type": "FeatureCollection",
        "features": geofencingFeatures
      }
    },

    getGeofencingFeature(center, radiusInKm, points) {
      if (!points) points = 64;

      var coords = {
        latitude: center[1],
        longitude: center[0]
      };
      var km = radiusInKm
      var ret = [];
      var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
      var distanceY = km / 110.574;

      var theta, x, y;
      for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);
        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);

      return {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [ret]
        }
      };
    },
    goToDevice(device) {
      /*
       * Flies to device on clicking in geolocalisation, depends on featuresPopup "devices"
       */
      try {
        this.mapViewer.featuresPopup.popup.remove()
      } catch (error) {
        //FIXME Silent error
        console.log(error)
      }

      var queryFeatures = this.mapViewer.QueryFeatures.query({
        viewer: this.mapViewer,
        key: "id",
        operator: "==",
        values: [device.id.toString()],
        layerId: "devices"
      })

      queryFeatures.then((result) => {

        this.mapViewer.map.once('moveend', (params) => {
          console.log("Calling addPopupToMap from GeneralOverviewMap.vue -> moveend");
          this.mapViewer.featuresPopup.addPopupToMap(result[0][0], "devices", false)
        })

        this.mapViewer.map.flyTo({
          zoom: 12,
          center: result[0][0].geometry.coordinates,
          essential: true
        })

      })
    },
  }
}
</script>

<style>
@import url('./lib/styles/style.css');

iframe#launcher {
  left: 10px;
  right: unset;
}

#map-plugins {
  position: absolute;
  top: 0px;
  right: 0px;

}

#risk-plugins {
  position: absolute;
  bottom: 0px;
}

#mini-map-plugins {
  position: absolute;
  bottom: 25px;
  left: 0px;
  width: auto;
  display: inline-block;
}

.general-overview-map {
  position: relative;
}

.general-overview-marker-info .marker-info-title img {
  width: 22px;
}

.general-overview-marker-info .marker-info-title {
  display: flex;
  align-items: center;
}

.general-overview-marker-info .marker-info-title label {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
}

.general-overview-marker-info .marker-info-content {
  margin-top: 8px;
}

.general-overview-marker-info .marker-info-content>div {
  margin-top: 6px;
}

.general-overview-marker-info .marker-info-content>div label {
  font-weight: 600;
  margin-right: 5px;
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

img[src*="emergency"] {
  -moz-animation: blink normal 1s infinite ease-in-out;
  /* Firefox */
  -webkit-animation: blink normal 1s infinite ease-in-out;
  /* Webkit */
  -ms-animation: blink normal 1s infinite ease-in-out;
  /* IE */
  animation: blink normal 1s infinite ease-in-out;
  /* Opera and prob css3 final iteration */
}
</style>
