export const defaultLocation = {
    id: null,
    place_query_search: "",
    country: "",
    address: "",
    city: "",
    latitude: null,
    longitude: null,
    affected_radius: null,
};
