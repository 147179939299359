<template>
  <GmapAutocomplete
    ref="GmapAutocomplete"
    :readonly="readonly"
    class="ivu-input"
    :placeholder="$t('Location ( Google search )')"
    @place_changed="locationSearched"
  />
</template>

<script>
    import {round} from "../../helpers/common.helper";

    export default {
        name: "AppGoogleSearch",
        props: {readonly: {default: false}, 'value': {default: ""}},
        data() {
            return {
                watchedFields: {country: 'country', locality: 'city'},
            }
        },
        watch:{
            value(value){
                this.$refs["GmapAutocomplete"].$refs.input.value = value;
            }
        },
        mounted() {
            this.$refs["GmapAutocomplete"].$refs.input.value = this.value;
        },

        methods: {
            locationSearched(place) {
                console.log(">>>>>> AppGoogleSearch.vue locationSearched", place);
                const extractedInformation = {};
                extractedInformation["place_query_search"] = this.$refs["GmapAutocomplete"].$refs.input.value;
                extractedInformation["address"] = place.formatted_address.split(",").slice(0, -2).join(", ");
                extractedInformation["latitude"] = round(place.geometry.location.lat(), 7);
                extractedInformation["longitude"] = round(place.geometry.location.lng(), 7);

                let structuredAddress = place.address_components.reduce(function (current, array) {
                    for (const itemType of array.types) {
                      current[itemType] = {long_name: array.long_name, short_name: array.short_name};
                    }
                    return current
                }, {});

                Object.keys(this.watchedFields).forEach((field) => {
                    let data = null;
                    if (structuredAddress.hasOwnProperty(field)) {
                        if (field === "country") {
                            data = structuredAddress[field].short_name;
                        } else {
                            data = structuredAddress[field].long_name;

                        }
                    }

                    extractedInformation[this.watchedFields[field]] = data;
                });

                this.$emit("searched", extractedInformation);
            },
            setInput(value) {
                this.$refs["GmapAutocomplete"].$refs.input.value = value;
            }
        }
    }
</script>

<style scoped>

</style>
