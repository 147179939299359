<template>
  <div>
    <Button
      v-if="!showFilters"
      type="success"
      class="s2ec-filter-button"
      @click="showFilters = true"
    >
      <Icon
        type="md-funnel"
        size="18"
      />
      {{ $t('Filter') }}
    </Button>

    <Row
      v-else
      type="flex"
      :gutter="10"
      align="middle"
    >
      <i-col
        :sm="24"
        :md="8"
      >
        <Row :gutter="10">
          <i-col
            :sm="24"
            :md="12"
          >
            <AppDatePicker
              v-model="filterData.updated"
              :placeholder="$t('Date updated')"
              type="date"
            />
          </i-col>
          <i-col
            :sm="24"
            :md="12"
          >
            <i-input
              v-model="filterData.title"
              :placeholder="$t('Title')"
            />
          </i-col>
        </Row>
      </i-col>
      <i-col
        :sm="12"
        :md="3"
      >
        <Button
          type="primary"
          long
          size="small"
          class="confirm-button"
          @click="triggerApply"
        >
          {{ $t('Apply') }}
        </Button>
      </i-col>
      <i-col
        :sm="12"
        :md="3"
      >
        <Button
          type="warning"
          long
          size="small"
          @click="triggerReset"
        >
          {{ $t('Reset') }}
        </Button>
      </i-col>
    </Row>
  </div>
</template>

<script>
    import AppDatePicker from "@/components/common/AppDatePicker"
    export default {
        name: "AnalysisFilter",
        components: {
            AppDatePicker
        },
        data() {
            return {
                showFilters: false,
                deviceStatuses: [
                    {value: "true", label: "On"},
                    {value: "false", label: "Off"},
                ],
                filterData: {
                    updated: null,
                    title: null
                }
            }
        },
        methods: {
            triggerApply() {
                this.$emit('applyFilter', this.filterData)

            },
            triggerReset() {
                this.resetDataName(this, "filterData");
                this.showFilters = false;
                this.$emit('applyFilter', this.filterData)
            }
        }
    }
</script>

<style scoped>

</style>