import Dashboard from "@/views/Dashboard"
import CountryInformation from "@/views/countryInfo/CountryInformation"
import Sites from "@/views/sites/Sites"
import Alerts from "@/views/alerts/Alerts"
import Incidents from "@/views/incidents/Incidents"
import Accounts from "@/views/accounts/Accounts"
import User from "@/views/accounts/contracts/users/Users"
import ClientContract from "@/views/accounts/contracts/ClientContract"
import Profile from "@/views/profile/Profile"
import MissionsManagement from "@/views/missions/MissionsManagement"
import SelectCountry from "@/views/countryInfo/SelectCountry"  // FIXME WHY IS THIS HERE ?
import CountryInfoDetail from "@/views/countryInfo/CountryInfoDetail"
import Notifications from "@/views/notifications/Notifications"
import Map from "@/views/worldMap/Map"

export default {
    path: '',
    component: Dashboard,
    children: [
        {
            path: 'country-information',
            component: CountryInformation,
            children: [
                {
                    path: '',
                    name: 'country-information',
                    component: SelectCountry,
                    meta: {auth: true},
                },
                {
                    path: ':country',
                    name: 'country-info-detail',
                    component: CountryInfoDetail,
                    meta: {auth: true},
                }
            ],
        },
        {
            path: '',
            component: Map,
            name: 'map',
            meta: {auth: true}
        },
        {
            path: 'missions-management',
            component: MissionsManagement,
            name: 'missions-management',
            meta: {auth: true}
        },
        {
            path: 'sites',
            component: Sites,
            name: 'sites',
            meta: {auth: true, is_admin: true, is_manager: true, is_head_of_site: true, is_superuser: true},
        },
        {
            path: 'alerts',
            component: Alerts,
            name: 'alerts',
            meta: {auth: true, is_admin: true, is_manager: true, is_superuser: true},
        },
        {
            path: 'incidents',
            component: Incidents,
            name: 'incidents',
            meta: {auth: true, is_admin: true, is_superuser: true},
        },
        {
            path: 'accounts',
            component: Accounts,
            name: 'accounts',
            meta: {auth: true},
        },
        {
            path: 'accounts/:id/contracts',
            component: ClientContract,
            name: 'contracts',
            meta: {auth: true},
        },
        {
            path: 'accounts/:id/contracts/:contractId',
            component: User,
            name: 'users',
            meta: {auth: true},

        },
        {
            path: 'accounts/:id/contracts/:contractId/profile/:userId',
            component: Profile,
            name: 'profile',
            meta: {auth: true},
        },
        {
            path: 'notifications',
            component: Notifications,
            name: 'notifications',
            meta: {auth: true},
        },
    ]
}
