<template>
  <AppArrowedPanel
    :title="title"
    :panel="panelType()"
    :show-arrow="showArrow"
  >
    <div class="emergency-content">
      <Card
        v-for="(emergency, idx) in emergencies.results"
        :key="emergency.id"
        :dis-hover="true"
        class="emergency-item card-list"
      >
        <div class="emergency-inner-content">
          <p class="name">
            {{ emergency.user_value | userDisplay }}
          </p>
          <p class="thin-text">
            {{ emergency.user_value.cellphone || $t('(No Phone)') }}
          </p>
          <p class="thin-text">
            {{ emergency.address || $t('(No Address)') }} <!-- FIXME DISPLAY CITY AND COUNTRY TOO? -->
          </p>
          <p class="thin-text">
            {{ fullDateFormat(emergency.created) }}
          </p>
        </div>
        <div class="emergency-item-action">
          <Icon
            v-if="!allowManage"
            type="md-create"
            size="20"
            :title="$t('Edit')"
            class="pointer-cursor"
            color="#000747"
            @click.native="triggerManage(emergency, idx, allowManage)"
          />
          <img
            src="../../assets/images/ico/location.png"
            class="pointer-cursor"
            alt=""
            @click="navigateLocation(emergency)"
          >
          <Poptip
            placement="right-start"
            transfer
            popper-class="emergency-profile"
            width="450"
          >
            <img
              src="../../assets/images/ico/person.png"
              class="pointer-cursor"
              alt=""
            >
            <div slot="content">
              <Card class="emergency-profile text-center">
                <Avatar
                  class="emergency-user-avatar"
                  :src="emergency.user_value.avatar"
                  icon="ios-person"
                />
                <p> {{ emergency.user_value | userDisplay }} </p>
              </Card>

              <Card class="emergency-profile-info">
                <p> {{ $t('Personal information') }} </p>
                <Row>
                  <i-col :md="10">
                    {{ $t('Passport number') }}
                  </i-col>
                  <i-col :md="14">
                    {{ emergency.user_value.passport_number }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('Passport number 2') }}
                  </i-col>
                  <i-col :md="14">
                    {{ emergency.user_value.passport_number2 }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('Cellphone') }}
                  </i-col>
                  <i-col :md="14">
                    {{ emergency.user_value.cellphone }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('Landline') }}
                  </i-col>
                  <i-col :md="14">
                    {{ emergency.user_value.landline }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('Address') }}
                  </i-col>
                  <i-col :md="14">
                    {{ emergency.user_value.address }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('City, Country') }}
                  </i-col>
                  <i-col :md="14">
                    {{ emergency.user_value.city }}, {{ emergency.user_value.country }}
                  </i-col>
                </Row>
              </Card>
              <Card class="emergency-profile-info">
                <p> {{ $t('Emergency contact') }} </p>
                <div>
                  <span> {{ emergency.user_value.emergency_contact_first_name }} </span>
                  <span> {{ emergency.user_value.emergency_contact_last_name }}</span>
                </div>
                <div>
                  <span> {{ emergency.user_value.emergency_contact_email }} </span>
                </div>
                <div>
                  <span> {{ emergency.user_value.emergency_contact_landline }} </span>
                </div>
                <div>
                  <span> {{ emergency.user_value.emergency_contact_cellphone }} </span>
                </div>
              </Card>
            </div>
          </Poptip>
        </div>
        <div class="emergency-manager">
          <Button
            v-if="allowManage"
            class="card-red-button"
            size="small"
            @click="triggerManage(emergency, idx)"
          >
            {{ $t('Manage') }}
          </Button>
        </div>
      </Card>
      <infinite-loading
        :identifier="identifier"
        @infinite="onInfiniteScroll"
      >
        <span slot="no-more" />
      </infinite-loading>
    </div>
  </AppArrowedPanel>
</template>

<script>
    import AppArrowedPanel from "@/components/common/AppArrowedPanel"
    import infiniteLoading from "vue-infinite-loading"
    import {getEmergenciesQry} from "@/services/alerts.service";
    import {getStringUrlParams} from "@/helpers/common.helper";
    import DateFormat from "@/mixins/dateFormat"

    export default {
        name: "Emergencies",
        components: {
            AppArrowedPanel,
            infiniteLoading
        },
        mixins: [DateFormat],
        props: {
            emergencyStatus: {default: null},
            showArrow: {default: true},
            title: {default: null},
            allowManage: {default: true}
        },
        data() {
            return {
                identifier: false,
                emergencies: {
                    count: 0,
                    next: null,
                    previous: null,
                    results: []
                }
            }
        },
        created() {
            this.$eventBus.$on("clientContextSelected", this.resetIdentifier);
        },
        beforeDestroy() {
            this.$eventBus.$off("clientContextSelected", this.resetIdentifier);
        },
        methods: {
            onInfiniteScroll($state) {
                let company = this.userProfileStore.getSelectedCompany;
                let getQueryParams = {status: this.emergencyStatus};
                if (this.emergencies.next) getQueryParams = getStringUrlParams(this.emergencies.next);

                getEmergenciesQry(getQueryParams)
                    .then(res => {
                        const {results, ...data} = res.data;
                        Object.assign(this.emergencies, data);
                        this.emergencies.results.push(...results);
                        $state.loaded();
                        if (!this.emergencies.next) {
                            $state.complete();
                        }
                    })
            },

            triggerManage(data, idx, allowManage) {
                this.$emit('triggerEmergencyUpdate', this.emergencyStatus, data, idx, allowManage)
            },

            panelType() {
                switch (this.emergencyStatus) {
                    case 'attempt':
                        return 'default';
                    case 'processing':
                        return 'warning';
                    default:
                        return 'success';
                }
            },

            navigateLocation(emergency) {
                this.$router.push({
                    name: 'map',
                    query: {page:'alerts', location: `${emergency.latitude},${emergency.longitude},12`}
                })
            },

            resetIdentifier() {
                this.resetDataName(this, 'emergencies');
                this.identifier = !this.identifier;
            }
        }
    }
</script>

<style>
    .emergency-content img.pointer-cursor{
        margin-left: 4px;
        margin-right: 4px;
    }
    .emergency-inner-content {
        margin-right: 50px;
    }

    .emergency-inner-content > p:not(:first-of-type) {
        font-size: 12px;
    }

    .emergency-inner-content .name {
        font-weight: 600;
    }

    .emergency-inner-content > p:not(.name) {
        font-size: 10px;
    }

    .emergency-content .emergency-item {
        position: relative;
        border: hidden;
    }

    .emergency-content .emergency-item-action {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .emergency-content .emergency-item-action .ivu-icon {
        position: relative;
        top: -5px;
        margin-right: 5px;
    }

    .emergency-content .emergency-manager {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
    .emergency-profile.ivu-card,.emergency-profile-info.ivu-card{
        margin-bottom: 8px;
    }

    .emergency-profile .ivu-avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        line-height: 80px;
        font-size: 60px;
    }

    .emergency-profile p {
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
    }

    .emergency-profile-info {
        font-size: 10px;
    }

    .emergency-profile, .emergency-profile-info {
        margin-bottom: 10px;
    }

    .emergency-user-avatar.ivu-avatar>img {
        width: auto;
        height: 100%;
    }

</style>
