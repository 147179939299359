<template>
  <div>
    <!----------- [START] TABLE ----------->
    <Table
    :columns="columns"
    :class="tableClass"
    :data="diplayedData"
    :row-class-name="rowClassName"
    :height="height"
    @on-row-click="onRowClick"
    :no-data-text="$t('No data')"
    :loading="loading"
    ></Table>
    <Page v-if="dataLength > pageSize" class="paging" :total="dataLength" :page-size="pageSize" @on-change="changePage"/>
  </div>
</template>

<script>
export default {
    name: "AppTable",
    props: {
        dataTable: {default: null},
        columns: {default: null},
        tableClass: {default: null},
        rowClassName: {default: null},
        height: {default: null},
        loading: {default: false},
    },
    data() {
        return {
            diplayedData: [],
            pageSize: 10,
        }
    },
    watch: {
        dataTable() {
            this.diplayedData = this.dataTable.slice(0, this.pageSize);
        },
    },
    computed: {
        dataLength() {
            return this.dataTable.length
        },
    },
    methods: {
        changePage(index) {
            console.log(index)
            var _start = (index - 1) * this.pageSize;
            var _end = index * this.pageSize;
            this.diplayedData = this.dataTable.slice(_start, _end);
        },
        onRowClick(tableRow, tableRowIndex) {
            this.$emit('on-row-click', tableRow, tableRowIndex)
        }
    }
}
</script>

<style scoped>
.paging {
    float: right;
    margin-top: 10px;
}

.ivu-page-item:hover,
.ivu-page-prev:hover:not(.ivu-page-disabled),
.ivu-page-next:hover:not(.ivu-page-disabled) {
    border-color: var(--secondary-bleu);
}
.ivu-page-item:hover a,
.ivu-page-prev:hover:not(.ivu-page-disabled) a,
.ivu-page-next:hover:not(.ivu-page-disabled) a {
    color: var(--secondary-bleu);
}
.ivu-page-item-active {
    border-color: var(--secondary-bleu);
}
.ivu-page-item-active a {
    color: var(--secondary-bleu);
}
.ivu-page-item {
    height: 30px !important;
    width: 30px !important;
}
</style>
