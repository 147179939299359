<template>
  <Slider
    ref="slider"
    v-model="progressValue"
    :disabled="disabled"
    :min="0"
    :max="5"
    :tip-format="tipData"
    :class="[`progress-${value}`, 'risk-slider-item']"
    show-tip="always"
    :title="title"
  />
</template>

<script>
    export default {
        name: "CountryInfoProgress",
        props: {'value': {default: 0}, disabled: {default: false}},
        data() {
            return {}
        },
        computed: {
            progressValue: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            title: {
                get() {
                    let text = 'No risk';
                    if (this.progressValue === 1) return this.$t('Minimal risk');
                    else if (this.progressValue === 2) return this.$t('Low risk');
                    else if (this.progressValue === 3) return this.$t('Possible risk');
                    else if (this.progressValue === 4) return this.$t('High risk');
                    else if (this.progressValue === 5) return this.$t('Very high risk');
                    else return text;
                }
            },
        },
        methods: {
            tipData() {
                return `${this.value}/5`
            }
        },

    }
</script>

<style>
	.progress-0 .ivu-slider-bar,
	.progress-0 .ivu-tooltip-inner {
		background: none;
	}

	.progress-1 .ivu-slider-bar,
	.progress-1 .ivu-tooltip-inner {
		background: var(--risk-1);
	}

	.progress-2 .ivu-slider-bar,
	.progress-2 .ivu-tooltip-inner {
		background: var(--risk-2);
	}

	.progress-3 .ivu-slider-bar,
	.progress-3 .ivu-tooltip-inner {
		background: var(--risk-3);
	}

	.progress-4 .ivu-slider-bar,
	.progress-4 .ivu-tooltip-inner {
		background: var(--risk-4);
	}

	.progress-5 .ivu-slider-bar,
	.progress-5 .ivu-tooltip-inner {
		background: var(--risk-5);
	}

	.risk-slider-item .ivu-tooltip-content {
		display: flex;
	}

	.risk-slider-item .ivu-tooltip-popper {
		top: -3.625em !important;
	}

	.progress-0.risk-slider-item .ivu-tooltip-content .ivu-tooltip-inner {
		background-color: transparent;
		border-color: transparent;
	}

	.progress-0.risk-slider-item .ivu-tooltip-content .ivu-tooltip-arrow {
		border-top-color: transparent;
	}

	.progress-1.risk-slider-item .ivu-tooltip-content .ivu-tooltip-inner {
		background-color: var(--risk-1);
		border-color: var(--risk-1);
		color: #FFFFFF;
	}

	.progress-1.risk-slider-item .ivu-tooltip-content .ivu-tooltip-arrow {
		border-top-color: var(--risk-1);
	}

	.progress-2.risk-slider-item .ivu-tooltip-content .ivu-tooltip-inner {
		background-color: var(--risk-2);
		border-color: var(--risk-2);
	}

	.progress-2.risk-slider-item .ivu-tooltip-content .ivu-tooltip-arrow {
		border-top-color: var(--risk-2);
	}

	.progress-3.risk-slider-item .ivu-tooltip-content .ivu-tooltip-inner {
		background-color: var(--risk-3);
		border-color: var(--risk-3);
		color: #FFFFFF;
	}

	.progress-3.risk-slider-item .ivu-tooltip-content .ivu-tooltip-arrow {
		border-top-color: var(--risk-3);
	}

	.progress-4.risk-slider-item .ivu-tooltip-content .ivu-tooltip-inner {
		background-color: var(--risk-4);
		border-color: var(--risk-4);
	}

	.progress-4.risk-slider-item .ivu-tooltip-content .ivu-tooltip-arrow {
		border-top-color: var(--risk-4);
	}

	.progress-5.risk-slider-item .ivu-tooltip-content .ivu-tooltip-inner {
		background-color: var(--risk-5);
		border-color: var(--risk-5);
	}

	.progress-5.risk-slider-item .ivu-tooltip-content .ivu-tooltip-arrow {
		border-top-color: var(--risk-5);
	}

	.risk-slider-item .ivu-tooltip-content .ivu-tooltip-inner {
		order: 1;
		border-radius: 50%;
		border: 8px solid var(--secondary-bleu);
		width: 20px;
		height: 20px;
		min-height: 20px;
		background-color: var(--secondary-bleu);
		color: #ffffff;
		display: flex;
		padding: 0;
		justify-content: center;
		align-items: center;
		z-index: 1;
		box-shadow: none;
		font-size: 10px;
	}

	.risk-slider-item .ivu-tooltip-content .ivu-tooltip-arrow {
		order: 0;
		border-top-color: var(--secondary-bleu);
		z-index: 0;
		border-width: 15px 9px 0;
		left: 6px;
		bottom: -1px;
	}

	.risk-slider-item .ivu-slider-button {
		display: none;
	}

	.risk-slider-item.ivu-slider.ivu-slider-disabled,
	.risk-slider-item.ivu-slider-disabled .ivu-slider-wrap {
		cursor: default !important;
	}

	.tooltip {
		text-decoration: none;
		position: relative;
	}

	.tooltip span {
		display: none;
	}

	.tooltip:hover span {
		display: block;
		position: fixed;
		overflow: hidden;
	}

</style>