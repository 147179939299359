<template>
  <div class="layout full-height s2ec-dashboard">
    <Layout class="full-height">
      <Header class="s2ec-header">
        <app-header />
      </Header>
      <Sider
        v-if="showMenu"
        v-model="showMenu"
        hide-trigger
        :width="auto"
        class="s2ec-mobile-bar"
      >
        <app-side-menu :show-menu="showMenu" />
      </Sider>
      <Layout style="overflow-y: hidden">
        <Sider
          v-model="menuCollapsed"
          hide-trigger
          :width="menuWidth"
          collapsible
          :collapsed-width="menuCollapseWidth"
          class="s2ec-side-bar"
          :class="this.$route.name === 'map' ? 'fullscreen-map' : ''"
        >
          <app-side-menu :menu-collapsed="menuCollapsed" />
        </Sider>
        <Layout>
          <i-content
            class="s2ec-content full-height"
            :class="this.$route.name === 'map' ? 'map' : ''"
            v-if="!showMenu"
          >
            <router-view />
          </i-content>
        </Layout>
      </Layout>
    </Layout>
    <TermsAndCondition
      v-model="showTacPopup"
      @accept="acceptTac"
      @tacVersion="storeTacVersion"
    />
  </div>
</template>

<script>
import AppHeader from "../views/header/AppHeader.vue";
import { mapState } from "vuex";
import AppSideMenu from "./AppSideMenu";
import {
  getAuthSession,
  logout,
  updateAuthSessionByKeys,
} from "../helpers/auth.helper";
import { patchAccount } from "@/services/auth.service";
import TermsAndCondition from "./authentification/TermsAndCondition";
import { useNotificationStore } from '../stores/notification';

export default {
  name: "Dashboard",
  components: {
    AppHeader,
    AppSideMenu,
    TermsAndCondition,
    useNotificationStore
  },
  data() {
    return {
      showMenu: false,
      menuCollapsed: false,
      menuCollapseWidth: 80,
      menuWidth: 210,
      showTacPopup: false,
      tacVersion: null,
    };
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  created() {
    let authSession = getAuthSession();

    if (authSession.new) {
      this.$AppModal.AppConfirm({
        loading: true,
        extraTitle: "Complete your profile information",
        message:
          '<p> You can complete your profile information now or skip this step </p> <p> You can always go back and complete your personal information in "Profile" </p>',
        okText: "Go to profile",
        cancelText: "Skip",
        extraClass: "profile-setup",
        iconType: null,
        onConfirm: () => {
          this.updateUserState();
          this.$AppModal.remove();
          this.$router.push({ name: "profile" });
        },
        onCancel: () => {
          this.$AppModal.remove();
          this.updateUserState();
        },
      });
    }
  },
  mounted() {
    this.$connect(
      `${process.env.VUE_APP_WS_PATH}?token=${getAuthSession().access_token}`,
      {
        format: "json",
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000,
      }
    );
    this.$options.sockets.onmessage = (message) => {
      if (message.isTrusted) {
        const response = JSON.parse(message.data);
        if (response.event === "notification") {
          this.$eventBus.$emit("onNotification", response);
        }
      }
    };
    this.$eventBus.$on("showSlide", this.showSlide);
    this.$eventBus.$on("closeMenu", this.closeMenu);
    if (this.$route.name !== 'notifications') { // Skip notification duplicated
      const notificationStore = useNotificationStore()
      notificationStore._infiniteHandler();
    }
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
    },
    showSlide() {
      this.showMenu = !this.showMenu;
    },
    collapsedSider() {
      this.menuCollapsed = !this.menuCollapsed;
    },
    updateUserState() {
      patchAccount({ new: false }).then((res) => {
        updateAuthSessionByKeys({ new: res.data.new });
      });
    },
    storeTacVersion(version) {
      this.tacVersion = version;
      if (version !== getAuthSession().tac_version) {
        this.showTacPopup = true;
      }
    },
    acceptTac(agree) {
      let self = this;
      if (!agree) {
        logout();
        setTimeout(function () {
          self.$router.push({ name: "login" });
        }, 4000); // Wait for warning popup to be read
      } else {
        patchAccount({ tac_version: this.tacVersion });
        updateAuthSessionByKeys({ tac_version: this.tacVersion }); // IMPORTANT, since auth session is kept in local storage...
      }
    },
  },
};
</script>

<style>
.s2ec-header {
  background-color: #ffffff;
  padding: 0 50px 0 20px !important;
  line-height: initial;
}

.s2ec-header .ivu-layout-header .div {
  box-shadow: 8px 8px 8px 8px rgba(112, 86, 240, 1);
}

.fullscreen-map {
  position: absolute;
  z-index: 1;
  height: calc(98.7% - 72px);
}
.s2ec-mobile-bar {
  display: none;
  background-color: #000747;
  margin-bottom: 30px !important;
  margin: auto;
  margin-top: 20px;
  border-radius: 10px 10px 8px 8px;
  box-shadow: 0px 2px 11px 2px;
}

.s2ec-content {
  margin: 20px;
}

.profile-setup .confirmation-message {
  padding: 0 !important;
}

.profile-setup .confirmation-message > p {
  text-align: left;
  margin-bottom: 5px;
}

.map {
  margin: 0px !important;
}


</style>