<template>
  <div id="control-buttons-container">
    <div
      class="card"
      style="width: 100px !important; cursor: revert"
      id="zoom-level"
    >
      <p>Zoom {{ currentZoom }}</p>
    </div>
    <div class="card card-left zoom" id="zoom-control">
      <img class="img-layer" :src="zoomInIconUrl" @click="triggerZoomIn" />
    </div>
    <div class="card card-right zoom">
      <img class="img-layer" :src="zoomOutIconUrl" @click="triggerZoomOut" />
    </div>
    <div
      class="card zoom"
      id="features-control-dropdown"
      @click="showFeaturesControl = !showFeaturesControl"
    >
      <Icon
        :class="showFeaturesControl ? 'show-layer' : ''"
        size="25"
        type="md-funnel"
        style="color: #3c3c3c"
        :title="$t('Layers')"
      />
    </div>
    <Transition name="slide-fade" appear>
      <div
        class="card"
        style="width: 250px !important"
        id="features-control"
        v-if="showFeaturesControl"
      >
        <div
          v-for="featureControl in featuresControl"
          :key="featureControl.layerId"
          class="tooltip"
          :class="currentZoom >= 6 && featureControl.layerId === 'risk' ? 'layer-disabled' : ''"
          @click="triggerFeatureControlActivation(featureControl)"
        >
          <img
            class="img-layer svg zoom"
            fill="#000102"
            :id="featureControl.layerId"
            style="margin-top: 2px"
            :src="
              featureControl.isActive
                ? featureControl.iconUrl
                : featureControl.iconUrlOff
            "
          />
            <span class="tooltiptext">{{ featureControl.label }} </span>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "ControlButtons",
  props: {
    mapViewer: {
      required: true,
      type: Object,
    },
  },
  computed: {
    map() {
      return this.mapViewer.map;
    },
    filteredLayerIds() {
      const filteredFeaturesControl = this.featuresControl.filter(
        (e) => !e.isActive
      );
      return filteredFeaturesControl.map((e) => e.layerId);
    },
    currentZoom() {
      if (!this.mapViewer.map) return 0;
      return Math.round(this.mapViewer.map.getZoom() * 10) / 10;
    },
  },
  data() {
    const resourcesUrl = document.location.origin + "/ressources";

    return {
      zoomControlStep: 3,
      zoomInIconUrl: `${resourcesUrl}/images/zoom-in.svg`,
      zoomOutIconUrl: `${resourcesUrl}/images/zoom-out.svg`,
      dropdownIconUrl: `${resourcesUrl}/images/filter.svg`,
      featuresControl: [
        {
          layerId: "risk",
          label: this.$t("Risk"),
          iconUrlOff: `${resourcesUrl}/images/symbologie/base/base-risk-off.svg`,
          iconUrl: `${resourcesUrl}/images/symbologie/base/base-risk.svg`,
          isActive: true,
        },
        {
          layerId: "devices",
          label: this.$t("Devices"),
          iconUrlOff: `${resourcesUrl}/images/symbologie/base/base-devices-off.svg`,
          iconUrl: `${resourcesUrl}/images/symbologie/base/base-devices.svg`,
          isActive: true,
        },
        {
          layerId: "sites",
          label: this.$t("Sites"),
          iconUrlOff: `${resourcesUrl}/images/symbologie/base/base-sites-off.svg`,
          iconUrl: `${resourcesUrl}/images/symbologie/base/base-sites.svg`,
          isActive: true,
        },
        {
          layerId: "poi",
          label: this.$t("Poi"),
          iconUrlOff: `${resourcesUrl}/images/symbologie/base/base-poi-off.svg`,
          iconUrl: `${resourcesUrl}/images/symbologie/base/base-poi.svg`,
          isActive: false,
        },
        {
          layerId: "incidents",
          label: this.$t("Incidents"),
          iconUrlOff: `${resourcesUrl}/images/symbologie/base/base-incidents-off.svg`,
          iconUrl: `${resourcesUrl}/images/symbologie/base/base-incidents.svg`,
          isActive: true,
        },
        {
          layerId: "geofencing",
          label: this.$t("Geofencing"),
          iconUrlOff: `${resourcesUrl}/images/symbologie/base/base-geofencing-off.svg`,
          iconUrl: `${resourcesUrl}/images/symbologie/base/base-geofencing.svg`,
          isActive: true,
        },
      ],
      showFeaturesControl: false,
    };
  },
  methods: {
    triggerZoomIn() {
      const zoom = this.zoomControlStep;
      this.zoomMap(zoom);
    },
    triggerZoomOut() {
      const zoom = -this.zoomControlStep;
      this.zoomMap(zoom);
    },
    zoomMap(zoom) {
      this.map.flyTo({
        center: this.map.getCenter(),
        zoom: Math.round(this.map.getZoom()) + zoom,
        essential: true,
      });
    },

    triggerFeatureControlActivation(featureControl) {
      const layerId = featureControl.layerId;
      let visibility = "visible";

      if (featureControl.isActive) visibility = "none";

      featureControl.isActive = !featureControl.isActive;
      this.updateMapLayerVisibility(layerId, visibility);
    },

    updateMapLayerVisibility(layerId, visibility) {
      this.map.setLayoutProperty(layerId, "visibility", visibility);

      if (layerId == "geofencing")
        this.map.setLayoutProperty(
          "incidents-geofencing",
          "visibility",
          visibility
        );

      const mainClusterSource = this.map.getSource("main-cluster");
      const mainInfraClusterSource = this.map.getSource("main-infracluster");

      mainClusterSource.setData(
        this.mapViewer.clustering.filterGeojson("title", this.filteredLayerIds)
      );
      mainInfraClusterSource.setData(
        this.mapViewer.clustering.filterGeojson("title", this.filteredLayerIds)
      );
    },
  },
};
</script>

<style scoped>
#control-buttons-container {
  position: absolute;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 180px;
  bottom: 10px;
}
#control-buttons-container p {
  margin: auto;
  font-size: 14px;
}
.tooltiptext {
  background-color: white;
  top: 90%;
  margin-left: 9px;
  padding: 7px;
  border-radius: 10px 10px 10px 0px;
  position: relative;
  color: black;
}
.zoom:hover {
  transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
}

.zoom {
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

#control-buttons-container .card {
  background-color: white;
  border-radius: 5px !important;
  box-shadow: rgb(0 0 0 / 36%) 0px 3px 11px;
  text-align: center;
  height: 35px;
  margin-right: 15px;
  width: 35px;
  padding: 5px;
}

#control-buttons-container .card-left {
  border-radius: 5px 0 0 5px !important;
}

#control-buttons-container .card-right {
  border-radius: 0 5px 5px 0 !important;
}
#zoom-level {
  width: 75px;
  font-family: Poppins-Regular, Poppins;
}

#features-control {
  margin: auto;
  gap: 3px;
}

#features-control .tooltip {
  margin: auto;
}
.img-layer {
  height: 19px;
  margin: auto;
}

.hidden {
  display: none !important;
}

#zoom-control {
  display: flex;
  margin-right: 1px !important;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.layer-disabled {
  display: none;
}
.slide-fade-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  opacity: 0;
}

.slide-fade-leave-active {
  transition: all 0.2s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-40px);
  opacity: 0;
}

.slide-fade-enter-active {
  animation: bounce-in 0.5s;
}

.show-layer {
  color: var(--secondary-bleu) !important;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
