import {markers} from "./marker.helper";

export const icons = {
    "Elections": "event/elections.svg",
    "Meeting": "event/meeting.svg",
    "Military": "event/military.svg",
    "National Date": "event/national-date.svg",
    "Religious": "event/religious.svg",
    "Trial": "event/trial.svg",
    "Airport": "poi/airport.svg",
    "Embassy": "poi/embassy.svg",
    "Hospital": "poi/hospital.svg",
    "Police Station": "poi/police.svg",
    "Station": "poi/station.svg",
    "Mobile": "device/phone-marker-special.png",
    "battery1": "device/battery1_2x.png",
    "battery2": "device/battery2_2x.png",
    "battery3": "device/battery3_2x.png",
    "battery4": "device/battery4_2x.png",
    "Site": "site/site.png"
};
