<template>
  <div class="full-height country-info-map">
    <div class="backgroundcolor">
      <i-col :md="24" style="height: 86px">
        <Card class="white-modern-card" style="height: 75px">
          <Row justify="start">
            <transition name="slide-fade" appear>
              <i-col :lg="10" :md="10">
                <i-col :xs="12" :md="10">
                  <i-Select placeholder="Continent" class="select-spacing" clearable v-model="codeContinent"
                    @on-change="sortByContinent(codeContinent)">
                    <i-Option v-for="(continent, index) in continents" v-bind:value="continent[0]" :key="index">
                      {{ continent[1] }}
                    </i-Option>
                  </i-Select>
                </i-col>
                <i-col :xs="12" :md="10">
                  <i-select filterable :placeholder="$t('Country')" class="select-spacing"
                    @on-change="triggerSelectCountry">
                    <i-option v-for="country in countryList" :key="country.value" :value="country.value"
                      :label="$t(country.display_name)">
                        <img :src="country.flag" alt="Drapeau du pays" class="flag-image" style="position: inherit;"/>
                        <span class="ml-4">{{ $t(country.display_name) }}</span>
                    </i-option>
                  </i-select>
                </i-col>
              </i-col>
            </transition>
            <i-col :lg="14" :md="14">
              <RisksLegend></RisksLegend>
            </i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :md="24">
        <Card class="white-modern-card" style="margin-top: -7px !important">
          <div class="gridcontainer scrollbar" id="style-4">
            <div v-for="country in countryList" :key="country.value">
              <button class="modern-btn-country" @click="triggerSelectCountry(country.value)">
                <img :src="country.flag" class="flag" />
                <p class="textcountry">
                  {{ $t(country.display_name) }}
                </p>
                <p class="risk" :class="classRisk(country.value)"></p>
              </button>
            </div>
          </div>
        </Card>
      </i-col>
    </div>
  </div>
</template>

<script>
import {
  getRiskQry,
  getGlobalRisks,
} from "../../services/countryInfo.service";
import RisksLegend from "./risks/RisksLegend.vue";
export default {
  name: "CountrySheet",
  props: ["countryList"],
  components: {
    getRiskQry,
    getGlobalRisks,
    RisksLegend,
  },
  data() {
    return {
      codeContinent: "",
      global_risk: {},
      continents: [
        ["AF", this.$t("Africa")],
        ["OC", this.$t("Australia/Oceania")],
        ["AS", this.$t("Asia")],
        ["EU", this.$t("Europe")],
        ["NA", this.$t("North America")],
        ["SA", this.$t("South America")],
      ], // AN/Antarctica ignored
    };
  },
  mounted() {
    getGlobalRisks().then((res) => {
      var risks = res.data.results.map((obj) => [obj.country, obj.global_risk]);
      this.global_risk = Object.fromEntries(risks);
    });
  },
  methods: {
    classRisk(iso) {
      return (
        this.global_risk[iso] ? "text-risk-" + this.global_risk[iso] : "text-risk-0"
      );
    },
    getGlobalRisk(iso) {
      getRiskQry(iso)
        .then((res) => {
          this.global_risk.push(res.data.global_risk);
        })
        .catch(() => {
          this.global_risk.push(0);
        });
    },
    triggerSelectCountry(country) {
      this.$router.push({ name: "country-info-detail", params: { country } });
    },
    sortByContinent(value) {
      this.$emit("filter-by-continent", value);
    },
  },
};
</script>
<style scoped>
.flag {
  float: left;
  width: 30px;
  height: 25px;
  margin-left: 5px;
  border-radius: 5px;
  border: solid 1px #E1E1E1;
}
.textcountry {
  float: left;
  margin-left: 10px;
  margin-top: 3px;
  font-size: 0.9em;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter {
  transform: translateX(-100px);
  opacity: 0;
}

.ivu-card-body:hover>#style-4::-webkit-scrollbar {
  visibility: visible;
}

.ivu-card-body:hover>#style-4::-webkit-scrollbar-thumb {
  display: block;
}



.gridcontainer {
  display: grid;
  text-align-last: center;
  margin: 20px;
  grid-template-columns: 20% 20% 20% 20% 20%;
  overflow-y: scroll;
  height: 570px;
  scrollbar-width: thin;
}

.risk {
  border-radius: 6px;
  border: solid 1px #ffffff;
  padding: 5px 5px;
  width: 5px;
  z-index: 50 !important;
  position: relative;
  margin: 16px 28px;
  text-align: center;
  color: white;
  background-color: #818181;
}

.backgroundcolor {
  background-color: #edecff;
  height: 347px;
  margin-top: 48px;
  padding: 2px;
  border-radius: 5px;
}

.white-modern-card {
  margin-top: 0px !important;
}

.text-risk-1 {
  background-color: #4f9cea;
}

.text-risk-2 {
  background-color: #39aa54;
}

.text-risk-3 {
  background-color: #e3c500;
}

.text-risk-4 {
  background-color: #ee823a;
}

.text-risk-5 {
  background-color: #d54848;
}
</style>

