import {axiosInstance} from "./base"
import {getAuthSession} from "../helpers/auth.helper";


export function postClientQry(data) {
    return axiosInstance.post("api/clients/", data, {})
}

export function getUsers(params) {
    return axiosInstance.get("api/users/", {params})
}

export function getUserQry(id) {
    return axiosInstance.get(`api/users/${id}`)
}

export function getClientsQry(params) {
    return axiosInstance.get("api/clients/", {params})
}

export function getContractsQry(id) {
    return axiosInstance.get(`api/clients/${id}/contracts/`, {})
}

export function getContractQry(clientId, contractId) {
    return axiosInstance.get(`api/clients/${clientId}/contracts/${contractId}/`, {})
}

export function getClientQry(id) {
    return axiosInstance.get(`api/clients/${id}`)
}

export function putClientQry(id, data) {
    return axiosInstance.put(`api/clients/${id}/`, data, {})
}

export function patchClientQry(id, data) {
    return axiosInstance.patch(`api/clients/${id}/`, data, {})
}
export function optionClientQry() {
    return axiosInstance.options(`api/clients/`, {})
}

export function deleteClientQry(id) {
    return axiosInstance.delete(`api/clients/${id}/`, {})
}

export function postValidateDomainQry(data) {
    return axiosInstance.post(`api/clients/validate-domain/`, data, {})
}

export function postInviteReferent(params) {
    return axiosInstance.post(`api/users/adding-referent/`, {params});
}

export function postRemoveReferent(params) {
    return axiosInstance.post(`api/users/remove-referent/`, {params});
}

export function getReferents(params) {
    return axiosInstance.post(`api/users/get-referents/`, {params})
}