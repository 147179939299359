<template>
  <div>
    <Button
      v-if="!showFilters"
      class="action-button"
      @click="showFilters = true"
    >
      <Icon
        type="md-funnel"
        size="18"
      />
      {{ $t('Filter') }}
    </Button>

    <Row
      v-else
      type="flex"
      :gutter="10"
      align="middle"
    >
      <i-col
        :md="24"
        :lg="18"
        class="filter-fields"
      >
        <Row :gutter="10">
          <i-col span="8">
            <Select
              v-model="filterData.incident_types"
              :placeholder="$t('Incident type')"
              transfer
              clearable
              filterable
              multiple
            >
              <Option
                v-for="type in incidentTypes"
                :key="type.id"
                :value="type.id"
              >
                {{ type.name }}
              </Option>
            </Select>
          </i-col>
          <i-col span="8">
            <Select
              v-model="filterData.country"
              :placeholder="$t('Country')"
              transfer
              clearable
              filterable
            >
              <Option
                v-for="item in countryList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.display_name }}
              </Option>
            </Select>
          </i-col>
        </Row>
      </i-col>
      <i-col
        :md="4"
        :lg="3"
      >
        <Button
          long
          size="small"
          class="confirm-button"
          @click="triggerApply"
        >
          {{ $t('Apply') }}
        </Button>
      </i-col>
      <i-col
        :md="4"
        :lg="3"
      >
        <Button
          class="cancel-button"
          long
          size="small"
          @click="triggerReset"
        >
          {{ $t('Reset') }}
        </Button>
      </i-col>
    </Row>
  </div>
</template>

<script>
    export default {
        name: "IncidentFilter",
        props: ['countryList', 'incidentTypes'],
        data() {
            return {
                showFilters: false,
                filterData: {
                    incident_types: [],
                    country: null,
                }
            }
        },
        methods: {
            triggerApply() {
                this.$emit('applyFilter', this.filterData)
            },
            triggerReset() {
                this.resetDataName(this, "filterData");
                this.showFilters = false;
                this.$emit('applyFilter', this.filterData)
            }
        },
        watch: {
          'filterData.incident_types': function (incident_types) {
            if(incident_types.length > 3)
              this.filterData.incident_types.pop()
          }
        }
    }
</script>

<style scoped>

</style>
