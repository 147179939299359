<template>
	<div>
		<Tag
			v-for="incidentTypeValue in incidentTypesValues"
			:key="incidentTypeValue.id"
			:color="incidentTypeValue.hex_color"
			class="incident-type-tag"
		>
			{{ incidentTypeValue.name }}
		</Tag>
	</div>
</template>

<script>
export default {
	name: "IncidentTypesTags",
	props: {
		incidentTypesValues: {
			type: Array,
			required: true,
		}
	},
	methods: {
		generateHTML() {
			const wrapper = document.createElement('div')
			const elem = document.createElement('div')
			wrapper.appendChild(elem)
			this.$mount(elem)
			const generatedHTML = wrapper.innerHTML
			return generatedHTML
		}
	}
}
</script>

<style scoped>
.incident-type-tag {
	font-family: 'Poppins', sans-serif;
	font-size: 10px;
	padding: 0px 20px;
	border-radius: 100px;
	height: 24px;
}
</style>