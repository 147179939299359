import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '../i18n'

Vue.use(VueI18n)



let browserLanguage = navigator.language.split(",")[0];
browserLanguage = browserLanguage.split('-')[0];

let selectedLanguage = localStorage.getItem('selected_language');

let bestLanguage = selectedLanguage || browserLanguage || "en"

const i18n = new VueI18n({
    locale: bestLanguage,
    fallbackLocale: "fr",
    messages
});

export default i18n
