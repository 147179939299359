export { BasemapSwitcher }


class BasemapSwitcher {
  /*
   * BasemapSwitcher is handling the basemap style switching, just by hiding or showing the selected map layout
   */

  constructor(object) {
    /*
     * FIXME: Remake the constructor to send several attributes instead of object
     */
    this.viewer = object.viewer
    this.map = this.viewer.map
    this.basemaps = object.basemap
    this.basemapSwitcherElement = document.createElement("div")

    this.build()
    this.viewer._eventConstructor("basemapswitcher-load", null)
  }

  build() {
    this.basemapSwitcherElement.id = "basemap"
    this.basemapSwitcherElement.setAttribute('basemap', 'default')
    this._setBasemapSwitcherBackground("satellite")

    this.basemapSwitcherElement.addEventListener("click", () => this.triggerSwitchBasemap())
    this.viewer.ViewerElement.appendChild(this.basemapSwitcherElement)
  }


  triggerSwitchBasemap() {
   /*
    * FIXME: make a proper version of target basemap retrieving,
    * like adding an attribute "target-basemap" on the element
    * so that we can handle multiple basemap switching
    */
    const currentBasemap = this.basemapSwitcherElement.getAttribute("basemap")
    const targetBasemap = currentBasemap === "default" ? "satellite" : "default"
    
    if (targetBasemap === "default")
      this._changeBasemap(currentBasemap, targetBasemap, "landuse_garages")
    else if (targetBasemap === "satellite")
      this._changeBasemap(currentBasemap, targetBasemap, "aeroway_taxiway")

      this.basemapSwitcherElement.setAttribute('basemap', targetBasemap)
    this.viewer._eventConstructor("basemap-change", targetBasemap)
  }

  _changeBasemap(currentBasemap, targetBasemap, layerTag) {
    this._setBasemapLayoutVisibility(targetBasemap, true)
    this._setBasemapLayoutVisibility(currentBasemap, false)
    this._setBasemapSwitcherBackground(currentBasemap)
    this.map.moveLayer("geofencing", layerTag);
    this.map.moveLayer("incidents-geofencing", layerTag);
  }

  _setBasemapLayoutVisibility(basemapId, visible) {
    if (basemapId === "default")
      return // default layout doesn't exist, this is just the very base of the map
    
    const visibility = visible ? "visible" : "none"
    this.map.setLayoutProperty(basemapId, 'visibility', visibility)
  }

  _setBasemapSwitcherBackground(background) {
    const apiURL = this.viewer.apiLocation
    const backgroundImage = `url(${apiURL}/images/style-${background}.png)`
    this.basemapSwitcherElement.style.backgroundImage = backgroundImage
  }
}