<template>
  <AppModal
    v-model="value"
    :header-name="$t(siteFormData.id ? 'Update site' : 'Create a site')"
    :footer-hide="false"
    @click:submit="!siteFormData.id ? validateForm('create') : validateForm('update')"
    @click:cancel="triggerCancel"
    width="600"
    :loading="loading"
  >
    <Form
      ref="siteForm"
      :model="siteFormData"
      :rules="siteFormRules"
    >
      <Row :gutter="10">
        <i-col :xs="24">
          <p class="create-site-form-label">
            {{ $t('Site information') }}
          </p>
        </i-col>
        <i-col :xs="24">
          <FormItem
            prop="name"
            :error="siteFormErrors.name"
          >
            <i-input
              v-model="siteFormData.name"
              :placeholder="$t('Name')"
            />
          </FormItem>
        </i-col>
        <i-col :xs="24">
          <FormItem
            prop="head_of_site"
            :error="siteFormErrors.head_of_site"
          >
            <Select
              v-model="siteFormData.head_of_site"
              :placeholder="$t('Head of site')"
            >
              <Option
                v-for="potentialHeadOfSiteUser in potentialHeadOfSiteUsers"
                :key="potentialHeadOfSiteUser.id"
                :value="potentialHeadOfSiteUser.id.toString()"
              >
                {{ potentialHeadOfSiteUser.full_name }}
              </Option>
            </Select>
          </FormItem>
        </i-col>
        <i-col :xs="24">
          <FormItem
            prop="place_query_search"
            :error="siteFormErrors.place_query_search"
          >
            <AppGoogleSearch
              ref="gSearch"
              @searched="triggerSearch"
            />
            <input
              v-model="siteFormData.place_query_search"
              type="hidden"
            >
          </FormItem>
        </i-col>
        <i-col
          :xs="24"
          :md="12"
        >
          <FormItem
            prop="latitude"
            :error="siteFormErrors.latitude"
          >
            <InputNumber
              v-model="siteFormData.latitude"
              :step="0.001"
              :placeholder="$t('Latitude')"
            />
          </FormItem>
        </i-col>
        <i-col
          :xs="24"
          :md="12"
        >
          <FormItem
            prop="longitude"
            :error="siteFormErrors.longitude"
          >
            <InputNumber
              v-model="siteFormData.longitude"
              :step="0.001"
              :placeholder="$t('Longitude')"
            />
          </FormItem>
        </i-col>
        <i-col
          :xs="24"
          :md="14"
        >
          <FormItem
            prop="address"
            :error="siteFormErrors.address"
          >
            <i-input
              v-model="siteFormData.address"
              :placeholder="$t('Address')"
            />
          </FormItem>
        </i-col>
        <i-col
          :xs="24"
          :md="10"
        >
          <FormItem
            prop="city"
            :error="siteFormErrors.city"
          >
            <i-input
              v-model="siteFormData.city"
              :placeholder="$t('City')"
            />
          </FormItem>
        </i-col>
        <i-col
          :xs="24"
          :md="12"
        >
          <FormItem
            prop="country"
            :error="siteFormErrors.country"
          >
            <Select
              v-model="siteFormData.country"
              :placeholder="$t('Country')"
              transfer
            >
              <Option
                v-for="item in worldInfoStore.countryList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.display_name }}
              </Option>
            </Select>
          </FormItem>
        </i-col>
        <i-col :xs="24">
          <GmapMap
            :center="gmapAttr.center"
            :zoom="gmapAttr.zoom"
            :options="gmapAttr.options"
            map-type-id="terrain"
            class="create-site-map"
          >
            <gmap-marker :position="currentMarker" />
          </GmapMap>
        </i-col>
      </Row>
    </Form>
  </AppModal>
</template>

<script>
  import AppModal from "@/components/common/AppModal"
  import AppGoogleSearch from "@/components/common/AppGoogleSearch"
  import {getUsers} from "@/services/accounts.service";
  import {postSiteQry, putSiteQry} from "@/services/sites.service";
  import _cloneDeep from "lodash/cloneDeep";
  import _isEqual from "lodash/isEqual"
  import Vue from 'vue';
  import { FORM_RULE_REQUIRED_FIELD } from "@/helpers/common.helper";

  export default {
    name: "CreateSite",

    components: {
      AppModal,
      AppGoogleSearch
    },
    props: ['value'],
    data() {
      return {
        loading: false,
        updatedIndex: null,

        gmapAttr: {
          center: {lat: 10, lng: 10},
          zoom: 4,
          options: Vue.config.gmapDefaultOptions
        },

        currentMarker: {
          lat: 10,
          lng: 10,
        },

        siteFormData: {
          id: null,
          client: null,  // Added at submission time
          name: "",
          head_of_site: "",
          place_query_search: "",
          longitude: null,
          latitude: null,
          address: "",
          city: "",
          country: "",
        },
        duplicateForm: {},  // Fixme rename this for clarity

        siteFormRules: {
          name: [
            FORM_RULE_REQUIRED_FIELD,
            {trigger: 'blur'},
          ],
          longitude: [
            FORM_RULE_REQUIRED_FIELD,
            {type: "number", trigger: 'blur'},
          ],
          latitude: [
            FORM_RULE_REQUIRED_FIELD,
            {type: "number", trigger: 'blur'},
          ],
          address: [
            FORM_RULE_REQUIRED_FIELD,
            {trigger: 'blur'},
          ],
          city: [
            FORM_RULE_REQUIRED_FIELD,
            {trigger: 'blur'},
          ],
          country: [
            FORM_RULE_REQUIRED_FIELD,
            {trigger: 'blur'},
          ],
        },
        siteFormErrors: {
          name: "",
          head_of_site: "",
          place_query_search: "",
          longitude: "",
          latitude: "",
          city: "",
          address: "",
          country: "",
        },

          potentialHeadOfSiteUsers: [{id: '', full_name: '---'}]
      }
    },
    watch: {
      'value': function (val) {
        if (val) {
          this.$nextTick(() => {
            this.duplicateForm = _cloneDeep(this.siteFormData);
          });
        }
      }
    },
    created() {
      this.$eventBus.$on('clientContextSelected', this.getHeadOfSiteUsers);  // FIXME rename to Client
      this.getHeadOfSiteUsers(); // Initial setup, even if Client is not selected yet
    },
    beforeDestroy() {
      this.$eventBus.$off("clientContextSelected", this.getHeadOfSiteUsers);
    },
    methods: {

      validateForm(action) {
        this.$refs['siteForm'].validate(async (valid) => {
          if (valid) {
            this.submitForm(action);
          }
        });
      },

      submitForm(action) {
        if (action === "create") {
          this.triggerAdd();
        } else {
          this.triggerUpdate();
        }
      },

      triggerAdd() {
        this.loading = true;
        this.siteFormData.client = this.userProfileStore.getSelectedCompany;
        postSiteQry(this.siteFormData)
          .then(res => {
            this.loading = false;
            this.$emit('siteCreated', res.data);
            this.cancel();
            this.$AppModal.AppInfo({
              extraTitle: this.$t('Site created !'),
              message: "",
            });
          })
          .catch(err => {
            this.loading = false;
            this.resetDataName(this, 'siteFormErrors');
            const error = err.response;
            if (error.status === 400) {
              for (let col in error.data) {
                this.siteFormErrors[col] = error.data[col][0];
              }
            }
            this.$Message.error({content: this.$t('Error creating your data'), duration: 5});
          })

      },
      getHeadOfSiteUsers(client) {
        getUsers({page_size:500, account_type: 2, client: (client || this.userProfileStore.getSelectedCompany)})
          .then(res => {
            this.potentialHeadOfSiteUsers = res.data.results
            this.potentialHeadOfSiteUsers.unshift({id: '', full_name: '---'});
          })
          .catch(err => {
            this.$Message.error({content: this.$t('Error getting your data'), duration: 5});
          })
      },
      triggerUpdate() {

        this.loading = true;

        if (!this.siteFormData.head_of_site)
            this.siteFormData.head_of_site = null;  // Normalize "undefined" value null, to erase Head of Site

        putSiteQry(this.siteFormData.id, this.siteFormData)
          .then(res => {
            this.loading = false;
            this.$emit('siteCreated', res.data, this.updatedIndex);
            this.cancel();
            this.$AppModal.AppInfo({
              extraTitle: this.$t('Site Updated'),
              message: "",
            });
          })
          .catch(err => {
            this.loading = false;
            this.resetDataName(this, 'siteFormErrors');
            const error = err.response;
            if (error.status === 400) {
              for (let col in error.data) {
                this.siteFormErrors[col] = error.data[col][0];
              }
            }
            this.$Message.error({content: this.$t('Error updating your data'), duration: 5});
          })

      },
      triggerCancel() {
        if (_isEqual(this.duplicateForm, this.siteFormData)) {
          this.cancel();
        } else {
          let confirmTitle
          let confirmMessage
          if (this.siteFormData.id) {
            confirmTitle = this.$t('Cancel Site Update')
            confirmMessage = this.$t('Are you sure you want to discard changes on site?')
          } else {
            confirmTitle = this.$t('Cancel Site Creation')
            confirmMessage = this.$t('Are you sure you want to cancel new site creation?')
          }
          this.$AppModal.AppConfirm({
            loading: true,
            method: 'cancel',
            message: confirmMessage,
            extraTitle: confirmTitle,
            iconType: "",
            cancelText: "No",
            okText: "Yes",
            onConfirm: (async () => {
              this.$AppModal.remove();
              this.cancel();
            }),
          })
        }
      },


      cancel() {
        this.$refs['siteForm'].resetFields();
        this.siteFormData.id = null;
        this.$emit('input', false);
      },

      triggerSearch(data) {
        console.log(">>>>>>triggerSearch", data);
        this.setMapView(data.latitude, data.longitude);
        for (let key in data) {
          if (this.siteFormData.hasOwnProperty(key)) {
            this.siteFormData[key] = data[key];
          }
        }
      },
      setUpdateData(data, idx) {
        this.updatedIndex = idx;
        for (let col in this.siteFormData) {
          if (col === 'head_of_site' && data[col]) {
            this.siteFormData[col] = data[col].toString();
          } else {
            this.siteFormData[col] = data[col];
          }
        }
        this.$refs['gSearch'].setInput(this.siteFormData.place_query_search);
        this.getHeadOfSiteUsers(this.siteFormData.client)
      },

      setMapView(lat, lng) {
        this.gmapAttr.center.lat = lat;
        this.gmapAttr.center.lng = lng;
        this.gmapAttr.zoom = 8;
        this.currentMarker.lat = lat;
        this.currentMarker.lng = lng;
      },
    }
  }
</script>

<style>
    .create-site-form-label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .create-site-gmap {
        width: 100%;
    }

    .create-site-space {
        padding: 20px;
    }

    .create-site-map {
        width: 100%;
        height: 250px;
    }

</style>
