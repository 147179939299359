<template />

<script>
    import * as VueGoogleMaps from 'vue2-google-maps'
    import _throttle from "lodash/throttle"
    import _debounce from "lodash/debounce"


    export default {
        name: "GmapGeoJson",
        mixins: [VueGoogleMaps.MapElementMixin],
        props: {"geoData": {type: Array}, "options": {default: {}}},
        watch: {
            geoData: function (data) {
                this.setGeoJson(data);
            }
        },
        methods: {
            setGeoJson(data) {
                this.$mapPromise.then(mapObject => {
                    data.forEach((value) => {
                        mapObject.data.addGeoJson(value);
                    });

                    if (this.options) {
                        mapObject.data.setStyle((feature) => {
                            return this.options
                        });
                    }

                    mapObject.data.addListener('click', this.click);
                    mapObject.data.addListener('mouseover', this.hover);
                    mapObject.data.addListener('mouseout', (e) => {
                        this.hover(e, true)
                    });
                })
            },

            click: _throttle(function (e) {
                this.$emit('onClick', e)
            }, 1000),

            hover: _debounce(function (e, out) {
                this.$emit('onHover', e, out)
            }, 500),
        },
    }
</script>

<style scoped>

</style>