<template>
  <div>
    <Poptip placement="bottom-end" width="350" class="profile" v-model="visible">
      <Avatar
        icon="ios-person"
        class="avatar-panel"
        v-if="userProfile.avatar != null"
        style="background: var(--text-low-opacity) !important"
        :src="userProfile.avatar"
      />
      <img v-else src="../../assets/images/ico/profile.svg" class="avatar-panel" width="25px" style="margin-top: 3px" />
      <div slot="content" class="notification-content full-height">
        <div class="notification-label text-center full-width">
          <div class="edit-profile-image circular-image" style="width: 100px; height: 100px">
            <img v-if="userProfile.avatar" size="70" :src="userProfile.avatar" />
            <Icon v-else type="ios-person" size="70" />
          </div>
          <Upload id="profilePic" action="" :before-upload="beforeDocumentUpload">
            <Button class="btn-profile-edit">
              <Icon type="ios-camera" class="ios-cam" />
            </Button>
          </Upload>
          <div class="profile mt-5">
            <p style="font-size: 18px">
              {{ userProfile.full_name }}
            </p>
            <p class="mt-3">{{ userProfile.email }}</p>
            <p class="mt-4">{{ $t(userProfile.user_role_display) }}</p>
          </div>
          <button class="account-button mt-4" @click="headerNavigate('profile')">
            {{ $t("Manage your ILIOS account") }}
          </button>
          <div class="hr"></div>
          <button class="account-button-transparent mt-3" @click="triggerLogout()">
            {{ $t("Logout") }}
          </button>
        </div>
      </div>
    </Poptip>
  </div>
</template>

<script>
import { logout } from "../../helpers/auth.helper";
import AppModal from "@/components/common/AppModal";
import { patchAccount } from "@/services/auth.service";
export default {
  name: "PanelAccountHeader",
  props: ["userProfile"],
  data() {
    return {
      showTacPopup: false,
      visible: false,
      tacVersion: null,
      showModal: false,
    };
  },
  components: {
    AppModal,
    patchAccount,
  },
  watch: {
    $route() {
      this.closePopTip();
    },
  },
  methods: {
    headerNavigate(name) {
      this.closePopTip();
      if (this.$route.params.userId != this.userProfile.id) {
        this.$router.push({
          name: name,
          params: {
            id: this.userProfile.client,
            contractId: this.userProfile.contracts[0],
            userId: this.userProfile.id,
          },
        });
        this.$eventBus.$emit("pushActiveMenu", name);
      }
    },
    triggerLogout() {
      this.closePopTip();
      logout();
      this.$router.push({ name: "login" });
    },
    closePopTip() {
      this.visible = false;
    },
    showTacPop() {
      this.closePopTip();
      this.showTacPopup = true;
    },
    beforeDocumentUpload(avatar) {
      this.imageLoading = true;
      let infoFormData = { avatar: avatar };
      patchAccount(infoFormData)
        .then((res) => {
          this.$Message.success(this.$t("Successfully updated your profile image"));
          let data = { ...res.data };
          this.userProfile.avatar = data.avatar;
          this.imageLoading = false;
        })
        .catch((err) => {
          this.imageLoading = false;
          this.$Message.error({
            // No need to detail, here
            content: this.$t("There was an error while updating your profile image"),
            duration: 5,
          });
        });
      return false;
    },
    removeAvatar() {
      let infoFormData = { avatar: "" };
      this.imageLoading = true;

      patchAccount(infoFormData)
        .then((res) => {
          this.userProfile.avatar = res.data.avatar;
          this.$Message.success(this.$t("Successfully deleted your profile image"));
          this.imageLoading = false;
        })
        .catch((err) => {
          this.imageLoading = false;
          this.$Message.error({
            // No need to detail, here
            content: this.$t("There was an error while deleting your profile image"),
            duration: 5,
          });
        });
    },
  },
};
</script>

<style scoped>
.profile span {
  cursor: pointer;
  transform: 1s;
  transition: all 0.3s ease-in-out;
}

.ios-cam {
  margin: 0.6px;
}
.profile span:hover {
  transform: scale(1.03);
}

.avatar-header {
  text-align: center;
  padding: 0%;
}
</style>
