export { InfraClustering };
import { Clustering } from "./Clustering.js";

class InfraClustering extends Clustering{

  customUpdateMarkers() {
    var customCluster = document.getElementsByClassName("infra-cluster");

    for (let index = 0; index < customCluster.length; index++) {
      const element = customCluster[index];

      this.generateCustomEventOnClusterElement(element, "click", "click-infracluster", {getFromParent: false, getMarkerFromParent: false, stopPropagation: true})
      this.generateCustomEventOnClusterElement(element, "mouseenter", "hover-infracluster", {getFromParent: false, getMarkerFromParent: false, stopPropagation: true})
    }
  }

  createClusterBox(props) {
    var offsets = [];
    var counts = [];
    var countsDetails = {};

    this.layers.forEach((element) => {
      counts.push(props[element.title]);
      countsDetails[element.title] = {
        count: props[element.title],
        ids: [],
      };
    });
    var idRefString = "[" + props["ids"].slice(0, -1) + "]";
    var idRef = JSON.parse(idRefString.replaceAll("'", '"'));

    idRef.forEach((element) => {
      var split = element.split("-");
      countsDetails[split[0]].ids.push(split[1]);
    });

    var isPulsing = false;

    var pulseCount = 0;

    if (idRef.some((i) => this.pulseValues.includes(i))) {
      isPulsing = true;
    }

    idRef.forEach((element) => {
      if (this.pulseValues.includes(element)) {
        pulseCount++;
      }
    });

    var total = 0;
    for (var i = 0; i < counts.length; i++) {
      offsets.push(total);
      total += counts[i];
    }

    var target = "";

    if (props.devices > 0) {
      target = "devices-base";
    } else if (props.incidents > 0) {
      target = "incidents-base";
    } else if (props.sites > 0) {
      target = "sites-base";
    } else if (props.poi > 0) {
      target = "poi-base";
    }

    var clusterBox = document.createElement("div");
    const markerSvgUrl = `${this.viewer.apiLocation}/images/symbologie/markers/marker-${target}.svg`

    clusterBox.setAttributeNS(null, "cluster-id", props.cluster_id);
    clusterBox.setAttributeNS(null, "pulse-count", pulseCount);
    clusterBox.setAttributeNS(
      null,
      "counts-details",
      JSON.stringify(countsDetails)
    );
    clusterBox.setAttribute("pulsing", isPulsing);
    clusterBox.classList.add("infra-cluster");
    clusterBox.innerHTML = `
      <span class="badge">${total}</span>
      <img class='marker' src="${markerSvgUrl}">
    `;
    return clusterBox;
  }
}
