<template>
  <AppModal
    v-model="value"
    width="1200"
    class-name="terms-and-conditions"
    :footer-hide="false"
    @click:submit="triggerAccept"
    @click:cancel="triggerDecline"
    :particularSubmitName="$t('Accept')"
    :particularCancelName="$t('Decline')"
  >
    <Row
      class="tc-header"
      type="flex"
      align="middle"
    >
      <i-col :span="2">
        <div class="tc-icon">
          <Icon
            type="ios-paper-outline"
            size="40"
          />
        </div>
      </i-col>
      <i-col :span="22">
        <div class="tc-title">
          <p>{{ $t("Terms & Conditions") }}</p>
          <p>{{ $t("Last updated") }} - {{ dateFormat(tc.updated_at) }} </p>
        </div>
      </i-col>
    </Row>
    <hr>
    <p
      class="tc-content"
      v-html="tc.content"
    />
  </AppModal>
</template>

<script>
  import AppModal from "@/components/common/AppModal"
  import {getTermsAndConditionQry} from "@/services/common.service";
  import DateFormat from "@/mixins/dateFormat"

  export default {
    name: "TermsAndCondition",
    components: {
      AppModal
    },
    mixins: [DateFormat],
    props: ['value'],
    data() {
      return {
        tc: {
          pk: null,
          content: "",
          created: null,
          updated: null
        }
      }
    },
    created() {
      getTermsAndConditionQry()
        .then(res => {
          this.tc = res.data;
          this.$emit('tacVersion', this.tc.pk);
        })
        .catch(err => {
          this.$Message.error({content: this.$t("Couldn't fetch current Terms&Conditions, please refresh the page"), duration: 5})
        })
    },
    methods:{
      triggerAccept(){
        this.$emit('accept', true);
        this.$emit('input', false);
      },
      triggerDecline(){
        this.$emit('accept', false);
        this.$emit('input', false);
        this.$AppModal.AppInfo({
          extraTitle: this.$t('Access declined'),
          message: this.$t("Unless you accept Terms and Conditions, you can't access the platform. For more information, please contact your manager."),
          iconType: "md-warning",
          iconColor: "#E53935",
          timeout: "4000",
        });
      }
    }
  }
</script>

<style scoped>
    .terms-and-conditions .tc-header, .terms-and-conditions .tc-content, .terms-and-conditions .tc-action {
        padding: 46px;
    }

    .terms-and-conditions hr{
        color: lightgray;

    }

    .terms-and-conditions .tc-content {
        max-height: 300px;
        overflow: auto;
    }

    .terms-and-conditions .tc-title p:first-of-type {
        font-size: 24px;
        font-weight: 600;
    }

    .terms-and-conditions .tc-title p:last-of-type {
        font-size: 15px;
    }

    .tc-action button {
        padding: 15px 80px 15px 80px;
        font-size: 18px;
    }

    .tc-action button:last-of-type{
        background-color: var(--secondary-bleu);
        color: #ffffff;
    }

    .tc-action button:first-of-type {
        margin-right: 40px;
        color: var(--secondary-bleu);
    }

    .terms-and-conditions .tc-header .tc-icon .ivu-icon{
        background: var(--secondary-bleu);
        padding: 10px;
        color: #ffffff;
        border-radius: 4px;
    }

</style>

<style>
    .terms-and-conditions .ivu-modal-body{
        padding: 0;
    }

</style>
