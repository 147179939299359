<template>
  <AppModal
    v-model="value"
    :header-name="$t('Create incident')"
    :footer-hide="false"
    @click:submit="updateIndex === null ? triggerCreate() : triggerUpdate()"
    @click:cancel="triggerCancel"
    :width="1200"
    :loading="loading"
  >
    <Form
      ref="incidentForm"
      :label-width="100"
      :model="incidentFormData"
      :rules="incidentRules"
      label-position="left"
    >
      <Row
        type="flex"
        :gutter="10"
      >
        <Card
          style="width: 100%; height: 100%"
          class="full-height incident-form"
        >
          <i-col
            span="12"
            style="padding-right: 30px;padding-left: 20px"
          >
            <FormItem
              prop="country"
              :label="$t('Country')"
              :error="incidentFormErrors.country"
            >
              <Select
                v-model="incidentFormData.country"
                transfer
                clearable
                filterable
              >
                <Option
                  v-for="item in worldInfoStore.countryList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.display_name"
                />
              </Select>
            </FormItem>
            <FormItem
              prop="incident_types"
              :label="$t('Type')"
              :error="incidentFormErrors.incident_types"
            >
              <Select
                v-model="incidentFormData.incident_types"
                transfer
                clearable
                multiple
              >
                <Option
                  v-for="incident_type in incidentTypes"
                  :key="incident_type.id"
                  :value="incident_type.id"
                  :label="incident_type.name"
                />
              </Select>

            </FormItem>
            <FormItem
              prop="expiration_date"
              :label="$t('Expiration')"
              :error="incidentFormErrors.expiration_date"
            >
              <AppDatePicker
                v-model="incidentFormData.expiration_date"
                type="date"
              />
            </FormItem>
            <FormItem
              prop="short_description"
              :error="incidentFormErrors.short_description"
              :label-width="0"
            >
              <p>{{ $t('Title') }}</p>
              <i-input v-model="incidentFormData.short_description" />
            </FormItem>
            <FormItem
              prop="long_description"
              :error="incidentFormErrors.long_description"
              :label-width="0"
            >
              <p>{{ $t('Long description') }}</p>
              <i-input
                v-model="incidentFormData.long_description"
                type="textarea"
                :autosize="{minRows:6, maxRows:8}"
                :row="7"
              />
            </FormItem>
            <FormItem
              prop="recommendations"
              :error="incidentFormErrors.recommendations"
              :label-width="0"
            >
              <p>{{ $t('Recommendations') }}</p>
              <i-input
                v-model="incidentFormData.recommendations"
                type="textarea"
                :autosize="{minRows:2, maxRows:4}"
                :row="3"
              />
            </FormItem>
          </i-col>
          <i-col
            span="12"
            style="padding-right: 20px;padding-left: 30px"
          >
            <i-col
              span="24"
              style="padding-bottom: 10px;"
            >
              <IncidentLocation
                ref="incidentLocation"
                v-model="incidentFormData.incident_locations"
                :country_list="worldInfoStore.countryList"
                :class="{'incident-location-error': incidentFormErrors.incident_locations.length > 0}"
                @addLocation="addLocation"
              />
            </i-col>            
          </i-col>
        </Card>
      </Row>
    </Form>
  </AppModal>
</template>

<script>
    import {postIncidentQry, putIncidentQry} from "@/services/incidents.service";
    import AppModal from "@/components/common/AppModal"
    import AppDatePicker from "@/components/common/AppDatePicker"
    import IncidentLocation from "../IncidentLocation"
    import _cloneDeep from "lodash/cloneDeep"
    import _isEqual from "lodash/isEqual"
    import {futureDateValidator, FORM_RULE_REQUIRED_FIELD} from "@/helpers/common.helper";

    export default {
        name: "CreateIncident",
        components: {
            AppModal,
            AppDatePicker,
            IncidentLocation
        },
        props: ['value', 'incidentTypes'],

        data() {

            return {
                loading: false,
                updateIndex: null,
                incidentFormData: {
                    id: null,
                    country: '',
                    expiration_date: null,
                    incident_types: [],
                    short_description: '',
                    long_description: '',
                    recommendations: '',
                    incident_locations: [],
                },
                incidentRules: {
                    country: FORM_RULE_REQUIRED_FIELD,
                    expiration_date: {validator: futureDateValidator},
                    incident_types: [
                      FORM_RULE_REQUIRED_FIELD,
                        {type: 'array'}
                    ],
                    short_description: FORM_RULE_REQUIRED_FIELD
                },
                incidentFormErrors: {
                    id: "",
                    country: "",
                    expiration_date: "",
                    incident_types: "",
                    short_description: "",
                    long_description: "",
                    recommendations: "",
                    incident_locations: "",
                },
                duplicateForm: {}  // Fixme rename this for clarity
            }
        },
        watch:{
            'value': function (val) {
                if (val) {
                    this.$nextTick(() => {
                        this.duplicateForm = _cloneDeep(this.incidentFormData);
                    });
                }
            },
            'incidentFormData.incident_types': function (incident_types) {
              if(incident_types.length > 3)
                this.incidentFormData.incident_types.pop()
            }
        },
        created() {

        },
        methods: {
            triggerCreate() {
                // console.log(">> INCIDENT FORM DATA:", this.incidentFormData);
                this.$refs['incidentForm'].validate(async (valid) => {
                    const locationValid = await this.$refs['incidentLocation'].handleValidate();
                    if (valid && locationValid) {
                        this.loading = true;
                        postIncidentQry(this.incidentFormData)
                            .then(res => {
                                this.$emit('incidentCreated', res.data);
                                this.cancel();
                                this.loading = false;
                                this.$Message.success(this.$t('Successfully created your data'));
                            })
                            .catch(err => {
                                this.loading = false;
                                this.resetDataName(this, 'incidentFormErrors');
                                const error = err.response;
                                if (error.status === 400) {
                                    for (let col in error.data) {
                                        this.incidentFormErrors[col] = error.data[col][0];
                                    }
                                }
                                this.$Message.error({content: this.$t('Error updating your data'), duration: 5});
                            })
                    }
                })
            },
            triggerUpdate() {
                this.$refs['incidentForm'].validate(async (valid) => {
                    const locationValid = await this.$refs['incidentLocation'].handleValidate();
                    if (valid && locationValid) {
                        this.loading = true;
                        putIncidentQry(this.incidentFormData.id, this.incidentFormData)
                            .then(res => {
                                this.$emit('incidentCreated', res.data, true, this.updateIndex);
                                this.cancel();
                                this.loading = false;
                                this.$Message.success(this.$t('Successfully updated your data'))
                            })
                            .catch(err => {
                                this.loading = false;
                                this.resetDataName(this, 'incidentFormErrors');
                                const error = err.response;
                                if (error.status === 400) {
                                    for (let col in error.data) {
                                        this.incidentFormErrors[col] = error.data[col][0];
                                    }
                                }
                                this.$Message.error({content: this.$t('Error updating your data'), duration: 5});
                            })
                    }
                })
            },

            triggerCancel(){
                if (_isEqual(this.duplicateForm, this.incidentFormData)) {
                    this.cancel();
                } else {
                    this.$AppModal.AppConfirm({
                        method: 'cancel',
                        loading: true,
                        message: "<p> " + this.$t("Any changes you have made will not be saved. \nAre you sure you want to leave this page ?") + "</p>",
                        extraTitle: this.$t("Cancel"),
                        iconType: "",
                        cancelText:  this.$t("No"),
                        okText:  this.$t("Yes"), 
                        onConfirm: (async () => {
                            this.$AppModal.remove();
                            this.cancel();
                        }),

                    })
                }
            },

            cancel() {
                this.$emit('input', false);
                this.$refs['incidentForm'].resetFields();
                this.$refs['incidentLocation'].resetTab();
                this.resetDataName(this, 'incidentFormData');
                this.resetDataName(this, 'incidentFormErrors');
                this.updateIndex = null;
            },
            addLocation(location) {
                this.incidentFormData.incident_locations.push(location)
            },

            setUpdateData(data, idx) {
                this.updateIndex = idx;
                const dataReset = _cloneDeep(data);

                Object.keys(this.incidentFormData).forEach((key) => {
                    this.incidentFormData[key] = dataReset[key];
                });
            },
        },

    }
</script>

<style scoped>
</style>
