<template>
  <Card class="country-general">
    <div class="general-header">
      <strong style="font-size: 14px">{{ $t('Country global risk level') }}</strong>
      <Icon
        v-if="s2ec.user.is_admin || s2ec.user.is_superuser"
        type="md-create"
        size="20"
        :title="$t('Edit')"
        class="pointer-cursor"
        @click.native="showUpdate"
      />
    </div>
    <div class="risk-content">
      <Row
        type="flex"
        align="middle"
        :gutter="10"
      >
        <i-col :sm="10">
          {{ $t('Global risk level') }}
        </i-col>
        <i-col :sm="14">
          <CountryInfoSlider
            v-model="risk.global_risk"
            :disabled="disallowEdit"
          />
        </i-col>
      </Row>
      <div>
        <Row
          v-if="!disallowEdit"
          type="flex"
          justify="end"
          :gutter="10"
        >
          <i-col>
            <Button
              type="success"
              class="confirm-button"
              @click="triggerUpdate"
            >
              {{ $t('Update') }}
            </Button>
          </i-col>
          <i-col>
            <Button
              type="default"
              class="cancel-button"
              @click="triggerCancel"
            >
              {{ $t('Cancel') }}
            </Button>
          </i-col>
        </Row>
      </div>
    </div>
    <Spin
      v-if="loading"
      fix
    />
  </Card>
</template>

<script>
    import CountryInfoSlider from "./CountryInfoSlider"
    import {patchRiskQry} from "../../../services/countryInfo.service";

    export default {
        name: "GlobalRisk",

        components: {
            CountryInfoSlider
        },
        props: ['value'],
        data() {
            return {
                disallowEdit: true,
                loading: false,
                riskCopy: {},
            }
        },
        computed: {
            risk: {
                get() {
                    return this.value
                }
            }
        },

        methods: {
            triggerUpdate() {
                this.loading = true;
                const {country, global_risk} = this.risk;
                patchRiskQry(country, {global_risk})
                    .then(res => {
                        this.$emit('input', res.data);
                        this.riskCopy = {};
                        this.disallowEdit = true;
                        this.$Message.success(this.$t('Successfully updated your data'));
                        this.loading = false;
                    })
                    .catch(err => {
                        this.$Message.error({content: this.$t('Error updating your data'), duration: 5});
                        this.loading = false;
                    })

            },
            showUpdate() {
                this.riskCopy = {...this.value};
                this.disallowEdit = false;
            },

            triggerCancel() {
                this.$emit('input', {...this.riskCopy});
                this.disallowEdit = true;
            }
        }
    }
</script>
