<template>
  <AppModal
    v-model="value"
    :header-name="$t('Add an attached location')"
    :footer-hide="false"
    @click:submit="!locationFormData.id ? validateForm('create') : validateForm('update')"
    @click:cancel="triggerCancel"
    :width="600"
    :loading="loading"
  >
    <div class="site-location-form">
      <Form
        ref="locationForm"
        label-position="left"
        :model="locationFormData"
        :rules="locationFormRules"
        autocomplete="off"
      >
        <h3 class="site-form-label">
          {{ $t('Attach entity') }}
        </h3>
        <FormItem
          :label="$t('Type:')"
          :label-width="80"
          prop="site_type"
          :error="locationFormErrors.site_type"
        >
          <Select v-model="locationFormData.site_type">
            <i-option
              v-for="site_type in site_types"
              :key="site_type.value"
              :value="site_type.value"
            >
              {{ site_type.display_name }}
            </i-option>
          </Select>
        </FormItem>
        <FormItem
          :label="$t('Name:')"
          :label-width="80"
          prop="name"
        >
          <i-input v-model="locationFormData.name" />
        </FormItem>
        <FormItem
          :label="$t('Landline 1:')"
          :label-width="80"
          prop="land_lines[0]"
        >
          <!-- FIXME "required" validation of landline0 fails if we open new "creation" form after opening/closing "edition" form -->
          <AppPhoneInput
            ref="location-form-landline0"
            v-model="locationFormData.land_lines[0]"
            style="width: 100%"
          />
        </FormItem>
        <FormItem
          :label="$t('Landline 2:')"
          :label-width="80"
          prop="land_lines[1]"
        >
          <AppPhoneInput
            ref="location-form-landline1"
            v-model="locationFormData.land_lines[1]"
            style="width: 100%"
          />
        </FormItem>
        <Row :gutter="10">
          <i-col :xs="24">
            <FormItem
              prop="place_query_search"
              :error="locationFormErrors.place_query_search"
            >
              <AppGoogleSearch
                ref="gSearch"
                v-model="locationFormData.place_query_search"
                @searched="triggerSearch"
              />
              <input
                v-model="locationFormData.place_query_search"
                type="hidden"
              >
            </FormItem>
          </i-col>
          <i-col
            :xs="24"
            :md="12"
          >
            <FormItem
              prop="latitude"
              :error="locationFormErrors.latitude"
            >
              <InputNumber
                v-model="locationFormData.latitude"
                :step="0.001"
                :placeholder="$t('Latitude')"
              />
            </FormItem>
          </i-col>
          <i-col
            :xs="24"
            :md="12"
          >
            <FormItem
              prop="longitude"
              :error="locationFormErrors.longitude"
            >
              <InputNumber
                v-model="locationFormData.longitude"
                :step="0.001"
                :placeholder="$t('Longitude')"
              />
            </FormItem>
          </i-col>
          <i-col
            :xs="24"
            :md="12"
          >
            <FormItem
              prop="city"
              :error="locationFormErrors.city"
            >
              <i-input
                v-model="locationFormData.city"
                :placeholder="$t('City')"
              />
            </FormItem>
          </i-col>
          <i-col
            :xs="24"
            :md="12"
          >
            <FormItem
              prop="address"
              :error="locationFormErrors.address"
            >
              <i-input
                v-model="locationFormData.address"
                :placeholder="$t('Address')"
              />
            </FormItem>
          </i-col>
          <i-col
            :xs="24"
            :md="12"
          >
            <FormItem
              prop="country"
              :error="locationFormErrors.country"
            >
              <Select
                v-model="locationFormData.country"
                :placeholder="$t('Country')"
                transfer
              >
                <Option
                  v-for="item in worldInfoStore.countryList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.display_name }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col :xs="24">
            <GmapMap
              :center="gmapAttr.center"
              :zoom="gmapAttr.zoom"
              :options="gmapAttr.options"
              map-type-id="terrain"
              class="create-site-map"
            >
              <gmap-marker :position="currentMarker" />
            </GmapMap>
          </i-col>
        </Row>
      </Form>
    </div>
  </AppModal>
</template>

<script>
    import AppModal from "@/components/common/AppModal"
    import AppPhoneInput from "@/components/common/AppPhoneInput"
    import AppGoogleSearch from "@/components/common/AppGoogleSearch"
    import {postSiteLocationQry, putSiteLocationQry} from "@/services/sites.service";
    import Vue from 'vue'
    import { FORM_RULE_REQUIRED_FIELD } from "@/helpers/common.helper";

    export default {
        name: "CreateLocation",
        components: {
            AppModal,
            AppGoogleSearch,
            AppPhoneInput
        },
        props: ['value', 'site', 'site_types'],
        data() {
            return {
                loading: false,
                updatedIndex: null,
                gmapAttr: {
                    center: {lat: 10, lng: 10},
                    zoom: 4,
                    options: Vue.config.gmapDefaultOptions
                },

                currentMarker: {
                    lat: 10,
                    lng: 10,
                },

                locationFormData: {
                    id: null,
                    site: null,
                    site_type: null,
                    name: "",
                    land_lines: new Array(2),
                    place_query_search: "",
                    longitude: null,
                    latitude: null,
                    address: "",
                    city: "",
                    country: "",
                },

                locationFormRules: {
                    site_type: [
                      FORM_RULE_REQUIRED_FIELD,
                        {type: "integer", trigger: 'blur'},
                    ],
                    name: [
                      FORM_RULE_REQUIRED_FIELD,
                        {trigger: 'blur'},
                    ],
                    "land_lines[0]": [
                      FORM_RULE_REQUIRED_FIELD,
                        {trigger: 'blur'},
                    ],
                    place_query_search: [],
                    longitude: [
                      FORM_RULE_REQUIRED_FIELD,
                      {type: "number", trigger: 'blur'},
                    ],
                    latitude: [
                      FORM_RULE_REQUIRED_FIELD,
                      {type: "number", trigger: 'blur'},
                    ],
                    address: [
                      FORM_RULE_REQUIRED_FIELD,
                      {trigger: 'blur'},
                    ],
                    city: [
                      FORM_RULE_REQUIRED_FIELD,
                      {trigger: 'blur'},
                    ],
                    country: [
                      FORM_RULE_REQUIRED_FIELD,
                      {trigger: 'blur'},
                    ],
                },

                locationFormErrors: {
                    site_type: "",
                    company: "",
                    name: "",
                    head_of_site: "",
                    place_query_search: "",
                    longitude: "",
                    latitude: "",
                    address: "",
                    city: "",
                    country: "",
                    land_lines: ""
                }
            }
        },
        methods: {

            validateForm(action) {
                this.$refs['locationForm'].validate(async (valid) => {
                  if (valid) {
                    this.submitForm(action);
                  }
                });
            },

            submitForm(action) {

                if (action === "create") {
                    this.triggerAdd();
                } else {
                    this.triggerUpdate();
                }
            },

            normalizeFormData() {
                this.locationFormData.land_lines = this.locationFormData.land_lines.filter(Boolean);
            },

            triggerAdd() {
                this.normalizeFormData();
                postSiteLocationQry(this.site, this.locationFormData)
                    .then(res => {
                        this.$AppModal.AppInfo({
                            extraTitle: this.$t('New location added'),
                            message: "",
                        });
                        this.$emit('locationCreated', res.data);
                        this.triggerCancel();
                        this.$AppModal.remove();
                    })
                    .catch(err => {
                        this.loading = false;
                        this.resetDataName(this, 'locationFormErrors');
                        const error = err.response;
                        if (error.status === 400) {
                            for (let col in error.data) {
                                this.locationFormErrors[col] = error.data[col][0];
                            }
                        }
                        this.$Message.error({content: this.$t('Error creating your data'), duration: 5});
                        this.$AppModal.remove();
                    })
            },

            triggerUpdate() {
                this.normalizeFormData();
                putSiteLocationQry(this.site, this.locationFormData.id, this.locationFormData)
                    .then(res => {
                        this.$emit('locationCreated', res.data, this.updatedIndex);
                        this.$AppModal.AppInfo({
                            extraTitle: this.$t('Location updated'),
                            message: "",
                        });
                        this.triggerCancel();
                        this.$AppModal.remove();
                    })
                    .catch(err => {
                        this.loading = false;
                        this.resetDataName(this, 'locationFormErrors');
                        const error = err.response;
                        if (error.status === 400) {
                            for (let col in error.data) {
                                this.locationFormErrors[col] = error.data[col][0];
                            }
                        }
                        this.$Message.error({content: this.$t('Error updating your data'), duration: 5});
                        this.$AppModal.remove();
                    })
            },

            triggerCancel() {
                this.$refs['locationForm'].resetFields();
                this.locationFormData.id = null;
                this.$emit('input', false);
            },

            triggerSearch(data) {
                this.setMapView(data.latitude, data.longitude);
                for (let key in data) {
                    if (this.locationFormData.hasOwnProperty(key)) {
                        this.locationFormData[key] = data[key];
                    }
                }
            },

            setUpdateData(data, idx) {
                this.updatedIndex = idx;
                for (let col in this.locationFormData) {
                    this.locationFormData[col] = data[col];
                }
                this.$refs['gSearch'].setInput(this.locationFormData.place_query_search);
            },

            setMapView(lat, lng) {
                this.gmapAttr.center.lat = lat;
                this.gmapAttr.center.lng = lng;
                this.gmapAttr.zoom = 8;
                this.currentMarker.lat = lat;
                this.currentMarker.lng = lng;
            },
        }
    }
</script>

<style scoped>
    .site-location-form {
        padding: 10px;
    }

    .site-location-form .ivu-form {
        border: 1px solid RGBA(112, 112, 112, 0.4);
        border-radius: 5px;
        padding: 20px 40px;

    }

    .site-form-label {
        margin-bottom: 20px;
    }
</style>
