import { defineStore } from "pinia";
import { getAirportsQry } from "@/services/common.service";
import debounce from "lodash/debounce"


export const useAirportStore = defineStore("airport", {
  state: () => ({
    _airports: {
      results: [],
    },
  }),
  getters: {
    getAirports: (state) => state._airports.results,
  },
  actions: {
    _loadAiports: debounce(function(query) {
      if (query.length > 2) {
        let params = { page_size: 5000, country: query, icao_code: query, iata_code: query, city: query, name: query };
        getAirportsQry(params)
          .then((res) => {
            this._airports.results = res.data.results;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 300), // Adjust the debounce delay (in milliseconds) as per your requirement
  },
});
