<template>
  <div class="full-height">
    <router-view
      :key="identifier"
      @triggerIdentifier="triggerIdentifier"
    />
  </div>
</template>

<script>
    export default {
        name: "CountryInformation",
        data() {
            return {
                identifier: 1,
            }
        },
        methods: {
            triggerIdentifier() {
                this.identifier += 1;
            }
        }

    }
</script>

<style>
    .vue-map-container {
        height: 100%;

    }

</style>