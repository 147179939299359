<template>
  <Row class="full-height">
    <Spin v-if="loadingData" fix />
    <!-- RAPPORT TODO LATER -->
    <!-- <Row type="flex">
      <Card class="white-modern-card mt-4 full-width" :md="12">
        <Row class="p-4">
          <p class="boldtext-card">{{ $t("Activity report") }}</p>
          <i-col :md="1">
            <i-switch
              true-color="#268044"
              class="mt-4"
              false-color="#CC0000"
              v-model="profile.user_preferences.activity_email"
              @on-change="triggerSwitchStatus('Activity')"
            />
          </i-col>
          <i-col :md="23">
            <p class="mt-4">{{ $t("Receive an activity report on missions, alerts, and emergency calls.") }}</p>
            <p class="mt-4">{{ $t("Frequency") }}</p>
            {{ profile.user_preferences.activity_frequency }}
            <Select v-model="profile.user_preferences.activity_frequency" class="mt-3" style="width: 20%">
              <Option v-for="frequency, index in frequencies" :key="index" :value="frequency.value">
                {{ frequency.text }}
              </Option>
            </Select>
          </i-col>
        </Row>
      </Card>
    </Row> -->
    <!-- NOTIFICATIONS -->
    <Row type="flex" v-if="!loadingData">
      <Card class="white-modern-card mt-4 full-width" :md="12">
        <div class="p-4">
          <p class="boldtext-card">{{ $t("Notifications") }}</p>
          <!-- NOTIFICATIONS LEFT -->
          <i-col :md="12">
            <!-- USER -->
            <Row>
              <div class="container-grey mt-4 mb-4 mr-3">
                <p>{{ $t("Notifications : User") }}</p>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :loading="lodingSwitch"
                      :disabled="!currentProfile"
                      v-model="profile.user_preferences.user_notification"
                      @on-change="triggerSwitchStatus('user_notification')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications on my mobile application") }}</p>
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :disabled="!currentProfile"
                      :loading="lodingSwitch"
                      v-model="profile.user_preferences.user_email"
                      @on-change="triggerSwitchStatus('user_email')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications by e-mail") }}</p>
                  </i-col>
                </Row>
              </div>
            </Row>
            <!-- INCIDENT -->
            <Row>
              <div class="container-grey mt-4 mb-4 mr-3">
                <p>Notifications : Incident</p>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :disabled="!currentProfile"
                      :loading="lodingSwitch"
                      v-model="profile.user_preferences.incident_notification"
                      @on-change="triggerSwitchStatus('incident_notification')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications on my mobile application") }}</p>
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :disabled="!currentProfile"
                      :loading="lodingSwitch"
                      v-model="profile.user_preferences.incident_email"
                      @on-change="triggerSwitchStatus('incident_email')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications by e-mail") }}</p>
                  </i-col>
                </Row>
              </div>
            </Row>
            <!-- EMERGENCY -->
            <Row>
              <div class="container-grey mt-4 mb-4 mr-3">
                <p>{{ this.$t("Notifications : Emergency") }}</p>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :disabled="!currentProfile"
                      :loading="lodingSwitch"
                      v-model="profile.user_preferences.emergency_notification"
                      @on-change="triggerSwitchStatus('emergency_notification')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications on my mobile application") }}</p>
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :disabled="!currentProfile"
                      :loading="lodingSwitch"
                      v-model="profile.user_preferences.emergency_email"
                      @on-change="triggerSwitchStatus('emergency_email')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications by e-mail") }}</p>
                  </i-col>
                </Row>
              </div>
            </Row>
          </i-col>
          <!-- NOTIFICATIONS RIGHT -->
          <i-col :md="12">
            <!-- MISSION -->
            <Row>
              <div class="container-grey mt-4 mb-4 ml-3">
                <p>Notifications : Mission</p>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :disabled="!currentProfile"
                      :loading="lodingSwitch"
                      v-model="profile.user_preferences.mission_notification"
                      @on-change="triggerSwitchStatus('mission_notification')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications on my mobile application") }}</p>
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :disabled="!currentProfile"
                      :loading="lodingSwitch"
                      v-model="profile.user_preferences.mission_email"
                      @on-change="triggerSwitchStatus('mission_email')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications by e-mail") }}</p>
                  </i-col>
                </Row>
              </div>
            </Row>
            <!-- ALERTES -->
            <Row>
              <div class="container-grey mt-4 mb-4 ml-3">
                <p>{{ $t("Notifications : Alerts") }}</p>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :disabled="!currentProfile"
                      :loading="lodingSwitch"
                      v-model="profile.user_preferences.alert_notification"
                      @on-change="triggerSwitchStatus('alert_notification')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications on my mobile application") }}</p>
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="2">
                    <i-switch
                      true-color="#268044"
                      class="mt-4"
                      false-color="#CC0000"
                      :disabled="!currentProfile"
                      :loading="lodingSwitch"
                      v-model="profile.user_preferences.alert_email"
                      @on-change="triggerSwitchStatus('alert_email')"
                    />
                  </i-col>
                  <i-col :md="22">
                    <p class="mt-4">{{ $t("I receive notifications by e-mail") }}</p>
                  </i-col>
                </Row>
              </div>
            </Row>
          </i-col>
        </div>
      </Card>
    </Row>
  </Row>
</template>

<script>
import { patchAccountPreferences } from "@/services/auth.service";
export default {
  name: "TabNotification",
  props: ["profile", "loadingData"],
  components: {
    patchAccountPreferences,
  },
  data() {
    return {
      frequency: null,
      lodingSwitch: false,
      frequencies: [
        {
          text: this.$t("Weekly"),
          value: "1",
        },
        {
          text: this.$t("Monthly"),
          value: "1",
        },
        {
          text: this.$t("Daily"),
          value: "1",
        },
      ],
    };
  },
  computed: {
    currentProfile() {
      const userIdParams = this.$route.params.userId;
      const internalId = this.userProfileStore.getInternatlId;
      if (userIdParams == internalId) {
        return true;
      }
      return false;
    },
  },
  methods: {
    triggerSwitchStatus(preferenceToUpdate) {
      const id = this.profile.id;
      const updatedPreferences = { [preferenceToUpdate]: this.profile.user_preferences[preferenceToUpdate] };

      this.lodingSwitch = true;
      patchAccountPreferences(id, updatedPreferences)
        .then((res) => {
          this.profile.user_preferences = res.data;
          this.$Message.success(this.$t("Successfully updated notifications preferences"));
        })
        .catch((err) => {
          this.$Message.error({
            content: this.$t("Error occurred"),
            duration: 5,
          });
        })
        .finally(() => {
          this.lodingSwitch = false;
        });
    },
  },
};
</script>

<style scoped>
.container-grey {
  padding: 1.438em;
  border: solid 1px #bab7cc;
}
</style>
