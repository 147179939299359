<template>
  <Form
    ref="gtkForm"
    :label-width="140"
    label-position="left"
    :model="fact.data"
    class="form-content"
  >
    <Row
      type="flex"
      align="middle"
      justify="end"
      :gutter="10"
      class="form-action"
    >
      <i-col>
        <Button
          v-if="fact.id"
          type="success"
          class="confirm-button"
          @click="triggerUpdate"
        >
          {{ $t('Update') }}
        </Button>
        <Button
          v-else
          type="success"
          class="confirm-button"
          @click="triggerCreate"
        >
          {{ $t('Create') }}
        </Button>
      </i-col>
      <i-col>
        <Button
          type="default"
          class="cancel-button"
          @click="triggerCancel()"
        >
          {{ $t('Cancel') }}
        </Button>
      </i-col>
    </Row>
    <Row
      type="flex"
      align="middle"
      :gutter="15"
    >
      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          :label="$t('Off. language:')"
          prop="language"
        >
          <i-input v-model="fact.data.language" />
        </FormItem>
      </i-col>
      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          :label="$t('Currency:')"
          prop="currency"
        >
          <i-input
            v-model="fact.data.currency"
            type="textarea"
            :autosize="{minRows: 1,maxRows: 4}"
          />
        </FormItem>
      </i-col>
      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          :label="$t('Capital:')"
          prop="capital"
        >
          <i-input v-model="fact.data.capital" />
        </FormItem>
      </i-col>
      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          :label="$t('Population (capital):')"
          prop="population"
        >
          <i-input
            v-model="fact.data.population"
            type="textarea"
            :autosize="{minRows: 1,maxRows: 4}"
          />
        </FormItem>
      </i-col>
      <i-col
        :xs="24"
        class="gtk-hr"
      >
        <hr>
      </i-col>
      <i-col :xs="24">
        <FormItem
          :label="$t('Head of state:')"
          prop="head_of_site"
        >
          <i-input v-model="fact.data.head_of_state" />
        </FormItem>
        <FormItem
          :label="$t('Jet lag:')"
          prop="jet_lag"
        >
          <i-input
            v-model="fact.data.jet_lag"
            type="textarea"
            :autosize="{minRows: 1,maxRows: 4}"
          />
        </FormItem>
      </i-col>
      <i-col :xs="24">
        <div
          v-for="(item, idx) in fact.data.extra_info"
          :key="idx"
        >
          <FormItem
            :label-width="0"
            :prop="`extra_info.${idx}.value`"
            class="gtk-form-extra-value"
            :rules="extraRules"
          >
            <i-input
              v-model="item.value"
              :placeholder="$t('Value')"
            />
          </FormItem>
          <FormItem
            :label-width="0"
            :prop="`extra_info.${idx}.content`"
            :rules="extraRules"
            class="gtk-form-extra-content"
          >
            <i-input
              v-model="item.content"
              :placeholder="$t('Content')"
            >
              <Icon
                slot="suffix"
                type="ios-close"
                size="20"
                class="pointer-cursor"
                @click.native="deleteExtraData(idx)"
              />
            </i-input>
          </FormItem>
        </div>
        <FormItem>
          <Button
            type="dashed"
            icon="md-add"
            @click="triggerExtraData"
          >
            {{ $t('Add extra info') }}
          </Button>
        </FormItem>
      </i-col>
    </Row>
  </Form>
</template>

<script>
    import _cloneDeep from "lodash/cloneDeep"
    import {postCountryFactsQry, putCountryFactsQry} from "../../../services/countryInfo.service";

    export default {
        name: "GoodToKnowForm",
        props: ["factType"],
        data() {
            return {
                extraRules: {required: true, message: this.$t('Required'), trigger: 'blur'},
                fact: {
                    id: null,
                    country: this.$route.params.country,
                    country_fact_type: null,
                    data: {
                        language: "",
                        capital: "",
                        currency: "",
                        population: "",
                        head_of_state: "",
                        jet_lag: "",
                        climate: "",
                        sanitary_risks: "",
                        extra_info: []
                    }
                },
                factDataRules: {
                    language: [],
                    capital: [],
                    currency: [],
                    population: [],
                    head_of_state: [],
                    jet_lag: [],
                    climate: [],
                    sanitary_risks: [],
                    extra_info: [],
                },
            }
        },
        methods: {

            triggerCreate() {
                this.$refs['gtkForm'].validate((valid) => {
                    if (valid) {
                        const formData = {
                            ...this.fact,
                            country_fact_type: this.factType.id,
                        };

                        postCountryFactsQry(formData)
                            .then(res => {
                                this.$emit('gtkCreated', res.data);
                                this.$Message.success(this.$t('Successfully created your data'));
                                this.triggerCancel();
                            })
                            .catch(() => {
                                this.$Message.error({content: this.$t('Unexpected error when creating your data'), duration: 5});
                            })

                    }
                })
            },

            triggerUpdate() {
                this.$refs['gtkForm'].validate((valid) => {
                    if (valid) {
                        putCountryFactsQry(this.fact.id, this.fact)
                            .then(res => {
                                this.$emit('gtkCreated', res.data, 0);
                                this.$Message.success(this.$t('Successfully updated your data'));
                                this.triggerCancel();
                            })
                            .catch(() => {
                                this.$Message.error({content: this.$t('Unexpected error when updating your data'), duration: 5});
                            })


                    }
                })
            },

            setUpdateData(data) {
                this.fact = _cloneDeep(data);
            },

            triggerCancel() {
                this.resetDataName(this, 'fact');
                this.$emit('triggerCancel');
            },

            triggerExtraData() {
                this.fact.data.extra_info.push({value: "", content: ""});
            },

            deleteExtraData(idx) {
                this.fact.data.extra_info.splice(idx, 1);
            }
        },

    }
</script>

<style>
    .gtk-form-extra-content {
        margin-left: 140px;
    }

    .gtk-form-extra-value {
        float: left;
        position: absolute;
        width: 100px;
    }

</style>
