export { PulsingSymbol }

class PulsingSymbol {

    constructor(object) {

        this.viewer = object.viewer
        this.maplibregl = this.viewer.maplibregl
        this.map = this.viewer.map
        this.layers = object.layers
        this.filters = {}
        this.filterValues = []


        this.build()
    }

    build() {

        var self = this

        this.addPulsingRenderer()

        this.layers.forEach(element => {
            this.setPulse(element)
        });

        this.viewer._eventConstructor("pulsingsymbol-load", this)


    }

    getFilter(filterProperties) {

        if (filterProperties.length > 1) {

            var filter = ["all"]
            filterProperties.forEach(element => {
                filter.push(["in", element.key].concat(element.values))
            });
        }
        else {
            var filter = ["in", filterProperties[0].key].concat(filterProperties[0].values)
        }



        return filter
    }

    addFilterValue(layerId, key, value) {


        if (!this.filters[layerId]) {
            this.filters[layerId] = []
        }


        if (this.filters[layerId].length == 0) {
            this.filters[layerId].push(["in", key, value])
        }
        else {

            if (this.filters[layerId][0] != "all") {
                this.filters[layerId].unshift("all")
            }

            var isExisting = false
            this.filters[layerId].forEach(element => {
                if (element[1] == key) {
                    element.push(value)
                    isExisting = true
                }

            });
            if (isExisting == false) {
                this.filters[layerId].push(["in", key, value])
            }
        }


        if (this.filters[layerId].length == 1) {
            this.map.setFilter(layerId, this.filters[layerId][0])
        }
        else if (this.filters[layerId].length > 1) {
            this.map.setFilter(layerId, this.filters[layerId])
        }


        this.filterValues.push(value)


        //this.viewer.clustering.setPulseValue(layerId, this.filterValues)




    }

    removeFilterValue(layerId, value) {



        var index = this.filters[layerId].indexOf(value);
        if (index !== -1) {
            this.filters[layerId].splice(index, 1);
        }
        this.map.setFilter(layerId, this.filters[layerId])



        var index = this.filterValues.indexOf(value);
        if (index !== -1) {
            this.filterValues.splice(index, 1);
        }

        this.viewer.clustering.setPulseValue(layerId, this.filterValues)

    }

    setPulse(element) {


        var self = this



        var layerProperties = {
            'id': element.id,
            'type': 'symbol',
            'source': element.layerId,

            'layout': {
                'icon-image': 'pulsing-dot',
                "icon-allow-overlap": true,
                "icon-ignore-placement": false
            }
        }


        if (element.minzoom) {
            layerProperties.minzoom = element.minzoom
        }
        if (element.maxzoom) {
            layerProperties.maxzoom = element.maxzoom
        }
        if (element.filter) {
            this.filters[element.id] = this.getFilter(element.filter)
            layerProperties.filter = this.filters[element.id]
        }

        this.map.addLayer(layerProperties)

        this.map.moveLayer(element.id, element.moveBefore);





    }



    addPulsingRenderer() {

        var self = this

        var size = 100;


        // This implements `StyleImageInterface`
        // to draw a pulsing dot icon on the map.
        var pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map,
            // get the rendering context for the map canvas.
            onAdd: function () {
                var canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            // Call once before every frame where the icon will be used.
            render: function () {
                var duration = 1000;
                var t = (performance.now() % duration) / duration;

                var radius = (size / 2) * 0.3;
                var outerRadius = (size / 2) * 0.7 * t + radius;
                var context = this.context;

                // Draw the outer circle.
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = 'rgba(255, 0, 0,' + (1 - t) + ')';
                context.fill();

                // Draw the inner circle.
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = 'rgba(255, 100, 100, 1)';
                context.strokeStyle = 'white';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                // Update this image's data with data from the canvas.
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;

                // Continuously repaint the map, resulting
                // in the smooth animation of the dot.
                self.map.triggerRepaint();

                // Return `true` to let the map know that the image was updated.
                return true;
            }
        };


        this.map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
    }




}