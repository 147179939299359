<template>
  <Card class="country-general">
    <div class="general-header">
      <strong style="font-size: 14px">{{ $t('Security risk levels') }}</strong>
      <Icon
        v-if="s2ec.user.is_admin || s2ec.user.is_superuser"
        type="md-create"
        :title="$t('Edit')"
        size="20"
        class="pointer-cursor"
        @click.native="showUpdate"
      />
    </div>
    <div class="risk-content">
      <Row
        type="flex"
        align="middle"
        :gutter="10"
      >
        <i-col :sm="10">
          <span>{{ $t('Foreign hostility') }}</span>
        </i-col>
        <i-col :sm="14">
          <CountryInfoSlider
            v-model="risk.foreign_hostility"
            :disabled="disallowEdit"
          />
        </i-col>
      </Row>
      <Row
        type="flex"
        align="middle"
        :gutter="10"
      >
        <i-col :sm="10">
          {{ $t('Political and social unrest') }}
        </i-col>
        <i-col :sm="14">
          <CountryInfoSlider
            v-model="risk.political_social_unrest"
            :disabled="disallowEdit"
          />
        </i-col>
      </Row>
      <Row
        type="flex"
        align="middle"
        :gutter="10"
      >
        <i-col :sm="10">
          {{ $t('Organized crime and delinquency') }}
        </i-col>
        <i-col :sm="14">
          <CountryInfoSlider
            v-model="risk.organized_crime_delinquency"
            :disabled="disallowEdit"
          />
        </i-col>
      </Row>
      <Row
        type="flex"
        align="middle"
        :gutter="10"
      >
        <i-col :sm="10">
          {{ $t('Terrorism') }}
        </i-col>
        <i-col :sm="14">
          <CountryInfoSlider
            v-model="risk.terrorism"
            :disabled="disallowEdit"
          />
        </i-col>
      </Row>
      <Row
        type="flex"
        align="middle"
        :gutter="10"
      >
        <i-col :sm="10">
          {{ $t('Travels') }}
        </i-col>
        <i-col :sm="14">
          <CountryInfoSlider
            v-model="risk.travels"
            :disabled="disallowEdit"
          />
        </i-col>
      </Row>
      <Row
        type="flex"
        align="middle"
        :gutter="10"
      >
        <i-col :sm="10">
          {{ $t('Global health rates') }}
        </i-col>
        <i-col :sm="14">
          <CountryInfoSlider
            v-model="risk.global_health_rates"
            :disabled="disallowEdit"
          />
        </i-col>
      </Row>
      <div>
        <Row
          v-if="!disallowEdit"
          type="flex"
          justify="end"
          :gutter="10"
        >
          <i-col>
            <Button
              type="success"
              class="confirm-button"
              @click="triggerUpdate"
            >
              {{ $t('Update') }}
            </Button>
          </i-col>
          <i-col>
            <Button
              type="default"
              class="cancel-button"
              @click="triggerCancel"
            >
              {{ $t('Cancel') }}
            </Button>
          </i-col>
        </Row>
      </div>
    </div>
  </Card>
</template>

<script>
    import CountryInfoSlider from "./CountryInfoSlider"
    import {patchRiskQry} from "../../../services/countryInfo.service";

    export default {
        name: "SecurityRiskLevels",

        components: {
            CountryInfoSlider
        },
        props: ['value'],
        data() {
            return {
                disallowEdit: true,
                riskCopy: {}
            }
        },
        computed: {
            risk: {
                get() {
                    return this.value
                }
            }
        },
        methods: {
            triggerUpdate() {
                this.loading = true;
                const {country, global_risk, ...data} = this.risk;
                patchRiskQry(country, data)
                    .then(res => {
                        this.$emit('input', res.data);
                        this.riskCopy = {};
                        this.disallowEdit = true;
                        this.$Message.success(this.$t('Successfully updated your data'));
                        this.loading = false;
                    })
                    .catch(err => {
                        this.$Message.error(this.$t('Error updating your data'));
                        this.loading = false;
                    })

            },

            showUpdate() {
                this.riskCopy = {...this.value};
                this.disallowEdit = false;
            },

            triggerCancel() {
                this.$emit('input', {...this.riskCopy});
                this.disallowEdit = true;
            }
        }
    }
</script>

<style scoped>

</style>
