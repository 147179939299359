<template>
  <Row class="full-height">
    <!-- SETTINGS -->
    <Row type="flex">
      <!-- PROFIL PICTURE -->
      <i-col :md="6" :xs="24">
        <Card class="white-modern-card mt-4 mr-4" :md="12">
          <Row class="p-4" style="height: 10.875em">
            <p class="boldtext-card">{{ $t("Profile picture") }}</p>
            <div class="edit-profile-image circular-image mt-5" style="width: 64px; height: 64px">
              <Upload
                id="profilePic"
                type="drag"
                action=""
                :disabled="!currentProfile || loadingPicture"
                :before-upload="beforeDocumentUpload"
              >
                <Spin fix v-if="loadPicture" />
                <div v-else>
                  <img v-if="currentAvatar" size="50" :src="currentAvatar" />
                  <Icon v-else type="ios-person" size="50" />
                </div>
              </Upload>
            </div>
          </Row>
          <Row type="flex" class="mt-5 button-card">
            <i-col :md="12" :xs="24" align="center">
              <Upload type="drag" action="" :before-upload="beforeDocumentUpload">
                <Button
                  class="button-left-action"
                  :disabled="!currentProfile || loadingPicture"
                  style="margin-left: var(--offset-0) !important"
                >
                  <p>
                    {{ $t("Download picture") }}
                  </p>
                </Button>
              </Upload>
            </i-col>
            <i-col :md="12" :xs="24" align="center">
              <Button
                :loading="loadingPicture"
                :disabled="loadingPicture || !currentProfile"
                class="button-right-action"
                @click="removeAvatar()"
              >
                <p>{{ $t("Delete picture") }}</p>
              </Button>
            </i-col>
          </Row>
        </Card>
      </i-col>
      <!-- ACCOUNT STATUS -->
      <i-col :md="6" :xs="24">
        <Card class="white-modern-card mt-4 mr-4" :md="12">
          <Row class="p-4" style="height: 10.875em">
            <p class="boldtext-card">{{ $t("Account status") }}</p>
            <Row class="mt-4">
              {{ $t("Status") }}
              <div v-if="profile.is_active" class="status-button-on mt-3">{{ $t("Active") }}</div>
              <div v-else-if="profile.user_onboarding_complete" class="status-button-off mt-3">
                {{ $t("Inactive") }}
              </div>
              <div
                v-else-if="!profile.user_onboarding_complete && profile.user_origin == 'INVITATION'"
                class="status-button-wait mt-3"
              >
                {{ $t("Invited") }}
              </div>
              <div v-else class="status-button-wait mt-3">
                {{ $t("Waiting") }}
              </div>
            </Row>
          </Row>
          <Row type="flex" class="mt-5 button-card">
            <i-col :md="24" :xs="24" align="center">
              <!-- ACTIF / INACTIF -->
              <Button
                v-if="profile.is_active"
                class="button-center-action"
                :loading="loadingStatus"
                :disabled="loadingStatus || currentProfile"
                @click="triggerChangeStateUser()"
              >
                {{ $t("Disabled user") }}
              </Button>
              <Button
                v-else-if="profile.user_onboarding_complete"
                class="button-center-action"
                :disabled="loadingStatus"
                :loading="loadingStatus"
                @click="triggerChangeStateUser()"
              >
                {{ $t("Activate") }}
              </Button>
              <!-- INVITATION -->
              <i-col v-else-if="!profile.user_onboarding_complete && profile.user_origin == 'INVITATION'" :md="24">
                <!-- RE-SEND  -->
                <i-col :md="12" :xs="24" align="center">
                  <Button
                    class="button-center-action border-right"
                    :disabled="loadingStatus"
                    :loading="loadingStatus"
                    @click="triggerSendNewInvitation()"
                  >
                    {{ $t("Send new invitation") }}
                  </Button>
                </i-col>
                <!-- DELETE INVITATION  -->
                <i-col :md="12" :xs="24" align="center">
                  <Button
                    class="button-right-action"
                    :disabled="loadingStatus"
                    :loading="loadingStatus"
                    @click="triggerDeleteElement()"
                  >
                    {{ $t("Delete invitation") }}
                  </Button>
                </i-col>
              </i-col>
              <!-- WAITING : case SELF_SIGNUP / OTHER -->
              <i-col v-else :md="24">
                <!-- VALIDATE -->
                <i-col :md="12" :xs="24" align="center">
                  <Button
                    class="button-center-action border-right"
                    :disabled="loadingStatus"
                    :loading="loadingStatus"
                    @click="triggerValidateUser()"
                  >
                    {{ $t("Validate") }}
                  </Button>
                </i-col>
                <!-- REJECT -->
                <i-col :md="12" :xs="24" align="center">
                  <Button
                    class="button-right-action"
                    :disabled="loadingStatus"
                    :loading="loadingStatus"
                    @click="triggerDeleteElement()"
                  >
                    <Icon type="md-close-circle" size="18" class="mr-3" />
                    {{ $t("Reject request") }}
                  </Button>
                </i-col>
              </i-col>
              <!-- END -->
            </i-col>
          </Row>
        </Card>
      </i-col>
      <!-- ROLE -->
      <i-col :md="6" :xs="24">
        <Card class="white-modern-card mt-4 mr-4" :md="12">
          <Row class="p-4" style="height: 10.875em">
            <p class="boldtext-card">{{ $t("Role") }}</p>
            <p class="mt-4">{{ $t("Role") }}</p>
            <div class="inputFake mt-4">
              {{ $t(profile.user_role_display) }}
            </div>
          </Row>
          <Row type="flex" class="mt-5 button-card">
            <i-col :md="24" :xs="24" align="center">
              <Button
                class="button-center-action"
                @click="triggerShowModal()"
                :disabled="!isManagerOrAbove() || currentProfile || isIdenticalRoleOrObove(profile.user_role)"
              >
                <p>{{ $t("Change role") }}</p>
              </Button>
            </i-col>
          </Row>
        </Card>
      </i-col>
      <userManagement
        ref="mangeUser"
        v-model="modalData"
        :loadingRole="loadingRole"
        :modalOnSubmit="triggerManageUser"
        @hide="triggerHideModal"
      />
      <!-- PASSWORD -->
      <i-col :md="6" :xs="24">
        <Card class="white-modern-card mt-4 mr-4" :md="12">
          <Row class="p-4" style="height: 10.875em">
            <p class="boldtext-card">{{ $t("Password") }}</p>
            <Row class="mt-4">
              <p>{{ $t("Password") }}</p>
              <Input class="mt-4" :disabled="!false" type="password" placeholder="***********" style="width: 80%" />
            </Row>
          </Row>
          <Row type="flex" class="mt-5 button-card">
            <i-col :md="24" :xs="24" align="center">
              <Button class="button-center-action" @click="triggerShowPasswordModal()" :disabled="!currentProfile">
                <p>{{ $t("Change password") }}</p>
              </Button>
              <ChangePassword ref="changePassword" :profile="profile" v-model="showChangePasswordModal" />
            </i-col>
          </Row>
        </Card>
      </i-col>
    </Row>
    <!-- PERSONAL INFORMATION -->
    <Row type="flex">
      <i-col :md="24" :xs="24">
        <Card class="white-modern-card mt-5 account-information" :md="12">
          <Row class="p-4">
            <p class="boldtext-card">{{ $t("Personal information") }}</p>
            <!-- FIRSTNAME LASTNAME EMAIL -->
            <Form ref="updateForm" :rules="infoRules" :model="profile">
              <Row class="mt-5">
                <i-col :md="6">
                  <p>{{ $t("Name") }}</p>
                  <FormItem prop="last_name" :error="infoErrors.last_name">
                    <Input
                      v-model="profile.last_name"
                      class="mt-3"
                      type="text"
                      @input="onInputChange"
                      :placeholder="$t('Name')"
                      style="width: 90%"
                    />
                  </FormItem>
                </i-col>
                <i-col :md="6">
                  <p>{{ $t("First name") }}</p>
                  <FormItem prop="first_name" :error="infoErrors.first_name">
                    <Input
                      v-model="profile.first_name"
                      class="mt-3"
                      type="text"
                      @input="onInputChange"
                      :placeholder="$t('First name')"
                      style="width: 90%"
                    />
                  </FormItem>
                </i-col>
                <i-col :md="12">
                  <i-col :md="12">
                    <p>{{ $t("Email") }}</p>
                    <Input
                      v-model="profile.email"
                      class="mt-3"
                      type="text"
                      :placeholder="$t('Email')"
                      disabled
                      style="width: 90%"
                    />
                  </i-col>
                  <i-col :md="12">
                    <Transition v-if="profilUpdate" name="fade" appear>
                      <Button
                        class="check-action-button mt-btn mt-3 mr-3"
                        style="width: auto; text-align: center"
                        :loading="loadingInputs"
                        @click="triggerUpdateInfo()"
                      >
                        <Icon type="md-checkmark-circle-outline" size="18" />
                      </Button>
                    </Transition>
                    <Transition v-if="profilUpdate" name="fade" appear>
                      <Button
                        class="cancel-action-button mt-btn mt-3"
                        :disabled="loadingInputs"
                        style="width: auto; text-align: center"
                        @click="cancel()"
                      >
                        <Icon type="md-close-circle" size="18" />
                      </Button>
                    </Transition>
                  </i-col>
                </i-col>
              </Row>
              <input type="submit" hidden />
            </Form>
            <!-- NATIONALITIES -->
            <Row class="mt-2">
              <div v-if="profile.nationalities != null">
                <div v-for="(nationality, index) in profile.nationalities" :key="nationality.id">
                  <i-col :md="6">
                    <p>{{ $t("Nationality") + " " + (index + 1) }}</p>
                    <i-select
                      filterable
                      :value="nationality.country"
                      :placeholder="$t('Nationality')"
                      class="mt-3"
                      style="width: 90%"
                      @on-change="triggerSelectNationality($event, index)"
                    >
                      <i-option
                        v-for="country in worldInfoStore.countryList"
                        :key="country.value"
                        :value="country.value"
                      >
                        <img :src="country.flag" alt="Drapeau du pays" class="flag-image" style="position: inherit" />
                        {{ $t(country.display_name) }}
                      </i-option>
                    </i-select>
                  </i-col>
                </div>
              </div>
              <i-col :md="5">
                <Button
                  class="cancel-button-secondary mt-3 mt-btn mr-3"
                  style="width: auto; text-align: center"
                  :loading="loadingNatio"
                  @click="removeNationality()"
                  v-if="profile.nationalities != null && profile.nationalities.length > 1"
                >
                  <Icon type="ivu-icon ivu-icon-md-trash mb-2 " size="23" />
                </Button>
                <Button
                  class="action-button-secondary mt-3 mt-btn"
                  style="width: auto; text-align: center"
                  :loading="loadingNatio"
                  :disabled="profile.nationalities != null && profile.nationalities.length >= 3"
                  @click="tempAddNationality()"
                >
                  {{ $t("Add nationality") }}
                </Button>
              </i-col>
            </Row>
            <!-- LANGUAGES SPOKEN -->
            <Row class="mt-4">
              <div v-if="profile.languages_spoken != null">
                <div v-for="(language, index) in profile.languages_spoken" :key="language.id">
                  <i-col :md="6">
                    <p>{{ $t("Spoken language") + " " + (index + 1) }}</p>
                    <i-select
                      filterable
                      :value="language.language"
                      @on-change="triggerSelectLanguage($event, index)"
                      :placeholder="$t('Spoken language')"
                      class="mt-3"
                      style="width: 90%"
                    >
                      <i-option
                        v-for="country in worldInfoStore.countryList"
                        :key="country.value"
                        :value="country.value"
                      >
                        <img :src="country.flag" alt="Drapeau du pays" class="flag-image" style="position: inherit" />
                        {{ $t(country.display_name) }}
                      </i-option>
                    </i-select>
                  </i-col>
                </div>
              </div>
              <i-col :md="5">
                <Button
                  class="cancel-button-secondary mt-3 mt-btn mr-3"
                  style="width: auto; text-align: center"
                  :loading="loadingLang"
                  @click="removeLanguage()"
                  v-if="profile.languages_spoken != null && profile.languages_spoken.length > 1"
                >
                  <Icon type="ivu-icon ivu-icon-md-trash mb-2 " size="23" />
                </Button>
                <Button
                  class="action-button-secondary mt-3 mt-btn mr-3"
                  style="width: auto; text-align: center"
                  :loading="loadingLang"
                  :disabled="profile.languages_spoken != null && profile.languages_spoken.length >= 3"
                  @click="tempAddLanguage()"
                >
                  {{ $t("Add language") }}
                </Button>
              </i-col>
            </Row>
          </Row>
        </Card>
      </i-col>
    </Row>
    <!-- CELLPHONES  -->
    <Row type="flex" class="mb-3">
      <i-col :md="24" :xs="24">
        <Card class="white-modern-card mt-5 account-information" style="height: auto" :md="12">
          <Row class="p-4">
            <p class="boldtext-card">{{ $t("Cellphones") }}</p>
            <Row>
              <i-col :md="16" class="mt-3">
                <Table
                  ref="selection"
                  class="s2ec-table missions-table mt-5"
                  :columns="tableCellphones.columns"
                  :data="sortedCellphones"
                ></Table>
              </i-col>
            </Row>
            <Row class="mt-2">
              <Transition name="fade" appear>
                <Button
                  class="action-button-secondary mt-btn mt-4 mr-3"
                  style="width: auto; text-align: center"
                  :loading="loading"
                  :disabled="getCellphones"
                  @click="triggerShowCellphoneModal()"
                >
                  {{ $t("Add cellphone") }}
                </Button>
              </Transition>
              <AddCellphone ref="cellForm" :profile="profile" :tempUpdate="tempUpdate" v-model="showAddCelphoneModal" />
            </Row>
          </Row>
        </Card>
      </i-col>
    </Row>
  </Row>
</template>

<script>
import { patchAccount, postInviteUserQry, deleteUserQry } from "@/services/auth.service";
import { patcherUserQry, patchUser, postValidateUserQry } from "@/services/userManagement.service"; // Fixme naming
import { FORM_RULE_REQUIRED_FIELD } from "@/helpers/common.helper";
import { getCountryNameByIso } from "@/helpers/country.helper";
import userManagement from "@/views/accounts/modals/userManagement";
import ChangePassword from "@/views/profile/modals/ChangePassword";
import AddCellphone from "@/views/profile/modals/AddCellphone";

export default {
  name: "TabProfile",
  props: ["profile", "loadingData"],
  components: {
    ChangePassword,
    AddCellphone,
    userManagement,
  },
  data() {
    return {
      info: {
        first_name: "",
        last_name: "",
      },
      infoErrors: {
        first_name: "",
        last_name: "",
      },
      infoRules: {
        first_name: [FORM_RULE_REQUIRED_FIELD],
        last_name: [FORM_RULE_REQUIRED_FIELD],
      },
      tempInfoProfile: {
        first_name: "",
        last_name: "",
      },
      tempUpdate: {},
      tableCellphones: {
        columns: [
          {
            title: this.$t("Priority"),
            key: "priority",
          },
          {
            title: this.$t("Country"),
            key: "country",
            render: (h, params) => {
              return h("div", {}, [
                h("p", {}, [
                  h("img", {
                    attrs: {
                      src: this.worldInfoStore.getCountryFlag(params.row.country),
                      alt: "Drapeau du pays",
                      class: "flag-image",
                    },
                  }),
                  h(
                    "p",
                    {
                      attrs: {
                        class: "ml-4",
                      },
                    },
                    this.$t(getCountryNameByIso(params.row.country))
                  ),
                ]),
              ]);
            },
          },
          {
            title: this.$t("Cellphone"),
            key: "number",
          },
          {
            title: this.$t("Type"),
            key: "type",
            render: (h, params) => {
              return h("div", {}, [
                h(
                  "p",
                  {
                    attrs: {
                      class: "ml-4",
                    },
                  },
                  this.$t(params.row.type)
                ),
              ]);
            },
          },
          {
            title: this.$t("Actions"),
            key: "actions",
            align: "right",
            render: (h, params) => {
              const actions = [];

              if (this.isManagerOrAbove() || this.currentProfile) {
                return [
                  h("Button", {
                    props: {
                      icon: "md-create",
                    },
                    attrs: {
                      title: this.$t("Edit"),
                    },
                    class: "table-button",
                    on: {
                      click: (evt) => {
                        evt.stopPropagation();
                        this.triggerShowUpdateModal(params.row, params.index);
                      },
                    },
                  }),
                  h("Button", {
                    props: {
                      icon: "md-trash",
                    },
                    attrs: {
                      title: this.$t("Delete"),
                    },
                    class: "table-button",
                    on: {
                      click: (evt) => {
                        evt.stopPropagation();
                        this.removeCellphone(params.index, params.row);
                      },
                    },
                  }),
                ];
              }
            },
          },
        ],
        data: [],
      },
      modalData: {
        showModal: false,
        form: {},
      },
      tempCellphones: [],
      profilUpdate: false,
      loadingPicture: false,
      loadingRole: false,
      loadingNatio: false,
      loadingLang: false,
      loadingStatus: false,
      loadingInputs: false,
      loadPicture: false,
      loading: false,
      showChangePasswordModal: false,
      showAddCelphoneModal: false,
      avatarTemp: null, // Loaded version of avatarFileForUpload, for temporary display
    };
  },
  computed: {
    currentProfile() {
      const userIdParams = this.$route.params.userId;
      const internalId = this.userProfileStore.getInternatlId;
      if (userIdParams == internalId) {
        return true;
      }
      return false;
    },
    sortedCellphones() {
      if (this.haveCellphone) {
        return this.profile.cellphones.sort((a, b) => a.priority - b.priority);
      }
    },
    getCellphones() {
      if (this.profile.cellphones == null) {
        return false;
      } else {
        if (this.profile.cellphones.length >= 3) {
          return true;
        }
        return false;
      }
    },
    haveCellphone() {
      if (this.profile.cellphones == null) {
        return false;
      } else {
        if (this.profile.cellphones.length > 0) {
          return true;
        }
        return false;
      }
    },
    currentAvatar() {
      return this.profile.avatar;
    },
    stillLoadingData() {
      return this.loadingData;
    },
  },
  watch: {
    stillLoadingData(loading) {
      if (loading == false) {
        if (this.tableCellphones.data != null) {
          this.tableCellphones.data = this.profile.cellphones;
        }
        this.getTempInfoProfile();
      }
    },
  },
  methods: {
    triggerShowUpdateModal(infoCellphone, idx) {
      this.tempUpdate = { ...infoCellphone, ...idx };
      this.triggerShowCellphoneModal();
    },

    triggerShowCellphoneModal() {
      (this.showAddCelphoneModal = false), (this.showAddCelphoneModal = true);
    },

    removeCellphone(idx, params) {
      this.tempCellphones = this.profile.cellphones.slice();
      this.tempCellphones.splice(idx, 1);
      this.$AppModal.AppConfirm({
        loading: true,
        extraTitle: this.$t("Delete a cellphone number"),
        message: this.$t("You are about to delete this cellphone") + " : " + params.number,
        onConfirm: () => {
          if (params.priority == 1) {
            this.tempCellphones.forEach((cellphone) => {
              if (cellphone.priority == 2) {
                cellphone.priority = 1;
              }
              if (cellphone.priority == 3) {
                cellphone.priority = 2;
              }
            });
          }
          if (params.priority == 2) {
            this.tempCellphones.forEach((cellphone) => {
              if (cellphone.priority == 3) {
                cellphone.priority = 2;
              }
            });
          }
          if (this.currentProfile) {
            // Current profile
            patchAccount({
              cellphones: JSON.stringify(this.tempCellphones),
            })
              .then((res) => {
                this.profile.cellphones = res.data.cellphones;
                this.$Message.success({
                  content: this.$t("Your cellphone number successfully deleted"),
                  duration: 5,
                });
              })
              .catch(() => {
                this.$Message.error({
                  content: this.$t("Error while deleting your cellphone number"),
                  duration: 5,
                });
              })
              .finally(() => {
                this.$AppModal.remove();
              });
          } else {
            // Selected user
            patchUser(this.profile.id, {
              cellphones: JSON.stringify(this.tempCellphones),
            })
              .then((res) => {
                this.profile.cellphones = res.data.cellphones;
                this.$Message.success({
                  content: this.$t("Cellphone number of user successfully deleted"),
                  duration: 5,
                });
              })
              .catch(() => {
                this.$Message.error({
                  content: this.$t("Error while deleting cellphone number of the user"),
                  duration: 5,
                });
              })
              .finally(() => {
                this.$AppModal.remove();
              });
          }
        },
      });
    },
    tempAddNationality() {
      if (this.profile.nationalities == null) {
        this.profile.nationalities = [];
      }
      if (this.profile.nationalities.length <= 2) {
        this.profile.nationalities.push({ country: "" });
      }
    },
    tempAddLanguage() {
      if (this.profile.languages_spoken == null) {
        this.profile.languages_spoken = [];
      }
      if (this.profile.languages_spoken.length <= 2) {
        this.profile.languages_spoken.push({ language: "" });
      }
    },
    removeLanguage() {
      let idx = this.profile.languages_spoken.length;
      this.profile.languages_spoken.splice(idx - 1, 1);
      if (this.currentProfile) {
        // Current profile
        patchAccount({
          languages_spoken: JSON.stringify(this.profile.languages_spoken),
        });
      } else {
        // Selected user
        patchUser(this.profile.id, {
          languages_spoken: JSON.stringify(this.profile.languages_spoken),
        });
      }
    },
    removeNationality() {
      let idx = this.profile.nationalities.length;
      this.profile.nationalities.splice(idx - 1, 1);
      if (this.currentProfile) {
        // Current profile
        patchAccount({
          nationalities: JSON.stringify(this.profile.nationalities),
        });
      } else {
        // Selected user
        patchUser(this.profile.id, {
          nationalities: JSON.stringify(this.profile.nationalities),
        });
      }
    },
    triggerSelectNationality(iso, idx) {
      this.profile.nationalities[idx] = { country: iso };
      this.loadingNatio = true;
      if (this.currentProfile) {
        // Current profile
        patchAccount({
          nationalities: JSON.stringify(this.profile.nationalities),
        })
          .then((res) => {
            this.$Message.success({
              content: this.$t("Successfully updated your nationality"),
              duration: 5,
            });
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("There was an error while updating your nationality"),
              duration: 5,
            });
          })
          .finally(() => {
            this.loadingNatio = false;
          });
      } else {
        // Selected user
        const data = {
          nationalities: JSON.stringify(this.profile.nationalities),
        };
        patchUser(this.profile.id, data)
          .then((res) => {
            this.$Message.success({
              content: this.$t("Successfully updated nationality of user"),
              duration: 5,
            });
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("There was an error while updating nationality of user"),
              duration: 5,
            });
          })
          .finally(() => {
            this.loadingNatio = false;
          });
      }
    },
    triggerSelectLanguage(iso, idx) {
      this.profile.languages_spoken[idx] = { language: iso };
      // Mettre à jour le compte avec la nouvelle chaîne JSON
      this.loadingLang = true;
      if (this.currentProfile) {
        patchAccount({
          languages_spoken: JSON.stringify(this.profile.languages_spoken),
        })
          .then((res) => {
            // this.profile.languages_spoken = res.data.languages_spoken;
            this.$Message.success({
              content: this.$t("Successfully updated your language"),
              duration: 5,
            });
          })
          .catch((err) => {
            this.$Message.error({
              content: this.$t("There was an error while updating your language"),
              duration: 5,
            });
          })
          .finally(() => {
            this.loadingLang = false;
          });
      } else {
        patchUser(this.profile.id, {
          languages_spoken: JSON.stringify(this.profile.languages_spoken),
        })
          .then((res) => {
            this.$Message.success({
              content: this.$t("Successfully updated language of user"),
              duration: 5,
            });
          })
          .catch((err) => {
            this.$Message.error({
              content: this.$t("There was an error while updating language of user"),
              duration: 5,
            });
          })
          .finally(() => {
            this.loadingLang = false;
          });
      }
    },
    getTempInfoProfile() {
      this.tempInfoProfile.last_name = this.profile.last_name;
      this.tempInfoProfile.first_name = this.profile.first_name;
    },
    onInputChange() {
      this.profilUpdate = true;
    },
    triggerUpdateInfo() {
      this.$refs["updateForm"].validate((valid) => {
        if (valid) {
          const data = {
            first_name: this.profile.first_name,
            last_name: this.profile.last_name,
          };
          this.loadingInputs = true;
          // PATCH MY PROFILE (error permission if use patcherUserQry)
          if (this.currentProfile) {
            patchAccount(data)
              .then((res) => {
                this.$Message.success({
                  content: this.$t("Successfully updated your profile") + ` : ${data.first_name} ${data.last_name}`,
                  duration: 5,
                });
                this.setUserProfile(res.data);
                this.getTempInfoProfile();
              })
              .catch((err) => {
                this.$Message.error({
                  content: this.$t("There was an error while updating your profile"),
                  duration: 5,
                });
              })
              .finally(() => {
                this.loadingInputs = false;
                this.profilUpdate = false;
              });
          } else {
            patcherUserQry(this.profile.id, data)
              .then((res) => {
                this.$Message.success({
                  content: this.$t("Successfully updated profile") + ` : ${data.first_name} ${data.last_name}`,
                  duration: 5,
                });
                this.setUserProfile(res.data);
                this.getTempInfoProfile();
              })
              .catch((err) => {
                this.$Message.error({
                  content: this.$t("There was an error while updating the profile"),
                  duration: 5,
                });
              })
              .finally(() => {
                this.loading = false;
                this.profilUpdate = false;
              });
          }
        }
      });
    },
    cancel() {
      this.profile.first_name = this.tempInfoProfile.first_name;
      this.profile.last_name = this.tempInfoProfile.last_name;
      this.profile.cellphone = this.tempInfoProfile.cellphone;
      this.profilUpdate = false;
    },
    setUserProfile(data) {
      this.profile.first_name = data.first_name;
      this.profile.last_name = data.last_name;
      // this.profile.cellphone = data.cellphone;
      this.profile.full_name = data.first_name + " " + data.last_name;
    },
    triggerShowPasswordModal() {
      (this.showChangePasswordModal = false), (this.showChangePasswordModal = true);
    },

    triggerDeleteElement() {
      this.$AppModal.AppConfirm({
        extraTitle: this.$t("Delete"),
        message: this.$t("You are about to delete this item. Are you sure to continue"),
        loading: true,
        cancelText: this.$t("Cancel"),
        onConfirm: () => {
          deleteUserQry(this.profile.id, { status: this.userStatus })
            .then(() => {
              this.$AppModal.remove();
              this.$Message.success(this.$t("Successfully deleted user"));
            })
            .catch(() => {
              this.$AppModal.remove();
              this.$Message.error({
                content: this.$t("Error occurred deleting user"),
                duration: 5,
              });
            })
            .finally(() => {
              this.$router.push({
                name: "users",
                params: {
                  id: this.profile.client,
                  contractId: this.profile.contracts[0],
                },
              });
            });
        },
      });
    },
    async triggerSendNewInvitation() {
      const data = {
        message: "reinvited",
        id: this.profile.id,
        email: this.profile.email,
        client: this.profile.client,
        contract: this.profile.contracts[0],
        user_role: 1, // Traveler by default
        reference_uid: this.profile.reference_uid,
      };
      this.loadingStatus = true;
      await postInviteUserQry(data)
        .then(() => {
          this.$Message.success(this.$t("Successfully reinvited") + ` ${data.email}`);
        })
        .catch((err) => {
          const error = err.response;
          this.$Message.error({ content: this.$t("Error occurred inviting") + " " + ` ${data.email}`, duration: 5 });
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
    triggerValidateUser() {
      this.loadingStatus = true;
      postValidateUserQry(this.profile.id)
        .then(() => {
          this.profile.is_active = true;
          this.$Message.success(this.$t("Successfully validated user registration"));
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred"),
            duration: 5,
          });
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
    triggerChangeStateUser() {
      this.loadingStatus = true;
      patcherUserQry(this.profile.id, { is_active: !this.profile.is_active })
        .then(() => {
          this.$Message.success(this.$t("Successfully updated") + " : " + this.profile.full_name);
          this.profile.is_active = !this.profile.is_active;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred"),
            duration: 5,
          });
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
    beforeDocumentUpload(avatar) {
      let reader = new FileReader();
      reader.readAsDataURL(avatar);
      let infoFormData = { avatar: avatar };
      this.loadPicture = true;
      reader.onload = () => {
        patchAccount(infoFormData)
          .then((res) => {
            this.userProfileStore._internalUser.profile.avatar = reader.result;
            this.$Message.success(this.$t("Successfully updating your profile image"));
          })
          .catch((err) => {
            this.$Message.error({
              content: this.$t("There was an error while updating your profile image"),
              duration: 5,
            });
          })
          .finally(() => {
            this.loadPicture = false;
          });
      };
    },
    removeAvatar() {
      if (this.currentAvatar) {
        let infoFormData = { avatar: "" };
        this.loadingPicture = true;

        patchAccount(infoFormData)
          .then((res) => {
            this.userProfileStore._internalUser.profile.avatar = null;
            this.$Message.success(this.$t("Successfully deleted your profile image"));
          })
          .catch((err) => {
            this.$Message.error({
              content: this.$t("There was an error while deleting your profile image"),
              duration: 5,
            });
          })
          .finally(() => {
            this.profile.avatar = "";
            this.loadingPicture = false;
          });
      } else {
        this.$Message.error({
          content: this.$t("No profile picture"),
          duration: 2,
        });
      }
    },
    triggerManageUser() {
      const userData = this.modalData.form;
      this.loadingRole = true;
      patcherUserQry(this.profile.id, { user_role: userData.user_role })
        .then((res) => {
          this.$Message.success(this.$t("Successfully updated"));
          this.profile.user_role = res.data.user_role;
          this.profile.user_role_display = res.data.user_role_display;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred on validating the request"),
            duration: 5,
          });
        })
        .finally(() => {
          this.triggerHideModal();
          this.loadingRole = false;
        });
    },
    triggerHideModal() {
      this.modalData.showModal = false;
    },
    triggerShowModal() {
      this.modalData.form = { ...this.profile };
      (this.modalData.showModal = false), (this.modalData.showModal = true);
    },
  },
};
</script>
<style scoped>
.custom-button:hover {
  color: var(--secondary-bleu) !important;
}

.white-modern-card .ivu-card-body {
  padding: 0 !important;
}
.border-right {
  border-right: solid 1px var(--border);
}
.ivu-btn {
  white-space: normal !important;
}

.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.mt-btn {
  margin-top: 25px;
}
.custom-icon {
  font-size: 4rem;
}

.inputFake {
  padding: 10px;
  width: 40%;
  border-radius: 5px;
  border: solid 1px #d3d9e6;
}

tr.ivu-table-row-hover td {
  background: #08d145 !important;
}
</style>
