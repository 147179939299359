<template>
  <AppArrowedPanel
    :show-arrow="true"
    :title="$t('Warnings')"
    panel="danger"
  >
    <div class="emergency-content">
      <Card
        v-for="(alert) in alerts.results"
        :key="alert.id"
        :dis-hover="true"
        class="emergency-item card-list"
      >
        <div class="emergency-inner-content">
          <p class="name">
            {{ alert.user_value | userDisplay }}
          </p>
          <p class="thin-text">
            {{ alert.user_value.cellphone || $t('(No Phone)') }}
          </p>
          <p class="thin-text">
            {{ alert.user_value.address || $t('(No Address)') }}
          </p>
          <p class="thin-text">
            {{ fullDateFormat(alert.created) }}
          </p>
        </div>
        <div class="emergency-item-action">
          <img
            src="../../assets/images/ico/location.png"
            :style="{opacity: alert.device_track ? '100%' : '50%'}"
            class="pointer-cursor"
            alt=""
            @click="navigateLocation(alert.device_track)"
          >
          <Poptip
            placement="right-start"
            transfer
            popper-class="emergency-profile"
            width="450"
          >
            <img
              src="../../assets/images/ico/person.png"
              class="pointer-cursor"
              alt=""
            >
            <div slot="content">
              <Card class="emergency-profile text-center">
                <Avatar
                  class="alert-user-avatar"
                  :src="alert.user_value.avatar"
                  icon="ios-person"
                /> <!-- FIXME AVATAR IS BROKEN -->
                <p> {{ alert.user_value | userDisplay }} </p>
              </Card>

              <Card class="emergency-profile-info">
                <p> {{ $t('Personal information') }} </p>
                <Row>
                  <i-col :md="10">
                    {{ $t('Passport number') }}
                  </i-col>
                  <i-col :md="14">
                    {{ alert.user_value.passport_number }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('Passport number 2') }}
                  </i-col>
                  <i-col :md="14">
                    {{ alert.user_value.passport_number2 }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('Cellphone') }}
                  </i-col>
                  <i-col :md="14">
                    {{ alert.user_value.cellphone }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('Landline') }}
                  </i-col>
                  <i-col :md="14">
                    {{ alert.user_value.landline }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('Address') }}
                  </i-col>
                  <i-col :md="14">
                    {{ alert.user_value.address }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('City') }}
                  </i-col>
                  <i-col :md="14">
                    {{ alert.user_value.city }}
                  </i-col>
                </Row>
                <Row>
                  <i-col :md="10">
                    {{ $t('Country') }}
                  </i-col>
                  <i-col :md="14">
                     {{ alert.user_value.country }}
                  </i-col>
                </Row>
              </Card>
              <Card class="emergency-profile-info">
                <p> {{ $t('Emergency contact') }} </p>
                <div>
                  <span> {{ alert.user_value.emergency_contact_first_name }} </span>
                  <span> {{ alert.user_value.emergency_contact_last_name }}</span>
                </div>
                <div>
                  <span> {{ alert.user_value.emergency_contact_email }} </span>
                </div>
                <div>
                  <span> {{ alert.user_value.emergency_contact_email }} </span>
                </div>
                <div>
                  <span> {{ alert.user_value.emergency_contact_cellphone }} </span>
                </div>
              </Card>
            </div>
          </Poptip>
        </div>
      </Card>
      <infinite-loading
        :identifier="identifier"
        @infinite="onInfiniteScroll"
      >
        <span slot="no-more" />
      </infinite-loading>
    </div>
  </AppArrowedPanel>
</template>

<script>

    import infiniteLoading from "vue-infinite-loading"
    import AppArrowedPanel from "@/components/common/AppArrowedPanel"
    import {getAlertsQry} from "@/services/alerts.service";
    import {getStringUrlParams} from "@/helpers/common.helper";
    import DateFormat from "@/mixins/dateFormat"

    // Fixme ranme this to Alerts.vue (it's a LIST)

    export default {
        name: "Alert",
        components: {
            AppArrowedPanel,
            infiniteLoading,
        },
        mixins: [DateFormat],
        props: {
            filterParams: {default: {}}
        },
        data() {
            return {
                identifier: false,
                alerts: {
                    count: 0,
                    next: null,
                    previous: null,
                    results: []
                },
            }
        },
        created() {
            this.$eventBus.$on("clientContextSelected", this.resetIdentifier);
        },
        beforeDestroy() {
            this.$eventBus.$off("clientContextSelected", this.resetIdentifier);
        },
        methods: {
            onInfiniteScroll($state) {
                let company = this.userProfileStore.getSelectedCompany;
                let getQueryParams = {user_is_inside: true, company: company, ...this.filterParams};
                if (this.alerts.next) getQueryParams = getStringUrlParams(this.alerts.next);

                getAlertsQry(getQueryParams)
                    .then(res => {
                        const {results, ...data} = res.data;
                        Object.assign(this.alerts, data);
                        this.alerts.results.push(...results);
                        $state.loaded();
                        if (!this.alerts.next) {
                            $state.complete();
                        }
                    })
            },

            navigateLocation(deviceTrack) {
                this.$router.push({
                    name: 'map',
                    query: {location: `${deviceTrack.latitude},${deviceTrack.longitude},12`}
                })

            },

            resetIdentifier() {
                this.resetDataName(this, 'alerts');
                this.identifier = !this.identifier;
            },
        }
    }
</script>

<style scoped>
    .emergency-profile .ivu-avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        line-height: 80px;
        font-size: 60px;
    }
    .alert-user-avatar.ivu-avatar>img {
        width: auto;
        height: 100%;
    }

</style>
