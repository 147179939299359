<template>
  <div class="mt-3">
    <Row type="flex" justify="space-between">
      <i-col :lg="4" :md="3" :xs="0">
        <p class="level one">1</p>
        {{ $t("Minimal risk") }}
      </i-col>
      <i-col :lg="4" :md="3" :xs="0">
        <p class="level two">2</p>
        {{ $t("Low risk") }}
      </i-col>
      <i-col :lg="4" :md="3" :xs="0">
        <p class="level three">3</p>
        {{ $t("Medium risk") }}
      </i-col>
      <i-col :lg="4" :md="3" :xs="0">
        <p class="level four">4</p>
        {{ $t("Important risk") }}
      </i-col>
      <i-col :lg="4" :md="3" :xs="0">
        <p class="level five">5</p>
        {{ $t("High risk") }}
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "RisksLegend",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.level {
  margin-right: 11px;
  margin-top: -5px;
  display: initial;
  padding: 5px 10px 5px 10px;
  color: white;
  border-radius: 50px;
  float: left;
}

.one {
  padding: 5px 11px 5px 11px !important;
  border: solid 1px #5178b7;
  background-color: #4f9cea;
}

.two {
  border: solid 1px #2b803f;
  background-color: #39aa54;
}

.three {
  border: solid 1px #a79731;
  background-color: #e3c500;
}

.four {
  border: solid 1px #c76727;
  background-color: #ee823a;
}

.five {
  border: solid 1px #ad4a4a;
  background-color: #d54848;
}
</style>
