import {axiosInstance, uncachedAxiosInstance} from "./base"


export function optionsMissionsQry(params) {
    return axiosInstance.options("api/missions/")
}

export function getMissionsQry(params) {
    return axiosInstance.get("api/missions/", {params})
}

export function postMissionQry(data) {
    return axiosInstance.post(`api/missions/`, data)
}

export function putMissionQry(id, data) {
    return axiosInstance.put(`api/missions/${id}/`, data)
}

export function patchMissionQry(id, data) {
    return axiosInstance.patch(`api/missions/${id}/`, data)
}

export function deleteMissionQry(id) {
    return axiosInstance.delete(`api/missions/${id}/`)
}

export function searchFlightsQry(params) {
    return axiosInstance.get(`api/search-flights/`, {params})
}

export function exportMissionsQry() {
    return uncachedAxiosInstance({
      url: `api/export-missions/`,
      method: "GET",
      responseType: "blob", // important
    });
}
