import Vue from "vue";
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB07KKffVqmK7R9V_7KRBhypB12931AGNE',
        libraries: 'places,drawing',
        language: 'en',
    },
})
