<template>
  <Form
    ref="pointsForm"
    :model="pointsFormData"
    :rules="pointsDataRules"
    :label-width="80"
    label-position="left"
    class="form-content"
  >
    <Row
      type="flex"
      align="middle"
      justify="end"
      :gutter="10"
      class="form-action"
    >
      <i-col>
        <Button
          v-if="updatedIndex !== null"
          type="success"
          long
          class="confirm-button"
          @click="triggerUpdate"
        >
          {{ $t('Update') }}
        </Button>
        <Button
          v-else
          type="success"
          long
          class="confirm-button"
          @click="triggerCreate"
        >
          {{ $t('Create') }}
        </Button>
      </i-col>
      <i-col>
        <Button
          type="default"
          long
          class="cancel-button"
          @click="triggerCancel"
        >
          {{ $t('Cancel') }}
        </Button>
      </i-col>
    </Row>
    <Row :gutter="15">
      <i-col :sm="12">
        <FormItem
          :label="$t('Category:')"
          prop="point_type"
          :error="pointsFormErrors.point_type"
        >
          <i-select v-model="pointsFormData.point_type">
            <i-option
              v-for="type in pointTypeList"
              :key="type.value"
              :value="type.value"
            >
              {{ type.display_name }}
            </i-option>
          </i-select>
        </FormItem>
        <FormItem
          :label="$t('Name:')"
          prop="name"
          :error="pointsFormErrors.name"
        >
          <i-input v-model="pointsFormData.name" />
        </FormItem>
      </i-col>
      <i-col :sm="12">
        <FormItem
          :label="$t('Landline:')"
          prop="landline"
          :error="pointsFormErrors.landline"
        >
          <AppPhoneInput
            ref="form-landline"
            v-model="pointsFormData.landline"
            style="width: 100%"
          />
        </FormItem>
        <FormItem
          :label="$t('Emergency number:')"
          prop="emergency_contact"
          :error="pointsFormErrors.emergency_contact"
        >
          <AppPhoneInput
            ref="form-emergency-contact"
            v-model="pointsFormData.emergency_contact"
            style="width: 100%"
          />
        </FormItem>
      </i-col>

      <i-col :sm="24">
        <FormItem
          :label-width="0"
          prop="place_query_search"
          :error="pointsFormErrors.place_query_search"
        >
          <AppGoogleSearch
            v-model="pointsFormData.place_query_search"
            @searched="triggerSearch"
          />
        </FormItem>
      </i-col>
      <i-col :sm="12">
        <FormItem
          :label-width="0"
          prop="latitude"
          :error="pointsFormErrors.latitude"
        >
          <i-input
            v-model="pointsFormData.latitude"
            :placeholder="$t('Latitude')"
          />
        </FormItem>
      </i-col>
      <i-col :sm="12">
        <FormItem
          :label-width="0"
          prop="longitude"
          :error="pointsFormErrors.longitude"
        >
          <i-input
            v-model="pointsFormData.longitude"
            :placeholder="$t('Longitude')"
          />
        </FormItem>
      </i-col>
      <i-col :sm="12">
        <FormItem
          :label-width="0"
          prop="city"
          :error="pointsFormErrors.city"
        >
          <i-input
            v-model="pointsFormData.city"
            :placeholder="$t('City')"
          />
        </FormItem>
      </i-col>
      <i-col :sm="12">
        <FormItem
          :label-width="0"
          prop="address"
          :error="pointsFormErrors.address"
        >
          <i-input
            v-model="pointsFormData.address"
            :placeholder="$t('Address')"
          />
        </FormItem>
      </i-col>

      <i-col :sm="24">
        <FormItem
          :label-width="0"
          prop="country"
          :error="pointsFormErrors.country"
        >
          <Select
            v-model="pointsFormData.country"
            :placement="'top'"
            class="app-disabled-select"
          >
            <Option
              v-for="country in worldInfoStore.countryList"
              :key="country.value"
              :value="country.value"
              :placeholder="$t('Country')"
            >
              {{ country.display_name }}
            </Option>
          </Select>
        </FormItem>
      </i-col>
      <i-col :sm="24">
        <GmapMap
          :center="gmapAttr.center"
          :zoom="gmapAttr.zoom"
          :options="gmapAttr.options"
          map-type-id="terrain"
          class="create-points-map"
        >
          <gmap-marker :position="currentMarker" />
        </GmapMap>
      </i-col>
    </Row>
    <Spin
      v-if="loading"
      fix
    />
  </Form>
</template>

<script>
    import AppGoogleSearch from "@/components/common/AppGoogleSearch"
    import AppPhoneInput from "@/components/common/AppPhoneInput";
    import {
        optionPointOfInterestQry,
        postPointOfInterestQry,
        putPointOfInterestQry
    } from "@/services/countryInfo.service";
    import Vue from 'vue'

    export default {
        name: "PointsForm",

        components: {
            AppGoogleSearch,
            AppPhoneInput
        },
        props: ['factType'],
        data() {
            return {
                pointTypeList: [],
                updatedIndex: null,
                previousPointType: null,
                loading: false,
                pointsFormData: {
                    id: null,
                    point_type: null,
                    name: "",
                    landline: "",
                    emergency_contact: "",
                    place_query_search: "",
                    longitude: 0,
                    latitude: 0,
                    city: "",
                    address: "",
                    country: ""
                },
                pointsDataRules: {
                    point_type: [
                        {required: true, type: "number", message: this.$t('Please provide a category'), trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: this.$t('Please provide a name'), trigger: 'blur'},
                    ],
                    landline: [],
                    emergency_contact: [],
                    place_query_search: [
                        {required: true, message: this.$t('Please  provide a location'), trigger: 'blur'},
                    ],
                    longitude: [
                        {required: true, type: 'number', message: this.$t('Please provide an address'), trigger: 'blur'},
                    ],
                    latitude: [
                        {required: true, type: 'number', message: this.$t('Please provide an address'), trigger: 'blur'},
                    ],
                    city: [],
                    address: [],
                    country: []
                },

                pointsFormErrors: {
                    point_type: "",
                    name: "",
                    landline: "",
                    emergency_contact: "",
                    place_query_search: "",
                    longitude: "",
                    latitude: "",
                    city: "",
                    address: "",
                    country: ""
                },

                gmapAttr: {
                    center: {lat: 10, lng: 10},
                    zoom: 4,
                    options: Vue.config.gmapDefaultOptions
                },
                currentMarker: {
                    lat: 10,
                    lng: 10,
                }
            }
        },
        created() {

            optionPointOfInterestQry()
                .then(res => {
                    this.pointTypeList = res.data.actions.POST.point_type.choices;

                })

        },
        methods: {
            triggerCreate() {
                this.$refs['pointsForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        postPointOfInterestQry(this.pointsFormData)
                            .then(res => {
                                this.$emit('pointsCreated', res.data, null);
                                this.triggerCancel();
                                this.loading = false;
                            })
                            .catch((err) => {
                                this.loading = false;
                                this.resetDataName(this, 'pointsFormErrors');
                                const error = err.response;
                                if (error.status === 400) {
                                    for (let col in error.data) {
                                        this.pointsFormErrors[col] = error.data[col][0];
                                    }
                                }
                                this.$Message.error({content: this.$t('Error creating your points'), duration: 5});
                            })
                    }
                })
            },
            triggerUpdate() {
                this.$refs['pointsForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        putPointOfInterestQry(this.pointsFormData.id, this.pointsFormData)
                            .then(res => {
                                this.loading = false;
                                this.$emit('pointsCreated', res.data, this.updatedIndex, this.previousPointType);
                                this.triggerCancel();
                            })
                            .catch((err) => {
                                this.loading = false;
                                this.resetDataName(this, 'pointsFormErrors');
                                const error = err.response;
                                if (error.status === 400) {
                                    for (let col in error.data) {
                                        this.pointsFormErrors[col] = error.data[col][0];
                                    }
                                }
                                this.$Message.error({content: this.$t('Error updating your points'), duration: 5});
                            })
                    }
                })

            },
            triggerCancel() {
                this.resetDataName(this, 'pointsFormData');
                this.updatedIndex = null;
                this.previousPointType = null;
                this.$emit('closeForm');
            },

            triggerSearch(data) {
                this.setMapView(data.latitude, data.longitude);
                for (let key in data) {
                    if (this.pointsFormData.hasOwnProperty(key)) {
                        this.pointsFormData[key] = data[key];
                    }
                }
            },


            setMapView(lat, lng) {
                this.gmapAttr.center.lat = lat;
                this.gmapAttr.center.lng = lng;
                this.gmapAttr.zoom = 8;
                this.currentMarker.lat = lat;
                this.currentMarker.lng = lng;
            },

            setUpdateData(data, idx, point_type) {
                this.updatedIndex = idx;
                this.previousPointType = point_type;
                for (let col in this.pointsFormData) {
                    this.pointsFormData[col] = data[col];
                }

                if (data.latitude && data.longitude) {
                    this.setMapView(data.latitude, data.longitude);
                }
            }
        },
    }
</script>

<style scoped>
    .points-form {
        max-width: 800px;
        padding: 80px 50px 0 50px;
        margin: 0 auto;
    }

    .points-form-action {
        position: flex;
        top: 70px;
        right: 100px;
        width: 300px;
    }

    .create-points-map {
        height: 300px;
        margin-bottom: 20px;
    }


</style>
