<template>
  <Form
    ref="locationForm"
    :model="location"
    :rules="locationRules"
  >
    <Row
      type="flex"
      :gutter="20"
    >
      <i-col
        :xs="24"
        :sm="24"
      >
        <FormItem
          prop="place_query_search"
          :label-width="0"
        >
          <AppGoogleSearch
            v-model="location.place_query_search"
            @searched="triggerSearch"
          />
        </FormItem>
      </i-col>

      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          prop="longitude"
          :label-width="0"
        >
          <InputNumber
            v-model="location.longitude"
            style="width: 140px;"
            :step="0.001"
            :title="$t('Longitude')"
            :placeholder="$t('Longitude')"
          />
        </FormItem>
      </i-col>

      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          prop="latitude"
          :label-width="0"
        >
          <InputNumber
            v-model="location.latitude"
            :step="0.001"
            style="width: 140px;"
            :title="$t('Latitude')"
            :placeholder="$t('Latitude')"
          />
        </FormItem>
      </i-col>

      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          prop="affected_radius"
          :label-width="0"
        >
          <InputNumber
            v-model="radius"
            :title="$t('Geofencing radius (m)')"
            :min="0"
            :step="1"
            :placeholder="$t('Affected radius (m)')"
            style="width:100%"
          />
        </FormItem>
      </i-col>

      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          prop="address"
          :label-width="0"
        >
          <i-input
            v-model="location.address"
            :title="$t('Address')"
            :placeholder="$t('Address')"
          />
        </FormItem>
      </i-col>

      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          prop="city"
          :label-width="0"
        >
          <i-input
            v-model="location.city"
            :title="$t('City')"
            :placeholder="$t('City')"
          />
        </FormItem>
      </i-col>

      <i-col
        :xs="24"
        :sm="12"
      >
        <FormItem
          prop="country"
          :label-width="0"
        >
          <Select
            v-model="location.country"
            :title="$t('Country')"
            :placement="'top'"
            class="incident-location-country-dropdown"
            :placeholder="$t('Country')"
          >
            <Option
              v-for="country in country_list"
              :key="country.value"
              :value="country.value"
              :placeholder="$t('Country')"
              :label="$t(country.display_name)"
            >
              <img :src="country.flag" alt="Drapeau du pays" class="flag-image" style="position: inherit;"/>
              <span class="ml-4">{{ $t(country.display_name) }}</span>
            </Option>
          </Select>
        </FormItem>
      </i-col>
    </Row>
  </Form>
</template>

<script>

    import AppGoogleSearch from "@/components/common/AppGoogleSearch"
    import { FORM_RULE_REQUIRED_FIELD } from "@/helpers/common.helper";

    export default {
        name: "LocationForm",
        components: {
            AppGoogleSearch
        },
        props: ['value', 'country_list'],
        data() {
            return {
                locationRules: {
                    longitude: [
                      FORM_RULE_REQUIRED_FIELD,
                        {type: "number", trigger: 'blur'}
                    ],
                    latitude: [
                      FORM_RULE_REQUIRED_FIELD,
                        {type: "number", trigger: 'blur'}
                    ],
                    place_query_search: [
                      FORM_RULE_REQUIRED_FIELD,
                        {trigger: 'blur'}
                    ],
                    country: [
                      FORM_RULE_REQUIRED_FIELD,
                        {trigger: 'blur'},
                    ],
                },

            }
        },
        computed: {
            location: {
                get() {
                    return this.value
                },
                set(val){
                    this.$emit('input',val);
                }
            },
            radius: {  // FIXME THIS IS NOW USELESS
                get() {
                    /*if (!this.location.affected_radius) {
                        this.location.affected_radius = null;
                    }*/
                    return this.location.affected_radius;
                },
                set(val) {
                    this.location.affected_radius = val;
                }
            }
        },
        methods: {
            triggerSearch(data) {
                this.$emit('setMapView', data.latitude, data.longitude);
                for (let key in data) {
                    if (this.value.hasOwnProperty(key)) {
                        this.value[key] = data[key];
                    }
                }
            },

            async validate() {
                return await this.$refs['locationForm'].validate(valid => valid);
            },

            resetField() {
              this.$refs['locationForm'].resetFields();
            }
        }
    }
</script>

<style scoped>
    .incident-location-country-dropdown .ivu-select-placeholder,
    .incident-location-country-dropdown .ivu-select-selected-value {
        background-color: #fff;
        cursor: text;
    }

    .incident-location-country-dropdown .ivu-select-selected-value {
        color: black;
    }
</style>
