import {axiosInstance, TILE_TTL, uncachedAxiosInstance} from "./base"
import axios from "axios"
import {jsonToFormData} from "../helpers/common.helper";

export function getSitesQry(params) {
    return axiosInstance.get("api/sites/", {params})
}

export function getSiteTilesQry(tiles, zoom, params) {
    var requests = tiles.map(coords => {
        return axiosInstance.get(`api/sites/tiles/${zoom}/${coords[0]}/${coords[1]}/`,
                                 {params, ttl: TILE_TTL})
    });
    return axios.all(requests)
}

export function postSiteQry(data) {
    return axiosInstance.post("api/sites/", data, {})
}

export function putSiteQry(id, data) {
    return axiosInstance.put(`api/sites/${id}/`, data, {})
}

export function deleteSiteQry(id) {
    return axiosInstance.delete(`api/sites/${id}/`, {})
}

export function getSiteLocationsQry(site_id, params) {
    return axiosInstance.get(`api/sites/${site_id}/locations/`, {params})
}

export function postSiteLocationQry(site_id, data) {
    return axiosInstance.post(`api/sites/${site_id}/locations/`, data, {})
}

export function putSiteLocationQry(site_id, location_id, data) {
    return axiosInstance.put(`api/sites/${site_id}/locations/${location_id}/`, data, {})
}

export function deleteSiteLocationQry(site_id, location_id) {
    return axiosInstance.delete(`api/sites/${site_id}/locations/${location_id}/`, {})
}

export function optionSiteLocationQry(site_id) {
    return axiosInstance.options(`api/sites/${site_id}/locations/`, {})
}

export function getSitesDocumentsQry(site_id, params) {
    return axiosInstance.get(`api/sites/${site_id}/documents/`, {params})
}

export function postSiteDocumentQry(site_id, data) {
    // Needs multipart data!
    return uncachedAxiosInstance.post(`api/sites/${site_id}/documents/`, jsonToFormData(data), {})
}

export function deleteSiteDocumentQry(site_id, document_id) {
    return axiosInstance.delete(`api/sites/${site_id}/documents/${document_id}/`, {})
}

export function getSitesContinent(params) {
    return axiosInstance.get("api/sites/continents-overview/", {params})  // DEPRECATED
}
