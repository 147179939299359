<template>
  <Row
    class="full-height"
    type="flex"
  >
    <i-col
      :xs="24"
      :md="12"
    >
      <Row
        class="full-height"
        type="flex"
        justify="center"
        align="middle"
      >
        <i-col
          :sm="24"
          :md="20"
          :lg="14"
        >
          <div class="auth-welcome text-center">
            <img
              src="@/assets/images/header/ilios-logo.svg"
              alt=""
            >
            <h1 class="text-center">
              {{ $t('Welcome to') }} ILIOS
            </h1>
          </div>
          <router-view />
        </i-col>
      </Row>
    </i-col>
    <i-col
      :xs="24"
      :md="12"
      class="auth-background full-height"
    />
  </Row>
</template>

<script>
    export default {
        name: "Auth",
    }
</script>

<style>

</style>
