<template>
	<div>
		<AppModal
			v-model="value"
			:width="400"
			class-name="sms-sending-popup"
		>
			<div class="text-center">
				<p class="sms-sending-note text-center">
					{{ $t("Send SMS") }} - {{ recipients.length }} {{ $t("recipients") }}
				</p>
			</div>

			<div class="sms-message-container">
				<i-input
					v-model="message"
					type="textarea"
					:row="4"
					:placeholder="$t('Your message')"
					:autosize="{ minRows: 4, maxRows: 4 }"
					@on-change="updateCharactersCount"
				/>
			</div>

			<div class="sms-characters-count">
				{{ meaningfulCharactersCount }} {{ $t("characters") }} (min
				{{ minCharactersCount }})
			</div>

			<Row type="flex" justify="center">
				<i-col
					:xs="24"
					:md="12"
					class="send-sms-actions"
					style="position: static"
				>
					<div>
						<Button
							type="success"
							class="confirm-button"
							size="large"
							long
							:disabled="meaningfulCharactersCount < minCharactersCount"
							@click="doSendSms()"
						>
							{{ $t("Send") }}
						</Button>
					</div>
					<div>
						<Button type="error" class="cancel-button" size="large" long @click="triggerClose">
							{{ $t("Cancel") }}
						</Button>
					</div>
				</i-col>
			</Row>
		</AppModal>
	</div>
</template>

<script>
import AppModal from "@/components/common/AppModal";
import { sendSms } from "../../../services/userManagement.service";

export default {
	name: "SmsSendingPopup",
	components: {
		AppModal,
	},

	props: {
		recipients: {
			type: Array,
			default: [],
		},
		value: {},
	},

	data() {
		return {
			message: "",
			meaningfulCharactersCount: 0,
			minCharactersCount: 30,
		};
	},
	mounted() {},
	methods: {
		updateCharactersCount() {
			this.meaningfulCharactersCount = this.message.trim().length;
		},
		doSendSms() {
			sendSms({ message: this.message, recipients: this.recipients })
				.then((res) => {
					this.$AppModal.AppInfo({
						extraTitle: "",
						timeout: 5000,
						message:
							this.$t(
								"Your message was properly sent, recipient phones found:"
							) +
							" " +
							res.data.recipients.length,
					});
					this.triggerClose();
				})
				.catch((error) => {
					this.$Message.error({
						content: this.$t("Error when sending SMS"),
						duration: 5,
					});
				})
				.finally(() => {});
		},
		triggerClose() {
			this.message = "";
			this.$emit("input", false);
		},
	},
};
</script>

<style>
.sms-sending-popup .sms-sending-note {
	font-weight: 500;
	font-size: 18px;
	padding-top: 10px;
	padding-bottom: 15px;
}

.sms-sending-popup .sms-message-container {
	margin-bottom: 14px;
}

.sms-sending-popup .sms-characters-count {
	font-size: 9px;
	margin-left: 15px;
	margin-bottom: 10px;
}

.sms-sending-popup .send-sms-actions > div:not(:last-of-type) {
	margin-bottom: 14px;
}
</style>
