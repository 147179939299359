import {axiosInstance, uncachedAxiosInstance, clientGrant, clientId, clientSecret} from "./base"
import {getAuthSession} from "../helpers/auth.helper";
import {jsonToFormData} from "../helpers/common.helper";
import axios from "axios";

export function postLoginQry(data) {
  const loginData = new FormData();
  loginData.append('grant_type', clientGrant);
  loginData.append('client_id', clientId);
  loginData.append('client_secret', clientSecret);
  loginData.append('username', data.email);
  loginData.append('password', data.password);

  return uncachedAxiosInstance.post("o/token/", loginData)
}

export async function postRefreshTokenQry(refresh_token) {
  const refreshTokenData = new FormData();
  refreshTokenData.append('grant_type', 'refresh_token');
  refreshTokenData.append('client_id', clientId);
  refreshTokenData.append('client_secret', clientSecret);
  refreshTokenData.append('refresh_token', refresh_token);

  return await uncachedAxiosInstance.post("o/token/", refreshTokenData)

}

export function getAccountQry(params, access_token) {
  const headers = {Authorization: `Bearer ${access_token ? access_token : getAuthSession().access_token}`};  // FIXME redundant

  return axiosInstance.get("api/account/", {headers, params})
}

export function getAccount() {/* FIXME factorize this everywhere */
  return axiosInstance.get("api/account/", {})
}

export function putAccount(data) {
  return uncachedAxiosInstance.put("api/account/", jsonToFormData(data), {})
}

export function patchAccount(data) {
  return uncachedAxiosInstance.patch("api/account/", jsonToFormData(data), {})
}


export function getAccountPreferences(id) {
  return axiosInstance.get(`api/account/${id}/preferences/`, {})
}

export function optionAccountPreferencesQry() {
  return axiosInstance.options("api/account/preferences", {})
}


export function putAccountPreferences(data) {
  return axiosInstance.put("api/account/preferences/", data, {})
}

export function patchAccountPreferences(id, data) {
  return axiosInstance.patch(`api/account/${id}/preferences/`, data, {})
}

export function putChangePassword(data) {
  return axiosInstance.put("/api/account/password-change/", data, {})
}


export function postLogoutQry() {
  let logoutData = new FormData();
  logoutData.append('client_id', clientId);
  logoutData.append('client_secret', clientSecret);
  logoutData.append('token', getAuthSession().access_token);
  let request1 = uncachedAxiosInstance.post("o/revoke_token/", logoutData);

  let logoutData2 = new FormData();
  logoutData2.append('client_id', clientId);
  logoutData2.append('client_secret', clientSecret);
  logoutData2.append('token', getAuthSession().refresh_token);
  let request2 = uncachedAxiosInstance.post("o/revoke_token/", logoutData2);

  return axios.all([request1, request2]); // No special error handling here
}

export function postUsersQry(data, params) { // FIXME: Obsolete
  return axiosInstance.post("api/users/", data, {params});
}

export function deleteUserQry(id, params) {
  return axiosInstance.delete(`api/users/${id}/`, {params});
}

export function postInviteUserQry(data, params) {
  return axiosInstance.post(`api/users/invite-user/`, data, {params});
}

export function getInvitationPasswordQry(key) {
  return axiosInstance.get(`api/users/invitation/${key}/`)
}

export function postInvitationPasswordQry(key, data) {
  return axiosInstance.post(`api/users/invitation/${key}/`, data)
}

export function postRegistrationQry(data) {
  return axiosInstance.post(`api/users/registration/`, data)
}

export function postConfirmRegistrationQry(key, data) {
  return axiosInstance.post(`api/users/registration/confirm/${key}/`, data)
}

export function getConfirmRegistrationQry(key) {
  return axiosInstance.get(`api/users/registration/confirm/${key}/`)
}

export function postUserPasswordResetQry(data) {
  return axiosInstance.post(`api/users/password-reset/`, data)
}

export function getUserPasswordResetQry(key) {
  return axiosInstance.get(`api/users/password-reset/confirm/${key}/`)
}

export function putUserPasswordResetQry(key, data) {  // FIXME this should be a POST!!!
  return axiosInstance.put(`api/users/password-reset/confirm/${key}/`, data)
}
