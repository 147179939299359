<template>
  <AppModal
    v-model="value"
    :header-name="$t('Add cellphone')"
    :footer-hide="false"
    @click:submit="addCellphones"
    @click:cancel="cancel"
    width="450"
    class="cellphone-modal"
  >
    <Form ref="cellForm" @submit.native.prevent="addCellphones" :rules="cellRules" :model="cellInfo">
      <Row class="mt-3">
        <FormItem :label="$t('Number')" prop="number" :error="cellErrors.number">
          <AppPhoneInput v-model="cellInfo.number" style="width: 100%" />
        </FormItem>
        <FormItem :label="$t('Country')" prop="country" :error="cellErrors.country">
          <i-select
            filterable
            v-model="cellInfo.country"
            :value="countryByCellphone"
            :placeholder="$t('Country')"
            class="mt-3"
          >
            <i-option
              v-for="country in worldInfoStore.countryList"
              :key="country.value"
              :value="country.value"
              :label="country.display_name"
            >
                <img :src="country.flag" alt="Drapeau du pays" class="flag-image">
                <span class="ml-4">{{ $t(country.display_name) }}</span>
            </i-option>
          </i-select>
        </FormItem>
        <FormItem :label="$t('Type')" prop="type" :error="cellErrors.type">
          <i-select v-model="cellInfo.type" :placeholder="$t('Type')" class="mt-3">
            <i-option value="Professionnel"> {{ $t("Professional") }} </i-option>
            <i-option value="Personnel"> {{ $t("Personal") }} </i-option>
          </i-select>
        </FormItem>
        <FormItem :label="$t('Priority')" prop="priority" :error="cellErrors.priority">
          <i-select v-model="cellInfo.priority" :placeholder="$t('Priority')" class="mt-3">
            <i-option value="1"> 1 </i-option>
            <i-option value="2" v-if="cellphonesLength(1)"> 2</i-option>
            <i-option value="3" v-if="cellphonesLength(2)"> 3</i-option>
          </i-select>
        </FormItem>
      </Row>
      <input type="submit" hidden />
      <Spin v-if="cellFormLoad" fix />
    </Form>
  </AppModal>
</template>

<script>
import AppModal from "@/components/common/AppModal";
import AppPhoneInput from "@/components/common/AppPhoneInput";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { FORM_RULE_REQUIRED_FIELD } from "@/helpers/common.helper";
import { patchAccount } from "@/services/auth.service";
import { patchUser } from "@/services/userManagement.service";

export default {
  name: "AddCellphone",

  components: {
    AppModal,
    AppPhoneInput,
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    profile: {
      required: true,
      type: Object,
    },
    tempUpdate: {
      required: false,
      type: Object,
    },
  },
  data() {
    const checkContact = (rule, value, callback) => {
      let str = rule.fullField;
      let regex = /^\+(?:[0-9] ?){6,14}[0-9]$/; // Regular expression for a valid international phone number

      if (this.profile.cellphone != null) {
        this.cellphoneAlreadyPresent(value);
      }

      if (this.alreadyPresent) {
        callback(new Error(this.$t("Cellphone number already present")));
      }
      if (value === "") {
        callback(new Error(this.$t("Invalid format")));
      } else if (/[a-zA-Z]/.test(value)) {
        callback(new Error(this.$t("Invalid format")));
      } else if (!regex.test(value)) {
        callback(new Error(this.$t("Invalid format")));
      } else {
        callback();
      }
    };
    return {
      cellInfo: {
        number: "",
        country: "",
        type: "",
        priority: "",
      },
      tempCellphones: [],
      cellRules: {
        number: [FORM_RULE_REQUIRED_FIELD, { validator: checkContact, trigger: "blur" }],
        country: [FORM_RULE_REQUIRED_FIELD],
        type: [FORM_RULE_REQUIRED_FIELD],
        priority: [FORM_RULE_REQUIRED_FIELD],
      },
      cellErrors: {
        number: "",
        country: "",
        type: "",
        priority: "",
      },
      alreadyPresent: false,
      editing: false,
      cellFormLoad: false,
    };
  },
  computed: {
    countryByCellphone() {
      const phoneNumber = parsePhoneNumberFromString(this.cellInfo.number);
      if (phoneNumber) {
        this.cellInfo.country = phoneNumber.country;
      } else {
        return "";
      }
    },
    currentProfile() {
      const userIdParams = this.$route.params.userId;
      const internalId = this.userProfileStore.getInternatlId;
      if (userIdParams == internalId) {
        return true;
      }
      return false;
    },
    getTempUpdate() {
      return this.tempUpdate;
    },
  },
  watch: {
    getTempUpdate(value) {
      if (this.cellInfo != null) {
        this.editing = true;
        this.cellInfo = value;
      }
    },
  },

  methods: {
    cellphonesLength(number) {
      if (this.profile.cellphones == null) {
        return false;
      } else {
        if (this.profile.cellphones.length >= number) {
            return true;
        }
        return false;
      }
    },
    cellphoneAlreadyPresent(value) {
      this.profile.cellphones.forEach((cellphone) => {
        if (cellphone.number == value) {
          this.alreadyPresent = true;
          return;
        } else {
          this.alreadyPresent = false;
        }
      });
    },
    addCellphones() {
      if (this.editing) {
        this.updateForm();
        return;
      }
      this.$refs["cellForm"].validate((valid) => {
        if (valid) {
          this.cellFormLoad = true;
          const data = {
            type: this.cellInfo.type,
            number: this.cellInfo.number,
            country: this.cellInfo.country,
            priority: this.cellInfo.priority,
          };
          if (this.profile.cellphones == null) {
            this.profile.cellphones = [];
          }
          this.tempCellphones = this.profile.cellphones.slice();
          this.tempCellphones.push(data);

          // Update priorities of existing cellphone numbers if new number has priority 1
          if (data.priority == 1) {
            this.profile.cellphones.forEach((cellphone) => {
              if (cellphone.priority == 2) {
                cellphone.priority = 3;
              }
              if (cellphone.priority == data.priority) cellphone.priority++;
            });
          }
          if (data.priority == 2) {
            this.profile.cellphones.forEach((cellphone) => {
              if (cellphone.priority == 2) {
                cellphone.priority = 3;
              }
              if (cellphone.priority == data.priority) cellphone.priority++;
            });
          }
          if (this.currentProfile) {
            // Current profile
            patchAccount({
              cellphones: JSON.stringify(this.tempCellphones),
            })
              .then((res) => {
                this.profile.cellphones = res.data.cellphones;
                this.$Message.success({
                  content: this.$t("Your cellphone number successfully created"),
                  duration: 5,
                });
              })
              .catch(() => {
                this.$Message.error({
                  content: this.$t("There was an error while creating the cellphone number"),
                  duration: 5,
                });
              })
              .finally(() => {
                this.cancel();
              });
          } else {
            // Selected user
            patchUser(this.profile.id, {
              cellphones: JSON.stringify(this.tempCellphones),
            })
              .then((res) => {
                this.profile.cellphones = res.data.cellphones;
                this.$Message.success({
                  content: this.$t("Cellphone of user successfully created"),
                  duration: 5,
                });
              })
              .catch(() => {
                this.$Message.error({
                  content: this.$t("There was an error while creating the cellphone number"),
                  duration: 5,
                });
              })
              .finally(() => {
                this.cancel();
              });
          }
        }
      });
    },
    updateForm() {
      this.$refs["cellForm"].validate((valid) => {
        if (valid) {
          this.cellFormLoad = true;
          const data = {
            type: this.cellInfo.type,
            number: this.cellInfo.number,
            country: this.cellInfo.country,
            priority: this.cellInfo.priority,
          };
          this.updatePriority(this.cellInfo._index, data.priority);
          let dataToUpdate = this.profile.cellphones
          dataToUpdate[this.tempUpdate._index] = data
          if (this.currentProfile) {
            // Current profile
            patchAccount({
              cellphones: JSON.stringify(dataToUpdate)
            })
              .then((res) => {
                this.profile.cellphones = res.data.cellphones;
                this.$Message.success({
                  content: this.$t("Your cellphone successfully updated"),
                  duration: 5,
                });
              })
              .catch(() => {
                this.$Message.error({
                  content: this.$t("There was an error while updating the cellphone"),
                  duration: 5,
                });
              })
              .finally(() => {
                this.editing = false;
                this.cancel();
              });
          } else {
            // Current profile
            patchUser(this.profile.id, {
              cellphones: JSON.stringify(dataToUpdate),
            })
              .then((res) => {
                this.profile.cellphones = res.data.cellphones;
                this.$Message.success({
                  content: this.$t("Cellphone of user successfully updated"),
                  duration: 5,
                });
              })
              .catch(() => {
                this.$Message.error({
                  content: this.$t("There was an error while updating the user cellphone"),
                  duration: 5,
                });
              })
              .finally(() => {
                this.editing = false;
                this.cancel();
              });
          }
        }
      });
    },
    updatePriority(index, newPriority) {
      // Check if new priority is valid
      if (newPriority < 1 || newPriority > 3) {
        return;
      }

      // Get the element to update
      const elementToUpdate = this.profile.cellphones[index];
      const oldPriority = this.profile.cellphones[index].priority;

      // Check if priority has changed
      if (elementToUpdate.priority === newPriority) {
        return;
      }

      // Update the priority of the element to update
      elementToUpdate.priority = newPriority;

      // Update priorities of other elements
      for (let i = 0; i < this.profile.cellphones.length; i++) {
        // Pass on rows not update
        if (i != index) {
          const currentElement = this.profile.cellphones[i];

          if (currentElement.priority == newPriority) {
            currentElement.priority = oldPriority;
          }
        }
      }
    },
    cancel() {
      this.$emit("input", false);
      this.cellFormLoad = false;
      this.cellInfo.number = "";
      this.$refs["cellForm"].resetFields();
    },
  },
};
</script>
