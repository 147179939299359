import {countries} from "../assets/countries";

export const markers = {
    "Elections": "event/marker-elections.png",
    "Meeting": "event/marker-meeting.png",
    "Military": "event/marker-military.png",
    "National Date": "event/marker-national-date.png",
    "Religious": "event/marker-religious.png",
    "Trial": "event/marker-trial.png",
    "GenericIncident": "incident/marker-generic.png",
    "Airport": "poi/marker-airport.png",
    "Embassy": "poi/marker-embassy.png",
    "Police Station": "poi/marker-police.png",
    "Station": "poi/marker-station.png",
    "Hospital": "poi/marker-hospital.png",
    "Mobile": "device/phone-marker-special.png",
    "Site": "site/site.png"
};

export function polygonCenter(polygon) {
  /* Grabbed from geojson-polygon-center package */
  if ('geometry' in polygon) polygon = polygon.geometry
  if (!Array.isArray(polygon)) polygon = polygon.coordinates[0]

  let minx, miny, maxx, maxy;  // Important, declare that properly before, else it breaks during minification

  minx = miny = 10000;
  maxx = maxy = -10000;

  for (var i = 0; i < polygon.length; i++) {
    var point = polygon[i]
    var x = point[0]
    var y = point[1]

    if (x < minx) minx = x
    else if (x > maxx) maxx = x
    if (y < miny) miny = y
    else if (y > maxy) maxy = y
  }

  return {
    type: 'Point',
    coordinates: [
      minx + ((maxx - minx) / 2),
      miny + ((maxy - miny) / 2)
    ]
  }
}

export function getMarker(marker) {
    let getMarker = markers[marker];
    if (getMarker) {
        return {
            url: require(`@/assets/images/marker/${getMarker}`),
            // size: {width: 50, height: 50, f: 'px', b: 'px'},
            scaledSize: {width: 50, height: 50, f: 'px', b: 'px'}
        }
    } else {
        return null
    }
}

function indexOfMaxList(list) {
    return list.reduce(function (accumulator, current, index) {
        if (current.length > accumulator.value) {
            return {value: accumulator.value, position: index}
        }
        return accumulator
    }, {value: 0, position: null})
}

export function polyCenter(country_alpha3) {  // Fixme renalme this
    let geojson = countries[country_alpha3];
    if (!geojson) {
        console.log(`No country frontier geojson found for ${country_alpha3}`);
        return [0, 0];  // Dirty fallback
    }

    const country_geom = geojson.features[0].geometry;
    let geoType = country_geom.type;
    const countryGeos = country_geom.coordinates;
    console.log("Displaying country_geom/countryGeos", country_geom, countryGeos);
    if (geoType === "Polygon") {
        let polyCenter = polygonCenter(country_geom);
        return polyCenter.coordinates;
    } else {
        const maxIndex = indexOfMaxList(countryGeos);
        let polyCenter = polygonCenter({"type": "Polygon", "coordinates": [countryGeos[maxIndex.position][0]]});
        return polyCenter.coordinates;
    }
}

export function countryAlertColor(risk) {
    switch (risk) {
        case 0:
            return "#2db7f5";
        case 1:
            return "lightgreen";
        case 2:
            return "green";
        case 3:
            return "#fff44f";
        case 4:
            return "orange";
        case 5:
            return "red";

        default:
            return "#000000"
    }
}

export function processGeoJson(geojson, polygon = false) {
    if (geojson) {
        if (polygon) {
            return geojson.coordinates.map((data) => {
                return data.map((latlng) => {
                    return {lat: latlng[1], lng: latlng[0]}
                })
            });

        } else {
            const feature_geometry = geojson.features[0].geometry;
            return feature_geometry.coordinates.map((data) => {
                return data.map((latlng) => {
                    return {lat: latlng[1], lng: latlng[0]}
                })

            });
        }
    }
}
