<template>
  <Row class="full-height">
    <Spin v-if="loadingData" fix />
    <!-- Client -->
    <Row type="flex">
      <i-col :md="24" :xs="24">
        <Card class="white-modern-card account-information mt-4" :md="12">
          <Row class="p-4">
            <p class="boldtext-card">{{ $t("Client") }}</p>
            <Row class="mt-4">
              <i-col :md="6">
                <p class="text-filter">{{ $t("Name of client") }}</p>
                <Input v-model="client.client_name"  disabled type="text" style="width: 90%" />
              </i-col>
              <i-col :md="6">
                <p class="text-filter">{{ $t("Type of client") }}</p>
                <Input v-model="client.client_type" class="mt-3" disabled type="text" style="width: 90%" />
              </i-col>
            </Row>
          </Row>
        </Card>
      </i-col>
    </Row>
    <!-- Contracts -->
    <Row type="flex">
      <i-col :md="24" :xs="24">
        <Card class="white-modern-card account-information mt-5" :md="12">
          <Row class="p-4">
            <p class="boldtext-card">{{ $t("Contracts") }}</p>
            <i-col :md="12" :xs="24" class="mt-3">
              <Row class="mt-3">
                <Table
                  ref="selection"
                  @on-row-click="openContract"
                  class="s2ec-table missions-table"
                  :columns="tableContracts.columns"
                  :data="profile.full_contracts"
                ></Table>
              </Row>
            </i-col>
          </Row>
        </Card>
      </i-col>
    </Row>
    <!-- SECURITY REFERENT -->
    <Row type="flex">
      <i-col :md="24" :xs="24">
        <Card class="white-modern-card mt-5 account-information" style="height: auto" :md="12">
          <Row class="p-4">
            <p class="boldtext-card">{{ $t("Security referent") }}</p>
            <Row class="mt-3">
              <i-col :md="12" class="mt-3">
                <Table
                  ref="selection"
                  :columns="tableReferents.columns"
                  class="s2ec-table missions-table"
                  :data="profile.security_referents"
                  @on-row-click="open"
                ></Table>
              </i-col>
            </Row>
            <Row class="mt-2">
              <Transition name="fade" appear>
                <Button
                  class="action-button-secondary mt-btn mt-4 mr-3"
                  style="width: auto; text-align: center"
                  :loading="loading"
                  :disabled="referentLength"
                  @click="triggerShowReferentModal()"
                >
                  {{ $t("Add security referent") }}
                </Button>
              </Transition>
              <AddReferent
                ref="referentForm"
                :profile="profile"
                :referents="referents"
                v-model="showAddReferentModal"
              />
            </Row>
          </Row>
        </Card>
      </i-col>
    </Row>
  </Row>
</template>
<script>
import moment from "moment";
import AddReferent from "@/views/profile/modals/AddReferent";
import { postRemoveReferent, getReferents } from "@/services/accounts.service";
import DateFormat from "@/mixins/dateFormat";

export default {
  name: "TabClient",
  props: ["profile", "loadingData", "client", "currentContract"],
  components: { AddReferent },
  mixins: [DateFormat],
  data() {
    return {
      loading: false,
      showAddReferentModal: false,
      tableContracts: {
        columns: [
          {
            title: this.$t("Id"),
            key: "contract_identifier",
            width: 200,
          },
          {
            title: this.$t("Name"),
            key: "contract_name",
          },
          {
            title: this.$t("Start date"),
            key: "contract_start_date",
            render: (h, params) => {
              return h("div", [h("p", this.dateFormat(params.row.contract_start_date))]);
            },
          },
          {
            title: this.$t("End date"),
            key: "contract_end_date",
            render: (h, params) => {
              return h("div", [h("p", this.dateFormat(params.row.contract_end_date))]);
            },
          },
          {
            title: this.$t("Status"),
            key: "contract_is_active",
            align: "right",
            render: (h, params) => {
              if (params.row.contract_is_active === false) {
                return h("div", {}, [
                  h(
                    "p",
                    {
                      style: {
                        float: "right",
                        backgroundColor: "#808080",
                        paddingLeft: "16px",
                        paddingTop: "6px",
                        paddingRight: "16px",
                        border: "none",
                        margin: "auto",
                        height: "30px",
                        color: "white",
                        textAlign: "center",
                        fontWeight: "500",
                        borderRadius: "5px",
                      },
                    },
                    this.$t("Completed")
                  ),
                ]);
              } else {
                return h("div", {}, [
                  h(
                    "p",
                    {
                      style: {
                        float: "right",
                        backgroundColor: "#268044",
                        paddingLeft: "16px",
                        paddingTop: "6px",
                        paddingRight: "16px",
                        border: "none",
                        textAlign: "center",
                        margin: "auto",
                        height: "30px",
                        color: "white",
                        fontWeight: "500",
                        borderRadius: "5px",
                      },
                    },
                    this.$t("Active")
                  ),
                ]);
              }
            },
          },
        ],
      },
      tableReferents: {
        columns: [
          {
            title: " ",
            width: 70,
            render: (h, params) => {
              if (params.row.avatar_absolute_url) {
                return h("div", [
                  h("img", {
                    attrs: {
                      src: params.row.avatar_absolute_url,
                    },
                    style: {
                      width: "30px",
                      height: "30px",
                      borderRadius: "50px",
                    },
                  }),
                ]);
              } else {
                return h("Icon", {
                  props: {
                    type: "ios-person",
                    size: "25",
                  },
                  style: {
                    backgroundColor: "#32307a",
                    borderRadius: "50px",
                    color: "white",
                    width: "30px",
                    height: "30px",
                    textAlign: "center",
                    paddingTop: "4px",
                  },
                });
              }
            },
          },
          {
            title: this.$t("Name"),
            key: "full_name",
          },
          {
            title: this.$t("Priority number"),
            key: "cellphone_priority",
            align: "right",
          },
          {
            title: this.$t("Email"),
            key: "email",
            align: "left",
          },
          {
            title: this.$t("Actions"),
            key: "actions",
            align: "right",
            render: (h, params) => {
              if (this.isManagerOrAbove() || this.currentProfile) {
                return [
                  h("Button", {
                    props: {
                      icon: "md-trash",
                    },
                    attrs: {
                      title: this.$t("Delete"),
                    },
                    class: "table-button",
                    on: {
                      click: (evt) => {
                        evt.stopPropagation();
                        this.removeReferent(params.index, params.row.id);
                      },
                    },
                  }),
                ];
              }
            },
          },
        ],
      },
      tempReferent: [],
      referents: {},
    };
  },
  computed: {
    stillLoadingData() {
      return this.loadingData;
    },
    currentProfile() {
      const userIdParams = this.$route.params.userId;
      const internalId = this.userProfileStore.getInternatlId;
      if (userIdParams == internalId) {
        return true;
      }
      return false;
    },
    referentLength() {
      if (this.profile.security_referents != null) {
        if (this.profile.security_referents.length >= 3) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    openContract(row) {
      this.$router.push({
        name: "users",
        params: {
          id: this.$route.params.id,
          contractId: row.id,
        },
      });
    },
    async fetchData() {
      while (!this.loadingData) {
        // Attendre que loadingData soit true
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      this.fetchManagerContract();
    },
    removeReferent(idx, id) {
      this.$AppModal.AppConfirm({
        loading: true,
        extraTitle: this.$t("Delete a referent security"),
        message: this.$t("You are about to delete referent security"),
        okText: this.$t("Delete"),
        onConfirm: () => {
          postRemoveReferent({
            security_referents: id,
            user_id: this.profile.id,
            current_id: this.userProfileStore.getInternatlId,
          })
            .then((res) => {
              this.profile.security_referents.splice(idx, 1);
              this.$Message.success({
                content: this.$t("The safety referent has been deleted"),
                duration: 5,
              });
            })
            .catch((err) => {
              this.$Message.error({
                content: this.$t(err.response.data.non_field_errors[0]),
                duration: 8,
              });
            })
            .finally(() => {
              this.$AppModal.remove();
            });
        },
      });
    },
    open(row) {
      this.$router.push({
        name: "profile",
        params: {
          id: row.client,
          contractId: row.contracts[0],
          userId: row.id,
        },
      });
      this.$emit("redirectProfile", row.id);
    },
    fetchManagerContract() {
      const params = {
        client: this.$route.params.id,
        page_size: 500,
      };
      getReferents(params)
        .then((response) => {
          const referents = response.data.filter(
            (referent) =>
              referent.id != this.$route.params.userId && referent.user_onboarding_complete == true
          );
          this.referents = referents;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred retrieving referents"),
            duration: 5,
          });
        });
    },
    triggerShowReferentModal() {
      (this.showAddReferentModal = false), (this.showAddReferentModal = true);
    },
    formatDate(date) {
      return moment(date).format("YYYY/MM/DD");
    },
  },
};
</script>
<style scoped>
.white-modern-card .ivu-card-body {
  padding: 0 !important;
}
.border-right {
  border-right: solid 1px #bab7cc;
}
.ivu-btn {
  white-space: normal !important;
}

.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
