import { QueryFeatures } from "./QueryFeatures.js"

export { InfraClusterPopup }

class InfraClusterPopup {

    constructor(object) {

        this.viewer = object.viewer
        this.map = this.viewer.map
        this.details = object.details
        this.marker = object.marker
        this.lngLat = object.marker._lngLat
        this.popup = null
        this.isInTouchSurface = false

        if (object.target) {
            this.target = object.target
        }

        this.build()
    }

    build() {

        var self = this

        this.addPopup()

        

    }

    addPopup() {



        var self = this



        var coordinates = this.lngLat;
        var description = "<div id='infra-cluster-popup' style='height:" + ((this.viewer.mapHeight / 2) - 70) + "px'></div>"



        while (Math.abs(this.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += this.lngLat.lng > coordinates[0] ? 360 : -360;
        }



        var markerHeight = 25

        var popupOffsets = {
            'top': [0, markerHeight],
            'bottom': [0, -markerHeight]
        };

        this.popup = new this.viewer.maplibregl.Popup({ offset: popupOffsets, className: "infra-popup", closeButton: false, })
        this.popup.setHTML(description)


        this.popup.setLngLat(coordinates)
        this.popup.addTo(this.map);
        document.getElementById('infra-cluster-popup').style.height = "unset"


        var touchSurface = document.getElementById('infra-cluster-popup').parentElement.parentElement

        var direction = this.getPopupDirection(touchSurface)

        switch (direction) {

            case "top":
                document.getElementById('infra-cluster-popup').parentElement.style.paddingBottom = "0px"
                break;

            case "bottom":
                document.getElementById('infra-cluster-popup').parentElement.style.paddingTop = "0px"

                break;

            case "right":
                document.getElementById('infra-cluster-popup').parentElement.style.paddingLeft = "0px"

                break;

            case "left":
                document.getElementById('infra-cluster-popup').parentElement.style.paddingRight = "0px"

                break;
            default:
                document.getElementById('infra-cluster-popup').parentElement.style.padding = "0px"
                break

        }


        //appendChild(touchSurface)



        touchSurface.onmouseenter = function (e) {
            self.isInTouchSurface = true
        }
        touchSurface.onmouseleave = function (e) {

            self.popup.remove()
            self.isInTouchSurface = false
        }

        this.marker._element.onmouseleave = function (e) {


            setTimeout(() => {
                if (self.isInTouchSurface == false) {
                    self.popup.remove()
                }
            }, 100);
        }


        for (const key in this.details) {

            const element = this.details[key];

            var queryFeatures = new QueryFeatures().query({
                viewer: self.viewer,
                key: "id",
                operator: "==",
                values: element.ids,
                layerId: key
            })

            queryFeatures.then(function (features) {
                if (features[0].length > 0) {

                    document.getElementById('infra-cluster-popup').appendChild(self.getFeaturesMarkersElements(features))


                }

            })

        }


    }

    getPopupDirection(element) {

        if (element.classList.contains('mapboxgl-popup-anchor-bottom')) {
            return "top"
        }
        if (element.classList.contains('mapboxgl-popup-anchor-top')) {
            return "bottom"
        }
        if (element.classList.contains('mapboxgl-popup-anchor-left')) {
            return "right"
        }
        if (element.classList.contains('mapboxgl-popup-anchor-right')) {
            return "left"
        }

    }


    getFeaturesMarkersElements(features) {

        var self = this

        var groupMarker = document.createElement('div')

        groupMarker.classList.add("infra-cluster-group")
        var layerId = features[1]

        features[0].forEach(element => {

            var box = document.createElement('span')
            var icon = document.createElement('img')
            icon.classList.add("infra-popup-icon")
            icon.style.backgroundImage = "background-image: url(" + this.apiLocation + "/images/symbologie/markers/marker-undefined.svg);"
            icon.setAttribute("src", this.viewer.apiLocation + "/images/symbologie/markers/marker-" + element.properties["map-icon"] + ".svg")
            icon.setAttribute("feature", JSON.stringify(element))
            icon.setAttribute('layerId', layerId)
            box.classList.add('relative')

            if (layerId == "devices") {
                if (element.properties["has_emergency"] == true) {
                    var pulseSource = document.createElement('div')
                    pulseSource.classList.add("pulse-source")
                    box.appendChild(pulseSource)
                    pulseSource.setAttribute('pulsing', true)
                }
            }

            groupMarker.appendChild(box)
            box.appendChild(icon)

            icon.onclick = function (params) {

                var feature = JSON.parse(this.getAttribute('feature'))
                var layerId = this.getAttribute('layerId')

                self.popup.remove()
                console.log("Calling addPopupToMap from icon.onclick #1");
                self.viewer.featuresPopup.addPopupToMap(feature, layerId, false, 22)

            }
            icon.onclick = function (params) {

                var feature = JSON.parse(this.getAttribute('feature'))
                var layerId = this.getAttribute('layerId')

                self.popup.remove()
                console.log("Calling addPopupToMap from icon.onclick #2");
                self.viewer.featuresPopup.addPopupToMap(feature, layerId, true, 22)

            }
        });

        return groupMarker
    }







}
