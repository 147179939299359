<template>
  <div>
    <div
      v-if="success"
      class="auth-success-message text-center"
    >
      <p> {{ $t('We sent you a verification link at your provided email') }}</p>
      <Button
        type="default"
        :to="{name: 'login'}"
      >
        {{ $t('Back') }}
      </Button>
    </div>

    <Form
      v-else
      ref="lostPassword"
      :model="forgotPassFormData"
      :rules="forgotPassRules"
      @submit.native.prevent="triggerSubmit"
    >
      <h4 class="text-center">
        {{ $t('Provide the information below to reset your password') }}
      </h4>
      <br><br>
      <FormItem
        prop="email"
        :error="forgotPassErrors.email"
      >
        <i-input
          v-model="forgotPassFormData.email"
          :placeholder="$t('Email')"
        />
      </FormItem>
      <FormItem>
        <Button
          long
          class="confirm-button"
          :loading="loading"
          @click="triggerSubmit"
        >
          {{ $t('Send request') }}
        </Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
    import {postUserPasswordResetQry} from "../../services/auth.service";

    export default {
        name: "LostPassword",
        data() {
            return {
                loading: false,
                success: false,
                forgotPassFormData: {
                    email: ""
                },
                forgotPassRules: {
                    email: [{required: true, type: "email", trigger: 'blur'}]
                },
                forgotPassErrors: {
                    email: ""
                }
            }
        },
        methods: {
            triggerSubmit() {

                this.$refs['lostPassword'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        postUserPasswordResetQry(this.forgotPassFormData)
                            .then(() => {
                                this.loading = false;
                                this.success = true;
                            })
                            .catch(err => {
                                this.loading = false;
                                this.resetDataName(this, 'forgotPassErrors');
                                const error = err.response;
                                if (error.status === 400) {
                                    for (let col in error.data) {
                                        this.forgotPassErrors[col] = error.data[col][0];
                                    }
                                }
                                this.$Message.error({content: this.$t('Error creating your data'), duration: 5});

                            })
                    }

                })


            }
        }
    }
</script>

<style scoped>


</style>
