<template>
  <Row :gutter="15" type="flex" justify="end">
    <i-col :sm="6" :md="6" :lg="3">
      <Poptip
        popper-class="app-notification-popper"
        v-model="poptipVisible"
        @on-visible-change="handleVisibleChange"
        :disabled="this.$route.name === 'notifications'"
        width="350"
      >
        <!-- FIXME Do not use private APIs of store, expose them as public if necessary or add getters -->
        <Badge :count="notificationStore.getUnreadNotifications" type="error" di class-name="notification-badge">
          <img
            class="widget"
            style="width: 23px; padding-top: 3px; alignment: center"
            src="../../assets/images/ico/blue_bell.png"
          />
        </Badge>
        <div slot="content" class="notification-content full-height">
          <div class="notification-label text-left full-width">
            <i-col :sm="22" style="display: inherit">
              <span class="history-account-actual" style="font-size: 14px !important;">{{ $t("Notifications") }}</span>
            </i-col>
            <i-col :sm="2">
              <Icon type="md-open" class="icon-open" @click="redirectToNotifications()" />
            </i-col>
          </div>
          <div class="notification-inner-content full-height text-left" ref="myDiv">
            <transition-group name="panel">
              <div v-for="(notification, index) in notificationStore.getNotifications" :key="notification.id">
                <div v-if="notification.content_object !== null">
                  <Row>
                    <!-- CARD NOTIF -->
                    <i-col :lg="23" :xs="22">
                      <div class="app-notification-popper">
                        <Row class="notification-item" name="card" :class="notification.read ? '' : 'notif-not-read'">
                          <span
                            class="close-notification"
                            @click="notificationStore._deleteNotification(notification, index)"
                          >
                            <Icon type="md-close" size="15"
                          /></span>
                          <span
                            v-if="!notification.read"
                            class="close-notification"
                            @click.stop="notificationStore._markNotificationAsRead(notification, index)"
                          >
                            <Icon type="md-eye-off" size="15"
                          /></span>
                          <!-- EMERGENCY -->
                          <div v-if="notification.notification_type === 'Emergency'">
                            <Row type="flex" align="middle">
                              <i-col :sm="4">
                                <img
                                  v-if="notification.content_object.user_value.avatar_absolute_url"
                                  class="user-png-emergency"
                                  :src="notification.content_object.user_value.avatar_absolute_url"
                                />
                                <Icon v-else type="ios-person" class="circle-incident" size="20" />
                                <Icon
                                  type="ios-call"
                                  size="10"
                                  class="circle-emergency-target"
                                  style="color: #d14646; bottom: 0px !important"
                                />
                              </i-col>
                              <i-col :sm="20">
                                <p>{{ notification.description }}</p>
                              </i-col>
                            </Row>
                          </div>
                          <!-- INFORMATIONS -->
                          <div v-else-if="notification.notification_type === 'Information'">
                            <div v-if="notification.content_object !== null">
                              <!-- INFORMATIONS -> MISSION model-->
                              <div v-if="notification.content_object.model_name === 'mission'">
                                <Row type="flex" align="middle">
                                  <i-col :md="4">
                                    <img size="20" class="flag-notification" :src="notification.content_object.flag" />
                                  </i-col>
                                  <i-col :md="20">
                                    <p class="ml-3">
                                      <strong>{{ notification.content_object.country_name }}</strong>
                                    </p>
                                  </i-col>
                                </Row>
                                <Row class="mt-3">
                                  <p>{{ notification.description }}</p>
                                </Row>
                              </div>
                              <!-- INFORMATIONS -> USER model-->
                              <div v-if="notification.content_object.model_name === 'user'">
                                <Row type="flex" align="middle">
                                  <i-col :sm="4">
                                    <img
                                      v-if="notification.user_value.avatar_absolute_url"
                                      class="user-png-emergency"
                                      :src="notification.user_value.avatar_absolute_url"
                                    />
                                    <Icon
                                      v-else
                                      type="ios-person"
                                      size="20"
                                      class="circle-incident"
                                      style="color: #fff"
                                    />
                                  </i-col>
                                  <i-col :sm="20">
                                    <p>{{ notification.description }}</p>
                                  </i-col>
                                </Row>
                              </div>
                              <!-- INFORMATIONS -> ALERT model-->
                              <div v-else-if="notification.content_object.model_name === 'alert'">
                                <Row type="flex" align="middle">
                                  <i-col :sm="4">
                                    <img
                                      v-if="notification.content_object.user_value.avatar_absolute_url"
                                      class="user-png-emergency"
                                      :src="notification.content_object.user_value.avatar_absolute_url"
                                    />
                                    <Icon v-else type="ios-person" class="circle-incident" size="20" />
                                    <Icon
                                      type="ios-locate-outline"
                                      size="10"
                                      class="circle-emergency-target"
                                      style="color: #d14646; bottom: 0px !important"
                                    />
                                  </i-col>
                                  <i-col :sm="20">
                                    <p>{{ notification.description | truncate(200) }}</p>
                                  </i-col>
                                </Row>
                              </div>
                              <!-- INFORMATIONS -> INCIDENT model-->
                              <div v-else-if="notification.content_object.model_name === 'incident'">
                                <Row type="flex" align="middle">
                                  <i-col :md="4">
                                    <img size="20" class="flag-notification" :src="notification.content_object.flag" />
                                  </i-col>
                                  <i-col :md="20">
                                    <p class="ml-3">
                                      <strong>{{ notification.content_object.country_name }}</strong>
                                    </p>
                                  </i-col>
                                </Row>
                                <Row class="mt-3">
                                  <p>{{ notification.description }}</p>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <Row type="flex" align="middle">
                              <i-col :sm="4">
                                <Icon
                                  type="ios-warning-outline"
                                  size="20"
                                  class="circle-emergency"
                                  style="color: #eeee"
                                />
                              </i-col>
                              <i-col :sm="20">
                                <p>{{ notification.description }}</p>
                              </i-col>
                            </Row>
                          </div>
                        </Row>
                        <!-- CREATED AT, SEE NOTIF -->
                        <Row style="margin-top: -12px">
                          <i-col :sm="12" :lg="23">
                            <p style="color: #2b2b2b; font-size: 10px">{{ notification.created_at | fromNow }}</p>
                          </i-col>
                          <i-col :sm="10" :lg="1">
                            <a
                              class="see-notif"
                              @click="navigateLocation(notification, notification.notification_type)"
                            >
                              {{ $t("See") }}
                            </a>
                          </i-col>
                        </Row>
                      </div>
                    </i-col>
                  </Row>
                </div>
              </div>
            </transition-group>
            <infinite-loading @infinite="notificationStore._infiniteHandler">
              <div slot="no-more">{{ $t("No more data") }}</div>
              <div slot="no-results">{{ $t("No data") }}</div>
            </infinite-loading>
          </div>
        </div>
      </Poptip>
    </i-col>

    <i-col :md="6" :lg="3">
      <Poptip
        popper-class="app-notification-popper ivu-settings"
        v-model="visibleSettings"
        width="280"
        placement="bottom-end"
      >
        <Badge type="success" class-name="notification-badge">
          <img class="widget1" style="width: 28px" src="../../assets/images/ico/setting.png" />
        </Badge>

        <div slot="content" class="notification-content full-height">
          <div class="notification-label text-left full-width">
            <span class="history-account-actual" style="font-size: 14px !important;">{{ $t("Settings") }}</span>
          </div>

          <Row class="notification-inner-content full-height text-left">
            <div class="notification-item">
              <div class="margin">
                {{ $t("Language") }} :
                <img
                  class="language-icon"
                  src="../../assets/images/icon/language/english.png"
                  width="20px"
                  height="15px"
                  @click="switchLanguage('en')"
                />
                <img
                  class="language-icon"
                  src="../../assets/images/icon/language/french.png"
                  width="20px"
                  height="15px"
                  @click="switchLanguage('fr')"
                />
              </div>
            </div>
          </Row>
        </div>
      </Poptip>
    </i-col>
    <i-col :md="6" :lg="3">
      <PanelAccountHeader :userProfile="userProfileStore.getInternalUserProfile"></PanelAccountHeader>
    </i-col>
  </Row>
</template>

<script>
import { patchNotificationsQry } from "@/services/notification.service";
import infiniteLoading from "vue-infinite-loading";
import AppScroller from "@/components/common/AppScroller";
import EmergencyPopup from "@/views/alerts/modals/EmergencyPopup";
import PanelAccountHeader from "@/views/header/PanelAccountHeader"
import i18n from "@/i18n/i18n";
import moment from "moment";
import "moment/locale/fr";

export default {
  name: "PanelSettings",
  components: {
    AppScroller,
    EmergencyPopup,
    infiniteLoading,
    PanelAccountHeader
  },
  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    },
    format(date) {
      return moment(date).format("L");
    },
  },
  data() {
    return {
      notification: 0,
      poptipVisible: false,
      visibleSettings: false,
    };
  },
  computed: {
  },
  watch: {
    $route() {
      this.handleVisibleChange(false);
      this.visibleSettings = false;
    },
  },
  methods: {
    navigateLocation(notif_info, notification_type) {
      // Mark notif as read
      if (!notif_info.read) {
        notif_info.read = true;
        this.notificationStore.decrementUnreadNotification();
        patchNotificationsQry(notif_info.id.toString(), { read: true });
      }
      if (notif_info.content_object === null || notif_info.content_object.device_track === null) {
        this.$Message.error({
          content: this.$t("User has not logged in for 30 days, no trace available"),
          duration: 5,
        });
        return;
      }

      this.notificationStore.setLastNotification(notif_info);
      switch (notification_type) {
        // Emergency
        case "Emergency":
          if (this.$route.name !== "map") {
            this.$router.push({
              name: "map",
              query: {
                location: `${notif_info.content_object.latitude},${notif_info.content_object.longitude},12`,
                id: `${notif_info.content_object.device_id}`,
              },
            });
          } else {
            this.handleVisibleChange(false);
          }
          break;
        // Information
        case "Information":
          // Alert
          if (notif_info.content_object.model_name === "alert") {
            this.$router.push({
              name: "map",
              query: {
                location: `${notif_info.content_object.device_track.latitude},${notif_info.content_object.device_track.longitude},12`,
                id: `${notif_info.content_object.device_track.device_id}`,
              },
            });
            // User
          } else if (notif_info.content_object.model_name === "user") {
            let routeParams = {
              name: notif_info.user_value.contracts.length >= 1 ? "users" : "contracts",
              params: {
                id: `${notif_info.user_value.client}`,
                contractId: `${notif_info.user_value.contracts[0]}`,
              },
            };
            if (this.$route.name !== "users") {
              this.$router.push(routeParams);
            } else {
              this.handleVisibleChange(false);
            }
            // Country info
          } else if (notif_info.content_object.model_name === "incident") {
            if (this.$route.params.country !== notif_info.content_object.country) {
              this.$router.push({
                name: "country-info-detail",
                params: {
                  country: `${notif_info.content_object.country}`,
                },
              });
            } else {
              this.handleVisibleChange(false);
            }
            // Missions
          } else if (notif_info.content_object.model_name === "mission") {
            if (this.$route.name !== "missions-management") {
              this.$router.push({ name: "missions-management" });
            } else {
              this.handleVisibleChange(false);
            }
          }
          break;
      }
    },
    switchLanguage(language) {
      if (i18n.locale === language) return;
      //i18n.locale = language;  USELESS
      localStorage.setItem("selected_language", language);
      //this.$router.go() behaves weirdly
      location.reload();
    },
    handleVisibleChange(visible) {
      this.poptipVisible = visible;
    },
    redirectToNotifications() {
      this.$router.push({ name: "notifications" });
      this.handleVisibleChange(false);
    },
    scrollToTop() {
      this.$refs.myDiv.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
  },
};
</script>

<style>
.notification-badge {
  font-size: 10px;
}

.language-item {
  margin-bottom: 1px;
}
.widget {
  cursor: pointer;
  transform: 1s;
  transition: transform 0.3s ease-in-out;
}

.widget:hover {
  background-color: var(--hover-icon);
}
.widget1 {
  transition: transform 0.9s ease-in-out;
}

.app-notification-popper .ivu-poptip-body-content {
  height: 100%;
}

.app-notification-popper .notification-content {
  padding-top: 25px;
  padding-bottom: 20px;
  position: relative;
}

.app-notification-popper .ivu-poptip-body {
  height: 800px;
  word-wrap: break-word !important;
}
.ivu-settings .ivu-poptip-body {
  height: 200px !important;
}

.ivu-settings .ivu-poptip-arrow {
  right: 8px !important;
}
.app-notification-popper .notification-label {
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.app-notification-popper .notification-see-all {
  position: absolute;
  bottom: 0;
  color: #2d8cf0;
}

.app-notification-popper .notification-inner-content {
  overflow: auto;
}

.app-notification-popper .notification-item {
  margin: 15px 2px 15px 0px;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  border: solid 1px #e1e1e1;
  /* padding: 8px 0; */
  white-space: normal;
  border-bottom: 1px solid RGBA(113, 87, 242, 0.1);
}

.notif-not-read {
  background-color: #edecff !important;
}

.notification-item .action-icon {
  float: right;
}

.language-icon {
  margin: -3px 7px;
  cursor: pointer;
}

.user-png {
  border-radius: 50px;
  width: 45px;
  margin: 3px 0px 0 -1px;
}

.country-png {
  float: left;
  width: 30px;
  height: 25px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: solid 1px #e1e1e1;
}

.close-notification {
  float: right;
  color: rgb(34, 34, 34);
  border-radius: 50%;
  cursor: pointer;
}

.panel-move,
.panel-enter-active,
.panel-leave-active {
  transition: all 0.2s ease;
}

.panel-enter-from,
.panel-leave-to {
  transform: translateX(-50px);
  opacity: 0;
}
</style>
