<template>
  <div>
    <AppModal
      v-model="value"
      :header-name="$t('Emergency note')"
      :footer-hide="false"
      @click:submit="submit"
      @click:cancel="cancel"
      :particularSubmitName="$t('Submit')"
      :width="400"
      class-name="create-travel-modal"
      :loading="loading"
    >
      <Form
        ref="emergencyForm"
        class="emergency-form-content"
        :model="emergency"
        :rules="emergencyRules"
      >
        <FormItem>
          <i-input
            v-model="emergency.user_value.full_name"
            :placeholder="$t('Traveler full name')"
            readonly
          />
        </FormItem>
        <!--FormItem> FIXME RESTORE THAT LATER WHEN API INCLUDES THIS DATA
          <i-input
            v-model="emergency.user_value.client.name"
            :placeholder="$t('Traveler company')"
            readonly
          />
        </FormItem-->
        <FormItem
          :label="$t('Phone number')"
          prop="cellphone"
        >
          <AppPhoneInput
            ref="form-cellphone"
            v-model="emergency.user_value.cellphone"
            style="width: 100%"
            disabled
          />
        </FormItem>
        <FormItem>
          <i-input
            v-model="emergency.user_value.email"
            :placeholder="$t('E-mail address')"
            readonly
          />
        </FormItem>
        <FormItem>
          <i-input
            v-model="emergency.created_at"
            :placeholder="$t('Emergency call date and hour')"
            readonly
          />
        </FormItem>
        <FormItem v-if="allowManage">
          <Select v-model="emergency.status">
            <i-option
              v-for="status in emergencyStatuses"
              :key="status.value"
              :value="status.value"
              :placeholder="$t('Alert status')"
            >
              {{ status.display_name }}
            </i-option>
          </Select>
        </FormItem>
        <FormItem prop="comment">
          <strong class="emergency-comment">{{ $t('Comment') }}</strong>
          <i-input
            v-model="emergency.comment"
            type="textarea"
            :placeholder="$t('Comment')"
            :autosize="{minRows: 4,maxRows: 4}"
          />

          <p
            v-if="updatedAttr.status"
            class="emergency-update-status"
          >
            Alert {{ statusVerboseEquivalent[updatedAttr.status] }} by {{ updatedAttr.created_by.email }}
          </p>
        </FormItem>
      </Form>
    </AppModal>
  </div>
</template>

<script>
    import {optionsEmergenciesQry, patchEmergenciesQry} from "@/services/alerts.service";
    import AppModal from "@/components/common/AppModal"
    import AppPhoneInput from "@/components/common/AppPhoneInput";

    export default {
        name: "EmergencyUpdate",
        components: {
            AppModal,
            AppPhoneInput
        }
        ,
        props: ['value'],
        data() {
            return {
                loading: false,
                allowManage: true,
                watchedEmergency: {
                    emergencyStatus: null,
                    idx: null
                },
                statusVerboseEquivalent: {2: "processed", 3: "closed"},
                updatedAttr: {},  // FIXME rename this
                emergencyStatuses: [],
                emergency: {
                    id: null,
                    user_value: {
                        full_name: "",
                        company: {
                            name: ""
                        },
                        cellphone: "",
                        email: "",
                    },
                    created_at: "",
                    status: "",
                    comment: ""
                },
                emergencyRules: {
                    comment: [
                        {
                            required: true,
                            message: "You have to complete the comment section in order to continue",
                            trigger: 'blur'
                        },
                    ],
                },


            }
        },
        mounted() {
            optionsEmergenciesQry()
                .then(res => {
                    this.emergencyStatuses = res.data.actions.POST.status.choices

                })
        },
        methods: {
            submit() {

                this.$refs['emergencyForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        patchEmergenciesQry(this.emergency.id, this.emergency)
                            .then(res => {
                                this.$emit('emergencyUpdated', this.watchedEmergency, res.data);
                                this.loading = false;
                                this.$Message.success(this.$t('Successfully updated'));
                                this.cancel();
                            })
                            .catch(err => {
                                this.loading = false;
                                this.$Message.error({content: this.$t('Error occurred'), duration: 5})
                            })
                    }
                })
            },
            cancel() {
                this.$emit('input', false);
                this.resetDataName(this, 'emergency');
                this.resetDataName(this, 'watchedEmergency');
                this.$refs['emergencyForm'].resetFields();
                this.updatedAttr = {};
            },

            setEmergencyData(emergencyStatus, data, idx, allowManage) {
                this.allowManage = allowManage === false ? allowManage : true;
                this.watchedEmergency = {emergencyStatus, idx};

                Object.keys(this.emergency).forEach((key) => {
                    if (key === "user_value") {
                        Object.keys(this.emergency.user_value).forEach((key2) => {
                            if (key2 in data.user_value) {
                                if (data.user_value[key2]) {
                                    this.emergency.user_value[key2] = data.user_value[key2];
                                }
                            }
                        });

                    } else {
                        this.emergency[key] = data[key]
                    }
                })
            },
        }
        
    }
</script>

<style>
    .emergency-form-content {
        padding: 10px 40px;
    }


    .emergency-note {
        font-weight: 500;
        font-size: 18px;
        padding-top: 30px;
        padding-bottom: 10px;
    }

    .emergency-update-status{
        margin-left: 10px;
        font-weight: 600;
    }
</style>
