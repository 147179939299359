import {postLogoutQry, postRefreshTokenQry} from "../services/auth.service";

export function setAuthSession(data) {
    const minToHour = data.expires_in / 60 / 60;
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + minToHour);

    data.expires_in = expiration;
    data.remember = true;  // Auto-renew session when temporary accessToken expires
    localStorage.setItem('auth_data', JSON.stringify(data))
}

export function getAuthSession() { // FIXME duplicated with new
    const auth_data = localStorage.getItem('auth_data');
    if (auth_data) {
        return JSON.parse(auth_data);
    } else {
        return {}
    }
}

function updateAuthSession(data) {
    const updatedAuth = Object.assign(getAuthSession(), data);  // Fixme needs to do setAuthSession too ??
    localStorage.setItem('auth_data', JSON.stringify(updatedAuth));
}

export function updateAuthSessionByKeys(data) {
    const sessionAuthData = getAuthSession();
    Object.keys(data).forEach((key)=> {
        if(sessionAuthData.hasOwnProperty(key)){
            sessionAuthData[key] = data[key]
        }
    });

    updateAuthSession(sessionAuthData);
}

export function removeAuthAttr() {
    localStorage.removeItem('auth_data');
}

export async function isAuthenticated() {
    const authSession = getAuthSession();
    if (authSession['access_token']) {
        const getExpiration = authSession['expires_in'];
        const dateNow = new Date();
        if (dateNow < new Date(getExpiration)) {
            return true
        } else {
            if (authSession['remember']) {
                try {
                    const refreshData = await postRefreshTokenQry(authSession['refresh_token']);
                    updateAuthSession(refreshData.data);
                    return true
                } catch (e) {
                    return true  // FIXME why???
                }

            }
        }
    }

    return false
}

export function logout() {
    postLogoutQry();
    removeAuthAttr();
}
