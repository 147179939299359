<template>
  <div class="tiptap">
    <editor-content
      class="app-tip-tap"
      :editor="editor"
    />
    <editor-menu-bar
      v-slot="{ commands, isActive }"
      :editor="editor"
    >
      <div
        v-if="editable"
        class="app-tiptap-menu"
      >
        <Button
          type="default"
          ghost
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <Strong>B</Strong>
        </Button>

        <Button
          type="default"
          ghost
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <i>I</i>
        </Button>

        
        <Button
          type="default"
          ghost
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <strong>U</strong>
        </Button>

        <Button
          type="default"
          ghost
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </Button>

        <Button
          type="default"
          ghost
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </Button>

        <Button
          type="default"
          ghost
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </Button>
        <Button
          type="default"
          ghost
          :class="{ 'is-active': isActive.heading({ level: 4 }) }"
          @click="commands.heading({ level: 4 })"
        >
          H4
        </Button>

        <Button
          type="default"
          ghost
          :class="{ 'is-active': isActive.heading({ level: 5 }) }"
          @click="commands.heading({ level: 5 })"
        >
          H5
        </Button>
        <Button
          type="default"
          ghost
          :class="{ 'is-active': isActive.heading({ level: 5 }) }"
          @click="commands.heading({ level: 5 })"
        >
          H5
        </Button>
        <Button
          type="default"
          ghost
          @click="commands.horizontal_rule"
        >
          HR
        </Button>
      </div>
    </editor-menu-bar>
  </div>
</template>

<script>
    import {Editor, EditorContent, EditorMenuBar} from 'tiptap'
    import {
        Blockquote,
        CodeBlock,
        HardBreak,
        Heading,
        HorizontalRule,
        OrderedList,
        BulletList,
        ListItem,
        TodoItem,
        TodoList,
        Bold,
        Code,
        Italic,
        Link,
        Strike,
        Underline,
        History,
    } from 'tiptap-extensions'

    export default {
        components: {
            EditorContent,
            EditorMenuBar,

        },
        props: {'value': {default: ""}, defaultEditable: {default: false}},
        data() {
            return {
                copyContent: "",
                editor: new Editor({
                    extensions: [
                        new Blockquote(),
                        new BulletList(),
                        new CodeBlock(),
                        new HardBreak(),
                        new Heading({levels: [1, 2, 3, 4, 5]}),
                        new HorizontalRule(),
                        new ListItem(),
                        new OrderedList(),
                        new TodoItem(),
                        new TodoList(),
                        new Link(),
                        new Bold(),
                        new Code(),
                        new Italic(),
                        new Strike(),
                        new Underline(),
                        new History(),
                    ],
                    content: ``,
                    editable: false,
                }),
                editable: this.defaultEditable
            }
        },
        watch: {
            editable() {
                this.editor.setOptions({
                    editable: this.editable,
                })
            },
        },
        created() {
            this.editor.setContent(this.value);
            this.editor.setOptions({
                editable: this.editable,
            })
        },

        beforeDestroy() {
            this.editor.destroy()
        },
        methods: {
            allowUpdate() {
                this.copyContent = this.editor.getHTML();
                this.editable = true;
                this.editor.setOptions({
                    editable: this.editable,
                });
            },
            cancel() {
                this.success();
                this.editor.setContent(this.copyContent);
                this.copyContent = "";
            },

            success() {
                this.editable = false;
                this.editor.setOptions({
                    editable: this.editable,
                });
            },

            submit() {
                let editorValue = this.editor.getHTML();
                if(editorValue === "<p></p>"){
                    editorValue = ""
                }
                return this.$emit('input', editorValue);
            },

            setContent(content){
                this.editor.setContent(content);
            }
        },
    }
</script>

<style>
    .app-tip-tap > div {
        height: 550px;
        padding: 5px 30px 30px 30px;
        overflow: auto;
        background: #f2f2f2;
        border-radius: 4px;
        border: 1px solid #d6d6d6;
    }

    .app-tip-tap ul {
        list-style-type: disc;
    }

    .app-tiptap-menu{
        padding: 10px 0;
    }

    .app-tiptap-menu button {
        color: #000 !important;
    }

    .app-tiptap-menu button.is-active {
        background: #1e3042;
        color: #ffffff !important;
    }

    .app-tiptap-menu {
        text-align: center;
    }


    .ivu-form-item.ivu-form-item-error .tiptap{
        border: 1px solid #ed4014;
    }
</style>