
export { ImagesOrientation }


class ImagesOrientation {

    constructor() {

    }

    getOrientation(element) {

        var self = this

        function main(resolve, reject) {

            element.addEventListener("load", function () {

                if (this.naturalHeight > this.naturalWidth) {
                    resolve("portrait")
                }
                else if (this.naturalHeight < this.naturalWidth) {
                    resolve("landscape")
                }
                else if (this.naturalHeight == this.naturalWidth) {
                    resolve("square")
                }

            })

        }

        return new Promise((resolve, reject) => main(resolve, reject))

    }

}