export { DataGetter };

import i18n from "@/i18n/i18n";

import { axiosInstance } from "@/services/base";

class DataGetter {
  // FIXME: Clean this class
  /*
   * This class seems to make the requests to the api to retrieve needed data
   */

  constructor() {
    this.backendUrl = process.env.VUE_APP_API_URL;
    this.events = document.createElement("div");
    this.geoJsonCollection = {};

    this.build();
  }

  build() {
    var sites = this.getDjangoData("sites");
    var risk = this.getDjangoData("risks");
    var devices = this.getDjangoData("devices");
    var incidents = this.getDjangoData("incidents", { current: 1 });
    var pointsOfInterest = this.getDjangoData("points-of-interest");
    var zonesOfInterest = this.getDjangoData("danger-zones");

    sites.then((data) => {
      this.formatSites(data);
    });

    risk.then((data) => {
      this.formatRisk(data);
    });

    devices.then((data) => {
      this.formatDevices(data);
    });

    incidents.then((data) => {
      this.formatIncidents(data);
    });

    pointsOfInterest.then((data) => {
      this.formatPointsOfInterest(data);
    });

    zonesOfInterest.then((data) => {
      this.formatZonesOfInterest(data);
    });
  }

  getDjangoData(endpoint, params) {
    return new Promise((resolve, reject) => {
      var values = [];
      var i = 1;
      req("?page_size=1000");

      function req(suffix) {
        i++;
        axiosInstance
          .get("api/" + endpoint + "/" + suffix, { params })
          .then((value) => {
            values = values.concat(value.data.results);
            if (value.data.next != null) {
              req("?page=" + i + "&page_size=1000");
            } else {
              resolve(values);
            }
          });
      }
    });
  }

  formatSites(data) {
    return new Promise((resolve, reject) => {
      var geojson = {
        type: "FeatureCollection",
        features: [],
      };

      data.forEach((element) => {
        var feature = {
          type: "Feature",
          properties: null,
          geometry: {
            type: "Point",
            coordinates: [element.longitude, element.latitude],
          },
        };
        feature.properties = this.flattenize(element);

        feature.properties.lng = feature.geometry.coordinates[0];
        feature.properties.lat = feature.geometry.coordinates[1];
        feature.properties["map-icon"] = "sites";

        geojson.features.push(feature);
      });

      this.geoJsonCollection["sites"] = geojson;
      this._eventConstructor("sites-load", geojson);

      resolve(geojson);
    });
  }

  formatRisk(data) {
    return new Promise((resolve, reject) => {
      var corel = {};
      data.forEach((element) => {
        corel[element.country_alpha3] = element;
      });

      var oReq = new XMLHttpRequest();
      oReq.onload = (e) => {
        var geojson = JSON.parse(e.target.responseText);
        geojson.features.forEach((element) => {
          element.properties = Object.assign(
            element.properties,
            corel[element.properties.iso_3]
          );
        });
        this.geoJsonCollection["risks"] = geojson;
        this._eventConstructor("risks-load", geojson);
        resolve(geojson);
      };
      oReq.open(
        "get",
        "/ressources/data/layers/world-boundaries.geojson",
        true
      );
      oReq.send();
    });
  }

  formatDevices(data) {
    return new Promise((resolve, reject) => {
      var geojson = {
        type: "FeatureCollection",
        features: [],
      };

      data.forEach((element) => {
        if (!element.latest_device_track) {
          return; // Skip display if no device track is available
        }

        var feature = {
          type: "Feature",
          properties: null,
          geometry: {
            type: "Point",
            coordinates: [
              element.latest_device_track.longitude,
              element.latest_device_track.latitude,
            ],
          },
        };

        feature.properties = this.flattenize(element);

        if (
          this.dateLessThanHoursAgo(
            new Date(feature.properties["latest_device_track.updated"]),
            4
          )
        ) {
          feature.properties.timeState = "b";
        } else {
          feature.properties.timeState = "a";
        }

        feature.properties.timeLabel = this.getEllapsedTime(
          new Date(feature.properties["latest_device_track.updated"])
        );
        feature.properties["map-icon"] =
          "devices-" + feature.properties.timeState;
        feature.properties.lng = feature.geometry.coordinates[0];
        feature.properties.lat = feature.geometry.coordinates[1];

        geojson.features.push(feature);
      });

      this.geoJsonCollection["devices"] = geojson;
      this._eventConstructor("devices-load", geojson);

      resolve(geojson);
    });
  }

  formatIncidents(data) {
    return new Promise((resolve, reject) => {
      var geojson = {
        type: "FeatureCollection",
        features: [],
      };

      data.forEach((element) => {
        var coordinates = [null, null];

        if (element.incident_locations[0]) {
          coordinates = [
            element.incident_locations[0].longitude,
            element.incident_locations[0].latitude,
          ];
        }

        var feature = {
          type: "Feature",
          properties: null,
          geometry: {
            type: "Point",
            coordinates: coordinates,
          },
        };

        feature.properties = this.flattenize(element);

        feature.properties.lng = feature.geometry.coordinates[0];
        feature.properties.lat = feature.geometry.coordinates[1];
        feature.properties["map-icon"] = "incidents-urgent"; // The same for all incidents!

        if (
          new Date(feature.properties["expiration_date"]) >
          new Date().setHours(0, 0, 0, 0)
        ) {
          feature.properties.timeLabel = "Moins d'une heures";
          geojson.features.push(feature);
        }
      });

      this.geoJsonCollection["incidents"] = geojson;
      this._eventConstructor("incidents-load", geojson);

      resolve(geojson);
    });
  }

  formatZonesOfInterest(data) {
    return new Promise((resolve, reject) => {
      var geojson = {
        type: "FeatureCollection",
        features: [],
      };

      data.forEach((element) => {
        var feature = {
          type: "Feature",
          properties: null,
          geometry: element.zone,
        };
        delete element.zone;
        feature.properties = this.flattenize(element);
        geojson.features.push(feature);
      });

      this.geoJsonCollection["danger-zones"] = geojson;
      this._eventConstructor("danger-zones-load", geojson);

      resolve(geojson);
    });
  }

  formatPointsOfInterest(data) {
    return new Promise((resolve, reject) => {
      var geojson = {
        type: "FeatureCollection",
        features: [],
      };

      data.forEach((element) => {
        var feature = {
          type: "Feature",
          properties: null,
          geometry: {
            type: "Point",
            coordinates: [element.longitude, element.latitude],
          },
        };

        feature.properties = element;

        feature.properties.lng = feature.geometry.coordinates[0];
        feature.properties.lat = feature.geometry.coordinates[1];
        feature.properties["map-icon"] = "poi-" + feature.properties.point_type;

        geojson.features.push(feature);
      });

      this.geoJsonCollection["points-of-interest"] = geojson;
      this._eventConstructor("points-of-interest-load", geojson);

      resolve(geojson);
    });
  }

  dateLessThanHoursAgo(date, hours) {
    const time = hours * 60 * 60 * 1000;
    const timeAgo = Date.now() - time;

    return date > timeAgo;
  }

  dateLessThanDaysAgo(date, days) {
    const time = days * 24 * 60 * 60 * 1000;
    const timeAgo = Date.now() - time;

    return date > timeAgo;
  }

  getEllapsedTime(date) {
    //FIXME: Clean this code
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " " + i18n.t("Year");
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " " + i18n.t("Month");
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " " + i18n.t("Day");
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " " + i18n.t("Hour");
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " " + i18n.t("Minute");
    }
    return Math.floor(seconds) + " second(s)";
  }

  flattenize(data) {
    //FIXME: Why the f* is it necessary to "flattenize" data ???
    var result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++)
          recurse(cur[i], prop + "[" + i + "]");
        if (l == 0) result[prop] = [];
      } else {
        var isEmpty = true;
        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + "." + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }
    recurse(data, "");
    return result;
  }

  _eventConstructor(eventName, option) {
    console.log(">> We dispatch event from DataGetter", eventName);
    var event = new CustomEvent(eventName, { detail: option });
    this.events.dispatchEvent(event);
  }
}
