<template>
  <AppModal
    v-model="value.showModal"
    :headerName="$t('New client')"
    :footer-hide="false"
    @click:submit="submitForm"
    @click:cancel="triggerCancel"
    width="700"
    :loading="loading"
    :closable="true"
    class="account-modal"
  >
    <Form
      ref="clientForm"
      :model="value.form"
      :rules="formRules"
      @submit.native.prevent="submitForm"
    >
      <Row class="mt-5">
        <i-col :md="12"> <strong>Identification </strong> </i-col>
        <i-col :md="12">
          <p>{{ $t("Name or Company name") }}<span class="requiredColor"> *</span></p>
          <FormItem prop="client_name" class="mt-3">
            <i-input
              v-model="value.form.client_name"
              :placeholder="$t('Write here')"
            />
          </FormItem>
          <p class="mt-4">{{ $t("Type") }}</p>
          <FormItem prop="client_type" class="mt-3">
            <i-select
              v-model="value.form.client_type"
              :placeholder="$t('Choose a type')"
            >
              <i-option value="professionnal">{{
                $t("Professionnal")
              }}</i-option>
              <i-option value="individual">{{ $t("Individual") }}</i-option>
            </i-select>
          </FormItem>
        </i-col>
      </Row>
      <Row class="mt-5">
        <i-col :md="12"
          ><strong> {{ $t("Contact informations") }}</strong>
        </i-col>
        <i-col :md="12">
          <p>{{ $t("Contact name") }}</p>
          <FormItem prop="client_contact_name" class="mt-3">
            <i-input
              v-model="value.form.client_contact_name"
              :placeholder="$t('Write here')"
            />
          </FormItem>
          <p>{{ $t("Phone") }}</p>
          <FormItem prop="client_phone" class="mt-3">
            <AppPhoneInput
              v-model="value.form.client_phone"
              :placeholder="$t('Write here')"
              class="phoneInput"
            >
            </AppPhoneInput>
          </FormItem>
          <p class="mt-4">{{ $t("Email address") }}</p>
          <FormItem prop="client_phone" class="mt-3">
            <i-input
              v-model="value.form.client_email"
              :placeholder="$t('Write here')"
            />
          </FormItem>
        </i-col>
      </Row>
      <Row class="mt-5">
        <i-col :md="12">
          <strong> {{ $t("Billing") }}</strong>
        </i-col>
        <i-col :md="12">
          <p>{{ $t("Country") }}</p>
          <FormItem prop="client_billing_country" class="mt-3">
            <i-select v-model="value.form.client_billing_country" filterable :placeholder="$t('Select a country')">
              <i-option
                v-for="country in countryList"
                :key="country.id"
                :value="country.value"
              >
                {{ country.display_name }}
              </i-option>
            </i-select>
          </FormItem>
          <p class="mt-4">{{ $t("City") }}</p>
          <FormItem prop="client_billing_city" class="mt-3">
            <i-input
              v-model="value.form.client_billing_city"
              :placeholder="$t('Write here')"
            />
          </FormItem>
          <p class="mt-4">{{ $t("Address") }}</p>
          <FormItem prop="client_billing_address" class="mt-3">
            <i-input
              v-model="value.form.client_billing_address"
              :placeholder="$t('Write here')"
            />
          </FormItem>
        </i-col>
      </Row>
      <input type="submit" hidden />
      <!-- FIXME: make clean Press Enter submit handling -->
    </Form>
  </AppModal>
</template>

<script>
import AppModal from "@/components/common/AppModal";
import AppPhoneInput from "@/components/common/AppPhoneInput";
import { buildRequiredRules } from "@/helpers/common.helper";

export default {
  name: "ClientModalForm",
  components: {
    AppModal,
    AppPhoneInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: {
        showModal: false,
        form: {},
      },
    },
    modalOnSubmit: {
      type: Function,
      required: true,
    },
    countryList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      formRules: [],
    };
  },
  watch: {
    "value.showModal"(newValue) {
      if (newValue === false) this.$emit("hide");
    },
  },
  created() {
    this.formRules = buildRequiredRules(this.value.form);
  },
  methods: {
    triggerCancel() {
      this.value.showModal = false;
      this.$refs["clientForm"].resetFields();
    },
    submitForm() {
      const clientFormElem = this.$refs.clientForm;
      clientFormElem.validate((valid) => {
        if (valid) this.modalOnSubmit();
      });
    },
  },
};
</script>

<style scoped></style>