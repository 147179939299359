import moment from "moment";

export default {
  data(){
    return {
      defaultDateFormat: "",
      defaultTimeFormat: ""
    }
  },
  mounted() {
    this.defaultDateFormat= "DD/MM/YYYY",
    this.defaultTimeFormat= "kk:mm A"
  },
  methods: {
    dateFormat(date) {
      if (!date) return "";
      return moment(date).format(this.defaultDateFormat);
    },

    fullDateFormat(date) {
      if (!date) return "";
      return moment(date).format(this.defaultDateFormat + " - " + this.defaultTimeFormat);
    },

    timeFormat(time) {
      if (!time) return "";
      return moment(time).format(this.defaultTimeFormat);
    },

    dateFormatHeader(date) {
      if (!date) return "";
      return moment().locale(date).format("dddd DD MMMM YYYY");
    },
  },
}