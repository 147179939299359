<template>
  <GmapMap
    ref="gmap"
    :center="gmapAttr.center"
    :zoom="gmapAttr.zoom"
    :options="gmapAttr.options"
    class="global-risk-map"
    map-type-id="terrain"
  >
    <template v-for='incident in incidents'>
      <gmap-marker
        v-for="incident_location in incident.incident_locations"
        :key="`incident-${incident_location.id}`"
        :position="{lat: incident_location.latitude, lng: incident_location.longitude}"
        :icon="markIt('GenericIncident')"
      />
    </template>
    <GmapGeoJson
      :geo-data="geoData"
      :options="geoOptions"
    />
  </GmapMap>
</template>

<script>
    import {countries} from "../../../assets/countries";
    import GmapGeoJson from "@/components/common/GmapGeoJson"
    import {polyCenter, countryAlertColor, getMarker} from "../../../helpers/marker.helper";
    import Vue from 'vue';

    export default {
        name: "GlobalRiskMap",
        components: {
            GmapGeoJson
        },
        props: ['countryRisk', 'incidents'],
        data() {
            return {
                gmapAttr: {
                    center: {lat: 10, lng: 10},
                    zoom: 4,
                    options: Vue.config.gmapDefaultOptions
                },
                geoOptions: {
                    fillColor: "#000",
                    strokeWeight: 1,
                },

                currentMarker: {
                    lat: 10,
                    lng: 10,
                },

                geoData: [],
            }
        },
        watch: {
            countryRisk: {
                handler(value) {
                    const polygonCenter = polyCenter(value.country_alpha3);
                    this.gmapAttr.center.lat = polygonCenter[1];
                    this.gmapAttr.center.lng = polygonCenter[0];
                    this.geoOptions.fillColor = countryAlertColor(value.global_risk);
                    this.geoData = [countries[value.country_alpha3]];
                },
                deep: true
            }
        },
        methods: {
            markIt(marker) {
                return getMarker(marker);
            },
        }

    }
</script>

<style scoped>
    .global-risk-map {
        margin: 20px 0;
        background: var(--risk-5);
        height: 400px;
    }


</style>
