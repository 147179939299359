<template>
  <AppModal
    v-model="value"
    :header-name="$t('Recover incident')"
    :width="600"
    :loading="loading"
  >
    <Form
      ref="incidentForm"
      :label-width="100"
      :model="incidentFormData"
      :rules="incidentRules"
      label-position="left"
    >
      <div class="full-height incident-form">
        <Row
          type="flex"
          :gutter="10"
        >
          <Card
            style="width: 100%; height: 100%"
            class="incident-form"
          >
            <i-col
              span="24"
              style="padding-right: 30px; padding-left: 20px"
            >
              <FormItem
                prop="short_description"
                :error="incidentFormErrors.short_description"
                :label-width="0"
              >
                <p>{{ $t('Short description') }}</p>
                <i-input
                  v-model="incidentFormData.short_description"
                  type="textarea"
                  :row="2"
                  :autosize="{minRows:2, maxRows:2}"
                />
              </FormItem>
              <FormItem
                prop="long_description"
                :error="incidentFormErrors.long_description"
                :label-width="0"
              >
                <p>{{ $t('Long description') }}</p>
                <i-input
                  v-model="incidentFormData.long_description"
                  type="textarea"
                  :autosize="{minRows:5, maxRows:8}"
                  :row="10"
                />
              </FormItem>
              <FormItem
                prop="expiration_date"
                :label="$t('Expiration')"
                :error="incidentFormErrors.expiration_date"
              >
                <AppDatePicker
                  v-model="incidentFormData.expiration_date"
                  type="date"
                />
              </FormItem>
            </i-col>
            <i-col
              span="8"
              style="padding: 5px;"
            />
            <i-col
              span="8"
              style="padding: 5px;"
            >
              <Button
                type="success"
                class="confirm-button"
                long
                @click="triggerRecover"
              >
                {{ $t('Recover') }}
              </Button>
            </i-col>
            <i-col
              span="8"
              style="padding: 5px;"
            >
              <Button
                type="error"
                class="cancel-button"
                long
                @click="triggerCancel"
              >
                {{ $t('Cancel') }}
                </Button>
            </i-col>
          </Card>
        </Row>
      </div>
    </Form>
  </AppModal>
</template>

<script>
    import AppModal from "@/components/common/AppModal"
    import AppDatePicker from "@/components/common/AppDatePicker"
    import {futureDateValidator} from "../../../helpers/common.helper";
    import {patchIncidentQry} from "../../../services/incidents.service";

    export default {
        name: "CreateIncident",
        components: {
            AppModal,
            AppDatePicker,
        },
        props: ['value'],

        data() {
            return {
                loading: false,
                updateIndex: null,
                incidentId: null,
                incidentFormData: {
                    expiration_date: null,
                    short_description: '',
                    long_description: '',
                },
                incidentRules: {
                    expiration_date: [
                        {validator: futureDateValidator}
                    ],
                },
                incidentFormErrors: {
                    expiration_date: "",
                    short_description: "",
                    long_description: "",
                },
            }
        },
        computed: {
        },
        watch:{
        },
        created() {
        },
        methods: {
            triggerRecover() {
                this.$refs['incidentForm'].validate(async (valid) => {
                    if (valid) {
                        this.loading = true;
                        patchIncidentQry(this.incidentId, this.incidentFormData)
                            .then(res => {
                                this.loading = false;
                                this.$emit('incidentRenewed', res.data, this.updateIndex);
                                this.cancel();
                                this.$Message.success(this.$t('Successfully updated your data'))
                            })
                            .catch(err => {
                                this.loading = false;
                                this.$Message.error({content: this.$t('Error updating your data'), duration: 5});
                            })
                    }
                })
            },

            setUpdateData(data, idx) {
                this.updateIndex = idx;
                this.incidentId = data.id;
                this.incidentFormData["short_description"] = data["short_description"]
                this.incidentFormData["long_description"] = data["long_description"]
                // No initial data for expiration_date, since it would probably be invalid anyway
            },

            triggerCancel(){
                this.cancel();
            },

            cancel() {
                this.$emit('input', false);
                this.$refs['incidentForm'].resetFields();
                this.resetDataName(this, 'incidentFormData');
                this.resetDataName(this, 'incidentFormErrors');
                this.updateIndex = null;
                this.incidentId = null;
            },

        },

    }
</script>

<style scoped>
</style>
