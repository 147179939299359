export { TilingManager }

import axios from "axios"
import { getIncidentTilesQry } from "@/services/incidents.service.js";

const ZOOM = 2

class TilingManager {

	constructor(map) {
		console.log("CREATING TILEMANAGER")
		this.map = map
		this.zoom = ZOOM
		this.tilesPerAxis = Math.pow(2, this.zoom)
		console.log(this.map)
		//TODO: (re)Find how to get camera boundaries + make the event that request the api
		//console.log(this.map.getLayers())
		this.initMaxTiles()
		this.fetchDataFromVisibleTiles()
		this.map.once("moveend", (e) => { this.fetchDataFromVisibleTiles(e) })
	}

	initMaxTiles() {
		const mapBounds = this.map.getBounds()
		const se = mapBounds.getSouthEast()

		const bottomRightTile = this.lngLatToTile(se.lng, se.lat)

		this.xMaxTiles = bottomRightTile.x + 1
		this.yMaxTiles = bottomRightTile.y + 1
	}

	lngLatToTile(lng, lat) {
		return {
			x: this.lngToIndex(lng),
			y: this.latToIndex(lat),
		}
	}

	indexToLat(y) {
		const n = Math.PI - 2 * Math.PI * y / this.tilesPerAxis;
		return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
	}
	
	tileToLngLat(x, y) {
		return {
			lng: this.indexToLng(x),
			lat: this.indexToLat(y),
		}
	}

	lngToIndex(lng) {
		return Math.floor((lng + 180) / 360 * this.tilesPerAxis)
	}

	latToIndex(lat) {
		const latPI = lat*Math.PI/180
		return Math.floor(
			(1 - Math.log(Math.tan(latPI) + 1 / Math.cos(latPI)) / Math.PI) / 2 * this.tilesPerAxis
		)
	}

	indexToLng(x) {
		return (x / this.tilesPerAxis * 360 - 180);
	}

	fetchDataFromVisibleTiles(event) {
		console.log("FETCH DATA FROM TILES")
		console.log(event)
		//console.log(event.lnglat)
		this.getVisibleTiles()
	}

	getVisibleTiles() {
		const mapCamera = this.map
		console.log(mapCamera)
	}

	/*
	function fetchTiles(tiles, zoom) {
		const currentTiles = tiles.map(t => {return {lng: tile2long(t[0], zoom), lat: tile2lat(t[1], zoom)}});
		console.log("WIPTILES CURRENT_TILES IDX", tiles[0]);
		console.log("WIPTILES CURRENT_TILES COORDS", currentTiles[0].lng, currentTiles[0].lat);

		//let request = getPointOfInterestTilesQry(tiles, zoom);  // .slice(0,2)FIXME
		let request = getIncidentTilesQry(tiles, zoom, {page_size: 1000, current: 1})
		
		request.then(axios.spread(function () {
				console.log("SPREAD DONE", arguments);  // All requests are now complete in arguments
			let points = [];
			for (const res of arguments) {
				points.push(...res.data.results);
				//console.log(res.data.results);
			}
			console.log("SPREAD DONE2", points);
			//self.pois.results = points;
		}));
	}*/
}