export default {
    install(Vue, options) {
        Vue.filter('truncate', function (string, count) {
            if (string) {
                if (string.length > count) {
                    return string.substring(0, count) + '...';
                } else {
                    return string
                }
            }
        });

        Vue.filter('userDisplay', function (data) {
            if (data) {
                if (data.first_name || data.last_name) {
                    return `${data.first_name} ${data.last_name}`
                } else {
                    return data.email.split('@')[0]
                }
            }
        });

        Vue.filter('full_address', function (data, addressSequence) {  // FIXME rename
            const address_list = [];

            addressSequence.forEach((key) => {
                if (data.hasOwnProperty(key)) {
                    if (data[key]) {
                        address_list.push(data[key])
                    }

                }
            });

            return address_list.join(", ")
        });

        Vue.filter("multi_location", function (data) {   // FIXME rename
            return data.map((location) => {
                return [location.city, location.country_name].filter(item => item).join(", ");

            }).join("; ");


        })
    }
}
