<template>
  <VueTelInput
    ref="vueTel"
    v-model="phone"
    class="app-phone-input"
    :disabled="disabled"
    :placeholder="$t('Write here')"
  />
</template>

<script>
    import VueTelInput from 'vue-tel-input'
    import 'vue-tel-input/dist/vue-tel-input.css';

    export default {
        name: "AppPhoneInput",

        components: {
            VueTelInput
        },
        props: ['value', 'disabled'],
        computed: {
            phone: {
                get() {
                    return this.value
                },
                set(data) {
                    this.$emit('input', data);
                }
            }
        },
        methods:{
            validate(){
                return  this.$refs['vueTel'].response.isValid;
            }
        }
    }
</script>

<style>
    .app-phone-input {
        background-color: rgba(255, 255, 255, 0.05) !important;
        border: 1px solid var(--border) !important;
        border-radius: 5px !important;
        box-shadow: none;
        height: 40px;
    }
    .app-phone-input ul {
        top: -202px !important;
        width: 335px !important;
    }

    .app-phone-input input {
        background-color: rgba(255, 255, 255, 0.01) !important;
        color: black;    
    }
    .app-phone-input input::placeholder {
        color: #c5c8ce;
    }

    .app-phone-input:hover {
        border: 1px solid var(--border);
    }
</style>
