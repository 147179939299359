import { mapStores } from 'pinia';
import { useWorldInfoStore } from '@/stores/worldInfo';
import { useNotificationStore } from '@/stores/notification';
import { useUserProfileStore } from '@/stores/userProfile';
import { useAirportStore } from '@/stores/airport';

export const commonPiniaStoresMixin = {
    mounted() {
    },
    computed: {
        ...mapStores(useWorldInfoStore, useNotificationStore, useUserProfileStore, useAirportStore),  // Mapped to this.worldInfoStore
    },

};
