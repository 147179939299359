<template>
  <Modal
    v-model="modalStatus"
    :closable="closable"
    :mask-closable="maskClosable"
    :footer-hide="footerHide"
    :width="width"
    :class="className"
    class="app-modal"
  >
    <div v-if="headerName !== null" slot="header" class="boldtext">
      {{ headerName }}
    </div> 
    <slot />
    <Spin
      v-if="loading"
      fix
    />
    <template v-if="particularFooter" slot="footer">
      <slot name="footer" />
    </template>
    <div v-else-if="!footerHide"
      slot="footer"
      class="d-flex flex-justify-content-end flex-align-items-center"
    >
      <Button
        class="confirm-button"
        @click="submit()"
        size="large"
      >
        {{ particularSubmitName ? particularSubmitName : $t("Validate") }}
      </Button>
      <Button
        class="cancel-button"
        @click="cancel()"
        size="large"
      >
        {{ particularCancelName ? particularCancelName : $t("Cancel") }}
      </Button>
    </div>
  </Modal>
</template>

<script>
    export default {
        name: "AppModal",
        props: {
            value: {default: false},
            headerName: {default: null},
            closable: {default: false},
            maskClosable: {default: false},
            footerHide: {default: true},
            loading: {default: false},
            width: {default: 520},
            className: {default: null},
            particularFooter: {default: false},
            particularSubmitName: {default: null},
            particularCancelName: {default: null}
        },
        computed: {
            modalStatus: {
                get: function () {
                    return this.value;
                },
                set: function () {
                    this.$emit('input', !this.value)
                }
            }
        },
        methods: {
          cancel() {
            this.$emit('click:cancel');
          },
          submit() {
            this.$emit('click:submit');
          }
        }
    }
</script>

<style>
.app-modal .ivu-modal-header {
    background: var(--secondary-bleu);
}

.app-modal .ivu-modal-header p {
    color: #ffffff;
    font-size: 11pt;
}

.app-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-modal .ivu-modal {
    margin-top: 20px;
    top: 10px;
}

.ivu-modal-content {
    border-radius: 0;
    -webkit-box-shadow: 0px 0px 12px -3px rgba(113, 87, 242, 0.3);
    -moz-box-shadow: 0px 0px 12px -3px rgba(113, 87, 242, 0.3);
    box-shadow: 0px 0px 12px -3px rgba(113, 87, 242, 0.3);
}

.ivu-modal > .ivu-modal-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 10px !important;
    padding-bottom: 5px;
  }

.ivu-modal > .ivu-modal-content > .ivu-modal-header {
    height: 50px;
    background-color: var(--secondary-bleu);
    display: flex;
    border-radius: 10px 10px 0 0 !important;
    align-items: center;
    padding-left: var(--offset--5);
    padding-right: var(--offset--5);
    line-height: 1.5;
    font-size: 1.25rem;
    font-weight: bold;
    color: #000;
    background: #fff;
  }

.ivu-modal > .ivu-modal-content > .ivu-modal-close .ivu-icon-ios-close {
    color: #000;
  }
  
.ivu-modal > .ivu-modal-content > .ivu-modal-close .ivu-icon-ios-close:hover {
  color: var(--text-low-opacity);
  }
  
.ivu-modal > .ivu-modal-content > .ivu-modal-body > .scrolling-container {
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 15px;
    max-height: calc(100% - 500px); 
  }

.incident-page .ivu-modal-body {
    padding: 5px;
}

.ivu-modal > .ivu-modal-content > .ivu-modal-footer {
  padding-left: var(--offset--5);
  padding-right: var(--offset--5);
}

.ivu-modal-mask {
    background-color: rgba(0, 0, 0, .5);
}

.profile-update-modal span:last-of-type {
    float: right;
    color: #939597;
}

@media screen and (max-width: 767px) {
    /*Profile*/
    .edit-profile .ivu-modal {
        max-height: 90%;
    }
}
   
</style>