<template>
  <Form
    ref="setPasswordForm"
    :model="form"
    :rules="setPasswordRules"
    @keyup.enter.native="triggerSetPassword"
  >
    <FormItem>
      <h4 class="text-center">
        {{ message }}
      </h4>
    </FormItem>
    
    <template v-if="includeFullname">
      <FormItem prop="first_name" :error="setPasswordErrors.first_name">
        <i-input
          v-model="form.first_name"
          type="text"
          :placeholder="$t('First name')"
        />
      </FormItem>
      <FormItem prop="last_name" :error="setPasswordErrors.last_name">
        <i-input
          v-model="form.last_name"
          type="text"
          :placeholder="$t('Last name')"
        />
      </FormItem>
    </template>
    <FormItem prop="password" :error="setPasswordErrors.password">
      <i-input
        v-model="form.password"
        type="password"
        :placeholder="$t('Password')"
      />
    </FormItem>
    <FormItem prop="repeat_password">
      <i-input
        v-model="form.repeat_password"
        type="password"
        :placeholder="$t('Repeat password')"
      />
    </FormItem>
    <FormItem
      v-if="includeTac"
      prop="agree_tac"
    >
      <Checkbox
        v-model="form.agree_tac"
        class="tc-input"
      >
        Agree to
      </Checkbox>
      <a @click="showTacPopup = true">
        {{ $t('Terms & Conditions') }}
      </a>
    </FormItem>
    <TermsAndCondition
      v-if="includeTac"
      v-model="showTacPopup"
      @accept="acceptTac"
      @tacVersion="storeTacVersion"
    />
    <Button
      long
      class="confirm-button"
      :loading="loading"
      @click="triggerSetPassword"
    >
      {{ $t('Set password') }}
    </Button>
  </Form>
</template>

<script>
  import TermsAndCondition from "@/views/authentification/TermsAndCondition";

  export default {
    name: "PasswordForm",
    components:{
      TermsAndCondition
    },
    props: {
      passwordSubmit: {
        type: Function,
      },
      message: {
        type: String,
        default: ""
      },
      includeTac: {
        type: Boolean,
        default: false
      },
      includeFullname: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('Please enter your password')))
        } else {
          if (this.form.repeat_password !== '') {
            this.$refs['setPasswordForm'].validateField('repeat_password');
          }
          callback();
        }
      };

      const validateRepeatPass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('Please enter your password')))
        } else if (value !== this.form.password) {
          callback(new Error(this.$t('Password not matched')))
        } else {
          callback();
        }
      };

      const tac_checkbox_validator = (rule, value, callback) => {
       if (value !== true) {
           callback(new Error(this.$t('T&C agreement is required')));
       } else {
         callback();
       }
      }

      return {
        showPassword: false,
        loading: false,
        success: false,
        showTacPopup: false,
        tacVersion: null,
        form: {
          first_name: "",
          last_name: "",
          password: "",
          repeat_password: "",
          agree_tac: false,  // NOT FOR SERVER, which relies on tac_version instead
        },
        setPasswordRules: {
          first_name: [
            {required: true, message: this.$t("Please enter your first name"), trigger: 'blur'},
            {type: 'string', min: 2, message: this.$t('Name should be at least 2 characters'), trigger: 'blur'}
          ],
          last_name: [
            {required: true, message: this.$t("Please enter your last name"), trigger: 'blur'},
            {type: 'string', min: 2, message: this.$t('Name should be at least 2 characters'), trigger: 'blur'}
          ],
          password: [
            {required: true, validator: validatePass, trigger: 'blur'},
            /* BEWARE keep that in sync with BACKEND constraints: */
            {type: 'string', min: 8, message: this.$t('Password should be at least 8 characters long'), trigger: 'blur'}
          ],
          repeat_password: [
            {required: true, validator: validateRepeatPass, trigger: 'blur'},
          ],
          agree_tac :[
            {required: true, type: "boolean", trigger: 'blur', validator: tac_checkbox_validator},
          ],
        },
        setPasswordErrors: {
          first_name: "",
          last_name: "",
          password: "",
        }
      }
    },
    methods: {
      setLoad(state) {
        this.loading = state;
      },

      triggerSetPassword() {
        this.$refs['setPasswordForm'].validate((valid) => {
          if (valid) {
            const data = {
              password: this.form.password,
              repeat_password: this.form.repeat_password
            }

            if (this.includeTac)
              data["tac_version"] = this.tacVersion
            if (this.includeFullname) {
              data["first_name"] = this.form.first_name
              data["last_name"] = this.form.last_name
            }
            
            this.passwordSubmit(data, this.setLoad);
          }
        })
      },

      storeTacVersion(version) {
         this.tacVersion = version;
      },
      acceptTac(agree){
        this.form.agree_tac = agree;
      },
      handleSubmissionErrors(err) {
        const error = err.response;
        if (error && error.status === 400) {
            for (let col in error.data) {
                this.setPasswordErrors[col] = error.data[col][0];
            }
            return true;
        } else {
          return false;  // Caller shall display an error by itself
        }
      }
    }
  }
</script>

<style scoped>
</style>
