

import { defineStore } from 'pinia'
import {getCountriesQry} from '../services/common.service'


export const useWorldInfoStore = defineStore('worldInfo', {
  state: () => ({
    _internalCountryList: null,
    _internalCountryMapper: null,
  }),

  getters: {
    countryList(state){
      this._ensureCountryLoadingDone();
      return state._internalCountryList || [];
    },
  },

  actions: {

    _ensureCountryLoadingDone() {
      /* Data is loaded ASYNCHRONOUSLY, without "await", but we count on VueJS Reactivity to update real values later in UI */

      if (this._internalCountryList) return;  // Already loaded
      console.log(">> We trigger loading of countries via API");  // TEMP

      /* It's OK if multiple requests are fired in parallel, the AXIOS cache handles that */
      getCountriesQry().then(res => {
        this._internalCountryList = res.data;
        this._internalCountryMapper = Object.fromEntries(res.data.map(entry => [entry.iso, entry]));
      });  // FIXME handle errors?
    },

    getCountryFlag(iso) {  // Might return empty value
      this._ensureCountryLoadingDone();
      let countryEntry = (this._internalCountryMapper || {})[iso];
      return countryEntry ? countryEntry.flag : '';
    },

  },
})
