import {axiosInstance} from "./base"


export function postClientContractQry(client_id, data) {
    return axiosInstance.post(`api/clients/${client_id}/contracts/`, data, {})
}

export function patchContractQry(client_id, contract_id, data) {
    return axiosInstance.patch(`api/clients/${client_id}/contracts/${contract_id}/`, data, {})
}

export function postCheckContractEmailQry(data) {
    return axiosInstance.post(`api/contracts/check-contract-email/`, data, {})
}

export function getClientContractsQry(client_id, params) {
    return axiosInstance.get(`api/clients/${client_id}/contracts/`, {params})
}

export function putClientContractQry(client_id, contract_id, data) {
    return axiosInstance.put(`api/clients/${client_id}/contracts/${contract_id}/`, data, {})
}

export function deleteClientContractQry(client_id, contract_id) {
    return axiosInstance.delete(`api/clients/${client_id}/contracts/${contract_id}/`, {})
}

export function postDomainOrEmailQry(client_id, contract_id, data) {
    return axiosInstance.post(`api/clients/${client_id}/contracts/${contract_id}/domains/`, data, {})
}

export function deleteDomainOrEmailQry(client_id, contract_id, domain_id) {
    return axiosInstance.delete(`api/clients/${client_id}/contracts/${contract_id}/domains/${domain_id}/`)
}