<template>
  <div class="confirmation-display">
    <Icon
      type="md-close"
      size="18"
      class="pointer-cursor app-info-close"
      @click.native="triggerClose"
    />
    <p class="text-center">
      <img
        v-if="iconType==='thumbsup'"
        src="../../assets/images/icon/site/thumbs_up.png"
        alt=""
        class="icon-image"
      >
      <Icon
        v-else
        :type="iconType"
        size="50"
        class="confirm-warning-icon"
        :color="iconColor"
      />
    </p>
    <h3 class="text-center extra-title">
      {{ extraTitle }}
    </h3>
    <p class="text-center confirmation-message">
      {{ message }}
    </p>
  </div>
</template>

<script>

    import i18n from '@/i18n/i18n'

    export default {
        name: "AppInfo",
        props: {
            'loadingEnabled': {default: true},
            "message": {default: i18n.t('Successfully submitted your form')},
            "extraTitle": {default: i18n.t('Success')},
            "iconType": {default: "thumbsup"},
            "iconColor": {default: "#007DB3"},
            "timeout": {default: 3000},

        },
        data() {
            return {
                loading: false
            }
        },

        computed: {
            cancel() {
                return i18n.t('Cancel');
            }
        },

        mounted() {
            setTimeout(() => {
                this.$AppModal.remove();
            }, this.timeout)
        },
        methods: {
            triggerClose() {
                this.$AppModal.remove();
            }
        }
    }
</script>

<style>
    .confirmation-display > * {
        margin-bottom: 14px;
    }

    .confirmation-display {
        padding: 20px 20px 0 40px;
    }

    .confirmation-display .confirmation-message {
        font-size: 14px;
        padding: 0 20px;
    }


    .confirmation-display .confirmation-action {
        margin-top: 20px;
    }

    .confirmation-display .confirmation-action button:first-of-type {
        margin-bottom: 10px;
    }

    .app-info-close {
        position: absolute;
        right: 10px;
        top: 5px;
    }
    .icon-image{
        width: 80px;
    }
    .extra-title{
        margin-top: 20px;
        font-weight: 500;
        font-size: 24px;
    }
</style>
