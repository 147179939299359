<template>
  <div class="full-height">
    <Row>
      <i-col :md="12" :xs="14">
        <p class="history-account-actual">{{ $t("Account") }}</p>
      </i-col>
      <i-col v-if="isOperatorOrAbove()" :md="12" :xs="10" class="text-right" align="right">
        <button class="action-button" @click="triggerShowCreateModal">
          {{ $t("Add account") }}
        </button>
        <ClientModalForm
          v-model="modalData"
          :modalOnSubmit="modalOnSubmit"
          :countryList="worldInfoStore.countryList"
          @hide="triggerHideModal"
        />
      </i-col>
    </Row>
    <Row class="mt-4">
      <i-col v-if="isOperatorOrAbove()" :md="12" :xs="24" class="mt-3">
        <i-col :md="8" :xs="12" class="mr-4">
          <p class="text-filter">{{ $t("Search") }}</p>
          <Input
            search
            type="text"
            v-model="clients.filter.name"
            @on-search="fetchClients({ client_name: clients.filter.name })"
            clearable
            @on-clear="fetchClients"
            :placeholder="$t('Search by name')"
          />
        </i-col>
        <i-col :md="8" :xs="8" class="mr-3">
          <p class="text-filter">{{ $t("Status") }}</p>
          <i-select
            type="text"
            :placeholder="$t('Status')"
            v-model="clients.filter.status"
            clearable
            @on-change="fetchClients({ client_is_active: clients.filter.status })"
          >
            <i-option value="true"> {{ $t("Active") }} </i-option>
            <i-option value="false"> {{ $t("Inactive") }} </i-option>
          </i-select>
        </i-col>
      </i-col>
    </Row>
    <transition appear name="slide-left-centered">
      <AppTable
        :columns="columns"
        table-class="s2ec-table mt-5"
        :data-table="clients.results"
        ref="table"
        @on-row-click="open"
        :row-class-name="getAccountsTableRowClassName"
        :no-data-text="$t('No data')"
        :loading="isLoading"
      />
    </transition>
  </div>
</template>
<script>
import ClientModalForm from "@/views/accounts/modals/ClientModalForm";
import {
  getClientsQry,
  deleteClientQry,
  patchClientQry,
  postClientQry,
  putClientQry,
} from "@/services/accounts.service";
import AppModal from "@/components/common/AppModal";
import AppTable from "@/components/common/AppTable";
/* FIXME REMOVE THIS OBSOLETE FILE */

export default {
  name: "Accounts",
  components: {
    ClientModalForm,
    getClientsQry,
    deleteClientQry,
    AppModal,
    AppTable,
  },
  data() {
    return {
      isLoading: false,
      clients: {
        results: [],
        count: 0,
        filter: {
          status: "",
          name: "",
        },
      },
      modalData: {
        showModal: false,
        form: {},
      },
      modalOnSubmit: null,
      columns: [
        {
          title: this.$t("Id"),
          key: "id",
          align: "left",
        },
        {
          title: this.$t("Name"),
          key: "client_name",
          sortable: true,
          align: "left",
        },
        {
          title: this.$t("Status"),
          key: "client_status",
          align: "center",
          render: (h, params) => {
            if (params.row.client_is_active === false) {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      paddingLeft: "16px",
                      paddingTop: "7px",
                      paddingRight: "16px",
                      border: "none",
                      textAlign: "center",
                      margin: "auto",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      width: "80px",
                      borderRadius: "5px",
                      backgroundColor: "#CC0000",
                    },
                  },
                  this.$t("Inactive")
                ),
              ]);
            } else {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      paddingLeft: "16px",
                      paddingTop: "7px",
                      paddingRight: "16px",
                      border: "none",
                      textAlign: "center",
                      width: "70px",
                      margin: "auto",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                      backgroundColor: "#268044",
                    },
                  },
                  this.$t("Active")
                ),
              ]);
            }
          },
        },
        {
          title: this.$t("Number of contracts"),
          key: "total_contracts",
          align: "center",
        },
        {
          title: this.$t("Actions"),
          key: "actions",
          width: "180",
          align: "center",
          render: (h, params) => {
            if (!this.isOperatorOrAbove()) return [];
            return [
              h("Button", {
                props: {
                  icon: "md-create",
                },
                attrs: {
                  title: this.$t("Edit"),
                },
                class: "table-button",
                on: {
                  click: (evt) => {
                    evt.stopPropagation(); // "click.stop" is not supported here
                    this.triggerShowUpdateModal(params.row);
                  },
                },
              }),
              h("Button", {
                props: {
                  icon: params.row.client_is_active ? "md-close" : "md-done-all",
                },
                attrs: {
                  title: params.row.client_is_active ? this.$t("Disable") : this.$t("Enable"),
                },
                class: "table-button",
                on: {
                  click: (evt) => {
                    evt.stopPropagation(); // "click.stop" is not supported here
                    this.changeStateAcccount(params.row.id, params.row.client_is_active);
                  },
                },
              }),
              h("Button", {
                props: {
                  icon: "md-trash",
                },
                attrs: {
                  title: this.$t("Delete"),
                },
                class: "table-button",
                on: {
                  click: (evt) => {
                    evt.stopPropagation(); // "click.stop" is not supported here
                    this.removeAccount(params.row.id, params.index);
                  },
                },
              }),
            ];
          },
        },
      ],
    };
  },
  computed: {
    emptyClientForm() {
      return {
        client_name: "",
      };
    },
  },
  created() {

    this.modalData.form = { ...this.emptyClientForm };
    this.modalOnSubmit = this.triggerCreateClient;

    this.fetchClients();
  },
  methods: {
    getAccountsTableRowClassName() {
      return "account-table__row";
    },
    fetchClients(filter_value) {
      this.isLoading = true;
      getClientsQry(filter_value)
        .then((res) => {
          this.clients.results = res.data.results;
          this.clients.count = res.data.count;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred retrieving clients"),
            duration: 5,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    open(row) {
      this.$router.push({
        name: "contracts",
        params: { id: row.id },
      });
    },
    removeAccount(id, idx) {
      this.$AppModal.AppConfirm({
        extraTitle: this.$t("Delete"),
        message: this.$t("You are about to delete this item. Are you sure to continue"),
        loading: true,
        cancelText: this.$t("Cancel"),
        onConfirm: () => {
          deleteClientQry(id)
            .then(() => {
              this.clients.count -= 1;
              this.clients.results.splice(idx, 1);
              this.$AppModal.remove();
              this.$Message.success(this.$t("Successfully deleted your client"));
            })
            .catch(() => {
              this.$AppModal.remove();
              this.$Message.error({
                content: this.$t("Error occurred deleting your client"),
                duration: 5,
              });
            });
        },
      });
    },
    openAccount() {
      this.$router.push({
        name: "contracts",
        params: { id: params.row.id },
      });
    },
    changeStateAcccount(id, state) {
      patchClientQry(id, { client_is_active: !state })
        .then(() => {
          this.$Message.success(this.$t("Successfully updated a client"));
          setTimeout(() => {
            window.location.reload(); // FIXME NO SUCH RELOAD IN S.P.A
          }, 500);
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred"),
            duration: 5,
          });
        });
    },
    triggerShowCreateModal() {
      this.modalData.form = { ...this.emptyClientForm };
      this.modalData.showModal = true;
      this.modalOnSubmit = this.triggerCreateClient;
    },
    triggerShowUpdateModal(clientData) {
      this.modalData.form = { ...clientData };
      this.modalData.showModal = true;
      this.modalOnSubmit = this.triggerUpdateClient;
    },
    triggerHideModal() {
      this.modalData.form = { ...this.emptyClientForm };
      this.modalData.showModal = false;
      this.modalOnSubmit = this.triggerCreateClient;
    },
    triggerCreateClient() {
      const clientData = this.modalData.form;

      postClientQry(clientData)
        .then(() => {
          this.$Message.success(this.$t("Successfully created your client"));
          this.fetchClients();
          this.triggerHideModal();
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error when adding a client"),
            duration: 5,
          });
        });
    },
    triggerUpdateClient() {
      const clientData = this.modalData.form;

      putClientQry(clientData.id, clientData)
        .then(() => {
          this.$Message.success(this.$t("Successfully updated a client"));
          this.fetchClients();
          this.triggerHideModal();
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error when updating a client"),
            duration: 5,
          });
        });
    },
  },
};
</script>

<style scoped>
.ivu-input {
  border-radius: 5px !important;
}
.s2ec-table.accounts2 .ivu-table-title {
  margin-bottom: 42px !important;
}

.ivu-btn.ivu-btn-default.ivu-btn-icon-only {
  color: var(--secondary-bleu) !important;
}
.account-table__row {
  height: 400px !important;
}
.ivu-btn:focus {
  box-shadow: none;
}

.ivu-table-wrapper {
  border: 1px solid var(--border) !important;
}

.ivu-input-icon {
  line-height: 40px;
}
</style>
