import { defineStore } from "pinia";
import { getAuthSession } from "@/helpers/auth.helper";
import { getUserQry } from "@/services/accounts.service";

export const useUserProfileStore = defineStore("userProfile", {
  state: () => ({
    _internalUser: {
      profile: {
        contracts_names: [],
        user_preferences: {},
        languages_spoken: [],
        nationalities: [],
        cellphones : []
      },
      selectedCompany: null,
    },
    _selectedUser: {
      profile: {
        contracts_names: [],
        user_preferences: {},
        languages_spoken: [],
        nationalities: [],
        cellphones : []
      },
    },
  }),
  getters: {
    getInternalUserProfile: (state) => state._internalUser.profile,
    getSelectedUserProfile: (state) => state._selectedUser.profile,
    getSelectedCompany: (state) => state._internalUser.selectedCompany,
    getInternatlId: (state) => state._internalUser.profile.id,
  },
  actions: {
    async _loadAuthSession() {
      let authSession = getAuthSession()
      this._internalUser.profile = authSession;
    },
    async _loadMyProfile(paramsId) {
      await getUserQry(paramsId)
        .then((res) => {
          this._internalUser.profile = { ...res.data };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async _loadUserProfile(paramsId) {
      await getUserQry(paramsId)
        .then((res) => {
          this._selectedUser.profile = { ...res.data };
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
});
