import App from './App.vue'
import Vue from 'vue'
import VueNativeSock from 'vue-native-websocket'
import "./plugins/iview.js";
import router from "./routers";
import {userTypeMixin} from "./plugins/auth.mixins";
import {commonPiniaStoresMixin} from "./plugins/commonPiniaStores";
import VueBar from 'vuebar';
import AppFilter from "./plugins/appFilter.plugin"
import AppConfirm from "./plugins/AppConfirm"
import VueI18n from 'vue-i18n'
import i18n  from "./i18n/i18n";
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import Zendesk from '@dansmaculotte/vue-zendesk'
import maplibregl from 'maplibregl-js-loader/maplibre-gl'
import { createPinia, PiniaVuePlugin } from 'pinia'

import VueLodash from 'vue-lodash'
import throttle from "lodash/throttle"
import debounce from "lodash/debounce"
import cloneDeep from "lodash/cloneDeep"
import isEqual from "lodash/isEqual"

require("./plugins/gmap");

import "./assets/css/style.css";

window.maplibregl = maplibregl // FIXME: Use maplibregl properly

Vue.config.productionTip = false;

Vue.config.gmapDefaultOptions = {disableDefaultUI: true, minZoom: 3};
Vue.component('GmapCluster', GmapCluster)

Vue.prototype.$AppModal = AppConfirm;
Vue.prototype.$eventBus = new Vue();

Vue.mixin(userTypeMixin);
Vue.mixin(commonPiniaStoresMixin);
Vue.use(require('vue-moment'));
Vue.use(VueBar);
Vue.use(AppFilter);
Vue.use(VueNativeSock, 'ws://localhost:8000/core/notification/', {connectManually: true});
Vue.use(VueI18n);
Vue.use(PiniaVuePlugin)
Vue.use(VueLodash, { lodash: { throttle, debounce, cloneDeep, isEqual } })

// Doesn't work for some reason : setMapStoreSuffix('XXXXXX');
// https://pinia.vuejs.org/cookbook/options-api.html#giving-access-to-the-whole-store
const pinia = createPinia()

Vue.use(Zendesk, {
  disabled: true,  // Manual loading will happen on non-login views
  /*settings: {
    webWidget: {
      color: {
        theme: '#78a300'
      }
    }
  }*/
})

new Vue({
    router,
    i18n,
    render: h => h(App),
    pinia,
}).$mount('#app');
