import {axiosInstance, TILE_TTL} from "./base"
import axios from "axios"


export function getIncidentTypesQry() {
    return axiosInstance.get(`api/incidents-types/`, {})
}

export function getIncidentQry(params) {
    return axiosInstance.get(`api/incidents/`, {params})
}

export function getIncidentTilesQry(tiles, zoom, params) {
    var requests = tiles.map(coords => {
        return axiosInstance.get(`api/incidents/tiles/${zoom}/${coords[0]}/${coords[1]}/`,
                                 {params, ttl: TILE_TTL})
    });
    return axios.all(requests)
}

export function postIncidentQry(data, params) {
    return axiosInstance.post(`api/incidents/`, data, {params})
}

export function putIncidentQry(id, data, params) {
    return axiosInstance.put(`api/incidents/${id}/`, data, {params})
}

export function patchIncidentQry(id, data, params) {
    return axiosInstance.patch(`api/incidents/${id}/`, data, {params})
}

export function deleteIncidentQry(id) {
    return axiosInstance.delete(`api/incidents/${id}/`, {})
}

export function getZonesOfInterest() {
    return axiosInstance.get(`api/danger-zones/`, {})
}

export function getZonesOfInterestTilesQry(tiles, zoom, params) {
    var requests = tiles.map(coords => {
        return axiosInstance.get(`api/danger-zones/tiles/${zoom}/${coords[0]}/${coords[1]}/`,
                                 {params, ttl: TILE_TTL})
    });
    return axios.all(requests)
}

export function postIncidentBroadcast(data) {
    return axiosInstance.post(`api/incidents/broadcast/`, data, {})
}
