<template>
  <Row
    :gutter="20"
    type="flex"
  >
    <i-col
      :xs="24"
      :md="24"
    >
      <Tabs
        ref="incidentTab"
        v-model="activeTab"
        type="card"
        :before-remove="tabRemove"
        @on-click="handleTabClicked"
      >
        <TabPane
          v-for="(location, idx) in locations"
          :key="idx"
          :label="(h) => optTabTitle(h, idx, location.id)"
          :name="idx.toString()"
        >
          <LocationForm
            ref="locationForm"
            v-model="locations[idx]"
            :country_list="country_list"
            @setMapView="setMapView"
          />
        </TabPane>
        <Button
          slot="extra"
          class="action-button action-button-little"
          size="small"
          @click="handleTabsAdd"
        >
          {{ $t('Add location') }}
        </Button>
      </Tabs>
    </i-col>
    <i-col
      :xs="24"
      :md="24"
    >
      <GmapMap
        :center="gmapAttr.center"
        :zoom="gmapAttr.zoom"
        :options="gmapAttr.options"
        map-type-id="terrain"
        style="height: 220px"
      >
        <gmap-marker :position="currentMarker" />
        <gmap-circle
          :center="gmapAttr.center"
          :radius="locations[parseInt(activeTab)].affected_radius"
          :options="gMapCircleOptions"
        />
      </GmapMap>
    </i-col>
  </Row>
</template>

<script>
    /* FIXME move this to modals subfolder */

    import LocationForm from "./modals/IncidentLocationForm"
    import {defaultLocation} from "../../helpers/incident.helper";
    import Vue from 'vue';

    export default {
        name: "IncidentLocation",
        components: {
            LocationForm
        },
        props: ['value', 'country_list'],
        data() {
            return {
                activeTab: "0",
                gmapAttr: {
                    center: {lat: 10, lng: 10},
                    zoom: 4,
                    options: Vue.config.gmapDefaultOptions
                },
                currentMarker: {
                    lat: 10,
                    lng: 10,
                },
                gMapCircleOptions: {
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.9,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,

                },
            }
        },
        computed: {
            locations: {
                get() {
                    if (this.value.length === 0) {
                        this.$emit('addLocation', {...defaultLocation});
                        return this.value
                    } else {
                        if (this.value[0].latitude && this.value[0].longitude) {
                            this.setMapView(this.value[0].latitude, this.value[0].longitude);
                        }
                        return this.value
                    }
                }
            }
        },
        created() {
        },
        methods: {
            async handleTabsAdd() {
                const valid = await this.handleValidate();

                if (valid) {
                    this.locations.push({...defaultLocation});
                    this.activeTab = (this.locations.length - 1).toString();
                }
            },

            async handleValidate() {
                const validaLocation = await this.$refs['locationForm'][this.locations.length - 1].validate();
                if (!validaLocation) {
                    this.activeTab = (this.locations.length - 1).toString();
                }
                return validaLocation;

            },

            tabRemove(idx) {
                return new Promise(() => {
                    this.locations.splice(idx, 1);
                    if (idx === 0) {
                        this.activeTab = "0";
                    } else {
                        this.activeTab = (idx - 1).toString();
                    }
                })
            },

            handleTabClicked(tab) {
                this.setMapView(this.value[parseInt(tab)].latitude, this.value[parseInt(tab)].longitude);
            },

            setMapView(lat, lng) {
                this.gmapAttr.center.lat = lat;
                this.gmapAttr.center.lng = lng;
                this.gmapAttr.zoom = 8;
                this.currentMarker.lat = lat;
                this.currentMarker.lng = lng;
            },

            optTabTitle(h, idx) {
                let text = "";
                if (idx === 0) {
                    text = this.$t('Primary location')
                } else {
                    text = this.$t('Location ') + (idx + 1);
                }
                return h('div', [
                    h('span', text),
                    h('Icon', {
                        props: {
                            type: 'ios-close',
                            size: '20',
                        },
                        on: {
                            click: () => {
                                function isEmpty(obj) {
                                    for (var key in obj) {
                                            if (obj[key])
                                                return false;
                                    }
                                    return true;
                                }
                                if (!isEmpty(this.locations[idx])) {
                                    this.$AppModal.AppConfirm({
										method: 'delete',
                                        onConfirm: (() => {
                                            this.tabRemove(idx);
                                            this.$AppModal.remove();
                                        })
                                    });
                                } else this.tabRemove(idx);
                            }
                        }
                    })
                ])
            },

            resetTab() {
                this.activeTab = "0";
                this.$refs.locationForm[0].resetField();
                this.resetDataName(this, 'gmapAttr');
                this.resetDataName(this, 'currentMarker');
            }
        }
    }
</script>

<style scoped>
    .action-button-little {
        height: 30px;
        width: 130px;
        margin: 5px 0;
    }

</style>
