<template>
  <div class="full-height">
    <Row
      type="flex"
      :gutter="10"
      class="alerts-content arrowed-panel-wrapper"
    >
      <i-col
        :sm="6"
        class="full-height"
      >
        <Emergencies
          ref="attempt"
          emergency-status="attempt"
          :filter-params="filterParams"
          :title="$t('Emergencies pending')"
          @triggerEmergencyUpdate="triggerEmergencyUpdate"
        />
      </i-col>
      <i-col
        :sm="6"
        class="full-height"
      >
        <Emergencies
          ref="processing"
          emergency-status="processing"
          :filter-params="filterParams"
          :title="$t('Emergencies in process')"
          @triggerEmergencyUpdate="triggerEmergencyUpdate"
        />
      </i-col>
      <i-col
        :sm="6"
        class="full-height"
      >
        <Emergencies
          ref="closed"
          emergency-status="closed"
          :filter-params="filterParams"
          :show-arrow="false"
          :allow-manage="false"
          :title="$t('Emergencies closed')"
          @triggerEmergencyUpdate="triggerEmergencyUpdate"
        />
      </i-col>
      <i-col
        :sm="6"
        class="full-height"
      >
        <Alert
          ref="warning"
          :filter-params="filterParams"
        />
      </i-col>
    </Row>
    <EmergencyUpdate
      ref="emergencyUpdate"
      v-model="showEmergencyUpdate"
      @emergencyUpdated="emergencyUpdated"
    />
  </div>
</template>

<script>
    import Emergencies from "@/views/alerts/Emergencies"
    import EmergencyUpdate from "@/views/alerts/modals/EmergencyUpdate"
    import Alert from "@/views/alerts/Alert"

    // FIXME rename this misleading view to emergenciesAndAlerts.vue

    export default {
        name: "Alerts",
        components: {
            Emergencies,
            EmergencyUpdate,
            Alert,
        },
        data() {
            return {
                showEmergencyUpdate: false,
                filterParams: {},
            }
        },
        methods: {
            emergencyUpdated(updateAttr, data) {
                const emergencyStatuses = ["attempt", "processing", "closed"];
                if (updateAttr.emergencyStatus !== emergencyStatuses[data.status - 1]) {
                    this.$refs[updateAttr.emergencyStatus].removeEmergency(updateAttr.idx);
                    this.$refs[emergencyStatuses[data.status - 1]].addEmergency(data);
                }
            },
            triggerEmergencyUpdate(emergencyStatus, data, idx, allowManage) {
                this.$refs['emergencyUpdate'].setEmergencyData(emergencyStatus, data, idx, allowManage);
                this.showEmergencyUpdate = true;
            },

            triggerFilter(filterData) {
                const {type, ...data} = filterData;
                this.filterParams = data;

                if (type) {
                    this.$refs[type].triggerFilter();
                }
            }
        }
    }
</script>

