import Auth from '@/views/authentification/Auth'
import Login from "@/views/authentification/Login"
import Registration from "@/views/authentification/Registration"
import Invitation from "@/views/authentification/Invitation"
import RegistrationConfirmation from "@/views/authentification/RegistrationConfirmation"
import LostPassword from "@/views/authentification/LostPassword"
import LostPasswordConfirmation from "@/views/authentification/LostPasswordConfirmation"
import {isAuthenticated} from "@/helpers/auth.helper";

export default {
    path: '/login',
    component: Auth,
    children: [
        {
            path: '',
            component: Login,
            name: 'login',
            beforeEnter: async (to, from, next) => {
                if (await isAuthenticated()) {
                    next({name: 'general-overview'});
                } else {
                    next();
                }
            },
        },
        {
            path: '/registration',
            component: Registration,
            name: 'registration',
        },
        {
            path: '/registration/confirm/:key',
            component: RegistrationConfirmation,
            name: 'registration-confirmation',
        },
        {
            path: '/invitation/:key',
            component: Invitation,
            name: 'invitation',
        },
        {
            path: '/lost-password/',
            component: LostPassword,
            name: 'lost-password',
        },
        {
            path: '/lost-password/confirm/:key',
            component: LostPasswordConfirmation,
            name: 'lost-password-confirmation',
        },


    ]
}