<template>
  <Form
    ref="analysisForm"
    class="analysis-form"
    :model="fact.data"
    :rules="factDataRules"
  >
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      class="form-action"
    >
      <i-col
        :md="24"
        class="text-right"
      >
        <Button
          v-if="fact.id"
          type="success"
          class="confirm-button"
          @click="triggerUpdate"
        >
          {{ $t('Update') }}
        </Button>
        <Button
          v-else
          type="success"
          class="confirm-button"
          @click="triggerCreate"
        >
          {{ $t('Create') }}
        </Button>
        <Button
          type="default"
          class="cancel-button"
          @click="triggerCancel"
        >
          {{ $t('Cancel') }}
        </Button>
      </i-col>
    </Row>
    <FormItem prop="title">
      <p>{{ $t('Title') }}</p>
      <i-input v-model="fact.data.title" />
    </FormItem>
    <FormItem prop="content">
      <p>{{ $t('Content') }}</p>
      <AppTipTap
        ref="tipTap"
        v-model="fact.data.content"
        :default-editable="true"
      />
    </FormItem>
  </Form>
</template>

<script>
    import AppTipTap from "@/components/common/AppTipTap";
    import {postCountryFactsQry, putCountryFactsQry} from "@/services/countryInfo.service";

    export default {
        name: "AnalysisForm",
        components: {
            AppTipTap
        },
        props: ['factType'],
        data() {
            return {
                updateIndex: null,
                fact: {
                    id: null,
                    country: this.$route.params.country,
                    country_fact_type: null,
                    is_active: true,
                    data: {
                        title: "",
                        content: "",
                    },
                },

                factDataRules: {
                    title: {required: true, message: this.$t('Field is required'), trigger: 'blur'},
                    content: {required: true, message: this.$t('Field is required'), trigger: 'blur'}
                }
            }
        },
        methods: {
            triggerCreate() {
                this.$refs['tipTap'].submit();
                this.$refs['analysisForm'].validate((valid) => {
                    if (valid) {
                        const formData = {
                            ...this.fact,
                            country_fact_type: this.factType.id,
                        };
                        postCountryFactsQry(formData)
                            .then(res => {
                                this.$emit('analysisCreated', res.data);
                                this.triggerCancel();
                                this.$Message.success(this.$t('Successfully created your data'))
                            })
                            .catch(() => {
                                this.$Message.error({content: this.$t('Error creating you data'), duration: 5});
                            })
                    }
                });

            },

            triggerUpdate() {
                this.$refs['tipTap'].submit();
                this.$refs['analysisForm'].validate((valid) => {
                    if (valid) {
                        putCountryFactsQry(this.fact.id, this.fact)
                            .then(res => {
                                this.$emit('analysisCreated', res.data, this.updateIndex);
                                this.triggerCancel();
                                this.$Message.success(this.$t('Successfully updated your data'))
                            })
                            .catch(() => {
                                this.$Message.error({content: this.$t('Error creating you data'), duration: 5});
                            })
                    }
                });
            },

            triggerCancel() {
                this.resetDataName(this, 'fact');
                this.updateIndex = null;
                this.$emit('triggerCancel');

            },

            setUpdateData(data, idx) {
                this.updateIndex = idx;
                Object.keys(this.fact).forEach((key) => {
                    this.fact[key] = data[key];
                    if(key === "data"){
                        this.$refs['tipTap'].setContent(data[key].content);
                    }
                });
            }
        }
    }
</script>

<style>
    .analysis-form .app-tip-tap {
        background: #ffffff;
    }
</style>
