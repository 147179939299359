export { Clustering };

import { FeaturesPopup } from "./FeaturesPopup.js";

class Clustering {

  constructor(object, layerId, unclusteredIconSize, loadEventName) {
    this.viewer = object.viewer;
    this.maplibregl = object.viewer.maplibregl;
    this.layers = object.layers;
    this.map = this.viewer.map;
    this.minzoom = object.minzoom;
    this.maxzoom = object.maxzoom;
    this.commonLayersId = object.commonLayersId;
    this.stops = object.stops;
    this.filter = [];
    this.isLoaded = false;
    this.categorizeClustering = object.categorizeClustering;
    this.colors = [];
    this.pulseValues = [];
    this.clusterCoord = {};
    this.layerId = layerId;
    this.unclusteredIconSize = unclusteredIconSize;
    this.loadEventName = loadEventName;

    this.markers = {}
    this.markersOnScreen = {}

    if (object.forcedgeometry) {
      this.forcedgeometry = object.forcedgeometry;

      this._GET(this.forcedgeometry.source).then((geojson) => {
        this.forcedgeometry.geojson = JSON.parse(geojson[0]);
        this.forcedgeometry.matchingtable = {};

        this.forcedgeometry.geojson.features.forEach((element) => {
          this.forcedgeometry.matchingtable[
            element.properties[this.forcedgeometry.centroidmatchingfield]
          ] = element.geometry;
        });
        this.build();
      });
    } else {
      this.build();
    }
  }

  build() {
    var jsons = [];

    this.layers.forEach((element) => {
      if (element.enabled == false) {
        this.filter.push(element.title);
      }

      var geojson = element.data;
      var sprite = element.sprite;
      var title = element.title;

      geojson.features.forEach((element2) => {
        element2.properties["title"] = title;

        if (element2.properties["icon"]) {
          element2.properties["sprite"] = element2.properties["icon"];
        } else {
          element2.properties["sprite"] = sprite;
        }
        if (element.centroidmatchingfield) {
          element2.geometry =
            this.forcedgeometry.matchingtable[
              element2.properties[element.centroidmatchingfield]
            ];
        }
      });

      jsons.push(JSON.parse(JSON.stringify(geojson)));
    });

    this.geoJsonCluster = this.mergeJson(jsons);
    this.addSource(this.layerId, this.geoJsonCluster);
    this.addLayer(this.layerId);
  }

  setPulseValue(layerId, values) {
    this.pulseValues = [];

    values.forEach((element) => {
      this.pulseValues.push(layerId + "-" + element);
    });
  }

  addPulseValue(layerId, values) {
    values.forEach((element) => {
      this.pulseValues.push(layerId + "-" + element);
    });
  }

  reload() {
    var id = this.layerId;

    this.addSource(id, this.geoJsonCluster);

    this.map.removeLayer(id + "-clustered");
    this.map.removeLayer(id + "-unclustered");
    this.map.removeLayer(id + "-count");

    this.addLayer(id);
  }

  mergeJson(jsonList) {
    var features = [];

    jsonList.forEach((element) => {
      features = features.concat(element.features);
    });

    jsonList[0].features = features;

    return jsonList[0];
  }

  filterGeojson(key, values) {
    var filteredGeoJson = JSON.parse(JSON.stringify(this.geoJsonCluster));

    for (let index = 0; index < filteredGeoJson.features.length; index++) {
      const element = filteredGeoJson.features[index];

      if (values.includes(element.properties[key])) {
        filteredGeoJson.features.splice(index, 1);
        index--;
      }
    }
    return filteredGeoJson;
  }

  addSource(id, json) {
    var sourceProperties = {
      type: "geojson",
      data: json,
      cluster: true,
      clusterRadius: 40,
      clusterProperties: {},
    };

    if (this.categorizeClustering) {
      this.layers.forEach((element) => {
        sourceProperties.clusterProperties[element.title] = [
          "+",
          ["case", ["==", ["get", "title"], element.title], 1, 0],
        ];
      });

      sourceProperties.clusterProperties["ids"] = [
        "concat",
        [
          "concat",
          "'",
          ["get", "title"],
          "-",
          ["get", this.commonLayersId],
          "',",
        ],
      ];
    }

    this.map.addSource(id, sourceProperties);
  }

  addLayer(id) {
    this.addClusteredLayerProperties(id)
    this.addUnclusteredLayer(id)
    if (!this.categorizeClustering)
      this.addCategorizedClustering(id)
    this.addFeaturesPopup(id)

    if (this.filter.length > 0) {
      this.map
        .getSource(id)
        .setData(this.filterGeojson("title", this.filter));
    }

    this.map.on("sourcedata", () => {
      if (
        this.map.getSource(id) &&
        this.map.isSourceLoaded(id) &&
        this.isLoaded == false
      ) {
        this.isLoaded = true;
        this.viewer._eventConstructor(this.loadEventName, null);
      }
    })

    if (this.categorizeClustering) {
      this.enableCategorization();
    }
  }

  addClusteredLayerProperties(id) {
    var clusteredLayerProperties = {
      id: id + "-clustered",
      type: "circle",
      source: id,
      filter: ["==", "cluster", true],
      maxzoom: this.maxzoom,
      minzoom: this.minzoom,
    };

    if (this.categorizeClustering) {
      clusteredLayerProperties.paint = {
        "circle-stroke-width": 0,
        "circle-color": "rgba(0, 0, 0, 0)",
        "circle-radius": 0,
      };
    }
    if (!this.categorizeClustering) {
      clusteredLayerProperties.paint = {
        "circle-stroke-width": 5,
        "circle-stroke-color": "rgba(113, 87, 242, 0.4)",
        "circle-color": "rgba(113, 87, 242, 0.8)",
        "circle-radius": ["step", ["get", "point_count"], 13, 3, 20, 6, 30],
      };
    }

    this.map.addLayer(clusteredLayerProperties);
  }

  addUnclusteredLayer(id) {
    this.map.addLayer({
      id: id + "-unclustered",
      type: "symbol",
      source: id,
      maxzoom: this.maxzoom,
      minzoom: this.minzoom,
      filter: ["!=", "cluster", true],
      layout: {
        "icon-image": "{map-icon}",
        "icon-size": this.unclusteredIconSize,
        "icon-allow-overlap": true,
        "icon-ignore-placement": false,
      },
    });
  }

  addCategorizedClustering(id) {
    this.map.addLayer({
      id: id + "-count",
      type: "symbol",
      source: id,
      maxzoom: this.maxzoom,
      minzoom: this.minzoom,
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 14,
      },
      paint: {
        "text-color": "#ffffff",
      },
    });
  }

  addFeaturesPopup(id) {
    this.featuresPopup = new FeaturesPopup({
      viewer: this.viewer,
      layers: [
        {
          layerId: id + "-unclustered",
          title: true,
          exclude: ["sprite", "title"],
        },
      ],
    });
  }

  enableCategorization() {
    this.layers.forEach((element) => {
      this.colors.push(element.color);
    });

    this.map.on("render", () => {
      if (!this.map.isSourceLoaded(this.layerId)) return;
      this.updateMarkers();
    });

    // after the GeoJSON data is loaded, update markers on the screen and do so on every map move/moveend
  }

  updateMarkers() {

    this.clusterCoord = {};

    var newMarkers = {};
    var features = this.map.querySourceFeatures(this.layerId);

    for (id in this.markersOnScreen) {
      this.markersOnScreen[id].remove();
    }

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (var i = 0; i < features.length; i++) {
      var coords = features[i].geometry.coordinates;
      var props = features[i].properties;
      if (!props.cluster) continue;
      var id = props.cluster_id;

      var marker = this.markers[id];
      if (!marker) {
        var el = this.createClusterBox(props);
        
        marker = this.markers[id] = new this.maplibregl.Marker({
          element: el,
        }).setLngLat(coords);
      }
      newMarkers[id] = marker;

      marker.addTo(this.map);
    }
    // for every marker we've added previously, remove those that are no longer visible

    this.markersOnScreen = newMarkers;

    this.clusterCoord = this.markersOnScreen;

    this.customUpdateMarkers()
  }

  generateCustomEventOnClusterElement(element, sourceEventName, customEventName, options={}) {
  
    if (!options.hasOwnProperty("getFromParent"))
      options.getFromParent = true
    if (!options.hasOwnProperty("getMarkerFromParent"))
      options.getMarkerFromParent = true
    if (!options.hasOwnProperty("includeLayerId"))
      options.includeLayerId = false
    if (!options.hasOwnProperty("toggleHover"))
      options.toggleHover = false
    if (!options.hasOwnProperty("stopPropagation"))
      options.stopPropagation = false
    
    //element.addEventListener(sourceEventName, (e) => { // addEventListener is buggy here, it infinitely add same event and cause big latence
    element[`on${sourceEventName}`] = (e) => { // FIXME: Use addEventListener instead ? Change structure of the code to create event listener properly
      const node = e.target

      if (options.toggleHover === true)
        node.previousElementSibling.classList.toggle("hover");

      const parentNode = node.parentNode
      const targetElem = (options.getFromParent === true) ? parentNode : node
      const markerNode = (options.getMarkerFromParent === true) ? parentNode : node

      const params = this.getParamsFromNodeAttributes(targetElem, node, options.includeLayerId)
      params.marker = this.clusterCoord[
        parseInt(markerNode.getAttributeNS(null, "cluster-id"))
      ]

      if (options.stopPropagation === true)
        e.stopPropagation()
        
      this.viewer._eventConstructor(customEventName, params)
    }
  }

  getParamsFromNodeAttributes(node, originalNode, includeLayerId=false) {
    const params = {
      details: JSON.parse(node.getAttributeNS(null, "counts-details")),
      pulse: parseInt(node.getAttributeNS(null, "pulse-count")),
      clusteriD: parseInt(node.getAttributeNS(null, "cluster-id")),
    }

    if (includeLayerId)
      params.target = originalNode.getAttributeNS(null, "layer-id")
    
    return params
  }

  _GET(path, param) {
    function main(resolve, reject) {
      var request = new XMLHttpRequest();

      request.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          resolve([request.responseText, param]);
        }
      };

      request.open("GET", path, true);
      //request.withCredentials = true;
      request.send();
      request.addEventListener("progress", function (e) {});
    }

    return new Promise((resolve, reject) => main(resolve, reject));
  }
}
