<template>
  <DatePicker
    v-model="currentDate"
    :type="type"
    :transfer="transfer"
    :placeholder="placeholder"
    :placement="placement"
    :readonly="readonly"
    :options="options"
    class="full-width"
  />
</template>

<script>
    import moment from 'moment'

    export default {
        name: "AppDatePicker",
        props: {
            value: {default: null},
            dateFormat: {default: 'YYYY-MM-DD'},
            transfer: {default: false},
            type: {default: 'date'},
            placement: {default: 'bottom-start'},
            placeholder: {default: 'Select date'},
            readonly: {default: false},
            options: {default: null}
        },
        computed: {
            currentDate: {
                get() {
                    return this.value
                },
                set(value) {
                    let currentDateFormat = this.dateFormat;
                    if (this.type === "datetime") currentDateFormat = `${this.dateFormat}Z`;

                    if (value) {
                        this.$emit('input', moment(value).format(currentDateFormat))
                    } else {
                        this.$emit('input', null)
                    }
                }
            }
        },

    }
</script>
