export { DonutClustering };
import { Clustering } from "./Clustering.js";

class DonutClustering extends Clustering {

  customUpdateMarkers() {
    this.updateCustomClusters()
    this.updateCustomClusterCenters()
    this.updateCustomClusterLabels()
    this.updateCustomClusterSegments()   
  }

  updateCustomClusters() {
    var customCluster = document.getElementsByClassName("custom-cluster");

    for (let index = 0; index < customCluster.length; index++) {
      const element = customCluster[index];
      this.generateCustomEventOnClusterElement(element, "click", "click-cluster", {getFromParent: false, stopPropagation: true})
    }
  }

  updateCustomClusterCenters() {
    var customClusterCenter = document.getElementsByClassName(
      "custom-cluster-center"
    );

    for (let index = 0; index < customClusterCenter.length; index++) {
      const element = customClusterCenter[index];

      this.generateCustomEventOnClusterElement(element, "click", "click-cluster-center", {stopPropagation: true})
      this.generateCustomEventOnClusterElement(element, "mouseover", "hover-cluster-center")
      this.generateCustomEventOnClusterElement(element, "mouseout", "leave-cluster-center")
    }
  }

  updateCustomClusterLabels() {
    var customClusterLabel = document.getElementsByClassName(
      "custom-cluster-label"
    );

    for (let index = 0; index < customClusterLabel.length; index++) {
      const element = customClusterLabel[index];

      this.generateCustomEventOnClusterElement(element, "click", "click-cluster-center", {stopPropagation: true})
      this.generateCustomEventOnClusterElement(element, "mouseover", "hover-cluster-center")
      this.generateCustomEventOnClusterElement(element, "mouseout", "leave-cluster-center", {toggleHover: true})
    }
  }

  updateCustomClusterSegments() {
    var customClusterSegment = document.getElementsByClassName(
      "custom-cluster-segment"
    );

    for (let index = 0; index < customClusterSegment.length; index++) {
      const element = customClusterSegment[index];

      this.generateCustomEventOnClusterElement(element, "click", "click-cluster-segment", {includeLayerId: true, stopPropagation: true})
      this.generateCustomEventOnClusterElement(element, "mouseover", "hover-cluster-segment", {includeLayerId: true})
      this.generateCustomEventOnClusterElement(element, "mouseout", "leave-cluster-segment", {includeLayerId: true})
    }
  }

  createClusterBox(props) {
    return this.createDonutChart(props);
  }

  createDonutChart(props) {
    var offsets = [];
    var counts = [];
    var countsDetails = {};

    var data = {};

    this.layers.forEach((element) => {
      counts.push(props[element.title]);
      countsDetails[element.title] = {
        count: props[element.title],
        ids: [],
      };
    });
    var idRefString = "[" + props["ids"].slice(0, -1) + "]";
    var idRef = JSON.parse(idRefString.replaceAll("'", '"'));

    idRef.forEach((element) => {
      var split = element.split("-");
      countsDetails[split[0]].ids.push(split[1]);
    });

    var isPulsing = false;

    var pulseCount = 0;

    if (idRef.some((i) => this.pulseValues.includes(i))) {
      isPulsing = true;
    }

    idRef.forEach((element) => {
      if (this.pulseValues.includes(element)) {
        pulseCount++;
      }
    });

    var total = 0;
    for (var i = 0; i < counts.length; i++) {
      offsets.push(total);
      total += counts[i];
    }

    var fontSize = 15;
    var fontSize =
      total >= this.stops.values[0]
        ? this.stops.fontSize[0]
        : total >= this.stops.values[1]
        ? this.stops.fontSize[1]
        : total >= this.stops.values[2]
        ? this.stops.fontSize[2]
        : 16;
    var r =
      total >= this.stops.values[0]
        ? this.stops.clusterSize[0]
        : total >= this.stops.values[1]
        ? this.stops.clusterSize[1]
        : total >= this.stops.values[2]
        ? this.stops.clusterSize[2]
        : 16;
    var r0 = Math.round(r * 0.6);
    var w = r * 2;

    var xmlns = "http://www.w3.org/2000/svg";
    var clusterBox = document.createElement("div");
    var clusterSvg = document.createElementNS(xmlns, "svg");
    var clusterCenter = document.createElementNS(xmlns, "circle");
    var clusterLabel = document.createElementNS(xmlns, "text");

    clusterBox.appendChild(clusterSvg);
    clusterSvg.appendChild(clusterCenter);
    clusterSvg.appendChild(clusterLabel);

    clusterSvg.setAttributeNS(null, "width", w);
    clusterSvg.setAttributeNS(null, "height", w);
    clusterSvg.setAttributeNS(null, "viewbox", "0 0 " + w + " " + w);
    clusterSvg.setAttributeNS(null, "text-anchor", "middle");
    //clusterSvg.setAttributeNS(null, 'id-ref', idRef)
    clusterSvg.setAttributeNS(null, "cluster-id", props.cluster_id);
    clusterSvg.setAttributeNS(null, "pulse-count", pulseCount);
    clusterSvg.setAttributeNS(
      null,
      "counts-details",
      JSON.stringify(countsDetails)
    );
    clusterSvg.setAttributeNS(
      null,
      "style",
      "font-size: " + fontSize + "px; display: block"
    );

    clusterCenter.setAttributeNS(null, "cx", r);
    clusterCenter.setAttributeNS(null, "cy", r);
    clusterCenter.setAttributeNS(null, "r", r0);
    clusterCenter.setAttributeNS(null, "fill", "white");

    clusterLabel.setAttributeNS(null, "dominant-baseline", "central");
    clusterLabel.setAttributeNS(
      null,
      "transform",
      "translate(" + r + ", " + r + ")"
    );

    clusterSvg.classList.add("custom-cluster");
    clusterCenter.classList.add("custom-cluster-center");
    clusterLabel.classList.add("custom-cluster-label");

    if (isPulsing) {
      clusterSvg.classList.add("pulse-cluster");
    }

    clusterLabel.innerHTML = total.toLocaleString();

    for (i = 0; i < counts.length; i++) {
      var clusterSegment = this.donutSegment(
        offsets[i] / total,
        (offsets[i] + counts[i]) / total,
        r,
        r0,
        this.colors[i],
        this.layers[i].title
      );
      clusterSvg.appendChild(clusterSegment);
      clusterSegment.classList.add("custom-cluster-segment");
    }

    return clusterBox;
  }

  donutSegment(start, end, r, r0, color, layerId) {
    if (end - start === 1) end -= 0.00001;

    var xmlns = "http://www.w3.org/2000/svg";
    var SegmentPath = document.createElementNS(xmlns, "path");

    var a0 = 2 * Math.PI * (start - 0.25);
    var a1 = 2 * Math.PI * (end - 0.25);
    var x0 = Math.cos(a0);
    var y0 = Math.sin(a0);
    var x1 = Math.cos(a1);
    var y1 = Math.sin(a1);
    var largeArc = end - start > 0.5 ? 1 : 0;
    var d = [
      "M",
      r + r0 * x0,
      r + r0 * y0,
      "L",
      r + r * x0,
      r + r * y0,
      "A",
      r,
      r,
      0,
      largeArc,
      1,
      r + r * x1,
      r + r * y1,
      "L",
      r + r0 * x1,
      r + r0 * y1,
      "A",
      r0,
      r0,
      0,
      largeArc,
      0,
      r + r0 * x0,
      r + r0 * y0,
    ].join(" ");

    SegmentPath.setAttributeNS(null, "fill", color);
    SegmentPath.setAttributeNS(null, "d", d);
    SegmentPath.setAttributeNS(null, "layer-id", layerId);

    return SegmentPath;
  }

}
