import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginRoute from './auth.router'
import DashboardRoute from './dashboard.router'
import {isAuthenticated} from "../helpers/auth.helper";
import {userTypeMixin} from '../plugins/auth.mixins'


Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        LoginRoute,
        DashboardRoute
    ],
});

router.beforeEach(async (to, from, next) => {
    const user = userTypeMixin.data().s2ec.user;

    if (to.meta.auth) {
        if (await isAuthenticated()) {
            const userType = Object.keys(user).find(key => user[key] === true);
            if (to.meta.is_admin || to.meta.is_manager || to.meta.is_head_of_site) {
                if (to.meta.hasOwnProperty(userType)) {
                    next();
                } else {
                    next({name: 'general-overview'})
                }
            } else {
                next()
            }
        } else {
            next({name: 'login'});
        }
    } else {
        next();
    }
});


export default router
