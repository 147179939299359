<template>
  <div>
    <Button
      v-if="!showFilters"
      class="s2ec-filter-button confirm-button mb-4"
      @click="showFilters = true"
    >
      <Icon
        type="md-funnel"
        size="18"
      />
      {{ $t('Filter') }}
    </Button>

    <Row
      v-else
      type="flex"
      :gutter="10"
      align="middle"
      class="mb-4"
    >
      <i-col
        :md="16"
        :lg="12"
      >
        <Row :gutter="10">
          <i-col
            :md="12"
            :lg="6"
          >
            <Select
              v-model="filterData.country"
              :placeholder="$t('Country')"
              transfer
              clearable
              filterable
            >
              <Option
                v-for="item in worldInfoStore.countryList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.display_name }}
              </Option>
            </Select>
          </i-col>
          <i-col
            :md="12"
            :lg="6"
          >
            <i-input
              v-model="filterData.city"
              :placeholder="$t('City')"
            />
          </i-col>
        </Row>
      </i-col>
      <i-col
        :md="4"
        :lg="3"
      >
        <Button
          :md="4"
          long
          size="small"
          class="confirm-button"
          @click="triggerApply"
        >
          {{ $t('Apply') }}
        </Button>
      </i-col>
      <i-col
        :md="4"
        :lg="3"
      >
        <Button
          class="cancel-button"
          long
          size="small"
          @click="triggerReset"
        >
          {{ $t('Reset') }}
        </Button>
      </i-col>
    </Row>
  </div>
</template>

<script>
    export default {
        name: "SiteFilter",
        props: [],
        data() {
            return {
                showFilters: false,
                filterData: {
                    country: null,
                    city: null
                }
            }
        },
        methods: {
            triggerApply() {
                this.$emit('applyFilter', this.filterData)

            },
            triggerReset() {
                this.resetDataName(this, "filterData");
                this.showFilters = false;
                this.$emit('applyFilter', this.filterData)
            }
        }
    }
</script>
