<template>
  <Menu
    :width="menuWidth"
    :active-name="activeMenu"
    class="full-height app-side-menu"
    :mode="showingMenu ? 'horizontal' : 'vertical'"
  >
    <MenuGroup :class="onCollapsed ? 's2ec-collapsed-menu' : ''" class="menu-group1">
      <div>
        <MenuItem name="general-overview" class="" :class="showingMenu ? 'ivu-mobile' : ''">
          <router-link :to="{ name: 'map' }" :title="$t('Map')" @click.native="closeMenu()">
            <img src="../../src/assets/images/ico/general_overview.svg" width="20px" />
            <p>{{ $t("Map") }}</p>
          </router-link>
        </MenuItem>
        <MenuItem name="country-information" class="" :class="showingMenu ? 'ivu-mobile' : ''">
          <router-link :to="{ name: 'country-information' }" :title="$t('Country')" @click.native="closeMenu()">
            <img src="../../src/assets/images/ico/flag.svg" width="20px" />
            <p>{{ $t("Country") }}</p>
          </router-link>
        </MenuItem>
        <MenuItem name="missions-management">
          <router-link
            :to="{ name: 'missions-management' }"
            :title="$t('Missions Management')"
            @click.native="closeMenu()"
          >
            <img src="../../src/assets/images/ico/plane.svg" width="20px" />
            <p>{{ $t("Missions") }}</p>
          </router-link>
        </MenuItem>
        <MenuItem
          v-if="s2ec.user.is_superuser || s2ec.user.is_admin || s2ec.user.is_manager || s2ec.user.is_head_of_site"
          name="sites"
        >
          <router-link :to="{ name: 'sites' }" :title="$t('Sites')" @click.native="closeMenu()">
            <img src="../../src/assets/images/ico/sites.svg" width="20px" />
            <p>{{ $t("Sites") }}</p>
          </router-link>
        </MenuItem>
        <MenuItem name="accounts">
          <router-link :to="{ name: 'accounts' }" :title="$t('Accounts')" @click.native="closeMenu()">
            <img src="../../src/assets/images/ico/accounts.svg" width="20px" />
            <p>{{ $t("Accounts") }}</p>
          </router-link>
        </MenuItem>

        <MenuItem v-if="s2ec.user.is_superuser || s2ec.user.is_admin" name="incidents">
          <router-link :to="{ name: 'incidents' }" :title="$t('Incidents')" @click.native="closeMenu()">
            <img src="../../src/assets/images/ico/incidents.svg" width="20px" />
            <p>{{ $t("Incidents") }}</p>
          </router-link>
        </MenuItem>
        <MenuItem v-if="s2ec.user.is_superuser || s2ec.user.is_admin || s2ec.user.is_manager" name="alerts">
          <router-link :to="{ name: 'alerts' }" :title="$t('Alerts')" @click.native="closeMenu()">
            <img src="../../src/assets/images/ico/alerts_white.svg" width="20px" />
            <p>{{ $t("Alerts") }}</p>
          </router-link>
        </MenuItem>
        <MenuGroup :class="onCollapsed ? 's2ec-collapsed-menu' : ''" class="menu-group"> </MenuGroup>
      </div>
    </MenuGroup>
  </Menu>
</template>

<script>
export default {
  name: "AppSideMenu",
  props: ["menuCollapsed"],
  data() {
    return {
      showingMenu: false,
      activeMenu: "",
      menuWidth: "280",
    };
  },
  computed: {
    onCollapsed() {
      return this.menuCollapsed;
    },
  },
  watch: {
    "$route.name"(value) {
      this.activeMenu = value;
      if (value === "map") {
        this.activeMenu = "general-overview";
      } else if (value === "contracts" || value === "users" || value === "profile") {
        this.activeMenu = "accounts";
      } else {
        this.activeMenu = this.$route.name;
      }
    },
  },
  mounted() {
    const self = this;
    if (this.$route.name === "map") {
      this.activeMenu = "general-overview";
    } else if (this.$route.name === "contracts" || this.$route.name === "users" || this.$route.name === "profile") {
      this.activeMenu = "accounts";
    } else if (this.$route.name === "country-info-detail") {
      this.activeMenu = "country-information";
    } else {
      this.activeMenu = this.$route.name;
    }
    this.$eventBus.$on("pushActiveMenu", this.switchActiveMenu);
  },
  beforeDestroy() {
    this.$eventBus.$off("pushActiveMenu", this.switchActiveMenu);
  },
  methods: {
    switchActiveMenu(route) {
      this.activeMenu = route;
    },
    closeMenu() {
      this.$eventBus.$emit("closeMenu");
    },
  },
};
</script>

<style>
.ivu-menu-item:hover {
  background-color: var(--hover-side);
  border-radius: 5px;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after {
  display: none;
}

.s2ec-side-bar .app-side-menu {
  background: transparent;
}

.ivu-menu-item {
  left: 7px;
  width: 90%;
}

.app-side-menu {
  padding: 0;
}

.s2ec-side-bar .app-side-menu .ivu-menu-item > a {
  font-size: 12px;
  display: flex;
  justify-items: center;
  align-items: center;
  color: #ffffff;
  padding: 6% 12%;
  height: 100%;
  margin-top: 23px;
}
.s2ec-side-bar .app-side-menu .ivu-menu-item > a > p {
  margin-left: 17px;
  white-space: nowrap;
}

.s2ec-side-bar .ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
  background: var(--secondary-bleu);
  border-radius: 5px;
  width: 90%;
}

.s2ec-side-bar .ivu-menu-vertical .ivu-menu-item,
.ivu-menu-vertical .ivu-menu-submenu-title {
  padding: 0;
}

.s2ec-collapsed-menu p {
  display: none;
}
.ivu-menu-vertical.ivu-menu-light:after {
  background: #f9f7ff;
}
.logo {
  width: 90px;
  color: white;
}

.s2ec-mobile-bar .app-side-menu {
  background: transparent;
}
.s2ec-mobile-bar .app-side-menu .ivu-menu-item > a {
  font-size: 12px;
  display: flex;
  justify-items: center;
  align-items: center;
  color: #ffffff;
  height: 100%;
  margin-top: 5px;
}
.ivu-menu-horizontal.ivu-menu-light:after {
  background: #ffffff00 !important;
}
.s2ec-mobile-bar .app-side-menu .ivu-menu-item > a > p {
  margin-left: 26px;
  white-space: nowrap;
}

.s2ec-mobile-bar .ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
  background: var(--secondary-bleu);
  border-radius: 5px;
  width: 90%;
}

.ivu-menu-vertical.ivu-menu-light:after {
  width: 0px !important;
}
</style>
