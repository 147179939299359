<template>
  <div class="full-height">
    <Row
      type="flex"
      :gutter="20"
      class="full-height"
      style="overflow: auto"
    >
      <i-col
        :xs="24"
        :md="24"
        :xl="24"
      >
        <div class=" site-detail-item lavender-shadow">
          <strong>{{ $t('Sites information') }}</strong>
          <div class="site-info">
            <div>
              <span class="detail-icon">
                <img
                  src="../../assets/images/ico/building_blue.png"
                  width="18"
                  alt=""
                >
              </span>
              <span>{{ $t('Site name') }}&nbsp;:&nbsp;</span>
              <span> {{ site.name }} </span>
            </div>
            <div v-if="site.head_of_site">
              <span class="detail-icon">
                <img
                  src="../../assets/images/ico/person.png"
                  width="16"
                  alt=""
                >
              </span>
              <span>{{ $t('Head of site') }}&nbsp;:&nbsp;</span>
              <span>{{ site.head_of_site_value.first_name }} {{ site.head_of_site_value.last_name }} </span>
            </div>
            <div>
              <span class="detail-icon">
                <img
                  src="../../assets/images/ico/location.png"
                  width="12"
                  alt=""
                  style="cursor: pointer;"
                  @click="mapLocation(site)"
                >
              </span>
              <span> {{ site | full_address(addressSequence) }}</span>
            </div>
          </div>
        </div>
        <SiteLocations
          ref="siteLocations"
        />
      </i-col>
    </Row>
  </div>
</template>

<script>
    import SiteLocations from "./SiteLocations"

    export default {
        name: "SiteDetail",
        components: {
            SiteLocations,
        },
        props: [],
        data() {
            return {
                site:{
                    id:null,
                    head_of_site:null,
                    head_of_site_value:{
                        first_name:'',
                        last_name:'',
                        }
                    },
                addressSequence: ['address', 'city', 'country_name'],
            }
        },
        methods: {
            mapLocation(location) {
                let str = location.latitude + ',' + location.longitude + ',' + 12;
                this.$router.push({name: 'map', query: {location: str}});
            },
            getSelectedSiteDetails(site){
                this.site=site
                this.$refs.siteLocations.getSiteData(site)
            },
            resetSiteDetails(){
                this.$refs.siteLocations.resetSiteData()
            },
        }
    }
</script>

<style>
    .site-page .detail-icon{
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        margin: 0 10px;
    }
    .site-page .site-detail-item {
        position: relative;
        background: #fff;
        padding: 10px;
    }

    .site-page .site-detail-item strong {
        font-size: 14px;
    }

    .site-page .site-detail-item:not(:last-of-type) {
        margin-bottom: 15px;
    }

    .site-page .site-info {
        margin-top: 10px;
        font-size: 14px;
        min-height: 85px;
    }

    .site-page .site-info > div {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    .site-detail-action button, .site-detail-action .ivu-upload {
        float: right;
    }

    .site-page .site-detail-inner-content:not(.document-list) {
        margin-top: 40px;
    }

</style>
