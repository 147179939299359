export { QueryFeatures }

class QueryFeatures {

  constructor(object) {
  }

  query(object) {
    this.viewer = object.viewer
    this.layer = object.layerId
    this.map = this.viewer.map
    this.key = object.key
    this.operator = object.operator
    this.values = object.values

    var self = this

    function main(resolve, reject) {
      var matchingFeatures = []
      var geoJson = self.viewer.data[self.layer]

      geoJson.features.forEach(feature => {
        switch (self.operator) {
          case "==":
            if (self.values.includes(feature.properties[self.key].toString())) {
              matchingFeatures.push(feature)
            }
            break;
          case "!=":
            if (!self.values.includes(feature.properties[self.key].toString())) {
              matchingFeatures.push(feature)
            }
            break;
        }
      });
      resolve([matchingFeatures, self.layer])
    }

    return new Promise((resolve, reject) => main(resolve, reject))
  }

  _GET(path, param) {

    function main(resolve, reject) {
      var request = new XMLHttpRequest();

      request.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          resolve([request.responseText, param])
        }
      };

      request.open("GET", path, true);
      //request.withCredentials = true;
      request.send();
      request.addEventListener('progress', function (e) {
      });
    }
    return new Promise((resolve, reject) => main(resolve, reject))
  }
}