import {axiosInstance} from "./base"


export function getNotificationsQry(params) {
    return axiosInstance.get("api/notifications/", {params})
}

export function deleteNotificationsQry(id) {
    return axiosInstance.delete(`api/notifications/${id}`, {})
}

export function deleteNotifications(ids) {
    return axiosInstance.post(`api/notifications/delete/`, {notifications: ids}, {})
}

export function markNotificationsRead(ids) {
    return axiosInstance.post(`api/notifications/mark-as-read/`, {notifications: ids}, {})
}

export function patchNotificationsQry(id, data) {
    return axiosInstance.patch(`api/notifications/${id}/`, data, {})
}
