<!-- FIXME NAMING - THIS IS ACTUALLY NOW THE LIST OF PAST INCIDENTS ONLY!! --->
<template>
  <div class="tabs-content">
    <div>
      <Row
        :gutter="20"
        style="margin-left: 10px"
      >
        <i-col span="4">
          <Select
            v-model="incidentFilterData.incident_types"
            :placeholder="$t('Incident type')"
            clearable
            filterable
            @on-change="updatePastIncidents"
          >
            <Option
              v-for="item in incidentTypes"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </Select>
        </i-col>
        <i-col span="4">
          <AppDatePicker
            v-model="incidentFilterData.incident_date"
            :placeholder="$t('Incident date')"
            type="date"
            @input="updatePastIncidents"
          />
        </i-col>
      </Row>
    </div>
    <div class="past-event-items">
      <div
        v-for="(incident, idx) in incidents.results"
        :key="`incident_${incident.id}`"
        class="past-event-item"
      >
        <div class="past-event-item-inner">
          <div
            v-if="s2ec.user.is_admin || s2ec.user.is_superuser"
            class="past-event-item-action"
          >
            <Icon
              type="md-time"
              size="20"
              class="pointer-cursor"
              @click.native="triggerRenewIncidentForm(incident, idx)"
            />
            <Icon
              type="md-trash"
              size="20"
              class="pointer-cursor"
              @click.native="triggerDelete(incident.id, idx)"
            />
          </div>

          <IncidentTypesTags
            :incidentTypesValues="incident.incident_types_values"
          />

          <p><strong>{{ incident.short_description }}</strong></p>
          <p>
            <span> {{ fullDateFormat(incident.incident_date) }} </span>
          </p>

          <p> {{ incident.incident_locations | multi_location | truncate(80) }} </p>

          <p
            v-if="showMore===incident.id"
            class="description"
          >
            {{ incident.long_description || $t('No description') }}
          </p>

          <p
            v-if="showMore===incident.id && incident.recommendations"
            class="recommendations"
          >
            <br>
            {{ incident.recommendations }}
          </p>

          <Icon
            v-if="showMore===incident.id"
            type="ios-arrow-up"
            size="20"
            class="pointer-cursor"
            @click.native="showLongDesc(incident.id)"
          />
          <Icon
            v-else
            type="ios-arrow-down"
            size="20"
            class="pointer-cursor"
            @click.native="showLongDesc(incident.id)"
          />
        </div>
      </div>
      <infinite-loading
        :identifier="incidentIdentifier"
        @infinite="getIncidentQuery"
      >
        <span slot="no-more" />
      </infinite-loading>
    </div>
    <RenewIncident
      ref="renewIncident"
      v-model="showRenewIncident"
      @incidentRenewed="incidentRenewed"
    />
  </div>
</template>

<script>
    import {deleteIncidentQry, getIncidentQry, getIncidentTypesQry} from "@/services/incidents.service";
    import IncidentTypesTags from "@/views/incidents/IncidentTypesTags"
    import infiniteLoading from "vue-infinite-loading"
    import {getStringUrlParams} from "@/helpers/common.helper";
    import {icons} from "@/helpers/icon.helper";
    import AppDatePicker from "@/components/common/AppDatePicker"
    import RenewIncident from "@/views/incidents/modals/RenewIncident"
    import DateFormat from "@/mixins/dateFormat"

    export default {
        name: "PastEvents",
        components: {
            infiniteLoading, AppDatePicker, RenewIncident, IncidentTypesTags
        },  // FIXME rename this
        mixins: [DateFormat],
        props: ['country'],
        data() {
            return {
                showMore: null,
                showRenewIncident: false,
                incidentTypes: [],
                incidentFilterData: {
                    incident_date: null,
                    incident_types: null  // Usually used for logical AND between types, but we only send a single value
                },
                incidents: {
                    count: 0,
                    next: null,
                    previous: null,
                    results: []
                },
                incidentIdentifier: false,
            }
        },
        created() {
            getIncidentTypesQry()
                .then(res => {
                   this.incidentTypes = res.data;
                })
                .catch(err => {
                   this.$Message.error(this.$t('Cannot retrieve incident types'))
                });
        },
        methods: {

            updatePastIncidents() {
                this.resetDataName(this, 'incidents');
                this.incidentIdentifier += 1;  // Reset infinite scroll of Incidents
            },

            incidentRenewed(data, idx) {
                this.incidents.results.splice(idx, 1, data)
            },

            getIncidentQuery($state) {
                let getQueryParams = {page_size: 500, country: this.$route.params.country, ...this.incidentFilterData};
                if (this.incidents.next) Object.assign(getQueryParams, getStringUrlParams(this.incidents.next));

                getQueryParams["country"] = getQueryParams["country"].toUpperCase();  // Workaround for bug of getStringUrlParams()

                if (!getQueryParams.incident_types) delete getQueryParams.incident_types;  // API doesn't tolerate empty value for this one...

                getIncidentQry(getQueryParams)
                    .then(res => {
                        const {results, ...data} = res.data;
                        Object.assign(this.incidents, data);
                        this.incidents.results.push(...results);
                        $state.loaded();
                        if (!this.incidents.next) {
                            $state.complete();
                        }
                    })
                    .catch(() => {
                        this.$Message.error({content: this.$t('Error retrieving incidents'), duration: 5})
                    });

            },
            getIcon(icon) {
                let n = icons[icon];
                return require(`@/assets/images/icon/${n}`);
            },
            showLongDesc(incidentId) {
                if (this.showMore === incidentId) {
                  this.showMore = null;
                } else {
                  this.showMore = incidentId;  // FIXME rename showMore to better name
                }
              this.$emit("selected-incident", this.showMore);
            },
            countryChange() {
                this.updatePastIncidents();
            },

            triggerRenewIncidentForm(data, idx) {
                this.$refs['renewIncident'].setUpdateData(data, idx);
                this.showRenewIncident = true;
            },

            triggerDelete(id, idx) {
                this.$AppModal.AppConfirm({
                    method: 'delete',
                    loading: true,
                    extraTitle: this.$t('Delete an incident'),
                    message: this.$t('You are about to delete this incident.'),
                    onConfirm: (() => {
                        deleteIncidentQry(id)
                            .then(() => {
                                this.incidents.results.splice(idx, 1);
                                this.$Message.success(this.$t('Successfully removed an incident.'));
                                this.$AppModal.remove()
                            })
                            .catch(() => {
                                this.$Message.error({content: this.$t('Error removing an incident'), duration: 5});
                                this.$AppModal.remove()
                            })
                    })
                });
            }
        }
    }
</script>

<style>

    .tabs-content .past-event-items {
        margin-top: 20px;
        background: #fff;
        min-height: 300px;
        max-height: 800px;
        overflow-y: auto;
        padding: 20px;
    }

    .tabs-content .past-event-filter-item {
        background: rgb(249, 247, 254);
        padding: 10px;
        border: 1px solid rgb(247, 247, 247);
        border-radius: 5px;
        height: 45px;
        padding-left: 25px;
    }

    .past-event-item-inner {
        position: relative;
    }

    .past-event-items .past-event-item:not(:last-of-type) {
        background: #ffffff;
        margin-bottom: 20px;
        padding: 20px;
        -webkit-box-shadow: 0px 2px 5px -1px rgba(113,87,242,0.3);
        -moz-box-shadow: 0px 2px 5px -1px rgba(113,87,242,0.3);
        box-shadow: 0px 2px 5px -1px rgba(113,87,242,0.3);
    }

    .past-event-items .description {
        margin-top: 20px;
        text-align: justify;
    }

    .past-event-items .description > textarea {
        text-align: justify;
        border: 0px;
        padding: 0;
        margin: 0;
        font-size: 12px;
    }

    .__past-event-items .ivu-icon {
        display: block;
        margin: 10px auto 0;
        width: 20px;
    }

    .past-event-items .description > textarea:focus {
        outline: none;
        border: 0 none #fff;
    }

    .past-event-filter-title > * {
        margin-right: 20px;
    }

    .tabs-content .past-event-items .entry-icon {
        height: 3%;
        width: 3%;
        vertical-align: middle;
        margin-bottom: 5px;
        margin-right: 5px
    }

    .past-event-items .event-type-text {
        font-size: 14px;
        font-weight: bold;
    }

    .past-event-item-action {
        position: absolute;
        right: 10px;
        top: 10px;
    }

</style>
