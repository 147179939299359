export { Viewer };

import i18n from "@/i18n/i18n";

import { BasemapSwitcher } from "./BasemapSwitcher.js";
import { Legend } from "./Legend.js";
import { FeaturesPopup } from "./FeaturesPopup.js";
import { DonutClustering } from "./DonutClustering.js";
import { InfraClustering } from "./InfraClustering.js";
import { InfraClusterPopup } from "./InfraClusterPopup.js";
import { PulsingSymbol } from "./PulsingSymbol.js";
import { ClusterPopup } from "./ClusterPopup.js";
import { QueryFeatures } from "./QueryFeatures.js";
import { TilingManager } from "./TilingManager.js";

class Viewer {
  constructor(elementId) {
    this.apiLocation = document.location.origin + "/ressources";
    this.maplibregl = window.maplibregl; //FIXME: remove this
    this.events = document.createElement("div"); // FIXME: rename this or remove
    this.map = null; // FIXME: Initialize map here or create a Map.js
    this.mapStyle = null; 
    this.ViewerElement = document.getElementById(elementId);
    this.devicePanel = document.getElementsByClassName("general-overview-devices")[0];
    this.data = {};
    this.layersLoaded = 0;
    this.totalLayers = 7;
    this.pulsingDeviceId = [];
    this.mapHeight = null;
    this.mapWidth = null;

    this.QueryFeatures = new QueryFeatures();

    this.ViewerElement.style.filter = "blur(5px)";
    if (this.devicePanel !== undefined) {
      this.devicePanel.style.filter = "blur(5px)";
    }

    this.getMapStyle().then((mapStyle) => {
      this.mapStyle = mapStyle;
      this.build();
    });

    /* 
      EVENTS

        * 'map-load'
        * 'clustering-load'
        * 'locator-load'
        * 'legend-load'
        * 'viewer-load'
        * 'basemapswitcher-load'
        * 'pulsingsymbol-load'
        * 'featurespopup-load'
        * 'layer-load'
        * 'all-layers-load'
        * 'layer-control-load'
        * 
        * 'basemap-change'
        * 
        * 'popup-open'
        * 'popup-close
        * 
        * 'position-set'
        * 'position-fly'
        * 
        * 'layer-toggle'
        * 'layers-control-expand'
        * 'layers-control-collapse'
        * 
        * 'legend-expand'
        * 'legend-collapse'
        * 'legend-feature-hover'
        * 'legend-feature-leave'
        * 
        * 'hover-cluster-center'
        * 'leave-cluster-center'
        * 'click-cluster-center'

      Listen event with :

      this.events.addEventListener("event-name", function (e) {
          //do something with e.detail.param
      })

      create event with :
      this._eventConstructor("event-name", param)
    */
  }

  build() {
    this.initMapLoaderElement()

    const startupCoordinates = this.initMapStartupCoordinates()
    const coordinates = startupCoordinates.coordinates
    const zoom = startupCoordinates.zoom

    this.initMap(coordinates, zoom)
    this.initBasemapSwitcher()

    this.map.on("load", () => {

      this.initMapSize()
      this.initTilingManager()
      this._eventConstructor("map-load", null);

      this.initMapCopyrightBlock()

      this.events.addEventListener("all-layers-load", (e) => {
        this.initAllLayers()
      });

      
      if (this.layersLoaded == this.totalLayers) {
        // Auto-call if we arrive too late
        this._eventConstructor("all-layers-load", this.layersLoaded);
      }
    });
  }

  initMapLoaderElement() {
    var loaderElement = document.createElement("div");
    var loaderContainer = document.createElement("div");
    var loaderImg = document.createElement("img");
    var spinnerImg = document.createElement("img");
    var attribution = document.createElement("span");
    loaderElement.id = "loader";
    attribution.innerHTML =
      '<a href="https://geotics.fr" style="position: relative;top: 25px;" target="_blank"><span style="padding-right: 10px;">Powered by</span><img src="' +
      this.apiLocation +
      '/images/geotics-min-light.svg" style="vertical-align: middle;height: 30px;"><span style="font-weight: bold;color: white; padding-left: 10px;font-size: medium;">Geotics</span></a>';
    loaderImg.setAttribute("src", this.apiLocation + "/images/ilios-logo.svg");
    spinnerImg.setAttribute("src", this.apiLocation + "/images/loader.svg");
    spinnerImg.classList.add("spinner");
    loaderContainer.appendChild(loaderImg);
    loaderContainer.appendChild(spinnerImg);
    //loaderContainer.appendChild(attribution)
    loaderElement.appendChild(loaderContainer);
    this.ViewerElement.parentElement.appendChild(loaderElement);
  }

  initMapStartupCoordinates() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var location = url.searchParams.get("location");

    if (location) {
      var zoom = location.split(",")[2];
      var coordinates = [location.split(",")[1], location.split(",")[0]];
    } else {
      var zoom = 2;
      var coordinates = [2, 46];
    }
    return {coordinates, zoom}
  }

  initMap(coordinates, zoom) {
    var mapElement = document.createElement("div");
    mapElement.id = "map";
    this.ViewerElement.appendChild(mapElement);

    this.map = new this.maplibregl.Map({
      container: "map", // container id
      //style : "https://demotiles.maplibre.org/style.json",
      style: this.mapStyle, // style URL
      center: coordinates, // starting position [lng, lat]
      zoom: zoom, // starting zoom
      minZoom: 2, // note the camel-case
      maxZoom: 22,
      antialias: true,
      doubleClickZoom: false,
    });
    const mapContainer = document.getElementById("map-viewer-container")
    this.map.addControl(new this.maplibregl.FullscreenControl({container: mapContainer}), "top-left")
    
    this.map.on("resize", () => {
      if (document.fullscreenElement) {
        document.getElementsByClassName('mapboxgl-ctrl-top-left')[0].style.left = '20px'
        document.getElementById("basemap").style.left = '70px';
        document.getElementById('control-buttons-container').style.left = '20px'
        document.getElementById('risk-plugins').style.left = '20px'
      } else {
        document.getElementsByClassName('mapboxgl-ctrl-top-left')[0].style.left = '219px'
        document.getElementById("basemap").style.left = '270px';
        document.getElementById('control-buttons-container').style.left = '230px'
        document.getElementById('risk-plugins').style.left = '230px'
      }
    });
  }

  initBasemapSwitcher() {
    this.basemapSwitcher = new BasemapSwitcher({
      viewer: this,
      basemap: ["default", "satellite"],
    });
  }

  initMapSize() {
    const mapboxgl = document.getElementsByClassName("mapboxgl-canvas")[0]
    if (mapboxgl) {
      this.mapHeight = parseInt(mapboxgl.getAttribute("height"));
      this.mapWidth = parseInt(mapboxgl.getAttribute("width"));
    }
  }


  initTilingManager() {
    this.tilingManager = new TilingManager(this.map, 300);
  }


  initMapCopyrightBlock() {
    var attributions = document.getElementsByClassName("mapboxgl-ctrl-attrib")[0];
    var geoticsAttribution = document.createElement("a");
    // geoticsAttribution.innerHTML = "<img src='" + this.apiLocation + "/images/geotics-min.svg'><span>Geotics</span>";
    // geoticsAttribution.setAttribute("href", "https://geotics.fr");
    // geoticsAttribution.setAttribute("target", "_blank");
    if (attributions) {
      attributions.appendChild(geoticsAttribution);
    }
  }

  initAllLayers() {
    //console.log("> event all-layers-load triggered")
    this.initLegend()
    this.initFeaturesPopup()
    this.initClustering()
    this.initInfraClustering()

    var deviceLocation = document.getElementsByClassName(
      "event-header-action"
    )[0];

    this.events.addEventListener("layers-control-expand", () => {
      deviceLocation.click();
    });

    this.events.addEventListener("legend-expand", () => {
      deviceLocation.click();
    });

    this.events.addEventListener("clustering-load", (params) => {
      this.pulsingSymbol = new PulsingSymbol({
        viewer: this,
        layers: [
          {
            layerId: "main-cluster",
            moveBefore: "building-3d",
            maxzoom: 10,
            id: "cluster-pulsing",
          },
          {
            layerId: "main-infracluster",
            moveBefore: "building-3d",
            minzoom: 10,
            id: "device-pulsing",
          },
        ],
      });

      this.pulsingSymbol.addFilterValue(
        "cluster-pulsing",
        "title",
        "devices"
      );

      this.addPulseDevice(999999999999999999999999999999999999); //init pulsingLayer

      this.data.devices.features.forEach((element) => {
        if (element.properties["has_emergency"] == true) {
          this.addPulseDevice(element.properties.id);
        }
      });
      //properties["user_value.has_alert"]


      this.events.addEventListener("pulsingsymbol-load", (e) => {
        this._eventConstructor("viewer-load", null);
      });

      this.events.addEventListener("click-cluster-center", (e) => {
        try {
          this.clusterPopup.popup.remove();
        } catch (error) {}

        this.clusterPopup = new ClusterPopup({
          viewer: this,
          details: e.detail.details,
          type: "center",
          lngLat: e.detail.marker._lngLat,
        });
      });

      this.events.addEventListener("click-cluster-segment", (e) => {
        try {
          this.clusterPopup.popup.remove();
        } catch (error) {}

        this.clusterPopup = new ClusterPopup({
          viewer: this,
          details: e.detail.details,
          target: e.detail.target,
          type: "segment",
          lngLat: e.detail.marker._lngLat,
        });
      });
    });

    this.events.addEventListener("infraclustering-load", (params) => {
      this.events.addEventListener("hover-infracluster", (e) => {
        try {
          this.infraClusterPopup.popup.remove();
        } catch (error) {}

        this.infraClusterPopup = new InfraClusterPopup({
          viewer: this,
          details: e.detail.details,
          marker: e.detail.marker,
        });
      });

      this.map.on("moveend", (params) => {
        var currentZoom = this.map.getZoom();

        if (currentZoom < 10) {
          try {
            this.infraClusterPopup.popup.remove();
          } catch (error) {}
        }
      });
    });
  }

  initLegend() {
    this.legend = new Legend({
      viewer: this,
      fields: [
        {
          value: "global_risk",
          label: i18n.t("Global risk"),
        },
        {
          value: "foreign_hostility",
          label: i18n.t("Foreign hostility"),
        },
        {
          value: "global_health_rates",
          label: i18n.t("Global health rates"),
        },
        {
          value: "organized_crime_delinquency",
          label: i18n.t("Organized crime and Delinquency"),
        },
        {
          value: "political_social_unrest",
          label: i18n.t("Political and social unrest"),
        },
        {
          value: "terrorism",
          label: i18n.t("Terrorism"),
        },
        {
          value: "travels",
          label: i18n.t("Travels"),
        },
      ],
      stops: [
        {
          value: 1,
          color: "#25C285",
          label: "Risque infime",
        },
        {
          value: 2,
          color: "#179C69",
          label: "Risque faible",
        },
        {
          value: 3,
          color: "#FFB300",
          label: "Risque possible",
        },
        {
          value: 4,
          color: "#FF6123",
          label: "Risque élevé",
        },
        {
          value: 5,
          color: "#E53935",
          label: "Risque extrême",
        },
      ],
    });
  }

  initFeaturesPopup() {
    this.featuresPopup = new FeaturesPopup({
      viewer: this,
      layers: [
        {
          layerId: "devices",
          title: "devices",
        },
        {
          layerId: "sites",
          title: "sites",
        },
        {
          layerId: "incidents",
          title: "incidents",
        },
        {
          layerId: "poi",
          title: "poi",
        },
      ],
    });
  }

  initClustering() {
    this.clustering = new DonutClustering({
      viewer: this,
      categorizeClustering: true,
      commonLayersId: "id",
      minzoom: 0,
      maxzoom: 10,
      stops: {
        values: [10, 5, 2],
        clusterSize: [20, 20, 20],
        fontSize: [15, 15, 15],
      },

      layers: [
        {
          data: this.data["sites"],
          sprite: "sites_1",
          title: "sites",
          enabled: true,
          color: "#3498DB",
          //centroidmatchingfield : "site_locations[0].country"
        },
        {
          data: this.data["devices"],
          sprite: "{map-icon}",
          title: "devices",
          enabled: true,
          color: "#2ECC71",
          //centroidmatchingfield : "latest_device_track.country"
        },
        {
          data: this.data["incidents"],
          sprite: "{map-icon}",
          title: "incidents",
          enabled: true,
          color: "#E67E22",
          //centroidmatchingfield : "incident_locations[0].country"
        },
        {
          data: this.data["poi"],
          sprite: "{map-icon}",
          title: "poi",
          enabled: false,
          color: "#E5E7E9",
          //centroidmatchingfield : "country"
        },
      ],
    },
      "main-cluster",
      ["interpolate", ["linear"], ["zoom"], 1, 0.6, 11, 1],
      "clustering-load",
    );
  }

  initInfraClustering() {
    this.infraClustering = new InfraClustering({
      viewer: this,
      categorizeClustering: true,
      commonLayersId: "id",
      minzoom: 10,
      maxzoom: 23,
      stops: {
        values: [10, 5, 2],
        clusterSize: [20, 20, 20],
        fontSize: [15, 15, 15],
      },
      layers: [
        {
          data: this.data["sites"],
          sprite: "sites_1",
          title: "sites",
          enabled: true,
          color: "#3498DB",
          //centroidmatchingfield : "site_locations[0].country"
        },
        {
          data: this.data["devices"],
          sprite: "{map-icon}",
          title: "devices",
          enabled: true,
          color: "#2ECC71",
          //centroidmatchingfield : "latest_device_track.country"
        },
        {
          data: this.data["incidents"],
          sprite: "{map-icon}",
          title: "incidents",
          enabled: true,
          color: "#E67E22",
          //centroidmatchingfield : "incident_locations[0].country"
        },
        {
          data: this.data["poi"],
          sprite: "{map-icon}",
          title: "poi",
          enabled: false,
          color: "#E5E7E9",
          //centroidmatchingfield : "country"
        },
      ],
    },
      "main-infracluster",
      0.85,
      "infraclustering-load",
    );
  }

  addPulseDevice(deviceId) {
    this.pulsingDeviceId.push(deviceId);

    this.pulsingSymbol.addFilterValue(
      "cluster-pulsing",
      "id",
      deviceId
    );
    this.pulsingSymbol.addFilterValue("device-pulsing", "id", deviceId);
    this.clustering.setPulseValue("devices", this.pulsingDeviceId);
    this.infraClustering.setPulseValue("devices", this.pulsingDeviceId);
  }

  addRisk(geojson) {
    this.addGeojson("risk", geojson, {
      id: "risk",
      type: "fill",
      source: "risk",
      maxzoom: 6,
      layout: {
        visibility: "visible",
      },
      paint: {
        "fill-color": {
          // "property": "data2_C1-Security Apparatus",
          property: "global_risk",
          stops: [
            [1, "#25C285"],
            [2, "#179C69"],
            [3, "#FFB300"],
            [4, "#FF6123"],
            [5, "#E53935"],
          ],
        },
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          1,
          0.5,
        ],
      },
    });

    this.map.moveLayer("risk", "ferry");

    this.layersLoaded++;
    this._eventConstructor("layer-load", this.layersLoaded); // FIXME monster duplication

    if (this.layersLoaded == this.totalLayers) {
      this._eventConstructor("all-layers-load", this.layersLoaded);
    }

    var isLoad = false;

    this.map.on("render", () => {
      if (!this.map.isSourceLoaded("risk")) return;

      if (isLoad == false) {
        isLoad = true;
        this.fadeLoader();
      }
    });
  }

  addIncidents(geojson) {
    this.addGeojson("incidents", geojson, {
      id: "incidents",
      type: "symbol",
      source: "incidents",
      minzoom: 7,
      layout: {
        "icon-image": "{map-icon}",
        "icon-size": 0.85,
        "icon-allow-overlap": true,
        "icon-ignore-placement": false,
      },
    });

    this.layersLoaded++;
    this._eventConstructor("layer-load", this.layersLoaded);

    if (this.layersLoaded == this.totalLayers) {
      this._eventConstructor("all-layers-load", this.layersLoaded);
    }
  }

  addIncidentsGeofencing(geojson) {
    this.addGeojson("incidents-geofencing", geojson, {
      id: "incidents-geofencing",
      type: "fill",
      source: "incidents-geofencing",
      minzoom: 10,
      paint: {
        "fill-color": "rgba(255,0,0,0.5)",
      },
      layout: {
        visibility: "visible",
      },
    });

    this.map.moveLayer("incidents-geofencing", "landuse_garages");
    this.layersLoaded++;
    this._eventConstructor("layer-load", this.layersLoaded);

    if (this.layersLoaded == this.totalLayers) {
      this._eventConstructor("all-layers-load", this.layersLoaded);
    }
  }

  addZonesOfInterest(geojson) {
    this.addGeojson("geofencing", geojson, {
      id: "geofencing",
      type: "fill",
      source: "geofencing",
      minzoom: 10,
      paint: {
        "fill-color": "rgba(255,0,0,0.5)",
        "fill-opacity": 0.8,
      },
      layout: {
        visibility: "visible",
      },
    });

    this.map.moveLayer("geofencing", "landuse_garages");

    this.layersLoaded++;
    this._eventConstructor("layer-load", this.layersLoaded);

    if (this.layersLoaded == this.totalLayers) {
      this._eventConstructor("all-layers-load", this.layersLoaded);
    }
  }

  addSites(geojson) {
    this.addGeojson("sites", geojson, {
      id: "sites",
      type: "symbol",
      source: "sites",
      minzoom: 7,
      layout: {
        "icon-image": "sites_1",
        "icon-size": 0.85,
        "icon-allow-overlap": true,
        "icon-ignore-placement": false,
      },
    });

    this.layersLoaded++;
    this._eventConstructor("layer-load", this.layersLoaded);
    //console.log("> Layers loaded", this.layersLoaded);

    if (this.layersLoaded == this.totalLayers) {
      //console.log("> ALL Layers loaded NOW");
      this._eventConstructor("all-layers-load", this.layersLoaded);
    }
  }

  addDevices(geojson) {
    console.log(">>>>> ADDING GEOJSON LAYER FOR DEVICES");
    this.addGeojson("devices", geojson, {
      id: "devices",
      type: "symbol",
      source: "devices",
      minzoom: 7,
      layout: {
        "icon-image": "{map-icon}",
        "icon-size": 0.85,
        "icon-allow-overlap": true,
        "icon-ignore-placement": false,
      },
    });

    this.layersLoaded++;
    this._eventConstructor("layer-load", this.layersLoaded);

    if (this.layersLoaded == this.totalLayers) {
      this._eventConstructor("all-layers-load", this.layersLoaded);
    }
  }

  addPoi(geojson) {
    this.addGeojson("poi", geojson, {
      id: "poi",
      type: "symbol",
      source: "poi",
      minzoom: 7,
      layout: {
        "icon-image": "{map-icon}",
        "icon-size": 0.85,
        "icon-allow-overlap": true,
        "icon-ignore-placement": false,
        visibility: "none",
      },
    });

    this.layersLoaded++;
    this._eventConstructor("layer-load", this.layersLoaded);

    if (this.layersLoaded == this.totalLayers) {
      this._eventConstructor("all-layers-load", this.layersLoaded);
    }
  }

  addGeojson(sourceId, geojson, style) {
    this.data[sourceId] = geojson;
    this.map.addSource(sourceId, {
      type: "geojson",
      // Use a URL for the value for the `data` property.
      data: geojson,
    });

    this.map.addLayer(style);
  }

  filterDeviceLayer(field, values) {
    var filter = ["in", field];

    values.forEach((value) => {
      filter.push(value);
    });

    this.map.setFilter("devices", filter);
  }

  fadeLoader(params) {
    this.ViewerElement.style.filter = "none";
    if (this.devicePanel !== undefined) {
      this.devicePanel.style.filter = "none";
    }

    document.getElementById("loader").classList.add("fully-loaded");
    setTimeout(() => {
      document.getElementById("loader").remove();
    }, 1200);
  }

  getMapStyle() {
    return new Promise((resolve, reject) => {
      this
        ._GET(this.apiLocation + "/data/style.json")
        .then((response) => {
          var mapStyle = JSON.parse(response);
          (mapStyle.sprite = this.apiLocation + "/data/sprites/sprite"),
            resolve(mapStyle);
        });
    });
  }

  _GET(path, param) {
    //FIXME: DRY this method with all other classes
    function main(resolve, reject) {
      var request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          resolve(request.responseText);
        }
      };
      request.open("GET", path, true);
      //request.withCredentials = true;
      request.send();
      request.addEventListener("progress", function (e) {});
    }
    return new Promise((resolve, reject) => main(resolve, reject));
  }

  _eventConstructor(eventName, option) {
    var event = new CustomEvent(eventName, { detail: option });
    this.events.dispatchEvent(event);
  }
}
