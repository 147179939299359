<template>
  <div>
    <div
      v-if="success"
      class="auth-success-message text-center"
    >
      <p> {{ $t('We have received your registration request') }}</p>
      <p>{{ $t('Our operators are processing it') }}</p>
      <p>{{ $t('You will receive an email once the process is done') }}</p>
      <p>{{ $t('See you soon') }}!</p>
      <Button
        type="default"
        :to="{name: 'login'}"
      >
        {{ $t('Back') }}
      </Button>
    </div>
    <PasswordForm ref="passwordFormRef"
      v-else
      :password-submit="triggerSetPassword"
      :message="message"
      :include_tac="false"
      :includeFullname="true"
    />
  </div>
</template>

<script>
  import {postConfirmRegistrationQry, getConfirmRegistrationQry} from "../../services/auth.service";
  import PasswordForm from "./PasswordForm"

  export default {
    name: "RegistrationConfirmation",
    components: {
      PasswordForm
    },
    data() {
      return {
        success: false,
        message: this.$t('Set your password to activate your account')
      }
    },

    mounted() {
      getConfirmRegistrationQry(this.$route.params.key)
        .then(() => {
        })
        .catch(() => {
          this.$Message.error({content: this.$t('Confirmation link is invalid or expired'), duration: 5});
          this.$router.push({name: 'login'});
        })
    },
    methods: {
      triggerSetPassword(data, load) {
        load(true);
        this.loading = true;
        postConfirmRegistrationQry(this.$route.params.key, data)
          .then(() => {
            load(false);
            this.success = true;
            this.$Message.success(this.$t('You successfully set password'));
          })
          .catch((err) => {
            let treated = this.$refs.passwordFormRef.handleSubmissionErrors(err)
            if (!treated)
              this.$Message.error({content: this.$t('Error occurred, your key is probably invalid or expired'), duration: 5});
            load(false);
          })


      },
    }
  }
</script>

<style scoped>

</style>
