export { Legend };

class Legend {
  constructor(object) {
    this.viewer = object.viewer;
    this.map = this.viewer.map;
    this.globalField = "global_risk";
    this.detailsFields = object.fields;
    this.stops = object.stops;

    this.build();
  }

  build() {
    var self = this;

    var legendElement = document.createElement("div");
    legendElement.id = "legend";
    legendElement.innerHTML =
      '<div class="legend-box" id="risk-legend" >' +
      '<h2><img class="icon" src="' +
      this.viewer.apiLocation +
      '/images/risk.svg"><i id="toggle-legend" class="toggle-expand"></i></h2>' +
      '<div id="layers-legend"><div id="flag-container"><div id="risk-flag" src=""><img id="risk-flag-icon" src=""><span id="risk-flag-label"></span></div></div></div>' +
      "</div>";
    this.viewer.ViewerElement.querySelectorAll("#risk-plugins")[0].appendChild(
      legendElement
    );
  
    document.getElementById("risk-plugins").style.display = "none";

    this.buildGlobalDom();
    this.buildDetailsDom();
    this.setLayerInteractivity();

    //document.getElementById('toggle-legend').onclick = function (e) {
    self.toggleExpand();
    //}

    var flagContainer = document.getElementById("risk-flag-icon");
    var flagLabel = document.getElementById("risk-flag-label");

    this.viewer.events.addEventListener("legend-feature-hover", function (e) {
      document.getElementById("risk-plugins").style.display = "block";

      flagContainer.src =
        self.viewer.apiLocation +
        "/images/flags/4x3/" +
        e.detail.properties.iso_3166_1_.toLowerCase() +
        ".svg";
      flagLabel.innerHTML = e.detail.properties.country_name;

      document.getElementById("legend-feature-details").style.maxHeight =
        "1000px";

      //self.map.getCanvas().style.cursor = 'help';
      var feature = e.detail;
      var elements = document
        .getElementById("layers-legend")
        .querySelectorAll(".raw-info");

      elements.forEach((element) => {
        element.style.display = "none";
      });

      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        if (index > 0) {
          var minVal = parseFloat(elements[index - 1].getAttribute("max-val"));
        } else {
          var minVal = 0;
        }
        var maxVal = parseFloat(elements[index].getAttribute("max-val"));
        var val = feature.properties[self.globalField];

        if (minVal < val && val <= maxVal) {
          element.style.display = "flex";
        }
      }

      self.detailsFields.forEach((element) => {
        var label = document.querySelectorAll(
          "[feature-details-label-target='" + element.value + "']"
        )[0];
        var count = document.querySelectorAll(
          "[feature-details-count-target='" + element.value + "']"
        )[0];
        var bar = document.querySelectorAll(
          "[feature-details-bar-target='" + element.value + "']"
        )[0];

        label.innerHTML = element.label;
        count.innerHTML = feature.properties[element.value];
        count.style.background = self.getFeatureCountColor(
          feature.properties[element.value]
        );
        bar.style.background = self.getFeatureCountColor(
          feature.properties[element.value]
        );
        bar.style.width =
          "calc(" + feature.properties[element.value] + " * 20%)";
      });
    });

    this.viewer.events.addEventListener("legend-feature-leave", function (e) {
      //self.map.getCanvas().style.cursor = '';
      document.getElementById("risk-plugins").style.display = "none";
    });

    this.viewer._eventConstructor("legend-load", null);
  }

  getFeatureCountColor(value) {
    var color;

    this.stops.forEach((element) => {
      if (value == element.value) {
        color = element.color;
      }
    });

    return color;
  }

  toggleExpand() {
    if (!this.isExpand()) {
      this.viewer._eventConstructor("legend-expand", null);
    } else {
      this.viewer._eventConstructor("legend-collapse", null);
    }

    document.getElementById("risk-legend").classList.toggle("expand");
  }

  expand() {
    this.viewer._eventConstructor("legend-expand", null);
    document.getElementById("risk-legend").classList.add("expand");
  }
  collapse() {
    this.viewer._eventConstructor("legend-collapse", null);
    document.getElementById("risk-legend").classList.remove("expand");
  }
  isExpand() {
    return document.getElementById("risk-legend").classList.contains("expand");
  }

  buildGlobalDom() {
    var self = this;

    var target = document.getElementById("layers-legend");

    target.innerHTML = target.innerHTML + "<div id='legend-feature-details'></div>";
  }

  buildDetailsDom() {
    this.detailsFields.forEach((element) => {
      document.getElementById("legend-feature-details").innerHTML +=
        "<div><span feature-details-label-target='" +
        element.value +
        "'>" +
        element.label +
        ":</span><span feature-details-count-target='" +
        element.value +
        "'> 0</span><div style='width:calc(100% - 30px);height:3px;background: rgba(0,0,0,0.1);'><div feature-details-bar-target='" +
        element.value +
        "'></div></div></div>";
    });
  }

  setLayerInteractivity() {
    var self = this;

    var hoveredStateId = null;

    this.map.on("mousemove", "risk", function (e) {
      if (self.isExpand()) {
        if (e.features.length > 0) {
          if (hoveredStateId !== null) {
            self.map.setFeatureState(
              {
                source: "risk",
                id: hoveredStateId,
              },
              { hover: false }
            );
          }
          if (e.features[0].id != hoveredStateId) {
            self.viewer._eventConstructor(
              "legend-feature-hover",
              e.features[0]
            );
          }
          hoveredStateId = e.features[0].id;
          self.map.setFeatureState(
            {
              source: "risk",
              id: hoveredStateId,
            },
            { hover: true }
          );
        }
      }
    });

    // When the mouse leaves the state-fill layer, update the feature state of the
    // previously hovered feature.
    this.map.on("mouseleave", "risk", function () {
      if (self.isExpand()) {
        if (hoveredStateId !== null) {
          self.map.setFeatureState(
            {
              source: "risk",
              id: hoveredStateId,
            },
            { hover: false }
          );
          self.viewer._eventConstructor("legend-feature-leave", null);
        }

        hoveredStateId = null;
      }
    });
  }
}
