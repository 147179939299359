import Modal from './confirm';
import AppConfirm from "./AppConfirm"
import AppInfo from "./AppInfo.vue"

let modalInstance;

function getModalInstance(render = undefined) {
    modalInstance = modalInstance || Modal.newInstance({
        closable: false,
        maskClosable: false,
        footerHide: true,
        render: render
    });

    return modalInstance;
}

function confirm(options) {
    const render = ('render' in options) ? options.render : undefined;
    let instance = getModalInstance(render);

    options.onRemove = function () {
        modalInstance = null;
    };

    instance.show(options);
}


Modal.AppConfirm = function (props = {}) {
    props.icon = 'confirm';
    props.className = `app-${props.method}-confirm app-confirm`;
    props.footerHide = true;
    props.render = (h) => {
        return h(AppConfirm,
            {
                props: {
                    method:props.method,
                    loadingEnabled: props.loading,
                    message: props.message,
                    extraTitle: props.extraTitle,
                    iconType: props.iconType,
                    iconColor: props.iconColor,
                    okText: props.okText,
                    cancelText: props.cancelText,
                    extraClass: props.extraClass
                },
                on: {
                    onConfirm() {
                        props.onConfirm ? props.onConfirm() : '';
                    },
                    onCancel() {
                        props.onCancel ? props.onCancel() : '';
                    }
                }
            })
    };

    return confirm(props);
};

Modal.AppInfo = function (props = {}) {
    props.footerHide = true;
    props.render = (h) => {
        return h(AppInfo,
            {
                props: {
                    loadingEnabled: props.loading,
                    message: props.message,
                    extraTitle: props.extraTitle,
                    timeout: props.timeout,
                    iconType: props.iconType,
                    iconColor: props.iconColor
                },
            })
    };

    return confirm(props);
};

Modal.remove = function () {
    if (!modalInstance) {   // at loading status, remove after Cancel
        return false;
    }

    const instance = getModalInstance();

    instance.remove();
};

export default Modal;
