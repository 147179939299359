<template>
  <div>
    <div
      v-if="success"
      class="auth-success-message text-center"
    >
      <p> {{ $t('We sent you an email to verify your account') }}</p>
      <p>{{ $t('See you soon') }}!</p>
      <Button
        type="default"
        :to="{name: 'login'}"
      >
        {{ $t('Back') }}
      </Button>
    </div>

    <Form
      v-else
      ref="registrationForm"
      :model="registrationFormData"
      :rules="registrationRules"
      @keyup.enter.native="triggerSubmit"
    >
      <h4 class="text-center">
        {{ $t('Provide the information below to create an account') }}
      </h4>
      <br><br>
      <FormItem
        prop="contract_identifier"
        :error="registrationErrors.contract_identifier"
      >
        <i-input
          v-model="registrationFormData.contract_identifier"
          :placeholder="$t('Contract identifier')"
        />
      </FormItem>
      <FormItem
        prop="email"
        :error="registrationErrors.email"
      >
        <i-input
          v-model="registrationFormData.email"
          :placeholder="$t('Professional e-mail')"
        />
      </FormItem>
      <FormItem
        prop="agree_tac"
        :error="registrationErrors.agree_tac"
      >
        <Checkbox
          v-model="registrationFormData.agree_tac"
          class="tc-input"
        >
          {{ $t('Agree to') }}
        </Checkbox>
        <a @click="showTacPopup = true">
          {{ $t('Terms & Conditions') }}
        </a>
      </FormItem>
      <TermsAndCondition
        v-model="showTacPopup"
        @accept="acceptTac"
        @tacVersion="storeTacVersion"
      />

      <Button
        long
        type="success"
        :loading="loading"
        @click="triggerSubmit"
      >
        {{ $t('Send request') }}
      </Button>
    </Form>
  </div>
</template>

<script>
    import {postRegistrationQry} from "@/services/auth.service";
    import TermsAndCondition from "../../views/authentification/TermsAndCondition";

    export default {
        name: "Registration",
        components:{
          TermsAndCondition
        },
        data() {

             const tac_checkbox_validator = (rule, value, callback) => {
              if (value !== true) {
                  callback(new Error(this.$t('T&C agreement is required')));
              } else {
                callback();
              }
             }

            return {
                loading: false,
                success: false,
                showTacPopup: false,
                tacVersion: null,
                registrationFormData: {
                    contract_identifier: '',
                    email: '',
                    agree_tac: false,  // NOT FOR SERVER, which relies on tac_version instead
                },
                registrationRules: {
                    contract_identifier: [
                        {required: true, trigger: 'blur'},
                    ],
                    email: [
                        {required: true, type: "email", trigger: 'blur'},
                    ],
                    agree_tac :[
                        {required: true, type: "boolean", trigger: 'blur', validator: tac_checkbox_validator},
                    ],
                },
                registrationErrors: {
                    contract_identifier: '',
                    email: '',
                    agree_tac: '',
                },
            }
        },

        methods: {
            triggerSubmit() {

                if (this.tacVersion === null) {
                  this.$Message.error({content: this.$t("Couldn't fetch current Terms&Conditions, please refresh the page"), duration: 5})
                  return;
                }

                this.$refs['registrationForm'].validate(valid => {

                    if (valid) {

                        this.loading = true;
                        let parameters = {tac_version: this.tacVersion,
                                          email: this.registrationFormData.email,
                                          contract_identifier: this.registrationFormData.contract_identifier.trim()}

                        postRegistrationQry(parameters)
                            .then(res => {
                                this.loading = false;
                                this.success = true;
                            })
                            .catch(err => {
                                this.loading = false;
                                this.resetDataName(this, 'registrationErrors');
                                const error = err.response;
                                if (error.status === 400) {
                                    for (let col in error.data) {
                                        this.registrationErrors[col] = error.data[col][0];
                                    }
                                }
                                let nonFieldError = error.data["non_field_errors"];
                                this.$Message.error({content: nonFieldError || this.$t('Error creating your account'), duration: 5});

                            })
                    }
                })
            },
           storeTacVersion(version) {
              this.tacVersion = version;
           },
            acceptTac(agree){
              this.registrationFormData.agree_tac = agree;
            },
        }

    }
</script>


<style scoped>
</style>
