<template>
  <div>
    <Card class="country-general">
      <div class="general-header">
        <div>
          <Icon
            type="md-flag"
            :size="25"
            style="margin-right: 15px"
          />
          <strong>{{ $t('Good to know') }}</strong>
        </div>
        <Icon
          v-if="(s2ec.user.is_admin || s2ec.user.is_superuser) && !showUpdateForm"
          type="md-create"
          size="20"
          class="pointer-cursor"
          v-bind="getTranslatableContentAttributes()"
          @click.native="triggerUpdateForm"
        />
      </div>
      <GoodToKnowForm
        v-if="(s2ec.user.is_admin || s2ec.user.is_superuser) && showUpdateForm"
        ref="gtk"
        :fact-type="factType"
        @gtkCreated="gtkCreated"
        @triggerCancel="triggerCancel"
      />

      <div v-else>
        <div
          v-for="fact in countryFact"
          :key="fact.id"
          class="general-content good-to-know"
        >
          <Row
            type="flex"
            align="middle"
            :gutter="15"
          >
            <i-col
              :md="24"
              :lg="12"
              class="gtk-display-item"
            >
              <label> {{ $t('Off. language:') }} </label>
              <p> {{ fact.data.language }} </p>
            </i-col>
            <i-col
              :md="24"
              :lg="12"
              class="gtk-display-item"
            >
              <label> {{ $t('Currency:') }} </label>
              <p> {{ fact.data.currency }} </p>
            </i-col>

            <i-col
              :md="24"
              :lg="12"
              class="gtk-display-item"
            >
              <label> {{ $t('Capital:') }} </label>
              <p> {{ fact.data.capital }} </p>
            </i-col>

            <i-col
              :md="24"
              :lg="12"
              class="gtk-display-item"
            >
              <label> {{ $t('Population (capital):') }} </label>
              <p> {{ fact.data.population }} </p>
            </i-col>
            <i-col
              :xs="24"
              class="gtk-hr"
            >
              <hr>
            </i-col>
            <i-col
              :xs="24"
              class="gtk-display-item"
            >
              <label> {{ $t('Head of state:') }} </label>
              <p> {{ fact.data.head_of_state }} </p>
            </i-col>

            <i-col
              :xs="24"
              class="gtk-display-item"
            >
              <label> {{ $t('Jet lag:') }} </label>
              <p> {{ fact.data.jet_lag }} </p>
            </i-col>

            <i-col :xs="24">
              <div
                v-for="(item, idx) in fact.data.extra_info"
                :key="idx"
              >
                <Row
                  :gutter="15"
                  type="flex"
                >
                  <i-col
                    :xs="24"
                    class="gtk-display-item"
                  >
                    <label :title="item.value"> {{ item.value | truncate(20) }}: </label>
                    <p> {{ item.content }} </p>
                  </i-col>
                </Row>
              </div>
            </i-col>
          </Row>
        </div>

        <div
          v-if="countryFact.length === 0"
          class="empty-fact-type"
        >
          <h4 class="text-center">
            {{ $t('No data') }}
          </h4>
        </div>
      </div>
      <Spin
        v-if="loading"
        fix
      />
    </Card>
  </div>
</template>

<script>
    import {
        getCountryFactsQry,
        getFactTypeQry
    } from "../../../services/countryInfo.service";
    import GoodToKnowForm from "./GoodToKnowForm"

    export default {
        name: "GoodToKnow",
        components: {
            GoodToKnowForm
        },
        props: ['country'],
        data() {
            return {
                loading: false,
                showUpdateForm: false,
                countryFact: [],
                factType: {},
            }
        },
        async created() {
			this.loading = true;
			getCountryFactsQry({country: this.$route.params.country, country_fact_type: 'good-to-know'})
					.then(res => {
						this.countryFact = res.data;
					})
					.catch(() => {
						this.$Message.error({content: this.$t('Cannot retrieve Good to know'), duration: 5})
					});

			getFactTypeQry("good-to-know")
					.then(res => {
						this.factType = res.data;
					})
					.catch(() => {
						this.$Message.error({content: this.$t('Unable to retrieve struct'), duration: 5})
					});
			this.loading = false;
        },
        methods: {
            gtkCreated(fact, idx) {
                if (idx !== undefined) {
                    this.countryFact.splice(idx, 1, fact);
                } else {
                    this.countryFact.push(fact);
                }
            },

            triggerCancel() {
                this.showUpdateForm = false;
            },

            triggerUpdateForm() {
                this.showUpdateForm = true;

                this.$nextTick(() => {
                    if (this.countryFact.length) {
                        this.$refs['gtk'].setUpdateData(this.countryFact[0]);
                    }
                });
            },
        },

    }
</script>

<style>

	.general-content.good-to-know {
		padding: 20px;
		background: #fff;
	}

  .good-to-know label {
    font-weight: 500;
  }

	.good-to-know .gtk-display-item {
		margin-bottom: 20px;
	}

	.good-to-know .gtk-display-item p {
		margin-left: 200px;
		display: inline-block;
	}

	.good-to-know .gtk-display-item label {
		position: absolute;
		float: left;
	}

	.gtk-hr {
		padding-top: 20px;
		padding-bottom: 30px;
		color: #dcdee2;
	}

</style>
