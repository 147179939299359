<template>
  <div class="full-height country-info-map">
    <div class="full-height country-info-map">
      <Row type="flex" justify="start">
        <p class="boldtext">{{ $t("Country information") }}</p>
      </Row>
      <CountrySheet
        :countryList="filteredCountryList"
        @filter-by-continent="filterCountryListByContinent"
      />
    </div>
  </div>
</template>

<script>
import CountrySheet from "./CountrySheet.vue";
import { getCountriesQry } from "../../services/common.service";
import Vue from "vue";

export default {
  name: "SelectCountry",
  components: {
    CountrySheet,
  },
  data() {
    return {
      countryList: [],
      selectedContinent: null,
    };
  },
  created() {
    getCountriesQry().then((res) => {
      this.countryList = res.data;
    });
  },
  computed: {
    filteredCountryList() {
      if (this.selectedContinent) {
        return this.worldInfoStore.countryList.filter(
          country => (country.continent == this.selectedContinent));
      }
      return this.worldInfoStore.countryList;
    }
  },
  methods: {
    triggerSelectContinent(country) {
      this.$router.push({ name: "country-info-detail", params: { country } });
    },
    filterCountryListByContinent(value) {
      this.selectedContinent = value;  // Can be null
    },
  },
};
</script>

