<template>
  <div class="full-height">
    <!-- HEADER -->
    <Row v-if="$route.name != 'contracts'" style="margin-bottom: 55px;">
      <i-col :md="16" :xs="14">
        <i-col :md="15" :xs="15">
          <router-link :to="{ name: 'accounts' }">
            <span class="history-account mr-3">{{ $t("Account") }}</span>
          </router-link>
          <Icon type="ios-arrow-forward" size="20" class="arrow" />
          <router-link :to="{ name: 'contracts' }">
            <span class="history-account" v-if="!isLoading"> {{ client.client_name }}</span>
            <span class="history-account" v-else> {{ $t("Client") }}</span>
          </router-link>
          <Icon type="ios-arrow-forward" size="20" class="arrow" />
          <span class="history-account-actual" v-if="!isLoading">{{ contract.contract_name }}</span>
          <span class="history-account-actual" v-else>{{ $t("Contract") }}</span>
        </i-col>
      </i-col>
      <i-col v-if="isManagerOrAbove()" :md="8" :xs="10" align="right">
        <button class="action-button btnContract" @click="triggerShowModal()">
          {{ $t("Invite user") }}
        </button>
        <InviteUser
          ref="inviteUser"
          v-model="showInviteUser"
          @refreshUsers="fetchContractUsers"
          :client="client"
          :contract="contract"
        />
      </i-col>
    </Row>
    <userManagement ref="mangeUser" v-model="modalData" :modalOnSubmit="triggerManageUser" @hide="triggerHideModal" />
    <!-- FILTER -->
    <Row class="mt-3" v-if="isOperatorOrAbove()">
      <i-col :md="24" :xs="24" class="mb-5">
        <!-- SEARCH BY NAME -->
        <i-col :md="5" :xs="12" class="mr-4">
          <p class="text-filter">{{ $t("Search") }}</p>
          <Input
            search
            type="text"
            v-model="users.filter.name"
            @on-search="fetchUsers({ full_name: users.filter.name })"
            clearable
            @on-clear="fetchUsers({})"
            :placeholder="$t('Search by name')"
          />
        </i-col>
        <!-- SEARCH BY CONTRACT -->
        <i-col :md="5" :xs="8" class="mr-4" v-if="$route.name == 'contracts'">
          <p class="text-filter">{{ $t("Contract") }}</p>
          <i-select
            type="text"
            :placeholder="$t('Contract')"
            v-model="users.filter.contract"
            multiple
            clearable
            @on-change="fetchUsers({ contract: [users.filter.contract] })"
          >
              <i-option v-for="contract in contracts" :key="contract.id" :value="contract.id">
              {{ $t(contract.contract_name) }}
            </i-option>
          </i-select>
        </i-col>
        <!-- STATUS FILTER -->
        <i-col :md="5" :xs="8" class="mr-4">
          <p class="text-filter">{{ $t("Status") }}</p>
          <i-select
            type="text"
            :placeholder="$t('Status')"
            v-model="users.filter.status"
            clearable
            multiple
            @on-change="fetchUsers({ status: users.filter.status })"
          >
            <i-option value="active"> {{ $t("Active") }} </i-option>
            <i-option value="disabled"> {{ $t("Inactive") }} </i-option>
            <i-option value="invited"> {{ $t("Invited") }} </i-option>
            <i-option value="waiting"> {{ $t("Waiting") }} </i-option>
          </i-select>
        </i-col>
        <!-- ROLE FILTER -->
        <i-col :md="5" :xs="8">
          <p class="text-filter">{{ $t("Role") }}</p>
          <i-select
            type="text"
            :placeholder="$t('Role')"
            v-model="users.filter.role"
            multiple
            clearable
            @on-change="fetchUsers({ user_role: [users.filter.role] })"
          >
            <i-option value="traveler"> {{ $t("Traveler") }} </i-option>
            <i-option value="head_of_site"> {{ $t("Head of Site") }} </i-option>
            <i-option value="manager"> {{ $t("Manager") }} </i-option>
            <i-option value="operator"> {{ $t("Operator") }} </i-option>
          </i-select>
        </i-col>
      </i-col>
    </Row>
    <transition appear name="slide-left-centered">
      <AppTable
        :columns="columns"
        table-class="s2ec-table"
        :data-table="users.results"
        ref="table"
        @on-row-click="open"
        :row-class-name="getAccountsTableRowClassName"
        :no-data-text="$t('No data')"
        :loading="isLoading"
      />
    </transition>
  </div>
</template>

<script>
import InviteUser from "@/views/accounts/modals/InviteUser";
import userManagement from "@/views/accounts/modals/userManagement";
import { getUsers, getContractQry, getClientQry } from "@/services/accounts.service";
import { deleteUserQry } from "@/services/auth.service";
import { patcherUserQry, postValidateUserQry } from "@/services/userManagement.service"; // Fixme naming
import AppTable from "@/components/common/AppTable";
import DateFormat from "@/mixins/dateFormat";

export default {
  name: "Users",
  components: {
    InviteUser,
    deleteUserQry,
    patcherUserQry,
    AppTable,
    userManagement,
  },
  mixins: [DateFormat],
  props: ["contracts"],
  data() {
    return {
      isLoading: false,
      showInviteUser: false,
      client: {
        client_name: "",
      },
      contract: {
        contract_identifier: "",
      },
      modalData: {
        showModal: false,
        form: {},
      },
      users: {
        count: 0,
        results: [],
        filter: {
          name: "",
          status: [],
          contract: [],
          role: [],
        },
      },
      columns: [
        {
          title: this.$t("Name"),
          key: "full_name",
          sortable: true,
        },
        {
          title: this.$t("Email"),
          key: "email",
        },
        {
          title: this.$t("Phone"),
          key: "cellphone[0]",
          render: (h, params) => {
            if (params.row.cellphones != null) {
              if (params.row.cellphones.length > 0) {
                return h("div", {}, [h("p", {}, params.row.cellphones != null ? params.row.cellphones[0].number : "")]);
              }
            }
          },
        },
        {
          title: this.$t("State"),
          key: "is_active",
          sortable: true,
          align: "center",
          render: (h, params) => {
            if (params.row.is_active === true) {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      backgroundColor: "#268044",
                      padding: "10px",
                      border: "none",
                      width: "80px",
                      margin: "auto",
                      paddingTop: "7px",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                    },
                  },
                  this.$t("Active")
                ),
              ]);
            } else if (params.row.user_onboarding_complete == false) {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      backgroundColor: "#A66811",
                      padding: "10px",
                      border: "none",
                      width: "80px",
                      margin: "auto",
                      paddingTop: "7px",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                    },
                  },
                  params.row.user_onboarding_complete
                    ? this.$t("Inactive")
                    : params.row.user_origin == "INVITATION"
                    ? this.$t("Invited")
                    : this.$t("Waiting")
                ),
              ]);
            } else {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      backgroundColor: "#CC0000",
                      padding: "10px",
                      border: "none",
                      width: "80px",
                      margin: "auto",
                      paddingTop: "7px",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                    },
                  },
                  params.row.user_onboarding_complete
                    ? this.$t("Inactive")
                    : params.row.user_origin == "INVITATION"
                    ? this.$t("Invited")
                    : this.$t("Waiting")
                ),
              ]);
            }
          },
        },
        {
          title: this.$t("Role"),
          key: this.$t("user_role"),
          width: 200,
          render: (h, params) => {
            return h("div", this.$t(params.row.user_role_display));
          },
        },
        {
          title: this.$t("Actions"),
          key: "actions",
          align: "right",
          render: (h, params) => {
            if (this.isManagerOrAbove()) {
              return [
                h("Button", {
                  props: {
                    icon: "md-create",
                    disabled: params.row.id == this.userProfileStore.getInternatlId,
                  },
                  attrs: {
                    title: this.$t("Edit"),
                  },
                  class: "table-button",
                  on: {
                    click: (evt) => {
                      evt.stopPropagation(); // "click.stop" is not supported here
                      this.triggerShowUpdateModal(params.row);
                    },
                  },
                }),
                h("Button", {
                  props: {
                    icon: params.row.is_active
                      ? "md-close"
                      : params.row.user_onboarding_complete
                      ? "md-checkmark"
                      : "md-done-all",
                    disabled:
                      (!params.row.user_onboarding_complete && params.row.user_origin == "INVITATION") ||
                      params.row.id == this.userProfileStore.getInternatlId, // FIXME dirty workaround
                  },
                  attrs: {
                    title:
                      params.row.id == this.userProfileStore.getInternatlId
                        ? this.$t("Unable to auto-deactivate")
                        : params.row.is_active
                        ? this.$t("Disable")
                        : params.row.user_onboarding_complete
                        ? this.$t("Enable")
                        : this.$t("Validate"),
                  },
                  class: "table-button",
                  on: {
                    click: (evt) => {
                      evt.stopPropagation(); // "click.stop" is not supported here
                      this.changeStateUser(params.row.id, params.row.is_active, params.row.user_onboarding_complete);
                    },
                  },
                }),
                h("Button", {
                  props: {
                    icon: "md-trash",
                    disabled: params.row.id == this.userProfileStore.getInternatlId,
                  },
                  attrs: {
                    title:
                      params.row.id == this.userProfileStore.getInternatlId
                        ? this.$t("Unable to delete yourself")
                        : this.$t("Delete"),
                  },
                  class: "table-button",
                  on: {
                    click: (evt) => {
                      evt.stopPropagation(); // "click.stop" is not supported here
                      this.removeUser(params.row.id, params.index);
                    },
                  },
                }),
              ];
            } else {
              return [];
            }
          },
        },
      ],
    };
  },
  async created() {
    if (typeof this.$route.params.id == "undefined") {
      // FIXME: This few lines should may not exist
      this.$router.push({
        name: "contracts",
        params: { id: 1 },
      });
    }
    try {
      this.isLoading = true;
      await this.fetchClient();
      await this.fetchContract();
      await this.fetchContractUsers();
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    contractId() {
      return this.$route.params.contractId;
    },
    clientId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getAccountsTableRowClassName() {
      return "account-table__row";
    },
    fetchUsers(filter_value) {
      this.isLoading = true;
      filter_value.client = this.clientId
      if (this.$route.name != "contracts") {
        getUsers(filter_value)
          .then((response) => {
            const filteredUsers = response.data.results.filter((user) => user.contracts.includes(this.contract.id));
            this.users.results = filteredUsers;
            this.users.count = response.data.count;
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("Error occurred retrieving users"),
              duration: 5,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        getUsers(filter_value)
          .then((response) => {
            this.users.results = response.data.results;
            this.users.count = response.data.count;
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("Error occurred retrieving users"),
              duration: 5,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    open(row) {
      if (this.$route.name != "contracts") {
        this.$router.push({
          name: "profile",
          params: {
            id: row.client,
            contractId: this.$route.params.contractId,
            userId: row.id,
          },
        });
      } else {
        if (row.contracts.length > 0) {
          this.$router.push({
            name: "profile",
            params: {
              id: row.client,
              contractId: row.contracts[0],
              userId: row.id,
            },
          });
        } else {
          this.$Message.error({
            content: this.$t("No contract for this user : ") + row.full_name,
            duration: 5,
          });
        }
      }
    },
    removeUser(id, idx) {
      this.$AppModal.AppConfirm({
        extraTitle: this.$t("Delete"),
        message: this.$t("You are about to delete this item. Are you sure to continue"),
        loading: true,
        cancelText: this.$t("Cancel"),
        onConfirm: () => {
          deleteUserQry(id, { status: this.userStatus })
            .then(() => {
              this.users.count -= 1;
              this.users.results.splice(idx, 1);
              this.$AppModal.remove();
              this.$Message.success(this.$t("Successfully deleted user"));
            })
            .catch(() => {
              this.$AppModal.remove();
              this.$Message.error({
                content: this.$t("Error occurred deleting user"),
                duration: 5,
              });
            });
        },
      });
    },
    triggerShowModal() {
      (this.showInviteUser = false), (this.showInviteUser = true);
    },
    triggerShowUpdateModal(userData) {
      this.modalData.form = { ...userData };
      this.modalData.showModal = true;
    },
    triggerManageUser() {
      const userData = this.modalData.form;

      patcherUserQry(userData.id, { user_role: userData.user_role })
        .then(() => {
          this.$Message.success(this.$t("Successfully updated"));
          setTimeout(() => {
            this.fetchContractUsers();
          }, 500);
          this.triggerHideModal();
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred on validating the request"),
            duration: 5,
          });
          this.triggerHideModal();
        });
    },
    async fetchClient() {
      await getClientQry(this.clientId)
        .then((response) => {
          this.client = response.data;
          return response;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred retrieving client"),
          });
        });
    },
    async fetchContract() {
      if (this.$route.name != "contracts") {
        await getContractQry(this.clientId, this.contractId)
          .then((response) => {
            this.contract = response.data;
            return response;
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("Error occurred retrieving contract"),
              duration: 5,
            });
          });
      }
    },
    async fetchContractUsers() {
      const params = {
        client: this.clientId,
        page_size: 500,
      };

      if (this.$route.name == "contracts") {
        await getUsers(params)
          .then((response) => {
            this.users.results = response.data.results;
            this.users.count = response.data.count;
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("Error occurred retrieving users"),
              duration: 5,
            });
          });
      } else {
        await getUsers(params)
          .then((response) => {
            const filteredUsers = response.data.results.filter((user) => user.contracts.includes(this.contract.id));
            this.users.results = filteredUsers;
            this.users.count = response.data.count;
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("Error occurred retrieving users"),
              duration: 5,
            });
          });
      }
    },
    changeStateUser(id, state, userOnboardingComplete) {
      if (!state && !userOnboardingComplete) {
        postValidateUserQry(id)
          .then(() => {
            this.$Message.success(this.$t("Successfully validated user registration"));
            setTimeout(() => {
              this.fetchContractUsers();
            }, 500);
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("Error occurred"),
              duration: 5,
            });
          });
      } else {
        patcherUserQry(id, { is_active: !state })
          .then(() => {
            this.$Message.success(this.$t("Successfully updated a user"));
            setTimeout(() => {
              this.fetchContractUsers();
            }, 500);
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("Error occurred"),
              duration: 5,
            });
          });
      }
    },
    triggerHideModal() {
      this.modalData.showModal = false;
      this.modalOnSubmit = this.triggerCreateContract;
    },
  },
};
</script>

<style>
.contract-list-action > .ivu-btn:first-of-type {
  margin-right: 20px;
}

.ivu-table-title {
  margin-bottom: 186px !important;
}

.ivu-input-icon {
  line-height: 40px;
}
</style>
