<template>
  <div class="full-height">
    <Row>
      <p class="boldtext">{{ $t("Notifications") }}</p>
    </Row>
    <Row class="d-flex">
      <i-col :md="15" class="center">
        <Card class="white-modern-card mt-4" style="height: 72vh !important" :md="12">
          <Row justify="start">
            <Button
              class="back-button mr-3"
              :loading="notificationStore._readLoading"
              :disabled="notificationStore._deleteLoading"
              @click="notificationStore._markMultipleNotificationAsRead()"
            >
              <Icon class="ivu-icon-ios-thumbs-up mr-3"></Icon>{{ $t("Mark as read") }}
            </Button>
            <Button
              class="back-button"
              :disabled="notificationStore._readLoading"
              :loading="notificationStore._deleteLoading"
              @click="notificationStore._deleteNotificationMultiple()"
            >
              <Icon class="ivu-icon-ios-trash mr-3"></Icon>{{ $t("Delete") }}
            </Button>
            <Button class="back-button mr-2" style="float: right" @click="scrollToTop()">
              <Icon type="ios-arrow-dropup-circle" size="18" />
            </Button>
          </Row>
          <hr class="mt-4" />
          <Row class="mt-4">
            <div class="content-card" style="height: 55vh; overflow-y: scroll !important" ref="myDiv">
              <p class="boldtext-small" v-if="notificationStore.getUnreadNotifications > 0">
                {{ $t("New notifications") + " (" + notificationStore.getUnreadNotifications + ")" }}
              </p>
              <transition-group name="list" appear>
                <div v-for="(notification, index) in sortedNotifications" :key="notification.id">
                  <div v-if="notification.content_object !== null">
                    <Row>
                      <!-- CHECKBOX -->
                      <i-col :lg="1" :xs="2">
                        <input
                          type="checkbox"
                          :disabled="notificationStore._readLoading || notificationStore._deleteLoading"
                          @change="notificationStore._checkAll = false"
                          v-model="notificationStore._notificationsSelected"
                          :value="{
                            id: notification.id,
                            idx: index,
                            read: notification.read,
                          }"
                          id="id-checkbox-listed"
                          style="margin: 40px 0px 0px"
                        />
                      </i-col>
                      <!-- CARD NOTIF -->
                      <i-col :lg="23" :xs="22">
                        <div class="app-notification-popper">
                          <Row class="notification-item" name="card" :class="notification.read ? '' : 'notif-not-read'">
                            <!-- EMERGENCY -->
                            <div v-if="notification.notification_type === 'Emergency'">
                              <Row type="flex" align="middle">
                                <i-col :lg="1">
                                  <img
                                    v-if="notification.content_object.user_value.avatar_absolute_url"
                                    class="user-png-emergency"
                                    :src="notification.content_object.user_value.avatar_absolute_url"
                                  />
                                  <Icon v-else type="ios-person" class="circle-incident" size="20" />
                                  <Icon
                                    type="ios-call"
                                    size="10"
                                    class="circle-emergency-target"
                                    style="color: #d14646; bottom: 0px !important"
                                  />
                                </i-col>
                                <i-col :md="23">
                                  <p class="ml-4">{{ notification.description }}</p>
                                </i-col>
                              </Row>
                            </div>
                            <!-- INFORMATIONS -->
                            <div v-else-if="notification.notification_type === 'Information'">
                              <div v-if="notification.content_object !== null">
                                <!-- INFORMATIONS -> MISSION model-->
                                <div v-if="notification.content_object.model_name === 'mission'">
                                  <Row type="flex" align="middle">
                                    <i-col :md="1">
                                      <img
                                        size="20"
                                        style="float: left"
                                        class="flag-notification"
                                        :src="notification.content_object.flag"
                                      />
                                    </i-col>
                                    <i-col :md="23">
                                      <p class="ml-3">
                                        <strong class="ml-3 text-notif">{{
                                          notification.content_object.country_name
                                        }}</strong>
                                      </p>
                                    </i-col>
                                  </Row>
                                  <Row class="mt-3">
                                    <p>{{ notification.description }}</p>
                                  </Row>
                                </div>
                                <!-- INFORMATIONS -> USER model-->
                                <div v-if="notification.content_object.model_name === 'user'">
                                  <Row type="flex" align="middle">
                                    <i-col :lg="1">
                                      <img
                                        v-if="notification.user_value.avatar_absolute_url"
                                        class="user-png-emergency"
                                        :src="notification.user_value.avatar_absolute_url"
                                      />
                                      <Icon
                                        v-else
                                        type="ios-person"
                                        size="20"
                                        class="circle-incident"
                                        style="color: #fff"
                                      />
                                    </i-col>
                                    <i-col :lg="23">
                                      <p class="ml-4">{{ notification.description }}</p>
                                    </i-col>
                                  </Row>
                                </div>
                                <!-- INFORMATIONS -> ALERT model-->
                                <div v-else-if="notification.content_object.model_name === 'alert'">
                                  <Row type="flex" align="middle">
                                    <i-col :lg="1">
                                      <img
                                        v-if="notification.content_object.user_value.avatar_absolute_url"
                                        class="user-png-emergency"
                                        :src="notification.content_object.user_value.avatar_absolute_url"
                                      />
                                      <Icon v-else type="ios-person" class="circle-incident" size="20" />
                                      <Icon
                                        type="ios-locate-outline"
                                        size="10"
                                        class="circle-emergency-target"
                                        style="color: #d14646; bottom: 0px !important"
                                      />
                                    </i-col>
                                    <i-col :lg="23">
                                      <p class="ml-4">{{ notification.description | truncate(200) }}</p>
                                    </i-col>
                                  </Row>
                                </div>
                                <!-- INFORMATIONS -> INCIDENT model-->
                                <div v-else-if="notification.content_object.model_name === 'incident'">
                                  <Row type="flex" align="middle">
                                    <i-col :md="1">
                                      <img
                                        size="20"
                                        class="flag-notification"
                                        :src="notification.content_object.flag"
                                      />
                                    </i-col>
                                    <i-col :md="23">
                                      <p class="ml-3 text-notif">
                                        <strong>{{ notification.content_object.country_name }}</strong>
                                      </p>
                                    </i-col>
                                  </Row>
                                  <Row class="mt-3">
                                    <p>{{ notification.description }}</p>
                                  </Row>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <Row type="flex" align="middle">
                                <i-col :sm="3" :md="4" :lg="2">
                                  <Icon
                                    type="ios-warning-outline"
                                    size="20"
                                    class="circle-emergency"
                                    style="color: #eee"
                                  />
                                </i-col>
                                <i-col :sm="21" :md="20" :lg="22">
                                  <p class="ml-4">{{ notification.description }}</p>
                                </i-col>
                              </Row>
                            </div>
                          </Row>
                          <!-- CREATED AT, SEE NOTIF -->
                          <Row style="margin-top: -12px">
                            <i-col :sm="12" :lg="23" :xs="22">
                              <p style="color: #2b2b2b; font-size: 10px">{{ notification.created_at | fromNow }}</p>
                            </i-col>
                            <i-col :sm="10" :lg="1" :xs="2">
                              <a
                                class="see-notif"
                                @click="navigateLocation(notification, notification.notification_type)"
                              >
                                {{ $t("See") }}
                              </a>
                            </i-col>
                          </Row>
                        </div>
                      </i-col>
                    </Row>
                  </div>
                </div>
              </transition-group>
              <infinite-loading @infinite="notificationStore._infiniteHandler">
                <div slot="no-more">{{ $t("No more data") }}</div>
                <div slot="no-results">{{ $t("No data") }}</div>
              </infinite-loading>
            </div>
          </Row>
        </Card>
      </i-col>
    </Row>
  </div>
</template>
<script>
import Icon from "iview/src/components/icon/icon.vue";
import { patchNotificationsQry } from "@/services/notification.service";
import moment from "moment";
import infiniteLoading from "vue-infinite-loading";

export default {
  name: "Notifications",
  components: {
    Icon,
    infiniteLoading,
  },
  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    },
  },
  computed: {
    sortedNotifications: function () {
      return this.notificationStore.getNotifications.sort((a, b) => a.read - b.read);
    },
  },
  watch: {
    sortedNotifications(val) {
      if (val[0]) {
        if (!val[0].read) {
          this.scrollToTop();
        }
      }
    },
  },
  methods: {
    navigateLocation(notif_info, notification_type) {
      if (!notif_info.read) {
        notif_info.read = true;
        patchNotificationsQry(notif_info.id.toString(), { read: true });
        this.notificationStore.decrementUnreadNotification();
      }
      if (notif_info.content_object === null || notif_info.content_object.device_track === null) {
        this.$Message.error({
          content: this.$t("User has not logged in for 30 days, no trace available"),
          duration: 5,
        });
        return;
      }

      this.notificationStore.setLastNotification(notif_info);
      switch (notification_type) {
        // Emergency
        case "Emergency":
          this.$router.push({
            name: "map",
            query: {
              location: `${notif_info.content_object.latitude},${notif_info.content_object.longitude},12`,
              id: `${notif_info.content_object.device_id}`,
            },
          });
          break;
        // Information
        case "Information":
          if (notif_info.content_object.model_name === "alert") {
            this.$router.push({
              name: "map",
              query: {
                location: `${notif_info.content_object.device_track.latitude},${notif_info.content_object.device_track.longitude},12`,
                id: `${notif_info.content_object.device_track.device_id}`,
              },
            });
          } else if (notif_info.content_object.model_name === "user") {
            let routeParams = {
              name: notif_info.user_value.contracts.length >= 1 ? "users" : "contracts",
              params: {
                id: `${notif_info.user_value.client}`,
                contractId: `${notif_info.user_value.contracts[0]}`,
              },
            };
            this.$router.push(routeParams);
          } else if (notif_info.content_object.model_name === "incident") {
            this.$router.push({
              name: "country-info-detail",
              params: {
                country: `${notif_info.content_object.country}`,
              },
            });
          } else if (notif_info.content_object.model_name === "mission") {
            this.$router.push({ name: "missions-management" });
          }
          break;
      }
    },
    scrollToTop() {
      this.$refs.myDiv.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.app-notification-popper .notification-item {
  margin: 15px 5px 15px 0px;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  border: solid 1px #e1e1e1;
  overflow-x: hidden;
  /* padding: 8px 0; */
  white-space: normal;
  border-bottom: 1px solid RGBA(113, 87, 242, 0.1);
}
.notif-not-read {
  background-color: #edecff !important;
}
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
}

.list-enter-from,
.list-leave-to {
  transform: translateX(50px);
  opacity: 0;
}
.ivu-checkbox-checked .ivu-checkbox-inner {
  background-color: var(--secondary-bleu) !important;
  border-color: var(--secondary-bleu) !important;
}

.ivu-checkbox-inner::after {
  border-color: var(--secondary-bleu) !important;
}
.flag-notification {
  margin-right: 200px;
}
</style>
