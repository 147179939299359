export const countries = {
  "AND": require('./AND.geo.json'),
  "ALA": require('./ALA.geo.json'),
  "CIV": require('./CIV.geo.json'),
  "MLT": require('./MLT.geo.json'),
  "MAR": require('./MAR.geo.json'),
  "ZAF": require('./ZAF.geo.json'),
  "MRT": require('./MRT.geo.json'),
  "DZA": require('./DZA.geo.json'),
  "UGA": require('./UGA.geo.json'),
  "DOM": require('./DOM.geo.json'),
  "HTI": require('./HTI.geo.json'),
  "TCD": require('./TCD.geo.json'),
  "DJI": require('./DJI.geo.json'),
  "LBR": require('./LBR.geo.json'),
  "AGO": require('./AGO.geo.json'),
  "LAO": require('./LAO.geo.json'),
  "RWA": require('./RWA.geo.json'),
  "MLI": require('./MLI.geo.json'),
  "MNE": require('./MNE.geo.json'),
  "TLS": require('./TLS.geo.json'),
  "CHN": require('./CHN.geo.json'),
  "BMU": require('./BMU.geo.json'),
  "ZWE": require('./ZWE.geo.json'),
  "BRN": require('./BRN.geo.json'),
  "JPN": require('./JPN.geo.json'),
  "SWZ": require('./SWZ.geo.json'),
  "PAN": require('./PAN.geo.json'),
  "GHA": require('./GHA.geo.json'),
  "AFG": require('./AFG.geo.json'),
  "TJK": require('./TJK.geo.json'),
  "NIC": require('./NIC.geo.json'),
  "GIN": require('./GIN.geo.json'),
  "MEX": require('./MEX.geo.json'),
  "PRT": require('./PRT.geo.json'),
  "SLE": require('./SLE.geo.json'),
  "GNB": require('./GNB.geo.json'),
  "SLB": require('./SLB.geo.json'),
  "ARM": require('./ARM.geo.json'),
  "ATF": require('./ATF.geo.json'),
  "NZL": require('./NZL.geo.json'),
  "ECU": require('./ECU.geo.json'),
  "SEN": require('./SEN.geo.json'),
  "GUF": require('./GUF.geo.json'),
  "BGD": require('./BGD.geo.json'),
  "PAK": require('./PAK.geo.json'),
  "UKR": require('./UKR.geo.json'),
  "PRK": require('./PRK.geo.json'),
  "VUT": require('./VUT.geo.json'),
  "TTO": require('./TTO.geo.json'),
  "KGZ": require('./KGZ.geo.json'),
  "SVK": require('./SVK.geo.json'),
  "LKA": require('./LKA.geo.json'),
  "BFA": require('./BFA.geo.json'),
  "SUR": require('./SUR.geo.json'),
  "USA": require('./USA.geo.json'),
  "GAB": require('./GAB.geo.json'),
  "VNM": require('./VNM.geo.json'),
  "NCL": require('./NCL.geo.json'),
  "MDA": require('./MDA.geo.json'),
  "HRV": require('./HRV.geo.json'),
  "MYS": require('./MYS.geo.json'),
  "BIH": require('./BIH.geo.json'),
  "NOR": require('./NOR.geo.json'),
  "GRL": require('./GRL.geo.json'),
  "ERI": require('./ERI.geo.json'),
  "ALB": require('./ALB.geo.json'),
  "CMR": require('./CMR.geo.json'),
  "FIN": require('./FIN.geo.json'),
  "THA": require('./THA.geo.json'),
  "ZMB": require('./ZMB.geo.json'),
  "PRY": require('./PRY.geo.json'),
  "PHL": require('./PHL.geo.json'),
  "SWE": require('./SWE.geo.json'),
  "CYP": require('./CYP.geo.json'),
  "IRL": require('./IRL.geo.json'),
  "IND": require('./IND.geo.json'),
  "DEU": require('./DEU.geo.json'),
  "AZE": require('./AZE.geo.json'),
  "URY": require('./URY.geo.json'),
  "IRN": require('./IRN.geo.json'),
  "QAT": require('./QAT.geo.json'),
  "NGA": require('./NGA.geo.json'),
  "FJI": require('./FJI.geo.json'),
  "LVA": require('./LVA.geo.json'),
  "FRA": require('./FRA.geo.json'),
  "BRA": require('./BRA.geo.json'),
  "GUY": require('./GUY.geo.json'),
  "LTU": require('./LTU.geo.json'),
  "MOZ": require('./MOZ.geo.json'),
  "RUS": require('./RUS.geo.json'),
  "ESP": require('./ESP.geo.json'),
  "ESH": require('./ESH.geo.json'),
  "JOR": require('./JOR.geo.json'),
  "CHE": require('./CHE.geo.json'),
  "PER": require('./PER.geo.json'),
  "NPL": require('./NPL.geo.json'),
  "KHM": require('./KHM.geo.json'),
  "PNG": require('./PNG.geo.json'),
  "MDG": require('./MDG.geo.json'),
  "SRB": require('./SRB.geo.json'),
  "LUX": require('./LUX.geo.json'),
  "KAZ": require('./KAZ.geo.json'),
  "ISL": require('./ISL.geo.json'),
  "AUT": require('./AUT.geo.json'),
  "SLV": require('./SLV.geo.json'),
  "DNK": require('./DNK.geo.json'),
  "CAN": require('./CAN.geo.json'),
  "PRI": require('./PRI.geo.json'),
  "FLK": require('./FLK.geo.json'),
  "BWA": require('./BWA.geo.json'),
  "CUB": require('./CUB.geo.json'),
  "JAM": require('./JAM.geo.json'),
  "GTM": require('./GTM.geo.json'),
  "GEO": require('./GEO.geo.json'),
  "ITA": require('./ITA.geo.json'),
  "YEM": require('./YEM.geo.json'),
  "TGO": require('./TGO.geo.json'),
  "BHS": require('./BHS.geo.json'),
  "KOR": require('./KOR.geo.json'),
  "TKM": require('./TKM.geo.json'),
  "SAU": require('./SAU.geo.json'),
  "EGY": require('./EGY.geo.json'),
  "BLR": require('./BLR.geo.json'),
  "SSD": require('./SSD.geo.json'),
  "ARG": require('./ARG.geo.json'),
  "SVN": require('./SVN.geo.json'),
  "COD": require('./COD.geo.json'),
  "CS-KM": require('./CS-KM.geo.json'),
  "BEL": require('./BEL.geo.json'),
  "GMB": require('./GMB.geo.json'),
  "MNG": require('./MNG.geo.json'),
  "LSO": require('./LSO.geo.json'),
  "TWN": require('./TWN.geo.json'),
  "LBN": require('./LBN.geo.json'),
  "KEN": require('./KEN.geo.json'),
  "CRI": require('./CRI.geo.json'),
  "BEN": require('./BEN.geo.json'),
  "KWT": require('./KWT.geo.json'),
  "TZA": require('./TZA.geo.json'),
  "CAF": require('./CAF.geo.json'),
  "BLZ": require('./BLZ.geo.json'),
  "COG": require('./COG.geo.json'),
  "ETH": require('./ETH.geo.json'),
  "SYR": require('./SYR.geo.json'),
  "COL": require('./COL.geo.json'),
  "BTN": require('./BTN.geo.json'),
  "HND": require('./HND.geo.json'),
  "GNQ": require('./GNQ.geo.json'),
  "UZB": require('./UZB.geo.json'),
  "BOL": require('./BOL.geo.json'),
  "NER": require('./NER.geo.json'),
  "HUN": require('./HUN.geo.json'),
  "OMN": require('./OMN.geo.json'),
  "BDI": require('./BDI.geo.json'),
  "ARE": require('./ARE.geo.json'),
  "MMR": require('./MMR.geo.json'),
  "VEN": require('./VEN.geo.json'),
  "LBY": require('./LBY.geo.json'),
  "SOM": require('./SOM.geo.json'),
  "CZE": require('./CZE.geo.json'),
  "NLD": require('./NLD.geo.json'),
  "SDN": require('./SDN.geo.json'),
  "TUR": require('./TUR.geo.json'),
  "GBR": require('./GBR.geo.json'),
  "BGR": require('./BGR.geo.json'),
  "CHL": require('./CHL.geo.json'),
  "IRQ": require('./IRQ.geo.json'),
  "MKD": require('./MKD.geo.json'),
  "GRC": require('./GRC.geo.json'),
  "IDN": require('./IDN.geo.json'),
  "ATA": require('./ATA.geo.json'),
  "EST": require('./EST.geo.json'),
  "TUN": require('./TUN.geo.json'),
  "AUS": require('./AUS.geo.json'),
  "NAM": require('./NAM.geo.json'),
  "PSE": require('./PSE.geo.json'),
  "MWI": require('./MWI.geo.json'),
  "POL": require('./POL.geo.json'),
  "ISR": require('./ISR.geo.json'),
  "ROU": require('./ROU.geo.json')
};
