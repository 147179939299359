<template>
  <AppModal 
    v-model="value.showModal" 
    :headerName="$t('New contract')"
    :footer-hide="false"
    @click:submit="submitForm"
    @click:cancel="triggerCancel"
    width="700" 
    :loading="loading" 
    class="contract-modal"
  >
    <Form ref="contractForm" :model="value.form" @submit.native.prevent="submitForm" >

      <Row class="mt-5">
        <i-col :md="12">
          <strong> {{ $t("Identification") }}</strong>
        </i-col>
        <i-col :md="12">
          <p>{{ $t("Contract number") }}</p>
          <FormItem class="mt-3" prop="contract_identifier">
            <i-input
              :placeholder="value.form.contract_identifier ? value.form.contract_identifier: $t('Auto generated')"
              :disabled="true"
            />
          </FormItem>
          <p class="mt-4">{{ $t("Contract name") }}<span class="requiredColor"> *</span></p>
          <FormItem prop="contract_name" class="mt-3">
            <Input
              v-model="value.form.contract_name"
              :placeholder="$t('Contract name')"></Input>
          </FormItem>
        </i-col>
      </Row>
      <Row class="mt-5">
        <i-col :md="12">
          <strong> {{ $t("Dates") }} </strong>
        </i-col>
        <i-col :md="12">
          <p>{{ $t("Departure Date") }}<span class="requiredColor"> *</span></p>
          <FormItem prop="contract_start_date" class="mt-3">
            <DatePicker type="date" confirm :placeholder="$t('Specific date')" style="width: 100%"
              v-model="value.form.contract_start_date" format="yyyy/MM/dd"></DatePicker>
          </FormItem>
          <p class="mt-4">{{ $t("Return date") }}<span class="requiredColor"> *</span></p>
          <FormItem prop="contract_end_date" class="mt-3">
            <DatePicker type="date" confirm :placeholder="$t('Specific date')" style="width: 100%"
              v-model="value.form.contract_end_date" format="yyyy/MM/dd"></DatePicker>
          </FormItem>
        </i-col>
      </Row>
      <Row class="mt-5">
        <i-col :md="12">
          <strong> {{ $t("Terms and condition") }} </strong>
        </i-col>
        <i-col :md="12">
          <p>{{ $t("Number of accesses") }}<span class="requiredColor"> *</span></p>
          <FormItem prop="contract_user_limit" class="mt-3">
            <i-select v-model="value.form.contract_user_limit" :placeholder="$t('Select number')">
              <i-option v-for="(range, idx) in rangeAccess" :key="idx" :value="range.value">
                {{ range.name }}
              </i-option>
            </i-select>
          </FormItem>
          <Checkbox v-model="getDomainsOrEmails" @on-change="allowDomains" class="tc-input"><span class="ml-3 mt-3"> {{ $t("Valid domain or email") }}</span></Checkbox>
          <FormItem prop="domains_or_emails" :error="domainError" class="mt-3 " :class="[allowDomainsOrEmails ? 'disabledClass' : '']">
            <vue-tags-input
              :tags="domainTags"
              :disabled="allowDomainsOrEmails"
              v-model="domain"
              :validation="domainValidator"
              @before-adding-tag="triggerAddDomain"
              @before-deleting-tag="triggerDeleteDomain"
              placeholder="domain.com"
            />
          </FormItem>
        </i-col>
      </Row>
      <input type="submit" hidden /> <!-- FIXME: make clean Press Enter submit handling -->
    </Form>
  </AppModal>
</template>

<script>
import AppModal from "@/components/common/AppModal";
import VueTagsInput from '@johmun/vue-tags-input';
import { buildRequiredRules, buildUnrequiredRules } from "@/helpers/common.helper";

export default {
  name: "ContractModalForm",

  components: {
    AppModal,
    VueTagsInput
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: {
        showModal: false,
        form: {},
      }
    },
    modalOnSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      allowDomainsOrEmails: true,
      rangeAccess: [
        {
          "name": "1 - 20",
          "value": 20,
        },
        {
          "name": "1 - 50",
          "value": 50,
        },
        {
          "name": "1 - 100",
          "value": 100,
        },
        {
          "name": "1 - 250",
          "value": 250,
        },
        {
          "name": this.$t('Unlimited'),
          "value": 999999
        }
      ],
      domainTags: [],
      domain: "",
      domainError: "",
      domainValidator: [
        {
          classes: 'invalid-domain-or-email',
          rule: ({text}) => {
            const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const domainRegex = /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/
            return !(emailRegex.test(text) || domainRegex.test(text))
          },
          disableAdd: true,
        },
      ],
    };
  },
  watch: {
    'value.showModal'(newValue) {
      if (this.value.form.domains_or_emails != null) {
        if (this.value.form.domains_or_emails.length > 0) {
          this.allowDomainsOrEmails = false
        } else {
          this.allowDomainsOrEmails = true
        }
      } 
      if (newValue === false) {
        this.domainTags = []
        this.domain = ""
        this.domainError = ""
        this.$emit("hide")
      }
      else {
        this.value.form.domains_or_emails.forEach(domain_or_email => {
          this.domainTags.push({
            text: domain_or_email,
          })
        })
      }
    },
    'allowDomainsOrEmails'(newValue) {
      if (newValue) { 
        this.domain = ""
        this.domainTags = []
        this.value.form.domains_or_emails = []
      }
    },
    'domainTags'(domains) {
      if (domains.length === 0) {
        this.allowDomainsOrEmails = true
        this.getDomainsOrEmails = false;
      }
    }
  },
  computed: {
    getDomainsOrEmails: {
      get: function() {
        if (this.value.form.domains_or_emails != null) {
          if (this.value.form.domains_or_emails.length > 0) {
            return true
          }
        }
        return false
      },
      set: function (value) {
        return value
      }
    }
  },
  created() {
    const requiredFields = {
      contract_name: null,
      contract_start_date: null,
      contract_end_date: null,
      contract_user_limit: null,
    }
    this.formRules = buildRequiredRules(requiredFields)
  },
  methods: {
    allowDomains() {
      this.allowDomainsOrEmails = !this.allowDomainsOrEmails
      this.formRules = []
      if (this.allowDomainsOrEmails) {
        const requiredFields = {
          domains_or_emails: null,
        }
        this.formRules = buildUnrequiredRules(requiredFields)
      } else {
        const requiredFields = {
          contract_name: null,
          contract_start_date: null,
          contract_end_date: null,
          contract_user_limit: null,
          domains_or_emails: null,
        }
        this.formRules = buildRequiredRules(requiredFields)
      }
    },
    submitForm() {
      const contractFormElem = this.$refs.contractForm
      contractFormElem.validate( valid => {
        if (valid) this.modalOnSubmit()
      })
    },
    triggerAddDomain(domain) {
      if (domain.tag.tiClasses.includes('ti-duplicate')) {
        this.$Message.error({ content: this.$t('You entered a duplicate value'), duration: 5 });
      } else if (!domain.tag.tiClasses.includes('ti-invalid')) {
        domain.addTag();
        this.domainTags.push(domain.tag)
        this.value.form.domains_or_emails.push(domain.tag.text);
      } else {
        this.$Message.error({ content: this.$t('Invalid format'), duration: 5 })
      }
    },
    triggerDeleteDomain(domain) {
      domain.deleteTag();
      this.domainTags.splice(domain.index, 1)
      this.value.form.domains_or_emails.splice(domain.index, 1);
    },
    triggerCancel() {
      this.value.showModal = false;
      this.$refs["contractForm"].resetFields();
    },
  },
};
</script>

<style>
.ti-input {
  border-radius: 4px;
  height: 40px;
}
.ti-new-tag-input {
  box-shadow: none;
}

.ti-tag.ti-valid {
  background-color: #6864F3;
  color: #fff;
  border-radius: 7px;
  display: flex;
  padding: 3px 5px;
  margin: 2px;
}

.disabledClass :nth-child(2n+1) {
  background-color: #EBEBEB;
  border-radius: 5px;
}
</style>
