<template>
  <div class="full-height incident-page">
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      class="mt-4 mb-4"
    >
      <i-col :md="16">
        <IncidentFilter
          :countryList="worldInfoStore.countryList"
          :incident-types="incidentTypes"
          @applyFilter="triggerFilter"
        />
      </i-col>

      <i-col
        :md="8"
        class="text-right"
      >
        <Button
          class="action-button"
          :disabled="!canEditTranslatableContent()"
          :title="getNonEditableLocaleMessageIfRelevant()"
          @click="triggerIncidentForm()"
        >
          {{ $t('Create incident') }}
        </Button>
      </i-col>
    </Row>

    <Table
      :columns="incidentColumns"
      :data="incidents.results"
      :loading="incidentTableLoad"
      height="700"
      class="s2ec-table table"
      @on-sort-change="handleSortChange"
    >
      <div
        slot="header"
        class="s2ec-table-header my-4"
      >
        <Icon type="ivu-icon ivu-icon-ios-megaphone" size="30" class="icon" />
        {{ $t("Incidents") }} - {{ incidents.count }}
      </div>
    </Table>
    <CreateIncident
      ref="createIncident"
      v-model="showCreateIncident"
      :incident-types="incidentTypes"
      @incidentCreated="incidentCreated"
    />
  </div>
</template>

<script>
    import moment from "moment";
    import IncidentExpand from "./IncidentExpand"
    import CreateIncident from "./modals/CreateIncident"
    import {
        getIncidentTypesQry,
        getIncidentQry,
        deleteIncidentQry
    } from "@/services/incidents.service";
    import PoptipBroadcast from "./PoptipBroadcast"
    import IncidentFilter from "./IncidentFilter";
    import IncidentTypesTags from "./IncidentTypesTags"
    import DateFormat from "@/mixins/dateFormat"

    export default {
        name: "Incidents",
        components: {
            IncidentFilter,
            CreateIncident,
            IncidentTypesTags
        },
        mixins: [DateFormat],
        data() {
            let self = this;

            return {
                showCreateIncident: false,
				        showRenewIncident: true,
                incidentTableLoad: null,
                incidents: {
                    count: 0,
                    next: null,
                    previous: null,
                    results: []
                },
                incidentTypes: [],
                country_list: [],
                filterParams: {},

                incidentColumns: [
                    {
                        title: this.$t('Type'),
                        width: 480,
                        className: 'text-capitalize',
						key: 'incident_types_values',
                        render: (h, params) => {
                            return h(IncidentTypesTags, {
                                props: {
                                    incidentTypesValues: params.row.incident_types_values
                                }
                            })
                        }
                    },
                    {
                        title: this.$t('Creation'),
                        key: 'incident_date',
                        sortable: true,
                        sortMethod: (a, b, type) => {
                            this.handleSort('incident_date',type);
                        },
                        render: (h, params) => {
                            return h("span", self.dateFormat(params.row.incident_date))
                        }
                    },
					{
						title: this.$t('Expiration'),
						key: 'expiration_date',
						sortable: true,
						sortMethod: (a, b, type) => {
						    this.handleSort('expiration_date',type);
						},
						render: (h, params) => {
							 return h("span", self.dateFormat(params.row.expiration_date))
						}
					},
                    {
                        title: this.$t('Country'),
                        key: 'incident_locations',
                        sortable: false,
                        render: (h, params) => {
                            return h("div", {
                                style: {
                                    display: "flex", // Utilisation de flexbox pour aligner les éléments horizontalement
                                    alignItems: "center", // Aligner verticalement sur l'axe Y
                                },
                            }, [
                                h("img", {
                                    attrs: {
                                        src: this.worldInfoStore.getCountryFlag(params.row.country),
                                        alt: "Drapeau du pays",
                                    },
                                    style: {
                                        width: "25px",
                                        borderRadius: "3px",
                                        marginRight: "8px", // Marge à droite pour séparer le drapeau du texte
                                    },
                                }),
                                h("p", {
                                    style: {
                                        marginBottom: "0", // Supprime la marge inférieure par défaut du paragraphe
                                    },
                                }, this.$t(params.row.country_name)),
                            ]);
                        }
                    },
                    {
                        title: this.$t('City'),
                        render: (h, params) => {
                            let locations = [];
                            let str = params.row.incident_locations;
                            if (str) {
                                str.forEach(location => {
                                    let city = location.city ? location.city : '';
                                    if (city) {
                                      if (location.country_name != params.row.country_name) {
                                        city += ' (' + location.country_name + ')';
                                      }
                                      locations.push(city);
                                    }
                                })
                            }
                            if (locations.length > 0) {
                                return h('span', locations.join(', '))
                            } else {
                                return h('span', this.$t('Not set'))
                            }
                        }
                    },
                    {
                        title: this.$t('Action'),
                        key: 'action',
                        align: 'right',
                        render: (h, params) => {
                            let color = '#959FB3';
                            let message = this.$t('Are you sure you want to broadcast this incident?')
                            if (params.row.broadcast) {
                                color = '#959FB3';
                                message = this.$t('Are you sure you want to stop broadcasting?')
                            }
                            if (!this.canEditTranslatableContent()) {
                            	return h('div', [
												h('Button', {
													props: {
														icon: 'md-information-circle',
														color: '#959FB3'
													},
													class: 'table-button',
													attrs: {
														title: this.getNonEditableLocaleMessageIfRelevant()
													}
												})])
							}
                            return h('div', [
                                h('Button', {
                                    props: {
                                        icon: 'md-create',
										color: '#959FB3'
                                    },
                                    attrs: {
                                        title: this.$t('Edit')
                                    },
                                    class: "table-button",
                                    on: {
                                        click: () => {
                                            this.triggerIncidentForm(true, params.row, params.index);
                                        }
                                    }
                                }),
                                h(PoptipBroadcast, {
                                    props: {
                                        id: params.row.id,
                                        val: !params.row.broadcast,
                                        message: message,
	                                    broadcast: params.row.broadcast,
	                                    color: color
                                    },
                                    class: 'table-button',
                                    attrs: {
                                        title: params.row.broadcast ? this.$t("Stop broadcast") : this.$t("Broadcast")
                                    },
	                                on: {
                                        broadcasting: (val) => {
                                            params.row.broadcast = val;
                                        }
	                                }
                                }),
                                h('Button', {
                                    props: {
                                        icon: 'md-trash',
                                    },
                                    attrs: {
                                        title: this.$t("Delete")
                                    },
                                    class: 'table-button',
                                    on: {
                                        click: () => {
                                            this.deleteIncidentQuery(params.row.id, params.index)
                                        }
                                    }
                                })
                            ])
                        }
                    },
                    {
                        type: 'expand',
                        width: 40,
                        render: (h, params) => {
                            return h(IncidentExpand, {
                                props: {
                                    row: params.row
                                }
                            })
                        }
                    }

                ],
				sortType: {key: null, order: null}
            }

        },
        created() {
            this.getIncidentQuery();
            this.getIncidentTypesQuery();
        },
        methods: {
            incidentCreated(data, update, idx) {
                if (update) {
                    this.incidents.results.splice(idx, 1, data)
                } else {
                    this.incidents.results.unshift(data);
                    this.incidents.count += 1;
                }

            },

            triggerIncidentForm(update, data, idx) {
                if (update) {
                    this.$refs['createIncident'].setUpdateData(data, idx);
                }
                this.showCreateIncident = true;
            },

            deleteIncidentQuery(id, idx) {
                this.$AppModal.AppConfirm({
					method: 'delete',
                    loading: true,
					extraTitle: this.$t('Delete an incident'),
					message: this.$t('You are about to delete this incident.'),
                    onConfirm: (() => {
                        deleteIncidentQry(id).then(() => {
                            this.$AppModal.remove();
                            this.incidents.results.splice(idx, 1);
                            this.incidents.count -= 1;
                            this.$Message.success(this.$t('Successfully deleted an incident'));
                        }).catch(() => {
                            this.$AppModal.remove();
                            this.$Message.error({content: this.$t('Error occurred on deleting this incident'), duration: 5})
                        });
                    })
                });
            },

            getIncidentQuery() {
                let company = this.userProfileStore.getSelectedCompany;
                const objParams = {company: company, expired: false, page_size: 1000, ...this.filterParams};
                getIncidentQry(objParams)
                    .then(res => {
						this.incidents = res.data;
                    })
            },
	        getIncidentTypesQuery() {
                getIncidentTypesQry()
                    .then(res => {
                        this.incidentTypes = res.data;
                        this.incidentTypes.sort((a, b) => a.name.localeCompare(b.name));  // Sort by name
                    })
                    .catch(err => {
                        this.$Message.error({content: this.$t('Error retrieving incident types'), duration: 5})
                    });
	        },
            triggerFilter(filters) {
                this.filterParams = filters;
                this.resetDataName(this, 'incidents');
                this.getIncidentQuery();
            },
			handleSortChange (data) {
				this.resetDataName(this, 'incidents');
				this.getIncidentQuery();
			},
			handleSort(key,order) {
				const typeValue = {"desc": "-", "asc": ""};
				this.filterParams['sort'] = `${typeValue[order]}${key}`;
			}
        }
    }
</script>

<style>
	.incident-page .incident-list-action {
		margin-right: 5px;
		margin-left: 5px;
	}

    .table .ivu-table-title {
        margin-bottom: 0px !important;
    }
    .ivu-table-cell-with-expand {
        height: 40px;
        line-height: 42px;
    }
</style>
