<template>
  <AppModal
    v-model="value"
    :header-name="$t('Delete user')"
    :footer-hide="false"
    @click:submit="submitForm"
    @click:cancel="cancel"
    width="450"
    class="delete-modal"
  >
    <Form ref="deleteForm" @submit.native.prevent="submitForm" :rules="nameRules" :model="nameInfos">
      <Row class="mt-3">
        <p class="mb-4">
          {{ $t("This action cannot be undone. It will permanently delete the user ") }}
          <strong> {{ full_name_or_id }}</strong>
        </p>
        <p>
          {{ $t("Please type ") }} <strong>{{ full_name_or_id }}</strong> {{ $t("to confirm") }}
        </p>
        <FormItem prop="repeat_full_name" :error="nameErrors.repeat_full_name">
          <Input type="text" v-model="nameInfos.repeat_full_name"></Input>
        </FormItem>
      </Row>
      <input type="submit" hidden />
      <Spin v-if="loading" fix />
    </Form>
  </AppModal>
</template>

<script>
import AppModal from "@/components/common/AppModal";
import { deleteUserQry } from "@/services/auth.service";

export default {
  name: "DeleteUser",

  components: {
    AppModal,
    deleteUserQry,
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    profile: {
      required: true,
      type: Object,
    },
  },
  data() {
    const validateCheck = (rule, value, callback) => {
      if (this.nameInfos.repeat_full_name.length == 0) {
        callback(new Error(this.$t("Please type the full name")));
      } else if (this.full_name_or_id != this.nameInfos.repeat_full_name) {
        callback(new Error(this.$t("Name not matched")));
        return;
      } else {
        callback();
      }
    };
    return {
      loading: false,
      disabled: true,
      nameInfos: {
        repeat_full_name: "",
      },
      nameRules: {
        repeat_full_name: [{ required: true, validator: validateCheck, trigger: "blur" }],
      },
      nameErrors: {
        repeat_full_name: "",
      },
    };
  },
  computed: {
    full_name_or_id() {
      return this.profile.full_name !== "" ? this.profile.full_name : this.profile.id;
    },
  },
  methods: {
    cancel() {
      this.loading = false;
      this.$refs['deleteForm'].resetFields()
      this.$emit("input", false);
    },
    submitForm() {
      this.$refs["deleteForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          deleteUserQry(this.profile.id, { status: this.profile.is_active })
            .then(() => {
              this.cancel();
              this.$Message.success(this.$t("Successfully deleted user"));
              this.$router.push({
                name: "users",
                params: {
                  id: this.profile.client,
                  contractId: this.profile.contracts[0],
                },
              });
            })
            .catch((err) => {
              this.loading = false;
              const error = err.response;
              if (error.status === 400) {
                this.resetDataName(this, "nameErrors");
                for (let col in error.data) {
                  this.nameErrors[col] = error.data[col][0];
                }
              } else {
                this.$Message.error({
                  content: this.$t("Error occurred deleting user"),
                  duration: 5,
                });
              }
            });
        }
      });
    },
  },
};
</script>
