<template>
  <!-- FIXME rename to Missions.vue -->
  <main>
    <Row v-if="!this.profile">
      <i-col :md="12" :xs="14">
        <p class="history-account-actual">{{ $t("Missions") }}</p>
      </i-col>
      <i-col :md="12" :xs="10" class="text-right" align="right">
        <button class="action-button" @click="showNewMissionModal">
          {{ $t("Create mission") }}
        </button>
        <button class="action-button" @click="exportMissions()">
          {{ $t("Export missions") }}
        </button>
      </i-col>
    </Row>

    <!----------- [START] MAIN TABS ----------->
    <Tabs type="card" v-if="!this.profile">
      <!----------- [START] MAIN TAB ----------->

      <TabPane :label="$t('Tracking table')" :name="$t('data')">
        <section>
          <!----------- [START] FILTERS  ----------->
          <Row class="flex-align-items-left flex-justify-content-left">
            <i-col class="mt-3">
              <!-- FILTER "SEARCH BY NAME" -->
              <i-col :md="4" :xs="12" class="mr-4">
                <p class="text-filter mb-3">{{ $t("Name") }}</p>
                <Input
                  search
                  type="text"
                  class="max-width-100"
                  v-model="moduleComponents.missionsFilters.searchByName"
                  clearable
                  :placeholder="$t('Search by name')"
                />
              </i-col>
              <!-- FILTER "STATUS" -->
              <i-col :md="4" :xs="12" class="mr-4">
                <p class="text-filter mb-3">{{ $t("Status") }}</p>
                <i-select
                  v-model="moduleComponents.missionsFilters.selectedPhase"
                  class="max-width-100"
                  :placeholder="$t('Status')"
                  @on-change="fetchMissions({ phase: [moduleComponents.missionsFilters.selectedPhase] })"
                  clearable
                  @on-clear="fetchMissions"
                  multiple
                >
                  <Option
                    v-for="item in moduleComponents.missionsFilters.phaseList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.display_name }}
                  </Option>
                </i-select>
              </i-col>
              <!-- FILTER "DESTINATION" -->
              <i-col :md="4" :xs="12" class="mr-4">
                <p class="text-filter mb-3">{{ $t("Country") }}</p>
                <i-select
                  filterable
                  :placeholder="$t('Destination')"
                  @on-change="fetchMissions({ country: [moduleComponents.missionsFilters.selectedCountry] })"
                  class="max-width-100"
                  v-model="moduleComponents.missionsFilters.selectedCountry"
                  @on-clear="fetchMissions"
                  multiple
                  clearable
                >
                  <i-option
                    v-for="country in moduleComponents.missionsFilters.countryList"
                    :key="country.value"
                    :value="country.value"
                    :label="$t(country.display_name)"
                  >
                    <img :src="country.flag" alt="Drapeau du pays" class="flag-image" />
                    <span class="ml-4">{{ $t(country.display_name) }}</span>
                  </i-option>
                </i-select>
              </i-col>
              <!-- FILTER "CONTINENT" -->
              <i-col :md="4" :xs="12" class="mr-4">
                <p class="text-filter mb-3">{{ $t("Continent") }}</p>
                <i-select
                  filterable
                  :placeholder="$t('Continent')"
                  @on-change="fetchMissions({ conitnent: [moduleComponents.missionsFilters.selectedContinent] })"
                  class="max-width-100"
                  v-model="moduleComponents.missionsFilters.selectedContinent"
                  @on-clear="fetchMissions"
                  multiple
                  clearable
                >
                  <i-Option
                    v-for="(continent, index) in moduleComponents.continents"
                    v-bind:value="continent[0]"
                    :key="index"
                  >
                    {{ continent[1] }}
                  </i-Option>
                </i-select>
              </i-col>
              <!-- FILTER "RISK" -->
              <i-col :md="4" :xs="12" class="mr-4">
                <p class="text-filter mb-3">{{ $t("Risk") }}</p>
                <i-select
                  v-model="moduleComponents.missionsFilters.selectedCountryRisk"
                  class="max-width-100"
                  :placeholder="$t('Risk')"
                  @on-change="fetchMissions({ global_risk: [moduleComponents.missionsFilters.selectedCountryRisk] })"
                  clearable
                  @on-clear="fetchMissions"
                  multiple
                >
                  <Option
                    v-for="item in moduleComponents.missionsFilters.countryRiskList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.display_name }}
                  </Option>
                </i-select>
              </i-col>
              <!-- FILTER "TYPE" -->
              <i-col :md="4" :xs="12" class="mr-4 mt-3">
                <p class="text-filter mb-3">{{ $t("Type") }}</p>
                <i-select
                  v-model="moduleComponents.missionsFilters.selectedType"
                  class="max-width-100"
                  :placeholder="$t('Type')"
                  clearable
                >
                  <Option v-for="item in moduleComponents.missionsFilters.typeList" :key="item.id" :value="item.value">
                    {{ item.display_name }}
                  </Option>
                </i-select>
              </i-col>
              <!-- FILTER "CLIENT" -->
              <i-col :md="4" :xs="12" class="mr-4 mt-3" v-if="isOperatorOrAbove()">
                <p class="text-filter mb-3">{{ $t("Client") }}</p>
                <i-select
                  v-model="moduleComponents.missionsFilters.selectedClient"
                  class="max-width-100"
                  :placeholder="$t('Client')"
                  filterable
                  clearable
                >
                  <Option v-for="item in moduleComponents.missionsFilters.clientList" :key="item.id" :value="item.id">
                    {{ item.client_name }}
                  </Option>
                </i-select>
              </i-col>
            </i-col>
          </Row>
          <!----------- [END] FILTERS  ----------->
          <transition appear name="slide-left-centered">
            <!----------- [START] TABLE ----------->
            <AppTable
              :columns="moduleComponents.missionsTable.headers"
              table-class="s2ec-table missions-table mt-5"
              :data-table="moduleComponents.missionsTable.data"
              :row-class-name="getMissionsTableRowClassName"
              ref="missionTable"
              @on-row-click="showMissionInfosModal"
              @on-sort-change="sortTableStyle"
              :no-data-text="$t('No data')"
              :loading="moduleComponents.missionsTable.isLoading"
            />
          </transition>

          <!----------- [END] TABLE ----------->
        </section>
      </TabPane>

      <!----------- [END] MAIN TAB ----------->
    </Tabs>
    <div v-else>
      <Row class="flex-align-items-left flex-justify-content-left">
        <i-col class="mt-3">
          <!-- FILTER "STATUS" -->
          <i-col :md="4" :xs="12" class="mr-4">
            <p class="text-filter mb-3">{{ $t("Status") }}</p>
            <i-select
              v-model="moduleComponents.missionsFilters.selectedPhase"
              class="max-width-100"
              :placeholder="$t('Status')"
              @on-change="fetchMissions({ phase: [moduleComponents.missionsFilters.selectedPhase] })"
              clearable
              @on-clear="fetchMissions"
              multiple
            >
              <Option v-for="item in moduleComponents.missionsFilters.phaseList" :key="item.value" :value="item.value">
                {{ item.display_name }}
              </Option>
            </i-select>
          </i-col>
          <!-- FILTER "CONTINENT" -->
          <i-col :md="4" :xs="12" class="mr-4">
            <p class="text-filter mb-3">{{ $t("Continent") }}</p>
            <i-select
              filterable
              :placeholder="$t('Continent')"
              @on-change="fetchMissions({ conitnent: [moduleComponents.missionsFilters.selectedContinent] })"
              class="max-width-100"
              v-model="moduleComponents.missionsFilters.selectedContinent"
              @on-clear="fetchMissions"
              multiple
              clearable
            >
              <i-Option
                v-for="(continent, index) in moduleComponents.continents"
                v-bind:value="continent[0]"
                :key="index"
              >
                {{ continent[1] }}
              </i-Option>
            </i-select>
          </i-col>
          <!-- FILTER "DESTINATION" -->
          <i-col :md="4" :xs="12" class="mr-4">
            <p class="text-filter mb-3">{{ $t("Country") }}</p>
            <i-select
              filterable
              :placeholder="$t('Destination')"
              @on-change="fetchMissions({ country: [moduleComponents.missionsFilters.selectedCountry] })"
              class="max-width-100"
              v-model="moduleComponents.missionsFilters.selectedCountry"
              @on-clear="fetchMissions"
              multiple
              clearable
            >
              <i-option
                v-for="country in moduleComponents.missionsFilters.countryList"
                :key="country.value"
                :value="country.value"
                :label="$t(country.display_name)"
              >
                <img :src="country.flag" alt="Drapeau du pays" class="flag-image" />
                <span class="ml-4">{{ $t(country.display_name) }}</span>
              </i-option>
            </i-select>
          </i-col>
          <!-- FILTER "RISK" -->
          <i-col :md="4" :xs="12" class="mr-4">
            <p class="text-filter mb-3">{{ $t("Risk") }}</p>
            <i-select
              v-model="moduleComponents.missionsFilters.selectedCountryRisk"
              class="max-width-100"
              :placeholder="$t('Risk')"
              @on-change="fetchMissions({ global_risk: [moduleComponents.missionsFilters.selectedCountryRisk] })"
              clearable
              @on-clear="fetchMissions"
              multiple
            >
              <Option
                v-for="item in moduleComponents.missionsFilters.countryRiskList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.display_name }}
              </Option>
            </i-select>
          </i-col>
          <!-- FILTER "TYPE" -->
          <i-col :md="4" :xs="12" class="mr-4">
            <p class="text-filter mb-3">{{ $t("Type") }}</p>
            <i-select
              v-model="moduleComponents.missionsFilters.selectedType"
              class="max-width-100"
              :placeholder="$t('Type')"
              clearable
            >
              <Option v-for="item in moduleComponents.missionsFilters.typeList" :key="item.id" :value="item.value">
                {{ item.display_name }}
              </Option>
            </i-select>
          </i-col>
        </i-col>
      </Row>
      <!----------- [END] FILTERS  ----------->
      <transition appear name="slide-left-centered">
        <!----------- [START] TABLE ----------->
        <AppTable
          :columns="moduleComponents.missionsTable.headers"
          table-class="s2ec-table missions-table mt-5"
          :data-table="moduleComponents.missionsTable.data"
          :row-class-name="getMissionsTableRowClassName"
          ref="missionTable"
          @on-row-click="showMissionInfosModal"
          @on-sort-change="sortTableStyle"
          :no-data-text="$t('No data')"
          :loading="moduleComponents.missionsTable.isLoading"
        />
      </transition>
    </div>

    <!----------- [END] MAIN TABS ----------->

    <!----------- [START] MODAL "TRAVEL INFOS" ----------->
    <EditMissionModal ref="editMissionModal" @missionCreated="missionsAltered" @missionUpdated="missionsAltered" />
    <!----------- [END] MODAL "TRAVEL INFOS" ----------->

    <!----------- [START] MODAL "TRAVEL INFOS" ----------->
    <MissionInfosModal ref="missionInfosModal" :mission="model.selectedMission" />
    <!----------- [END] MODAL "TRAVEL INFOS" ----------->
  </main>
</template>

<script>
/* IMPORTS
 * ------------------------------------------------------------------------- */
import EditMissionModal from "./modals/EditMissionModal";
import MissionInfosModal from "./modals/MissionInfosModal";
import { getMissionsQry, deleteMissionQry, exportMissionsQry } from "@/services/missionManagement.service";
import { getCountriesQry } from "@/services/common.service";
import { getAuthSession } from "@/helpers/auth.helper";
import DateFormat from "@/mixins/dateFormat";
import AppTable from "@/components/common/AppTable";
import { getClientsQry } from "@/services/accounts.service";

import moment from "moment";
export default {
  name: "MissionManagement",
  components: {
    EditMissionModal,
    MissionInfosModal,
    AppTable,
  },
  mixins: [DateFormat],
  props: ["profile"],
  data() {
    return {
      model: {
        missions: {
          results: [],
          realMissionExample: [
            // DUMMY EXAMPLE, IMMEDIATELY OVERRIDDEN
            {
              id: 9999,
              user: 374,
              user_value: {
                id: 374,
                is_active: true,
                email: "transient@test.com",
                first_name: "TestFirstName1",
                last_name: "TestLastName1",
                get_full_name: "TestFirstName1 TestLastName1",
                job_title: "",
                account_type: 1,
                avatar: null,
                cellphone: "+33766554433",
                landline: "+33144556677",
                registration_status: 1,
                registration_status_name: "Self Sign-up",
                account_type_value: "Traveler",
                on_alert: false,
                on_emergency: false,
                company: 9,
                company_name: "S2E Consulting",
              },
              mission_flight_bookings: [],
              destination_country: "FR",
              destination_country_name: "France",
              start_date: "2021-03-25T11:08:24.615356Z",
              end_date: "2021-03-30T11:08:24.615356Z",
              global_risk: 2,
              is_round_trip: false,
              created: "2021-03-15T11:08:30.846098Z",
              updated: "2021-03-15T11:08:30.846098Z",
            },
          ],
        },
        selectedMission: null,
      },
      moduleComponents: {
        countriesList: [],
        missionsFilters: {
          currentTab: "pending",
          countryList: [],
          typeList: [
            {
              id: 1,
              value: "EXPATRIATION",
              display_name: this.$t("Expatriation"),
            },
            {
              id: 2,
              value: "TRIP",
              display_name: this.$t("Trip"),
            },
          ],
          phaseList: [
            {
              id: 1,
              value: "pending",
              display_name: this.$t("Pending"),
            },
            {
              id: 2,
              value: "started",
              display_name: this.$t("Started"),
            },
            {
              id: 3,
              value: "finished",
              display_name: this.$t("Completed"),
            },
          ],
          countryRiskList: [
            // Do not display risk level 0!
            {
              value: 1,
              display_name: this.$t("Risk") + " 1",
            },
            {
              value: 2,
              display_name: this.$t("Risk") + " 2",
            },
            {
              value: 3,
              display_name: this.$t("Risk") + " 3",
            },
            {
              value: 4,
              display_name: this.$t("Risk") + " 4",
            },
            {
              value: 5,
              display_name: this.$t("Risk") + " 5",
            },
          ],
          clientList: [],
          searchByName: "",
          selectedClient: "",
          selectedCountry: [],
          selectedContinent: [],
          selectedType: [],
          selectedCountryRisk: [],
          selectedPhase: [],
        },
        missionsTable: {
          isLoading: false,
          data: [],
          headers: [],
        },
        continents: [
          ["AF", this.$t("Africa")],
          ["OC", this.$t("Australia/Oceania")],
          ["AS", this.$t("Asia")],
          ["EU", this.$t("Europe")],
          ["NA", this.$t("North America")],
          ["SA", this.$t("South America")],
        ], // AN/Antarctica ignored
      },
    };
  },
  computed: {
    missionFiltersHack() {
      return [
        this.moduleComponents.missionsFilters.searchByName,
        this.moduleComponents.missionsFilters.selectedCountry,
        this.moduleComponents.missionsFilters.selectedContinent,
        this.moduleComponents.missionsFilters.selectedCountryRisk,
        this.moduleComponents.missionsFilters.selectedType,
        this.moduleComponents.missionsFilters.selectedPhase,
        this.moduleComponents.missionsFilters.selectedClient,
      ];
    },
    lastNotification() {
      return this.notificationStore.lastNotification;
    },
  },
  watch: {
    missionFiltersHack() {
      this.refreshMissionsDisplay();
    },
    lastNotification(mission) {
      if (mission.hasOwnProperty("content_object")) {
        this.showModalMissionFromNotification(mission);
      }
    },
  },
  created() {
    this.lookupMissionsOptions();
    this.refreshMissionsDisplay();
    this.fetchClients();
  },
  mounted() {
    if (this.lastNotification.hasOwnProperty("content_object")) {
      this.showModalMissionFromNotification(this.lastNotification);
    }
  },
  methods: {
    fetchClients() {
      getClientsQry()
        .then((response) => {
          this.moduleComponents.missionsFilters.clientList = response.data.results;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred retrieving client"),
          });
        });
    },
    fetchMissions(filter_value) {
      this.moduleComponents.missionsTable.isLoading = true;
      getMissionsQry(filter_value)
        .then((res) => {
          this.model.missions.results = res.data.results;
        })
        .catch((exc) => {
          this.$Message.error({
            content: this.$t("Error retrieving missions"),
            duration: 5,
          });
        })
        .finally(() => {
          this.moduleComponents.missionsTable.isLoading = false;
        });
    },
    showModalMissionFromNotification(mission) {
      let missionId = mission.content_object.id;
      if (mission.content_object.hasOwnProperty("start_date")) {
        let intervalId = setInterval(() => {
          if (!this.moduleComponents.missionsTable.isLoading && this.model.missions.results.length > 0) {
            const missionSelected = this.model.missions.results.find((m) => m.id === missionId);
            clearInterval(intervalId);
            this.model.selectedMission = missionSelected;
            this.$refs.missionInfosModal.show();
            this.notificationStore._resetLastNotification();
          }
        }, 1000);
      }
    },
    getMissionStatus(startDate, endDate) {
      let now = moment();
      let start = moment(startDate, "DD/MM/YYYY");
      let end = moment(endDate, "DD/MM/YYYY");

      if (now.isBefore(start)) {
        return "pending";
      } else if (now.isBetween(start, end)) {
        return "started";
      } else if (now.isAfter(end)) {
        return "finished";
      }
    },
    lookupMissionsOptions() {
      getCountriesQry().then((res) => {
        this.moduleComponents.missionsFilters.countryList = res.data; // FIXME replace with worldInfoStore.countryList
      });
    },
    resetMissionsFilters() {
      // Can't use this.resetDataName() since these are nested values...
      let initialMissionsFiltersData = this.$options.data.apply(this).moduleComponents.missionsFilters;
      let defaultMissionsFilters = {
        searchByName: initialMissionsFiltersData.searchByName,
        selectedCountry: initialMissionsFiltersData.selectedCountry,
        selectedContinent: initialMissionsFiltersData.selectedContinent,
        selectedCountryRisk: initialMissionsFiltersData.selectedCountryRisk,
        selectedType: initialMissionsFiltersData.selectedType,
        selectedPhase: initialMissionsFiltersData.selectedPhase,
        selectedClient: initialMissionsFiltersData.selectedClient,
      };
      Object.assign(this.moduleComponents.missionsFilters, defaultMissionsFilters);
    },
    _doRefreshMissionsDisplay() {
      this.moduleComponents.missionsTable.isLoading = true;
      this.moduleComponents.missionsTable.data = [];

      this.model.missions.results = [];

      let filters = { page_size: 500 };
      this.$route.name == "profile" ? (filters["user"] = this.profile.id) : "";
      let missionsFilters = this.moduleComponents.missionsFilters;
      if (missionsFilters.selectedCountryRisk) filters["risk"] = missionsFilters.selectedCountryRisk;
      if (missionsFilters.selectedCountry) filters["destination_country"] = missionsFilters.selectedCountry;
      if (missionsFilters.selectedContinent) filters["continent"] = missionsFilters.selectedContinent;
      if (missionsFilters.searchByName) filters["user_search"] = missionsFilters.searchByName;
      if (missionsFilters.selectedType) filters["mission_type"] = missionsFilters.selectedType;
      if (missionsFilters.selectedPhase) filters["phase"] = missionsFilters.selectedPhase;
      if (missionsFilters.selectedClient) filters["client"] = missionsFilters.selectedClient;

      getMissionsQry(filters)
        .then((res) => {
          // BEWARE - this result can arrive whereas tab has already been switched...
          this.model.missions.results = [];
          this.model.missions.results = res.data.results;
          this.setMissionsTableModuleComponentData();
          this.moduleComponents.missionsTable.headers = this.getMissionsTableModuleComponentHeaders();
        })
        .catch((exc) => {
          this.$Message.error({
            content: this.$t("Error retrieving missions"),
            duration: 5,
          });
        })
        .finally(() => {
          this.moduleComponents.missionsTable.isLoading = false;
        });
    },
    refreshMissionsDisplay(skipDebounce) {
      if (skipDebounce) this._doRefreshMissionsDisplay();
      else this.lodash.debounce(this._doRefreshMissionsDisplay, 500)();
    },
    updateMission(rawMissionData, rowIndex) {
      this.$refs.editMissionModal.showForMissionEdition(rawMissionData);
    },
    deleteMission(id, rowIndex) {
      let title = this.$t("Delete mission");
      let message = this.$t("Are you sure you want to delete this mission?");
      let successMessage = this.$t("Mission successfully deleted");
      let errorMessage = this.$t("Error performing your action");

      this.$AppModal.AppConfirm({
        method: "delete",
        extraTitle: title,
        message: message,
        loading: true,
        cancelText: this.$t("Cancel"),
        onConfirm: () => {
          deleteMissionQry(id)
            .then(() => {
              this.moduleComponents.missionsTable.data.splice(rowIndex, 1);
              this.$Message.success(successMessage);
            })
            .catch((e) => {
              this.$Message.error({ content: errorMessage, duration: 5 });
            })
            .finally(() => {
              this.$AppModal.remove();
            });
        },
      });
    },
    getMissionsTableModuleComponentHeaders() {
      // FIXME why lazy loading?
      let headers = [
        {
          title: this.$t("Name"),
          key: "userName",
          sortable: true,
        },
        {
          title: this.$t("Country"),
          key: "destination",
          align: "right",
          sortable: true,
          render: (h, params) => {
            return h("div", {}, [h("p", {}, this.$t(params.row.destination))]);
          },
        },
        {
          title: this.$t("Risk"),
          key: "riskLevel",
          align: "left",
          sortable: true,
          render: (h, params) => {
            return h(
              "Tag",
              {
                props: {
                  color: this.getIViewTagComponentColorAttrValue(params.row.riskLevel),
                },
              },
              params.row.riskLevel != null ? params.row.riskLevel : "?"
            );
          },
        },
        {
          title: this.$t("Type"),
          key: "type",
          sortable: true,
          align: "left",
        },
        {
          title: this.$t("Start date"),
          key: "startDate",
          sortable: true,
          align: "right",
        },
        {
          title: this.$t("End date"),
          key: "endDate",
          sortable: true,
          align: "left",
        },
        // {
        //   title: this.$t("PNR"),
        //   key: "pnr",
        //   sortable: true,
        //   render: (h, params) => {
        //     return [
        //       h("Checkbox", {
        //         props: {
        //           value: params.row.pnr,
        //           disabled: true,
        //         },
        //       }),
        //     ];
        //   },
        // },
        {
          title: this.$t("Status"),
          render: (h, params) => {
            if (this.getMissionStatus(params.row.startDate, params.row.endDate) == "finished") {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      float: "left",
                      paddingLeft: "16px",
                      paddingTop: "6px",
                      paddingRight: "16px",
                      border: "none",
                      textAlign: "center",
                      width: "auto",
                      margin: "auto",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                      backgroundColor: "#808080",
                    },
                  },
                  this.$t("Expired")
                ),
              ]);
            } else if (this.getMissionStatus(params.row.startDate, params.row.endDate) == "pending") {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      float: "left",
                      paddingLeft: "16px",
                      paddingTop: "6px",
                      paddingRight: "16px",
                      border: "none",
                      textAlign: "center",
                      width: "auto",
                      margin: "auto",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                      backgroundColor: "#A66811",
                    },
                  },
                  this.$t("Planning")
                ),
              ]);
            } else if (this.getMissionStatus(params.row.startDate, params.row.endDate) == "started") {
              return h("div", {}, [
                h(
                  "p",
                  {
                    style: {
                      float: "left",
                      paddingLeft: "16px",
                      paddingTop: "6px",
                      paddingRight: "16px",
                      border: "none",
                      textAlign: "center",
                      width: "auto",
                      margin: "auto",
                      height: "30px",
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "5px",
                      backgroundColor: "#268044",
                    },
                  },
                  this.$t("In progress")
                ),
              ]);
            }
          },
        },
        {
          title: this.$route.name == "profile" ? " " : this.$t("Actions"),
          key: "actions",
          align: "right",
          render: (h, params) => {
            if(this.$route.name != "profile") {
              if (
                !(
                  this.s2ec.user.is_superuser ||
                  this.s2ec.user.is_admin ||
                  this.s2ec.user.is_manager ||
                  params.row.rawMissionData.user == getAuthSession().id
                )
              ) {
                return []; // No actions allowed on other users for unprivileged user
              }
            return [
              h("Button", {
                props: {
                  icon: "md-create",
                },
                attrs: {
                  title: this.$t("Edit"),
                },
                class: "table-button",
                on: {
                  click: (evt) => {
                    evt.stopPropagation(); // "click.stop" is not supported here
                    this.updateMission(params.row.rawMissionData, params.index);
                  },
                },
              }),
              h("Button", {
                props: {
                  icon: "md-trash",
                },
                attrs: {
                  title: this.$t("Delete"),
                },
                class: "table-button",
                on: {
                  click: (evt) => {
                    evt.stopPropagation(); // "click.stop" is not supported here
                    this.deleteMission(params.row.id, params.index);
                  },
                },
              }),
              ];
            }
          },
        },
      ];
      return headers;
    },
    getIViewTagComponentColorAttrValue(riskLevel) {
      // FIXME duplicated block
      let color = "black";
      switch (riskLevel) {
        case 0:
          color = "#25c285";
          break;
        case 1:
          color = "#25c285";
          break;
        case 2:
          color = "#25c285";
          break;
        case 3:
          color = "#fbb000";
          break;
        case 4:
          color = "#ff6123";
          break;
        case 5:
          color = "#ff6123";
          break;
      }
      return color;
    },
    getMissionsTableRowClassName() {
      return "missions-table__row";
    },
    setMissionsDataSource(tabName) {
      this.resetMissionsFilters();
      this.moduleComponents.missionsFilters.currentTab = tabName;
      this.refreshMissionsDisplay(true);
    },
    setMissionsTableModuleComponentData() {
      let missionsDataSource = this.model.missions.results;
      this.moduleComponents.missionsTable.data = [];

      missionsDataSource.forEach((missionData) => {
        this.moduleComponents.missionsTable.data.push({
          id: missionData.id,
          userName: missionData.user_value.full_name,
          destination: missionData.destination_country_name,
          riskLevel: missionData.global_risk,
          startDate: this.dateFormat(missionData.start_date),
          endDate: this.dateFormat(missionData.end_date),
          pnr: missionData.pnr_code ? true : false,
          type: missionData.mission_type_name,
          rawMissionData: missionData,
        });
      });
    },
    showMissionInfosModal(tableRow, tableRowIndex) {
      this.model.selectedMission = this.model.missions.results[tableRowIndex];

      this.$refs.missionInfosModal.show();
    },
    sortTableStyle(tableRow) {
      //do not work ToDo
      tableRow.column.className = "bold";
      tableRow.className = "bold";
    },
    showNewMissionModal() {
      this.$refs.editMissionModal.show();
    },
    missionsAltered() {
      this.refreshMissionsDisplay();
    },
    triggerSelectCountry(country) {
      this.$router.push({ name: "country-info-detail", params: { country } });
    },
    show(index) {
      this.$Modal.info({
        title: "User Info",
        content: "test",
      });
    },
    remove(index) {
      this.data6.splice(index, 1);
    },
    exportMissions() {
      exportMissionsQry()
        .then((res) => {
          console.log("exportMissionsQry returned", res);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.dataIsLoading = false;
          this.$Message.error({
            content: this.$t("Error retrieving data export"),
            duration: 5,
          });
        });
    },
  },
};
</script>

<style>
.ivu-checkbox-checked .ivu-checkbox-inner {
  background-color: var(--secondary-bleu) !important;
  border-color: var(--secondary-bleu) !important;
}

.ivu-checkbox-inner::after {
  border-color: var(--secondary-bleu) !important;
}

.ivu-tabs-tab .ivu-tabs-tab-active .ivu-tabs-tab-focused:hover {
  color: var(--secondary-bleu);
  background-color: unset;
}

.ivu-tabs.ivu-tabs-card > .ivu-tabs-bar > .ivu-tabs-nav-container .ivu-tabs-tab {
  background-color: unset;
  border: none;
}

.ivu-tabs-tab {
  padding-left: 0px !important;
}

.ivu-tabs-tab-focused {
  color: var(--secondary-bleu) !important;
}
.ivu-tabs.ivu-tabs-card > .ivu-tabs-nav-container .ivu-tabs-tab {
  background-color: unset;
  line-height: 55px;
  border: none;
  font-size: 14px;
  margin: auto;
}
</style>
