import Vue from "vue";
import i18n from "@/i18n/i18n";
import IncidentTypesTags from "../../../incidents/IncidentTypesTags";
import moment from "moment";

import { QueryFeatures } from "./QueryFeatures.js";
export { ClusterPopup };

class ClusterPopup {
  constructor(object) {
    this.viewer = object.viewer;
    this.map = this.viewer.map;
    this.details = object.details;
    this.type = object.type;
    this.lngLat = object.lngLat;
    this.popup = null;

    if (object.target) {
      this.target = object.target;
    }

    this.build();
  }

  build() {
    switch (this.type) {
      case "center":
        this.centerClusterPopup();

        break;

      case "segment":
        this.segmentClusterPopup();

        break;
    }
  }

  centerClusterPopup() {
    var self = this;

    var coordinates = this.lngLat;
    var description =
      "<div id='cluster-popup' style='height:" +
      (this.viewer.mapHeight / 2 - 70) +
      "px' ></div>";

    while (Math.abs(this.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += this.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    var markerHeight = 25;

    var popupOffsets = {
      top: [0, markerHeight],
      bottom: [0, -markerHeight],
    };

    this.popup = new this.viewer.maplibregl.Popup({ offset: popupOffsets });
    this.popup.setHTML(description);

    this.popup.setLngLat(coordinates);
    this.popup.addTo(this.map);

    var tableContainer = document.createElement("div");

    tableContainer.classList.add("table-container");

    tableContainer.style.maxHeight = this.viewer.mapHeight / 2 - 110 + "px";

    var tabsContainer = document.createElement("nav");
    var contentContainer = document.createElement("div");

    document.getElementById("cluster-popup").appendChild(tabsContainer);
    document.getElementById("cluster-popup").appendChild(contentContainer);
    contentContainer.appendChild(tableContainer);

    tabsContainer.classList.add("tabs-container");

    for (const key in this.details) {
      const element = this.details[key];

      var queryFeatures = new QueryFeatures().query({
        viewer: self.viewer,
        key: "id",
        operator: "==",
        values: element.ids,
        layerId: key,
      });

      queryFeatures.then(function (features) {
        var properties = [];
        var geometries = [];

        if (features[1] == "devices") {
          var dangerCount = 0;

          features[0].forEach((element) => {
            properties.push(element.properties);
            geometries.push(element.geometry);
            if (self.viewer.pulsingDeviceId.includes(element.properties.id)) {
              dangerCount++;
            }
          });

          if (properties.length > 0) {
            var title = document.createElement("h3");
            title.innerHTML =
              "<img src='" +
              self.viewer.apiLocation +
              "/images/" +
              features[1] +
              "-popup-header.svg'>" +
              i18n.t("Devices") +
              " <span class='feature-count'>" +
              geometries.length +
              "</span><span id='danger-count-table'></span>";
            var table = self.getTableFromJSON(
              features[1],
              properties,
              geometries,
              self.viewer.pulsingDeviceId
            );
            tabsContainer.appendChild(title);
            tableContainer.appendChild(table);
          }

          if (dangerCount != 0) {
            document.getElementById("danger-count-table").innerHTML =
              dangerCount;
          }
        } else {
          features[0].forEach((element) => {
            properties.push(element.properties);
            geometries.push(element.geometry);
          });

          if (properties.length > 0) {
            var title = document.createElement("h3");
            title.innerHTML =
              "<img src='" +
              self.viewer.apiLocation +
              "/images/" +
              features[1] +
              "-popup-header.svg'>" +
              i18n.t(
                features[1].charAt(0).toUpperCase() + features[1].slice(1)
              ) +
              " <span class='feature-count'>" +
              geometries.length +
              "</span>";
            var table = self.getTableFromJSON(
              features[1],
              properties,
              geometries,
              null
            );
            tabsContainer.appendChild(title);
            tableContainer.appendChild(table);
          }
        }

        if (properties.length > 0) {
          title.setAttribute("tab", features[1]);
          table.setAttribute("tab-content", features[1]);

          title.onclick = function (e) {
            for (
              let index = 0;
              index < document.querySelectorAll("[tab-content]").length;
              index++
            ) {
              const element = document.querySelectorAll("[tab-content]")[index];
              element.classList.remove("active");
            }
            for (
              let index = 0;
              index < document.querySelectorAll("[tab]").length;
              index++
            ) {
              const element = document.querySelectorAll("[tab]")[index];
              element.classList.remove("active");
              try {
                element.previousSibling.classList.remove("previous-active");
              } catch (error) {}
            }
            try {
              title.previousSibling.classList.toggle("previous-active");
            } catch (error) {}
            title.classList.toggle("active");
            table.classList.toggle("active");

            if (table.scrollHeight < self.viewer.mapHeight / 2 - 70) {
              document.getElementById("cluster-popup").style.height =
                table.scrollHeight + 30 + "px";
            } else {
              document.getElementById("cluster-popup").style.height =
                self.viewer.mapHeight / 2 - 70 + "px";
            }
          };
        }

        for (
          let index = 0;
          index < document.querySelectorAll("[tab-content]").length;
          index++
        ) {
          const element = document.querySelectorAll("[tab-content]")[index];
          element.classList.remove("active");
        }

        document.querySelectorAll("[tab-content]")[0].classList.add("active");

        for (
          let index = 0;
          index < document.querySelectorAll("[tab]").length;
          index++
        ) {
          const element = document.querySelectorAll("[tab]")[index];
          element.classList.remove("active");
        }
        document.querySelectorAll("[tab]")[0].classList.add("active");

        if (
          document.querySelectorAll("[tab-content]")[0].scrollHeight <
          self.viewer.mapHeight / 2 - 70
        ) {
          document.getElementById("cluster-popup").style.height =
            document.querySelectorAll("[tab-content]")[0].scrollHeight +
            30 +
            "px";
        }
      });
    }
  }

  segmentClusterPopup() {
    var self = this;

    var coordinates = this.lngLat;
    var description =
      "<div id='cluster-popup' style='height:" +
      (this.viewer.mapHeight / 2 - 70) +
      "px' ></div>";

    while (Math.abs(this.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += this.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    var markerHeight = 25;

    var popupOffsets = {
      top: [0, markerHeight],
      bottom: [0, -markerHeight],
    };

    this.popup = new this.viewer.maplibregl.Popup({ offset: popupOffsets });
    this.popup.setHTML(description);

    this.popup.setLngLat(coordinates);
    this.popup.addTo(this.map);

    var tableContainer = document.createElement("div");

    tableContainer.classList.add("table-container");
    tableContainer.style.maxHeight = this.viewer.mapHeight / 2 - 110 + "px";

    var tabsContainer = document.createElement("nav");
    var contentContainer = document.createElement("div");

    document.getElementById("cluster-popup").appendChild(tabsContainer);
    document.getElementById("cluster-popup").appendChild(contentContainer);
    contentContainer.appendChild(tableContainer);

    tabsContainer.classList.add("tabs-container");

    var queryFeatures = new QueryFeatures().query({
      viewer: self.viewer,
      key: "id",
      operator: "==",
      values: self.details[self.target].ids,
      layerId: self.target,
    });

    queryFeatures.then(function (features) {
      var properties = [];
      var geometries = [];

      var title = document.createElement("h3");

      if (features[1] == "devices") {
        var dangerCount = 0;

        features[0].forEach((element) => {
          properties.push(element.properties);
          geometries.push(element.geometry);
          if (self.viewer.pulsingDeviceId.includes(element.properties.id)) {
            dangerCount++;
          }
        });

        title.innerHTML =
          "<img src='" +
          self.viewer.apiLocation +
          "/images/" +
          features[1] +
          "-popup-header.svg'>" +
          i18n.t("Devices") +
          " <span class='feature-count'>" +
          geometries.length +
          "</span><span id='danger-count-table'></span>";

        if (properties.length > 0) {
          title.innerHTML =
            "<img src='" +
            self.viewer.apiLocation +
            "/images/" +
            features[1] +
            "-popup-header.svg'>" +
            i18n.t("Devices") +
            " <span class='feature-count'>" +
            geometries.length +
            "</span><span id='danger-count-table'>";
          var table = self.getTableFromJSON(
            features[1],
            properties,
            geometries,
            self.viewer.pulsingDeviceId
          );
        }

        tabsContainer.appendChild(title);
        tableContainer.appendChild(table);

        if (dangerCount != 0) {
          document.getElementById("danger-count-table").innerHTML = dangerCount;
        }
      } else {
        features[0].forEach((element) => {
          properties.push(element.properties);
          geometries.push(element.geometry);
        });

        var title = document.createElement("h3");
        title.innerHTML =
          "<img src='" +
          self.viewer.apiLocation +
          "/images/" +
          features[1] +
          "-popup-header.svg'>" +
          i18n.t(features[1].charAt(0).toUpperCase() + features[1].slice(1)) +
          " <span class='feature-count'>" +
          geometries.length +
          "</span>";

        var table = self.getTableFromJSON(
          features[1],
          properties,
          geometries,
          null
        );

        tabsContainer.appendChild(title);
        tableContainer.appendChild(table);
      }

      if (table.scrollHeight < self.viewer.mapHeight / 2 - 70) {
        document.getElementById("cluster-popup").style.height =
          table.scrollHeight + 60 + "px";
      }

      title.classList.add("active");
      table.classList.add("active");

      title.setAttribute("tab", features[1]);
      table.setAttribute("tab-content", features[1]);
    });
  }

  getTableFromJSON(title, json, geometries, pulseValue) {
    var self = this;
    var container = document.createElement("div");

    if (title == "devices") {
      var a = [];
      var b = [];
      var c = [];
      var aGeom = [];
      var bGeom = [];
      var cGeom = [];

      for (let index = 0; index < json.length; index++) {
        const element = json[index];
        const geometry = geometries[index];

        if (element["has_emergency"]) {
          a.push(element);
          aGeom.push(geometry);
        } else if (element["has_alert"]) {
          b.push(element);
          bGeom.push(geometry);
        } else {
          c.push(element);
          cGeom.push(geometry);
        }
      }

      var sortedJson = [].concat(a, b, c);
      var sortedGeometries = [].concat(aGeom, bGeom, cGeom);

      json = sortedJson;
      geometries = sortedGeometries;
    }

    for (let index = 0; index < json.length; index++) {
      const properties = json[index];

      var itemCard = document.createElement("div");
      var template;

      itemCard.classList.add("item-card");
      container.appendChild(itemCard);
      itemCard.setAttribute(
        "geometry-coordinates",
        JSON.stringify(geometries[index].coordinates)
      );
      itemCard.setAttribute("properties", JSON.stringify(properties));
      const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
        hour: "2-digit",
      };
      var latest_device_track = new moment(
        properties["latest_device_track.updated_at"]
      ).fromNow();

      switch (title) {
        case "sites":
          template = `
                    <div>
                        <img class="mini-ico" src="${this.viewer.apiLocation}/images/symbologie/popup/popup-${title}.svg"><span class="bold">${properties["client_name"]}</span>-<span>${properties["name"]}</span>
                    </div>
                    `;
          break;

        case "devices":
          if (self.viewer.pulsingDeviceId.includes(properties.id)) {
            itemCard.classList.add("pulsing-row");
          }
          if (properties["has_emergency"]) {
            var stateIcon = "device_on_emergency";
          } else if (properties["has_alert"]) {
            var stateIcon = "device_on_alert";
          } else {
            var stateIcon = "device_normal";
          }

          template = `
                    <div style="display:flex">
                      <div>
                        <div>
                            <span class="bold">${properties["user_value.full_name"]}</span>
                            <br>
                            <span>${properties["latest_device_track.country_name"]}</span> - <span>${properties["latest_device_track.city"]}</span>
                          </div>
                        <div>
                            <span>${properties["client_name"]}</span>
                        </div>
                      </div>
                      <div class="v-center" style="text-align: center; margin-left:auto;width:125px">
                        <div>
                          <img style="height:20px; margin: auto" src="${this.viewer.apiLocation}/images/symbologie/popup/${stateIcon}.svg">
                          <span style="font-size:x-small; display:block;text-transform: initial;">${latest_device_track}</span>
                        </div>
                      </div>
                    </div>

                    `;
          break;

        case "incidents":
          const incidentTypesValues = [];
          for (let i = 0; i < 3; i++) {
            if (properties.hasOwnProperty(`incident_types_values[${i}].id`)) {
              incidentTypesValues.push({
                id: properties[`incident_types_values[${i}].id`],
                name: properties[`incident_types_values[${i}].name`],
                hex_color: properties[`incident_types_values[${i}].hex_color`],
              });
            }
          }
          const incidentTypesTags = new Vue({
            methods: IncidentTypesTags.methods,
            render: (h) =>
              h(IncidentTypesTags, { props: { incidentTypesValues } }),
          });
          const incidentTagsHTML = incidentTypesTags.generateHTML();

          template = `
                    <div style="display:flex;color:black">
                        <div class="v-center">
                            <!--<img class="mini-ico" src="${this.viewer.apiLocation}/images/symbologie/popup/popup-${title}-${properties["incident_type_value.name"]}.svg">-->
                        </div>
                        <div>
                            <div class="bold"><span>${properties["incident_locations[0].country_name"]}</span> - <span>${properties["incident_date"]}</span>
                            ${incidentTagsHTML}
                            <div><span>${properties["short_description"]}</span></div>
                        </div>
                    </div>
                    `;

          break;

        case "poi":
          template = `
                    <div style="display:flex">
                        <div class="v-center">
                            <img class="mini-ico" src="${this.viewer.apiLocation}/images/symbologie/popup/popup-${title}-${properties["point_type"]}.svg">
                        </div>
                        <div>
                            <div><span class="bold">${properties["name"]}</span>
                            <br><span>${properties["point_type_value"]}</span></div>
                            <div><span>${properties["place_query_search"]}</span></div>
                        </div>
                    </div>
                    `;
          break;
      }

      itemCard.innerHTML = template;

      itemCard.onclick = function () {
        self.popup.remove();
        var properties = JSON.parse(this.getAttribute("properties"));
        var coordinates = [properties.lng, properties.lat];

        console.log("Calling addPopupToMap from ClusterPopup");
        self.viewer.featuresPopup.addPopupToMap(
          {
            properties: properties,
            geometries: {
              coordinates: coordinates,
            },
          },
          title,
          true
        );
      };
    }

    return container;
  }
}
