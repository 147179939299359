<template>
  <div class="arrowed-panel">
    <p
      class="arrowed-panel-label"
      :class="[headerClass, panel]"
    >
      {{ title }}
      <span
        v-if="showArrow"
        class="arrowed"
      />
    </p>
    <div class="arrowed-panel-content lavender-shadow">
      <slot />
    </div>
  </div>
</template>

<script>
    export default {
        name: "AppArrowedPanel",
        props: {
            title: {default: null},
            showArrow: {default: true},
            headerClass: {default: ""},
            panel: {default: 'default'}
        }
    }
</script>

<style>
    .arrowed-panel .arrowed-panel-label {
        height: 60px;
        padding: 16px;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
    }

    .arrowed-panel-content {
        border: solid 1px var(--border);
        border-radius: 5px;
        margin-top: 10px;
    }

    .arrowed {
        width: 10px;
        height: 60px;
        background: #ffffff;
        position: absolute;
        top: 0;
        right: -2px;
        z-index: 99;
    }

    .arrowed:after, .arrowed:before {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .arrowed:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: var(--risk-3);
        border-width: 30px;
        margin-top: -30px;
    }

    .arrowed:before {
        border-color: rgba(194, 225, 245, 0);
        border-left-color: #fff;
        border-width: 35px;
        margin-top: -35px;
    }


    .arrowed-panel .arrowed-panel-label.default,
    .arrowed-panel .arrowed-panel-label.default .arrowed {
        background: #5AAFF2;
    }

    .arrowed-panel .arrowed-panel-label.warning,
    .arrowed-panel .arrowed-panel-label.warning .arrowed {
        background: var(--risk-3);
    }

    .arrowed-panel .arrowed-panel-label.success,
    .arrowed-panel .arrowed-panel-label.success .arrowed {
        background: var(--risk-5);
    }

    .arrowed-panel .arrowed-panel-label.danger,
    .arrowed-panel .arrowed-panel-label.danger .arrowed {
        background: #CE464A;
    }

    .arrowed-panel-label.default .arrowed:after {
        border-left-color: #5AAFF2;
    }

    .arrowed-panel-label.warning .arrowed:after {
        border-left-color: var(--risk-3);
    }

    .arrowed-panel-label.success .arrowed:after {
        border-left-color: var(--risk-5);
    }


    .arrowed-panel-label.danger .arrowed:after {
        border-left-color: #CE464A;
    }





</style>
