<template>
  <div class="incident-expand-content">
    <Input
      v-if="row.long_description"
      :id="row.id"
      v-model="row.long_description"
      type="textarea"
      class="full-width"
      :autosize="{minRows:4,
                  maxRows:4}"
      :row="4"
      readonly
    />
    <p v-else>
      {{ $t('No long description') }}
    </p>
  </div>
</template>

<script>
    export default {
        name: "IncidentExpand",
        props: ['row'],

        mounted() {
            let textArea = document.getElementById(this.row.id);
            textArea.children[0].classList.add('textAreaCustom');
        }
    }
</script>

<style>
    .incident-expand-content {
        padding: 20px 50px;
    }

    .textAreaCustom {
        text-align: justify;
        width: 100%;
        line-height: 1.2;
        background-color: rgba(201, 201, 201, 0.07);
    }
</style>
