<template>
  <div>
    <AppModal
      v-model="value"
      :width="400"
      class-name="emergency-popup"
    >
      <Icon
        type="md-close"
        style="float: right"
        title="decline"
        size="14"
        class="pointer-cursor action-icon decline"
        @click.native="triggerClose"
      />

      <div class="text-center">
        <p class="emergency-note text-center">
          <img src="@/assets/images/ico/emergency.png"><br>
          {{ $t('Emergency call') }}
        </p>
      </div>

      <div class="emergency-container">
        <div v-for="emergency in emergencies">
          <p class="emergency-text text-center">
            {{ emergency.description }} <!-- FIXME this field doesn't exist ?? -->
          </p>

          <Row
            v-if="hasMapLocation(emergency)"
            type="flex"
            justify="center"
            :gutter="15"
            class="emergency-update-action"
          >
            <i-col :span="18">
              <Button
                class="confirm-button"
                long
                @click="goToMapLocation(emergency)"
              >
                {{ $t('Locate') }}
              </Button>
            </i-col>
          </Row>
          <br>
        </div>
      </div>
    </AppModal>
  </div>
</template>

<script>
    import AppModal from "@/components/common/AppModal"

    export default {
        name: "EmergencyPopup",
        components: {
            AppModal
        },
        props: ['value', 'emergencies'],
        data() {
            return {
            }
        },
        mounted() {
        },
        methods: {
            triggerClose() {
                this.$emit('input', false);
            },
            hasMapLocation(emergency) {
                return (emergency.content_object && emergency.content_object.model_name	== "emergency")
            },
            goToMapLocation(emergency) {
                let str = emergency.content_object.latitude + ',' + emergency.content_object.longitude + ',' + 12;
                this.$router.push({name: 'map', query: {location: str}});
                this.triggerClose()
            },
        }
    }
</script>

<style>

    .emergency-popup .emergency-note {
        font-weight: 500;
        font-size: 18px;
        padding-top: 30px;
        padding-bottom: 10px;
    }

    .emergency-popup .emergency-text {
        margin-bottom: 15px;
    }

    .emergency-popup .emergency-container {
        overflow-y: auto;
        max-height: 400px;
        padding-right: 15px;
    }

</style>
