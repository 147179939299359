<template>
  <!-- FIXME MOVE THIS TO COMPONENTS -->
  <!----------- [START] MODAL "TRAVEL INFOS" ----------->
  <AppModal
    v-model="modalIsVisible"
    :header-name="$t(isUpdateMode() ? 'Modify Mission' : 'Create Mission')"
    :width="600"
    class-name="edit-mission-modal"
    @on-cancel="reset"
    :footer-hide="false"
    @click="hide"
    :particular-footer="true"
  >
    <!----------- [START] MODAL BODY ----------->
    <div class="d-flex flex-grow-1 flex-column">
      <Spin v-if="dataIsLoading" fix>
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load" />
      </Spin>
      <!----------- [START] WIZARD STEP VISUALS ----------->
      <div class="wizard my-3">
        <div class="wizard__step-visuals px-5">
          <div
            v-for="(wizardStepData, wizardStepIndex) in wizardConf.steps"
            :key="wizardStepIndex"
            :class="[
              'wizard__step-visual',
              {
                'wizard__step-visual--current': wizardStepIndex == wizardConf.currentStepIndex,
              },
              {
                'wizard__step-visual--previous': wizardStepIndex < wizardConf.currentStepIndex,
              },
            ]"
          >
            {{ wizardStepData.visual }}
          </div>
        </div>
      </div>
      <!----------- [END] WIZARD STEP VISUALS ----------->
      <hr class="divider divider-wizard" />

      <div class="flex-grow-1 overflow-x-hidden px-5">
        <!----------- [START] "FLIGHTS" WIZARD STEP CONTENT ----------->
        <div v-if="isActiveWizardStep('mission_type')" class="d-flex flex-column wizard-body">
          <div class="d-flex flex-column flex-justify-content-center">
            <Row class="d-flex mt-5 flex-column">
              <h4>{{ $t("Select mission") }}</h4>
              <RadioGroup v-model="currentMissionData.mission_type" class="d-flex flex-column my-3">
                <Radio label="TRIP" class="my-3">
                  {{ $t("Trip") }}
                </Radio>
                <Radio label="EXPATRIATION" class="my-3">
                  {{ $t("Expatriation") }}
                </Radio>
              </RadioGroup>
            </Row>

            <Row class="mt-3">
              <h4>{{ $t("Select creation method") }}</h4>
              <RadioGroup v-model="currentMissionData.use_pnr_code" class="d-flex flex-column my-3">
                <Radio :label="0" class="my-3">
                  {{ $t("Manual") }}
                </Radio>
                <Radio :label="1" class="my-3">
                  {{ $t("Search booking record (PNR)") }}
                </Radio>
              </RadioGroup>
            </Row>

            <Row class="mt-3">
              <h4>{{ $t("Add mission date") }}</h4>
              <row class="d-flex flex-align-items-center my-3">
                <i-col span="3" class="d-flex flex-align-items-center">
                  <span class="col-3"> {{ $t("Start") }} : </span>
                </i-col>
                <i-col span="7" class="d-flex flex-align-items-center">
                  <AppDatePicker
                    v-model="currentMissionData.start_date"
                    class="mt-4"
                    :transfer="true"
                    type="date"
                    :placeholder="$t('Mission start date')"
                  />
                </i-col>
              </row>

              <row class="d-flex flex-align-items-center my-3">
                <i-col span="3" class="d-flex flex-align-items-center">
                  <span> {{ $t("End") }} : </span>
                </i-col>
                <i-col span="7" class="d-flex flex-align-items-center">
                  <AppDatePicker
                    v-model="currentMissionData.end_date"
                    class="mt-4"
                    :transfer="true"
                    type="date"
                    :placeholder="$t('Mission end date')"
                  />
                </i-col>
              </row>
            </Row>
          </div>
        </div>
        <!----------- [END] "FLIGHTS" WIZARD STEP CONTENT ----------->

        <div v-if="isActiveWizardStep('mission_users')" class="d-flex flex-column wizard-body">
          <div class="d-flex mt-5 flex-column">
            <Row class="mb-4">
              <i-col span="18">
                <h4>{{ $t("Enter PNR number") }}</h4>
                <i-input
                  class="mt-3"
                  v-model="currentMissionData.pnr_code"
                  :disabled="!currentMissionData.use_pnr_code"
                />
              </i-col>
            </Row>

            <Row class="my-4" v-if="!isUpdateMode() && travelerList">
              <i-col span="18">
                <h4>{{ $t("Enter concerned person") }}</h4>
                <Select
                  v-model="currentMissionData.selectedTravelerList"
                  class="mt-3"
                  filterable
                  multiple
                  :placeholder="$t('Select one or several travelers')"
                >
                  <Option v-for="item in travelerList" :key="item.id" :value="item.id">
                    {{ item.label }}
                  </Option>
                </Select>
              </i-col>
            </Row>

            <Row class="my-4">
              <i-col span="18">
                <h4>{{ $t("Enter destination") }}</h4>
                <Select
                  v-model="currentMissionData.destination_country"
                  class="mt-3"
                  :placeholder="$t('Country')"
                  filterable
                >
                  <Option v-for="country in worldInfoStore.countryList" :key="country.value" :value="country.value">
                    {{ country.display_name }}
                  </Option>
                </Select>
              </i-col>
            </Row>
          </div>
        </div>

        <div v-if="isActiveWizardStep('mission_options')" class="d-flex flex-column wizard-body">
          <div class="d-flex mt-5 flex-column">
            <Row class="mb-4">
              <i-col span="18">
                <h4>{{ $t("How are you welcomed") }}</h4>
                <Select
                  v-model="currentMissionData.reception_upon_arrival"
                  class="mt-3 max-width-100"
                  :placeholder="$t('Choose a reception option')"
                  clearable
                >
                  <Option
                    v-for="item in vueRootData.missionCreationOptions.reception_upon_arrival.choices"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.display_name }}
                  </Option>
                </Select>
              </i-col>
            </Row>

            <Row class="my-4">
              <i-col span="18">
                <h4>{{ $t("Have you planned accomodation") }}</h4>
                <Select
                  v-model="currentMissionData.accommodation"
                  class="mt-3 max-width-100"
                  :placeholder="$t('Choose an accommodation option')"
                  clearable
                >
                  <Option
                    v-for="item in vueRootData.missionCreationOptions.accommodation.choices"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.display_name }}
                  </Option>
                </Select>
              </i-col>
            </Row>

            <Row class="my-4">
              <i-col span="18">
                <h4>{{ $t("How do you plan to move around") }}</h4>
                <Select
                  v-model="currentMissionData.vehicle"
                  class="mt-3 max-width-100"
                  :placeholder="$t('Choose a transport option')"
                  clearable
                  placement="top"
                >
                  <Option
                    v-for="item in vueRootData.missionCreationOptions.vehicle.choices"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.display_name }}
                  </Option>
                </Select>
              </i-col>
            </Row>
          </div>
        </div>

        <!----------- [START] "FLIGHTS" WIZARD STEP CONTENT ----------->
        <div v-if="isActiveWizardStep('mission_flights')" class="d-flex flex-column wizard-body">
          <div
            v-for="(flightBookingData, flightBookingIndex) in currentMissionData.mission_flight_bookings"
            v-bind:key="flightBookingIndex"
            class="d-flex mt-5 flex-column"
          >
            <Row type="flex" justify="space-between" class="mb-2">
              <h3>
                {{ $t("Flight") + " " + (flightBookingIndex + 1) }}
              </h3>
            </Row>
            <Row
              v-if="flightBookingData.flight_number && !modifyFlight[flightBookingIndex]"
              type="flex"
              align="middle"
              justify="space-between"
              class="mb-2"
            >
              <i-col span="22">
                <span class="previously-selected-flight">
                  {{ flightBookingData.arrival_airport_value.name }}
                  - {{ flightBookingData.departure_airport_value.name }} - {{ flightBookingData.flight_number }} -
                  {{ fullDateFormat(flightBookingData.scheduled_departure_time_local) }}
                </span>
              </i-col>
              <i-col span="1">
                <Button class="little-button little-button-action" @click="modifyFlightBooking(flightBookingIndex)">
                  <Icon type="md-create" :title="$t('Edit')" />
                </Button>
              </i-col>
            </Row>
            <div v-if="!flightBookingData.flight_number || modifyFlight[flightBookingIndex]">
              <div v-if="isUpdateMode()">
                <p class="ml-3 mt-3">{{ $t("Previous") }} :</p>
                <span class="previously-selected-flight ml-3 mt-3">
                  {{ flightBookingData.arrival_airport_value.name }}
                  - {{ flightBookingData.departure_airport_value.name }} - {{ flightBookingData.flight_number }} -
                  {{ fullDateFormat(flightBookingData.scheduled_departure_time_local) }}
                </span>
              </div>
              <Row class="mb-2 d-flex flex-justify-content-space-between mt-3">
                <i-col span="11">
                  <Select
                    v-model="flightBookingData.departureAirport"
                    class="max-width-100"
                    filterable
                    :placeholder="$t('Departure airport')"
                    clearable
                    :remote-method="airportStore._loadAiports"
                    @on-change="searchScheduledFlights(flightBookingData)"
                  >
                    <Option
                      v-for="(airport, airportIndex) in airportStore.getAirports"
                      :key="airportIndex"
                      :value="JSON.stringify(airport)"
                    >
                      {{ airport.name }} - {{ airport.country_name }}
                    </Option>
                  </Select>
                  <Select
                    v-model="flightBookingData.arrivalAirport"
                    class="mt-3 max-width-100"
                    filterable
                    :placeholder="$t('Arrival Airport')"
                    clearable
                    :remote-method="airportStore._loadAiports"
                    @on-change="searchScheduledFlights(flightBookingData)"
                  >
                    <Option
                      v-for="(airport, airportIndex) in airportStore.getAirports"
                      :key="airportIndex"
                      :value="JSON.stringify(airport)"
                    >
                      {{ airport.name }} - {{ airport.country_name }}
                    </Option>
                  </Select>

                  <AppDatePicker
                    v-model="flightBookingData.selectedFlightDate"
                    class="mt-3 max-width-100"
                    :transfer="true"
                    type="date"
                    :placeholder="$t('Flight date')"
                    @input="searchScheduledFlights(flightBookingData, flightBookingIndex)"
                  />
                </i-col>
                <i-col span="11">
                  <Select
                    v-model="flightBookingData.selectedFlightIndex"
                    class="max-width-100"
                    filterable
                    :placeholder="$t('Choose a flight')"
                    :disabled="!flightBookingData.selectableFlightsEnable"
                  >
                    <Option
                      v-for="(flightData, flightIndex) in flightBookingData.selectableFlights"
                      :key="flightIndex"
                      :value="flightIndex"
                    >
                      Flight {{ flightData.full_flight_number }} - {{ flightData.departure.passengerLocalTime }} local
                    </Option>
                  </Select>
                </i-col>
                <i-col span="1">
                  <Button class="little-button little-button-red" @click="removeFlightBookingForm(flightBookingIndex)">
                    <Icon type="md-trash" />
                  </Button>
                </i-col>
              </Row>
            </div>
          </div>
          <div class="d-flex mt-5">
            <Button class="action-button" @click="addFlightBookingForm" size="large">
              <Icon type="md-add" /> {{ $t("Add flight") }}
            </Button>
          </div>
        </div>
        <!----------- [END] "FLIGHTS" WIZARD STEP CONTENT ----------->
      </div>
    </div>
    <!----------- [END] MODAL BODY ----------->

    <!----------- [START] MODAL FOOTER ----------->
    <div slot="footer" class="d-flex flex-justify-content-space-between flex-align-items-center">
      <div>
        <Button
          class="back-button"
          :disabled="isCurrentWizardStepTheFirstOne()"
          size="large"
          @click="goToPreviousWizardStep"
        >
          {{ $t("Previous") }}
        </Button>
        <Button class="confirm-button" size="large" @click="goToNextWizardStep">
          {{ isCurrentWizardStepTheLastOne() ? $t("Validate") : $t("Next") }}
        </Button>
      </div>
      <Button class="cancel-button" @click="hide" size="large">
        {{ $t("Cancel") }}
      </Button>
    </div>
    <!----------- [END] MODAL FOOTER ----------->
  </AppModal>
  <!----------- [END] MODAL "TRAVEL INFOS" ----------->
</template>
<script>
import AppModal from "@/components/common/AppModal";
import { getUsers } from "@/services/accounts.service.js";
import {
  optionsMissionsQry,
  postMissionQry,
  putMissionQry,
  searchFlightsQry,
} from "@/services/missionManagement.service";
import AppDatePicker from "@/components/common/AppDatePicker";
import moment from "moment";
import axios from "axios";
import DateFormat from "@/mixins/dateFormat";
import { useAirportStore } from "@/stores/airport";

export default {
  name: "EditMissionModal",
  components: {
    AppDatePicker,
    AppModal,
    useAirportStore,
  },
  mixins: [DateFormat],
  props: [],
  data() {
    let data = {
      modalIsVisible: false,
      dataIsLoading: false,
      modifyFlight: [],
      travelerList: null, // Remains null for non-privileged users, else if it's an Array it MUST be filled!
      wizardConf: {
        currentStepIndex: 0,
        steps: [
          {
            id: "mission_type",
            visual: "1",
          },
          {
            id: "mission_users",
            visual: "2",
          },
          {
            id: "mission_options",
            visual: "3",
          },
          {
            id: "mission_flights",
            visual: "4",
          },
        ],
      },
    };
    Object.assign(data, this.initialMissionState());
    return data;
  },
  computed: {
    vueRootData() {
      return this.$root.$data; // BIG HACK
    },
  },
  created() {
    const airportStore = useAirportStore();

    if (this.s2ec.user.is_superuser || this.s2ec.user.is_admin || this.s2ec.user.is_manager) {
      this.travelerList = []; // SIGNALS that we want users to be selected!
      getUsers({ page_size: 500 })
        .then((res) => {
          res.data.results.forEach((value) => {
            this.travelerList.push({
              label: value.email,
              id: value.id,
            });
          });
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error retrieving users"),
            duration: 5,
          });
        });
    }
    if (!this.$root.$data.missionCreationOptions) {
      optionsMissionsQry()
        .then((res) => {
          this.$root.$data.missionCreationOptions = res.data.actions.POST;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error retrieving mission creation options"),
            duration: 5,
          });
        });
    }
  },
  methods: {
    initialMissionState() {
      return {
        currentMissionData: {
          id: null, // Not null if we're in an "edit existing mission" case

          use_pnr_code: null, // Will be 0 or 1 due to <Radio> limitation
          pnr_code: "",

          mission_type: "", // TRIP or EXPATRIATION
          start_date: null,
          end_date: null,

          selectedTravelerList: [], // Ignored when UPDATING an existing Mission
          destination_country: "",

          // These are integer fields in DB, they get forced to "undefined" by select form
          reception_upon_arrival: null,
          accommodation: null,
          vehicle: null,

          mission_flight_bookings: [
            {
              flight_number: "",
              scheduled_departure_time_local: "",
              scheduled_arrival_time_local: "",

              // TEMP work data!
              departureAirport: [],
              arrivalAirport: [],
              selectedFlightDate: "", // Input for flight search
              selectableFlightsEnable: false, // Enable or not the listing of available flights
              selectableFlights: [], // Data returned by flight schedule provider
              selectedFlightIndex: null, // Index of wanted flight in selectableFlights
            },
          ],
        },
      };
    },
    isUpdateMode() {
      return this.currentMissionData.id !== null;
    },

    showForMissionEdition(missionData) {
      missionData = JSON.parse(JSON.stringify(missionData)); // CLONE tree
      missionData.use_pnr_code = missionData.pnr_code ? 1 : 0;

      for (const mission_flight_booking of missionData.mission_flight_bookings) {
        Object.assign(mission_flight_booking, {
          // FIXME CODE DUPLICATION WITH initialMissionState()
          departureAirportIdx: null,
          arrivalAirportIdx: null,
          selectedFlightDate: "",
          selectableFlightsEnable: false,
          selectableFlights: [],
          selectedFlightIndex: null,
        });
        /* Now prefill flight forms from current values */

        var _selectedFlightDate = moment.utc(mission_flight_booking.scheduled_departure_time_local);
        if (_selectedFlightDate.isValid()) mission_flight_booking.selectedFlightDate = _selectedFlightDate.toDate(); // utc().format("YYY-MM-DD");
      }

      this.currentMissionData = missionData; // FULL replacement of data

      this.modifyFlight = [];
      this.currentMissionData.mission_flight_bookings.forEach((mission, index) => {
        this.modifyFlight[index] = false;
      });
      this.show();
    },
    addFlightBookingForm() {
      let newFlightBookingData = this.initialMissionState().currentMissionData.mission_flight_bookings[0];
      this.currentMissionData.mission_flight_bookings.push(newFlightBookingData);
      this.modifyFlight.push(true);
    },
    removeFlightBookingForm(flightBookingIndex) {
      this.currentMissionData.mission_flight_bookings.splice(flightBookingIndex, 1);
      this.modifyFlight.splice(flightBookingIndex, 1);
    },
    modifyFlightBooking(flightBookingIndex) {
      this.currentMissionData.mission_flight_bookings[flightBookingIndex].selectedFlightDate = null;
      this.modifyFlight.splice(flightBookingIndex, 1, true);
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    searchScheduledFlights(flightBookingData, flightBookingIndex) {
      let { selectedFlightDate } = flightBookingData;

      if (!selectedFlightDate) {
        // null or undefined here, but not 0!
        flightBookingData.selectableFlightsEnable = false;
        flightBookingData.selectableFlights = []; // RESET
        flightBookingData.selectedFlightIndex = null; // RESET
        return;
      }

      let flightSearchParams = {
        departure_airport_iata: JSON.parse(
          this.currentMissionData.mission_flight_bookings[flightBookingIndex].departureAirport
        ).iata_code,
        arrival_airport_iata: JSON.parse(
          this.currentMissionData.mission_flight_bookings[flightBookingIndex].arrivalAirport
        ).iata_code,
        departure_date: selectedFlightDate,
      };
      this.dataIsLoading = true;

      searchFlightsQry(flightSearchParams)
        .then((res) => {
          this.dataIsLoading = false;
          // FIXME THIS GETS CALLED ON TAB RELOAD TOO!!
          flightBookingData.selectableFlightsEnable = true;
          flightBookingData.selectableFlights = res.data;
          if (flightBookingData.selectedFlightIndex >= flightBookingData.selectableFlights.length) {
            flightBookingData.selectedFlightIndex = null; // RESET
          }
        })
        .catch(() => {
          this.dataIsLoading = false;
          this.$Message.error({
            content: this.$t("Error retrieving available flights"),
            duration: 5,
          });
        });
    },
    getWizardStepIndexFromIdentifier(id) {
      for (const [stepKey, stepData] of this.wizardConf.steps.entries()) if (stepData.id == id) return stepKey;
    },
    validateCurrentPageForm() {
      let currentStepIndex = this.wizardConf.currentStepIndex;
      let errors;

      switch (currentStepIndex) {
        case 0:
          errors = this.checkFormPageOne();
          break;
        case 1:
          errors = this.checkFormPageTwo();
          break;
        case 2:
          errors = this.checkFormPageThree();
          break;
        case 3:
          errors = this.checkFormPageFour();
          break;
        default:
          console.warn("Abnormal currentStepIndex in validateCurrentPageForm:", currentStepIndex);
      }
      return errors;
    },

    goToNextWizardStep() {
      let errorMsg = this.validateCurrentPageForm();
      if (errorMsg) {
        this.displayFormError(errorMsg);
        return;
      }

      if (this.isCurrentWizardStepTheLastOne()) {
        this.attemptMissionFormSubmission();
      } else {
        this.wizardConf.currentStepIndex++;
      }
    },
    goToPreviousWizardStep() {
      if (!this.isCurrentWizardStepTheFirstOne()) {
        this.wizardConf.currentStepIndex--;
      }
    },
    isActiveWizardStep(stepIdentifier) {
      return this.wizardConf.currentStepIndex == this.getWizardStepIndexFromIdentifier(stepIdentifier);
    },
    isCurrentWizardStepTheFirstOne() {
      return !this.wizardConf.currentStepIndex;
    },
    isCurrentWizardStepTheLastOne() {
      return this.wizardConf.currentStepIndex == this.wizardConf.steps.length - 1;
    },
    getDefaultMissingInputErrorMessage() {
      return this.$t("Please fill all the fields");
    },
    checkFormPageOne() {
      let data = this.currentMissionData;
      if (!data.mission_type || data.use_pnr_code === null || !data.start_date || !data.end_date) {
        return this.getDefaultMissingInputErrorMessage();
      }

      let startDate = moment(data.start_date);
      let endDate = moment(data.end_date);
      if (!endDate.isAfter(startDate)) {
        return this.$t("End date must be after start date");
      }

      if (!data.use_pnr_code) data.pnr_code = ""; // Delete a possibly existing/updated code
    },
    checkFormPageTwo() {
      /* Returns an error message if problems */
      let data = this.currentMissionData;
      if (
        !data.destination_country ||
        (!this.isUpdateMode() &&
          this.travelerList && // Falsy value if no user selection must occur
          !data.selectedTravelerList.length)
      ) {
        return this.getDefaultMissingInputErrorMessage();
      }

      if (data.use_pnr_code) {
        if (!data.pnr_code) return this.getDefaultMissingInputErrorMessage();
        else return this.$t("PNR code not recognized");
      }
    },
    checkFormPageThree() {
      // Nothing to check, all options in this page can be left blank!
    },
    checkFormPageFour() {
      /* Returns an error message if problems */
      let data = this.currentMissionData;
      /* NOPE DANGEROUS - what if we edit past flights ??
      // FIXME it'd be even better to prevent the OAG search request if the date is in the past...
      for (const mission_flight_booking of data.mission_flight_bookings) {
        if (!mission_flight_booking.selectedFlightDate) continue;
        let flightDate = moment(mission_flight_booking.selectedFlightDate);
        if (flightDate.isBefore(undefined, "day")) {
          return this.$t(
            "Flight date can't be in the past"
          );
        }
      }*/
      /* NOPE now we can have NO FLIGHTS AT ALL
      if (
        !data.mission_flight_bookings[0].flight_number &&
        data.mission_flight_bookings[0].selectedFlightIndex == null
      ) {
        return this.$t("Please select at least one flight");  // FIXME remove this constraint?
      }*/
      for (const mission_flight_booking of data.mission_flight_bookings) {
        if (
          mission_flight_booking.selectedFlightIndex == null && // This is set when we CREATE a flight
          !mission_flight_booking.flight_number // This is set when we VIEW a saved flight
        ) {
          return this.$t("Unfinished flight selections, please complete or remove them");
        }
      }
    },

    displayFormError(msg) {
      this.$Message.error({
        content: msg,
        duration: 5,
      });
    },
    attemptMissionFormSubmission() {
      let data = this.currentMissionData;

      // BY CONSTRUCTION, here all the data must be properly filled! //

      // From "YYYY-MM-DD" - we use UTC as fake "local timezone"
      let momentStartDate = moment.utc(data.start_date);
      let momentEndDate = moment.utc(data.end_date);

      let params = {
        mission_type: data.mission_type,
        destination_country: data.destination_country,
        start_date: momentStartDate.utc().format(),
        end_date: momentEndDate.utc().format(),
        reception_upon_arrival: data.reception_upon_arrival || null,
        accommodation: data.accommodation || null,
        vehicle: data.vehicle || null,
        mission_flight_bookings: [],
      };

      for (const flightBooking of data.mission_flight_bookings) {
        var tempFlight = null;
        if (flightBooking.selectedFlightIndex != null) {
          let selectedFlightInfo = flightBooking.selectableFlights[flightBooking.selectedFlightIndex];

          tempFlight = {
            flight_number: selectedFlightInfo.full_flight_number,
            departure_airport: JSON.parse(flightBooking.departureAirport).id,
            arrival_airport: JSON.parse(flightBooking.arrivalAirport).id,
            scheduled_departure_time_local: selectedFlightInfo.scheduled_departure_time_local,
            scheduled_arrival_time_local: selectedFlightInfo.scheduled_arrival_time_local,
          };
        } else if (flightBooking.flight_number) {
          /* We keep previous values */
          tempFlight = {
            id: flightBooking.id,
            flight_number: flightBooking.flight_number,
            departure_airport: flightBooking.departure_airport,
            arrival_airport: flightBooking.arrival_airport,
            scheduled_departure_time_local: flightBooking.scheduled_departure_time_local,
            scheduled_arrival_time_local: flightBooking.scheduled_arrival_time_local,
          };
        }
        if (tempFlight) {
          params.mission_flight_bookings.push(tempFlight);
        } // Else, no actual flight was selected nor memorized, it's ABNORMAL but we move on for now
      }

      if (this.isUpdateMode()) {
        // MISSION UPDATE - FOR SINGLE USER //
        let missionUpdateParams = { user: data.user, ...params };
        putMissionQry(data.id, missionUpdateParams)
          .then((res) => {
            this.$Message.success(this.$t("Mission successfully updated"));
            this.hide();
            this.$emit("missionUpdated");
          })
          .catch(() => {
            this.$Message.error({
              content: this.$t("Error updating mission"),
              duration: 5,
            });
          });
      } else {
        // MISSION CREATION - FOR ONE OR SEVERAL USERS //
        this.dataIsLoading = true;
        let promises = [];
        let selectedUsers = [null]; // By default, mission is created for oneself
        if (data.selectedTravelerList.length) {
          // Per validation rules, CANNOT be empty if this.travelerList is truthy
          selectedUsers = data.selectedTravelerList; // Replaces all
        }
        for (const userId of selectedUsers) {
          let missionCreationParams = { ...params }; // BEWARE do not just use mutable "params" object
          if (userId !== null) {
            missionCreationParams.user = userId;
          }
          promises.push(postMissionQry(missionCreationParams));
        }
        axios
          .all(promises)
          .then((res) => {
            this.dataIsLoading = false;
            this.$Message.success(this.$t("Mission successfully created"));
            this.hide();
            this.$emit("missionCreated");
          })
          .catch(() => {
            this.dataIsLoading = false;
            this.$Message.error({
              content: this.$t("Error creating mission"),
              duration: 5,
            });
          });
      }
    },
    reset() {
      this.wizardConf.currentStepIndex = 0;
      Object.assign(this, this.initialMissionState());
    },
    show() {
      this.modalIsVisible = true;
    },
    hide() {
      this.reset();
      this.modalIsVisible = false;
    },
  },
};
</script>

<style>
.edit-mission-modal .ivu-modal > .ivu-modal-content > .ivu-modal-body {
  padding: 0 !important;
}

.wizard-body {
  height: 500px;
}
</style>
