<template>
  <AppModal
    v-model="value"
    :header-name="$t('Change password')"
    :footer-hide="false"
    @click:submit="changePass"
    @click:cancel="cancel"
    width="450"
    class="invite-modal"
  >
    <Form ref="passForm" @submit.native.prevent="changePass" :rules="passRules" :model="passInfo">
      <Row class="mt-3">
        <FormItem :label="$t('Old password: ')" prop="old_password" :error="passErrors.old_password">
          <i-input
            v-model="passInfo.old_password"
            type="password"
            :placeholder="$t('Old password')"
            style="width: 100%"
          />
        </FormItem>
        <FormItem :label="$t('New password: ')" prop="new_password" :error="passErrors.new_password">
          <i-input
            v-model="passInfo.new_password"
            type="password"
            :placeholder="$t('New password')"
            style="width: 100%"
          />
        </FormItem>
        <FormItem
          :label="$t('Confirm new password: ')"
          prop="repeat_new_password"
          :error="passErrors.repeat_new_password"
        >
          <i-input
            v-model="passInfo.repeat_new_password"
            type="password"
            :placeholder="$t('Confirm password')"
            style="width: 100%"
          />
        </FormItem>
      </Row>
      <input type="submit" hidden />
      <Spin v-if="passwordFormLoad" fix />
    </Form>
  </AppModal>
</template>

<script>
import AppModal from "@/components/common/AppModal";
import { putChangePassword } from "@/services/auth.service";

export default {
  name: "ChangePassword",

  components: {
    AppModal,
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    profile: {
      required: true,
      type: Object,
    },
  },
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("Please enter your password")));
      } else if (value !== this.passInfo.repeat_new_password) {
        callback(new Error(this.$t("Password not matched")));
      } else {
        callback();
      }
    };
    return {
      passInfo: {
        old_password: "",
        new_password: "",
        repeat_new_password: "",
      },
      passRules: {
        old_password: [
          { required: true, message: this.$t("Password cannot be empty"), trigger: "blur" },
          {
            type: "string",
            min: 8,
            message: this.$t("Password should be more than 7 characters"),
            trigger: "blur",
          },
        ],
        new_password: [
          { required: true, message: this.$t("Password cannot be empty"), trigger: "blur" },
          {
            type: "string",
            min: 8,
            message: this.$t("Password should be more than 7 characters"),
            trigger: "blur",
          },
        ],
        repeat_new_password: [{ required: true, validator: validatePassCheck, trigger: "blur" }],
      },
      passErrors: {
        old_password: "",
        new_password: "",
        repeat_new_password: "",
      },

      passwordFormLoad: false,
    };
  },
  computed: {},
  methods: {
    changePass() {
      this.$refs["passForm"].validate((valid) => {
        if (valid) {
          this.passwordFormLoad = true;
          putChangePassword(this.passInfo)
            .then(() => {
              this.$Message.success(this.$t("Successfully updated your password"));
              this.cancel();
            })
            .catch((err) => {
              this.passwordFormLoad = false;
              const error = err.response;
              if (error.status === 400) {
                this.resetDataName(this, "passErrors");
                for (let col in error.data) {
                  this.passErrors[col] = error.data[col][0];
                }
              } else {
                this.$Message.error({
                  content: this.$t("There is an error creating your data"),
                  duration: 5,
                });
              }
            });
        }
      });
    },
    cancel() {
      this.$emit("input", false);
      this.$refs["passForm"].resetFields();
    },
    submitForm() {},
  },
};
</script>
