<template>
  <div class="full-height general-overview-page">
    <div class="general-overview-content">
      <MapViewer />
    </div>
  </div>
</template>

<script>
import AdminExtraHeader from "../header/AdminExtraHeader";
import MapViewer from "./MapViewer";

export default {
  name: "Map",
  components: {
    AdminExtraHeader,
    MapViewer
  },
  data() {
    return {
      // Initialize map data
    }
  },
  methods: {
    // Methods for retrieving data or other actions
  }
};
</script>

<style scoped>
.general-overview-page .general-overview-menu {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  background: transparent;
  padding-left: 20px;
}

.general-overview-page .general-overview-content {
  height: 100%;
}

.general-overview-page .general-overview-menu > a {
  width: 150px;
  text-align: center;
  background: #ffffff;
  margin-right: 20px;
  font-size: 12px;
}

.general-overview-page .general-overview-menu > a.ivu-menu-item-selected {
  background: var(--secondary-bleu);
  color: #ffffff !important;
}

.general-overview-page .general-overview-menu:after {
  content: none !important;
}
</style>
