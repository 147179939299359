<template>
  <Row
    type="flex"
    align="middle"
    class="full-height app-header-content"
    :gutter="15"
  >
    <i-col :xs="3" :lg="6">
      <input
        @change="showMenu()"
        type="checkbox"
        href="#"
        class="menu-open"
        name="menu-open"
        id="menu-open"
      />
      <img
        :src="require('@/assets/images/header/logo_ilios_modern.png')"
        alt="s2e consulting"
        style="width: 220px;"
        class="logo logoslide"
      />
      <label
        class="menu-open-button"
        for="menu-open"
        :class="{ ischeck: isCheck }"
      >
        <span class="lines line-1"></span>
        <span class="lines line-2"></span>
        <span class="lines line-3"></span>
      </label>
    </i-col>
    <i-col :xs="0" :lg="11" class="clock">
      <div class="header-clock">
        <p class="dateFormat">
          {{ dateNow }}
          <digitalClock :blink="true" :display-seconds="true" />
        </p>
      </div>
    </i-col>
    <i-col :xs="9" :lg="0">
      <img
        :src="require('@/assets/images/header/logo_ilios_modern.png')"
        alt="s2e consulting"
        style="width: 160px;"
        class="logo"
      />
    </i-col>
    <i-col :xs="5" :lg="7" class="text-right">
      <PanelSettings :userProfile="userProfileStore.getInternalUserProfile"></PanelSettings>
    </i-col>
  </Row>
</template>

<script>
import PanelSettings from "./PanelSettings";
import DigitalClock from "vue-digital-clock";
import { useUserProfileStore } from '@/stores/userProfile';
import DateFormat from "../../mixins/dateFormat";
import PanelAccountHeader from "./PanelAccountHeader.vue";

export default {
  components: {
    DigitalClock,
    PanelSettings,
    PanelAccountHeader,
    useUserProfileStore
  },
  mixins: [DateFormat],
  data() {
    return {
      isCheck: "",
      companies: {},
      company_list: [],
    };
  },
  computed: {
    dateNow() {
      return this.dateFormatHeader(this.$i18n.locale);
    },
  },
  created() {
    const userProfileStore = useUserProfileStore()
    userProfileStore._loadAuthSession();
  },
  mounted() {
    this.$eventBus.$on("closeMenu", this.showMenu);
  },
  methods: {
    showMenu() {
      this.$eventBus.$emit("showSlide");
    },
    closeMenu() {
      this.$eventBus.$emit("closeMenu");
    },
  },
};
</script>

<style>
.menu-profile-detail {
  margin-top: 10px;
}
.arrow-down {
  margin-top: 10px;
}
.card {
  cursor: pointer;
  display: flex;
  border-radius: 10px !important;
}
.icon-setting {
  color: var(--secondary-bleu);
  float: left;
}
.menu-profile-detail span {
  text-align: left;
  margin-left: 10px;
}
.app-header-content .header-clock {
  text-align: right;
}

.header-clock {
  margin-right: 20%;
}
.app-header-content .header-clock .clock {
  margin-left: 50px;
}
.clock {
  text-align: center;
}
.ivu-poptip-popper {
  z-index: 2002;
}

.logoheader {
  display: none;
}
/* Burger button  */

.menu-item,
.menu-open-button {
  background: #fff;
  border-radius: 5px;
  width: 29px;
  height: 29px;
  margin-left: -6px;
  margin-top: -14px;
  position: absolute;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform ease-out 200ms;
  transition: -webkit-transform ease-out 200ms;
  transition: transform ease-out 200ms;
  transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14);
}

.menu-open {
  display: none;
}

.lines {
  width: 17px;
  height: 2px;
  background: #0b124e;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8.5px;
  margin-top: -0.5px;
  -webkit-transition: -webkit-transform 200ms;
  transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms, -webkit-transform 200ms;
}

.line-1 {
  -webkit-transform: translate3d(0, -8px, 0);
  transform: translate3d(0, -8px, 0);
}

.line-2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.line-3 {
  -webkit-transform: translate3d(0, 8px, 0);
  transform: translate3d(0, 8px, 0);
}

.ischeck {
  background: #fff !important;
}
.menu {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80px;
  height: 80px;
  text-align: center;
  box-sizing: border-box;
  font-size: 26px;
}

.menu-open-button {
  display: none;
  z-index: 2;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14);
}

.avatar-menu {
  height: 160px;
  width: 160px;
  border-radius: 500px;
}
.ivu-poptip {
  padding: 8px;
  border-radius: 10px;
  transition: 0.3s;
}

.ivu-poptip:hover {
  background-color: var(--hover-icon);
  cursor: pointer;
}
.hr {
  height: 1px;
  width: 100%;
  background-color: #a6a6a6;
  margin: 10px 0 12px;
}

.profile .ivu-poptip-body {
  padding: 0px;
}
</style>
