<template>
  <div>
    <Card class="country-general">
      <div class="general-header">
        <div>
          <Icon
            type="md-calendar"
            :size="25"
            style="margin-right: 15px"
          />
          <strong>{{ $t('Main dates to remember') }}</strong>
        </div>
        <Icon
          v-if="(s2ec.user.is_admin || s2ec.user.is_superuser) && disallowEdit"
          type="md-create"
          size="20"
          :title="$t('Edit')"
          class="pointer-cursor"
          v-bind="getTranslatableContentAttributes()"
          @click.native="showUpdate(0)"
        />
      </div>
      <div
        v-for="(fact, idx) in countryFact"
        :key="fact.id"
        class="general-content form-content"
      >
        <div>
          <Row
            v-if="!disallowEdit"
            type="flex"
            justify="end"
            :gutter="10"
            class="form-action"
          >
            <i-col>
              <Button
                type="success"
                long
                class="confirm-button"
                @click="triggerUpdate(fact, idx)"
              >
                {{ $t('Update') }}
              </Button>
            </i-col>
            <i-col>
              <Button
                type="default"
                long
                class="cancel-button"
                @click="triggerCancel(idx)"
              >
                {{ $t('Cancel') }}
              </Button>
            </i-col>
          </Row>
        </div>
        <div
          v-show="disallowEdit"
          class="dtr-content"
        >
          <p v-html="fact.data.content" />
        </div>
        <AppTipTap
          v-show="!disallowEdit"
          ref="generalTipTap"
          v-model="fact.data.content"
          class="tiptap"
        />
      </div>
      <div
        v-if="countryFact.length === 0"
        class="empty-fact-type"
      >
        <h4 class="text-center">
          {{ $t('No data') }}
        </h4>
      </div>
      <Spin
        v-if="loading"
        fix
      />
    </Card>
  </div>
</template>

<script>
    import AppTipTap from "@/components/common/AppTipTap"
    import {
        getCountryFactsQry,
        getFactTypeQry,
        postCountryFactsQry,
        putCountryFactsQry
    } from "@/services/countryInfo.service";
    import _cloneDeep from "lodash/cloneDeep"

    export default {
        name: "General",

        components: {
            AppTipTap
        },
        props: ['country'],
        data() {
            return {
                loading: false,
                disallowEdit: true,
                countryFact: [],  // Fixme badly named
                copyCountryFact: [],
                factType: {},
            }
        },
        async created() {
            this.loading = true;
            await getCountryFactsQry({country: this.$route.params.country, country_fact_type: 'main-dates-to-remember'})
                .then(res => {
                    this.countryFact = res.data;
                })
                .catch(() => {
                    this.$Message.error({content: this.$t('Cannot retrieve dates to remember'), duration: 5})
                });

            await getFactTypeQry("main-dates-to-remember")
                .then(res => {
                    this.factType = res.data;
                })
                .catch(() => {
                    this.$Message.error({content: this.$t('Unable to retrieve struct'), duration: 5})
                });
            this.loading = false;
        },
        methods: {
            triggerUpdate(fact, idx) {
                this.$refs['generalTipTap'][idx].submit();
                if (fact.id) {
                    putCountryFactsQry(fact.id, fact)
                        .then(res => {
                            this.countryFact.splice(idx, 1, res.data);
                            this.tipTapSuccess(idx);
                        })
                        .catch(() => {
                            this.$Message.success(this.$t('Error saving your data'))
                        })

                } else {
                    const factData = {
                        data: fact.data,
                        country_fact_type: this.factType.id,
                        country: this.$route.params.country
                    };

                    postCountryFactsQry(factData)
                        .then(res => {
                            this.countryFact.splice(idx, 1, res.data);
                            this.tipTapSuccess(idx);
                        })
                        .catch(err => {
                            this.$Message.success(this.$t('Error saving your data'))
                        })
                }
            },

            tipTapSuccess(idx) {
                this.disallowEdit = true;
                this.$refs['generalTipTap'][idx].success();
            },

            triggerCancel(idx) {
                this.disallowEdit = true;
                this.$refs['generalTipTap'][idx].cancel();

                this.$nextTick(() => {
                    this.countryFact = _cloneDeep(this.copyCountryFact);
                })
            },

            showUpdate(idx) {
                this.copyCountryFact = _cloneDeep(this.countryFact);
                if (this.countryFact.length === 0) {
                    this.countryFact.push({data: this.factType.data_structure})
                }

                this.$nextTick(() => {
                    this.disallowEdit = false;
                    this.$refs['generalTipTap'][idx].allowUpdate();
                });
            },
        }

    }
</script>

<style>

    .dtr-content {
        padding: 30px;
        max-height: 250px;
        overflow: auto;
        background: #fff;
    }

    .general-content {
        font-size: 14px;
    }

</style>
