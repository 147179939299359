<template>
  <div class="full-height profile">
    <Row type="flex">
      <i-col :md="17">
        <router-link :to="{ name: 'accounts' }">
          <span class="history-account mr-3"> {{ $t("Account") }}</span>
        </router-link>
        <Icon type="ios-arrow-forward" size="20" class="arrow" />
        <router-link :to="{ name: 'contracts' }">
          <span class="history-account" v-if="!loadingData">{{ profile.client_name }}</span>
          <span class="history-account" v-else>{{ $t("Contract") }}</span>
        </router-link>
        <Icon type="ios-arrow-forward" size="20" class="arrow" />
        <span class="history-account-actual">{{ profile.full_name ? profile.full_name : profile.id }}</span>
      </i-col>
      <i-col :md="7" align="right">
        <Button class="action-button" @click="triggerShowModal()" v-if="!currentProfile">
          {{ $t("Delete user") }}
        </Button>
        <DeleteUser ref="deleteUser" :profile="profile" v-model="showDeleteUser" />
      </i-col>
    </Row>
    <Row class="marginTopHeader">
      <Tabs v-model="activeTab">
        <Spin v-if="loadingData" fix />
        <TabPane :label="$t('Profile')" name="Profile">
          <TabProfile :loadingData="loadingData" :profile="profile" @infoUpdated="profile = $event"> </TabProfile>
        </TabPane>
        <TabPane :label="$t('Missions')" name="Mission">
          <MissionsManagement :profile="profile"></MissionsManagement>
        </TabPane>
        <TabPane :label="$t('Notifications')" name="Notifications">
          <TabNotifications
            :loadingData="loadingData"
            :profile="profile"
            @infoUpdated="profile = $event"
          ></TabNotifications>
        </TabPane>
        <TabPane :label="$t('Client')" name="Client">
          <TabClient
            :loadingData="loadingData"
            :profile="profile"
            :client="client"
            :currentContract="currentContract"
            @redirectProfile="redirectProfile"
            @infoUpdated="profile = $event"
          ></TabClient>
        </TabPane>
      </Tabs>
    </Row>
  </div>
</template>

<script>
import { getClientQry } from "@/services/accounts.service";
import TabProfile from "@/components/accounts/profile/TabProfile";
import TabNotifications from "@/components/accounts/profile/TabNotifications";
import MissionsManagement from "@/views/missions/MissionsManagement.vue";
import TabClient from "@/components/accounts/profile/TabClient";
import DeleteUser from "@/views/profile/modals/DeleteUser";

export default {
  name: "Profile",
  components: {
    TabProfile,
    TabNotifications,
    TabClient,
    DeleteUser,
    MissionsManagement,
  },
  data() {
    return {
      editInfo: false,
      showDeleteUser: false,
      loadingData: false,
      editAvatarInfo: false,
      client_name: "",
      activeTab: "Profile",
      client: [],
      currentContract: [],
      lastUpdate: null,
      loading: false,
      imageLoading: false,
    };
  },
  mounted() {
    this.getProfile();
    this.fetchClient();
  },
  computed: {
    currentProfile() {
      const userIdParams = this.$route.params.userId;
      const internalId = this.userProfileStore.getInternatlId;
      if (userIdParams == internalId) {
        return true;
      }
      return false;
    },
    profile() {
      const userIdParams = this.$route.params.userId;
      const internalId = this.userProfileStore.getInternatlId;
      if (userIdParams == internalId) {
        return this.userProfileStore.getInternalUserProfile;
      } else {
        return this.userProfileStore.getSelectedUserProfile;
      }
    },
  },
  methods: {
    async redirectProfile(userId) {
      this.activeTab = "Profile";
      this.loadingData = true;
      await this.userProfileStore._loadUserProfile(userId);
      this.loadingData = false;
    },
    getCurrentContract() {
      this.currentContract = this.client.client_contracts.find(
        (contract) => contract.id == this.$route.params.contractId
      );
    },
    openEditInfo() {
      this.$refs.personalInfo.setFormData(this.profile, this.lastUpdate);
      this.editInfo = true;
    },
    openAvaterInfo() {
      this.$refs.avaterInfo.setFormData(this.profile, this.lastUpdate);
      this.editAvatarInfo = true;
    },
    async fetchClient() {
      await getClientQry(this.$route.params.id)
        .then((res) => {
          this.client = res.data;
          this.getCurrentContract();
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred retrieving client"),
            duration: 5,
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.loadingData = false;
          }, 1000);
        });
    },
    async getProfile() {
      const userIdParams = this.$route.params.userId;
      const internalId = this.userProfileStore.getInternatlId;
      this.loadingData = true;
      if (userIdParams != internalId) {
        await this.userProfileStore._loadUserProfile(userIdParams);
      } else {
        await this.userProfileStore._loadMyProfile(internalId);
      }
    },
    triggerShowModal() {
      (this.showDeleteUser = false), (this.showDeleteUser = true);
    },
  },
};
</script>

<style scoped>
.profile .cards {
  margin: 15px 15px 15px 0px;
  background: white;
}

.cardTitle {
  text-align: left;
  font-size: 15px;
  padding-left: 10px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}

.card-content {
  letter-spacing: 1px;
  padding-left: 50px;
  line-height: 28px;
}
.cardTitle .text-title {
  padding-left: 10px;
}
</style>
