<template>
  <div>
    <br>
    <h4 class="text-center">
      {{ $t('Sign in by entering the information below') }}
    </h4>
    <br>
    <p class="text-center register-now">
      <router-link :to="{name: 'registration'}">
        {{ $t('Register now') }}
      </router-link>
    </p>
    <Form
      ref="authForm"
      :model="authData"
      :rules="authRules"
      @keyup.enter.native="triggerSignIn"
    >
      <FormItem prop="email">
        <i-input
          v-model="authData.email"
          autofocus="autofocus"
          :placeholder="$t('Email address')"
          name="email"
          autocomplete="on"
        />
      </FormItem>
      <FormItem prop="password">
        <i-input
          v-model="authData.password"
          :placeholder="$t('Password')"
          :type="showPassword ? 'text':'password'"
        >
          <Icon
            slot="suffix"
            :type="showPassword ? 'ios-eye-off':'ios-eye'"
            size="25"
            class="pointer-cursor"
            @click="showPassword = !showPassword"
          />
        </i-input>
      </FormItem>
    </Form>

    <Button
      long
      class="confirm-button"
      :loading="signInLoading"
      :html-type="'submit'"
      @click="triggerSignIn"
    >
      {{ $t('Sign in') }}
    </Button>
    <p class="text-center lost-password">
      <router-link :to="{name: 'lost-password'}">
        {{ $t('Lost password') }}
      </router-link>
    </p>
  </div>
</template>

<script>
  import {postLoginQry, getAccountQry} from "../../services/auth.service"
  import {setAuthSession} from "../../helpers/auth.helper"

  export default {
    name: "Login",
    components:{
    },
    data() {
      return {
        showPassword: false,
        signInLoading: false,
        authData: {
          email: "",
          password: ""
        },
        authRules: {
          email: [
            {required: true, message: this.$t('Please provide an email'), trigger: 'blur'},
          ],
          password: [
            {required: true, message: this.$t('Please provide a password'), trigger: 'blur'},
          ]
        },
      }
    },
    methods: {
      triggerSignIn() {
        this.$refs['authForm'].validate((valid) => {
          if (valid) {
            this.signInLoading = true;
            postLoginQry(this.authData)
              .then(res => {
                getAccountQry({}, res.data.access_token)
                  .then(res2 => {
                    let sessionToSave = Object.assign(res.data, res2.data);
                    setAuthSession(sessionToSave);
                    this.$router.push({
                      name: "map",
                    });
                    this.signInLoading = false;
                  })
                  .catch(() => {
                    this.$Message.error({content: this.$t('Cannot retrieve user detail'), duration: 5})
                  })
              })
              .catch((err) => {
                let message = this.$t('A server error occurred during authentication');
                if (err.response && err.response.status == 400) { // err.request is also available
                    message = this.$t('Invalid username/password');
                }
                this.$Message.error({content: message, duration: 5});
                this.signInLoading = false;
              })
          }
        })
      },
    }
  }
</script>

<style scoped>

    .lost-password {
        margin-top: 5px;
        font-size: 11px;
        text-decoration: underline;
    }

    .register-now {
        margin-bottom: 10px;
        text-decoration: underline;
    }

</style>
