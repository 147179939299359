<template>
  <div class="site-detail-item lavender-shadow">
    <div class="site-detail-action">
      <strong>{{ $t('Attached locations') }}</strong>
      <Button
        class="confirm-button"
        @click="showCreateLocation = true"
      >
        {{ $t('Add an attached location') }}
      </Button>
    </div>

    <CreateLocation
      v-if="site&&site.id"
      ref="createLocation"
      v-model="showCreateLocation"
      :site_types="site_types"
      :site="site.id"
      @locationCreated="locationCreated"
    />

    <Row class="site-detail-inner-content text-left">
      <i-col
        span="24"
        class="location-list-header"
      >
        <Row :gutter="10">
          <i-col :xs="14">
            {{ $t('Address') }}
          </i-col>
          <i-col :xs="6">
            {{ $t('Type') }}
          </i-col>
          <i-col
            :xs="4"
            class="text-center"
          >
            {{ $t('Action') }}
          </i-col>
        </Row>
      </i-col>

      <i-col :xs="24">
        <div
          v-bar
          class="location-list-body"
        >
          <div>
            <Row
              v-for="(location, idx) in site_locations_list"
              :key="location.id"
              :gutter="10"
              type="flex"
            >
              <i-col
                :lg="12"
                :xl="12"
                :xxl="14"
              >
                {{ location.full_address }}
              </i-col>

              <i-col
                :lg="6"
                :xl="6"
                :xxl="6"
              >
                {{ locationTypeLabel(location.site_type) }}
              </i-col>
              <i-col
                :lg="6"
                :xl="6"
                :xxl="4"
                class="text-center"
              >
                <div class="location-list-action">
                  <Icon
                    type="md-pin"
                    size="16"
                    color="#000747"
                    :title="$t('Locate')"
                    class="pointer-cursor"
                    @click="mapLocation(location)"
                  />
                  <Icon
                    type="md-create"
                    size="16"
                    color="#000747"
                    :title="$t('Edit')"  
                    class="pointer-cursor"
                    @click="triggerUpdateLocation(location, idx)"
                  />
                  <Icon
                    type="md-trash"
                    size="16"
                    color="#000747"
                    :title="$t('Delete')"
                    class="pointer-cursor"
                    @click="triggerDelete(location, idx)"
                  />
                </div>
              </i-col>
            </Row>
          </div>
        </div>
      </i-col>
    </Row>
    <Spin
      v-if="siteInfoMounting"
      fix
    />
  </div>
</template>

<script>
  import CreateLocation from "./modals/CreateLocation"
  import {deleteSiteLocationQry, optionSiteLocationQry} from "@/services/sites.service";

  export default {
    name: "SiteLocation",
    components: {
      CreateLocation
    },
    props: [],
    data() {
      return {
        siteInfoMounting: false,  // Never enabled for now?
        site_locations: {
          count: 1,
          next: null,
          previous: null,
          results: []
        },
        site: null,
        site_types: [],
        site_locations_list: [],
        showCreateLocation: false,
      }
    },
    methods: {

      triggerUpdateLocation(data, idx) {
        this.$refs['createLocation'].setUpdateData(data, idx);
        this.showCreateLocation = true;
      },

      triggerDelete(data, idx) {
        this.$AppModal.AppConfirm({
          method: 'delete',
          extraTitle: this.$t('Delete attached location'),
          message: this.$t('Are you sure you want to delete this attached location?'),
          loading: true,
          onConfirm: (() => {
            deleteSiteLocationQry(this.site.id, data.id)
              .then(() => {
                this.site_locations.count -= 1;
                this.site_locations_list.splice(idx, 1);
                this.$Message.success(this.$t('Successfully deleted attached location'));
                this.$AppModal.remove();
              })
              .catch(() => {
                this.$Message.error({content: this.$t('Error deleting attached location'), duration: 5});
                this.$AppModal.remove();
              })

          })

        })
      },

      locationCreated(data, idx) {
        if (idx !== undefined) {
          this.site_locations_list.splice(idx, 1, data);
        } else {
          this.site_locations_list.unshift(data);
        }
      },

      locationTypeLabel(value) {
        let locationType = this.site_types.find((item) => {
          return item.value === value
        });

        if (locationType) {
          return locationType['display_name']
        } else {
          return null
        }
      },
      mapLocation(location) {
        let str = location.latitude + ',' + location.longitude + ',' + 12;
        this.$router.push({name: 'map', query: {location: str}});
      },
      async getSiteData(site) {
        this.resetSiteData()
        this.site = site
        this.site_locations_list = site.site_locations
        await optionSiteLocationQry(site.id)
          .then(res => {
            this.site_types = res.data.actions.POST.site_type.choices
          });
      },
      resetSiteData() {
        this.site_locations = {
          count: 1,
          next: null,
          previous: null,
          results: []
        }
        this.site = null
        this.site_locations_list = []
      }
    }
  }
</script>

<style scoped>
    .site-page .site-detail-inner-content .location-list-header {
        background: #F2F1F6;
        padding: 14px 10px;
        font-weight: 600;
    }

    .site-page .location-list-body {
        height: 200px;
        background: #ffffff;
        margin-bottom: 14px;
    }

    .site-page .location-list-body > div > .ivu-row-flex {
        padding: 10px 0;
        margin-left: 5px !important;
        margin-right: 5px !important;
        width: calc(100% + 5px);
    }


    .site-page .location-list-body > div > .ivu-row-flex:not(:last-of-type) {
        border-bottom: 1px solid RGBA(112, 112, 112, 0.4);
    }

    .site-page .location-list-body .location-list-action .ivu-icon:not(:last-of-type) {
        padding-right: 5px
    }
</style>
