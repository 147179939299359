<template>
  <div class="full-height">
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      class="mt-5 mb-4"
    >
      <i-col
        :xs="4"
        :md="1"
        :lg="1"
      >
        <img
          :src="country.flag"
          style="height: 30px;"
          align="middle"
        >
      </i-col>
      <i-col
        :xs="20"
        :md="10"
        :lg="14"
        :xl="14"
        :xxl="16"
      >
        <div class="country-detail-country text-left">
          <i-select
            :value="$route.params.country"
            filterable
            @on-change="triggerSelectCountry"
          >
            <Option
              v-for="country in country_list"
              :key="country.value"
              :value="country.value"
              :label="$t(country.display_name)"
            >
              <img :src="country.flag" alt="Drapeau du pays" class="flag-image" style="position: inherit;"/>
              <span class="ml-4">{{ $t(country.display_name) }}</span>
            </Option>
          </i-select>
        </div>
      </i-col>
      <i-col
        :xs="24"
        :md="12"
        :lg="12"
        :xl="9"
        :xxl="5"
        class="text-right country-info-action"
      >
        <Button
          class="back-button"
          @click="goBackToMap"
        >
          <span>{{ $t('Back') }}</span>
        </Button>
      </i-col>
    </Row>

    <Row
      type="flex"
      :gutter="10"
      class="country-info-detail-content"
    >
      <i-col
        :sm="11"
        :md="8"
      >
        <GlobalRisk v-model="countryRisk" />
        <GlobalRiskMap
          :country-risk="countryRisk"
          :incidents="displayedIncidents"
        />
        <SecurityRiskLevels v-model="countryRisk" />
      </i-col>
      <i-col
        :sm="13"
        :md="16"
        class="country-info-general"
      >
        <Tabs
          v-model="activeTab"
          type="card"
          :animated="false"
          @on-click="changeTab"
        >
          <TabPane
            :label="$t('Notifications')"
            name="past-events"
          >
            <PastEvents ref="pastEvents"
              @selected-incident="handleSelectedIncident"
            />
          </TabPane>
          <TabPane
            :label="$t('General')"
            name="general"
          >
            <div class="tabs-content">
              <MainDatesToRemember />
              <Points />
              <GoodToKnow />
            </div>
          </TabPane>
          <TabPane
            :label="$t('Analysis')"
            name="analysis"
          >
            <div>
              <Analysis :fact-type-slug="'analysis'" />
            </div>
          </TabPane>
        </Tabs>
      </i-col>
    </Row>
  </div>
</template>

<script>

    import GlobalRisk from "./risks/GlobalRisk"
    import GlobalRiskMap from "./risks/GlobalRiskMap"
    import SecurityRiskLevels from "./risks/SecurityRiskLevels"
    import MainDatesToRemember from "./facts/MainDatesToRemember"
    import GoodToKnow from "./facts/GoodToKnow"
    import Points from "./facts/Points"
    import Analysis from "./facts/Analysis"
    import PastEvents from "./pastEvents/PastEvents"
    import {getRiskQry} from "../../services/countryInfo.service";
    import {getCountriesQry} from '../../services/common.service'
    import {getIncidentQry} from "../../services/incidents.service";

    export default {
        name: "CountryInfo",

        components: {
            GlobalRisk,
            GlobalRiskMap,
            SecurityRiskLevels,
            MainDatesToRemember,
            GoodToKnow,
            Points,
            Analysis,
            PastEvents
        },
        data() {
            let currentTab = window.location.hash.substring(1);
            return {
                activeTab: currentTab || 'past-events',
                countryRisk: {
                    country: null,
                    global_risk: 0,
                    foreign_hostility: 0,
                    political_social_unrest: 0,
                    organized_crime_delinquency: 0,
                    terrorism: 0,
                    travels: 0,
                    country_global_health_rates: 0,
                    global_health_rates: 0,
                },
                document: null,

                incidents: {
                    count: 0,
                    next: null,
                    previous: null,
                    results: []
                },
                selectedIncidentId: null,

                country: {
                    flag_url: null,
                    country: null,
                    value: null,
                },
                country_list: []
            }
        },

        created() {
            this.$eventBus.$emit('pushActiveMenu','country-information');
            this.setupCountryLists();
            this.$eventBus.$on("clientContextSelected", this.setupCountryLists);
        },
        beforeDestroy() {
            this.$eventBus.$off("clientContextSelected", this.setupCountryLists);
        },
        mounted() {
            getRiskQry(this.$route.params.country)
                .then(res => {
                    this.countryRisk = res.data;
                })
                .catch(() => {
                    this.$Message.error(this.$t('Unable to retrieve risks'));
                });

            let getIncidentsParams = {page_size:500, country: this.$route.params.country};

            getIncidentQry(getIncidentsParams)  // Fixme we need pagination here!!
                .then(res => {
                    const {results, ...data} = res.data;
                    Object.assign(this.incidents, data);
                    this.incidents.results.push(...results);
                }).catch(err => {
                this.$Message.error(this.$t('Unable to retrieve incidents'));
            })
        },
        computed: {
            displayedIncidents() {
                if (!this.incidents || !this.selectedIncidentId) return [];
                return this.incidents.results.filter(incident => incident.id === this.selectedIncidentId);
            },
        },
        methods: {
            triggerSelectCountry(country) {
                this.$router.replace({name: 'country-info-detail', params: {country}});  // In-place change!
                this.$nextTick(() => {
                    this.country = country;
                    this.$emit('triggerIdentifier');
                    this.$refs['pastEvents'].countryChange();
                })
            },
            changeTab(tabName) {
                window.location = "#" + tabName;
            },
            async setupCountryLists(){
                let country_list = (await getCountriesQry()).data;
                let country = country_list.filter(a => a.value === this.$route.params.country)[0];
                if (country) {
                    this.country = country;
                    this.country_list = country_list;
                } else {
                    this.goBackToMap();  // Wanted country is not found, so we navigate upwards
                }

            },
            handleSelectedIncident(incidentId) {
              this.selectedIncidentId = incidentId;  // Could be null
            },
            goBackToMap() {
                this.$router.push({name:'country-information'})
            }
        }
    }
</script>


<style>
    .country-detail-country {
        background: #fff;
        width: 200px;
        height: 30px;
        vertical-align: middle;
        display: table-cell;
        font-weight: 600;
        text-align: center;
        box-shadow: 2px 2px lightgray;
    }

    .country-info-detail-content > div:not(.country-info-detail-content) {
        margin-bottom: 20px;
    }

    .general-content {
        position: relative;
    }

</style>
