import {axiosInstance} from "./base"


export function getCountriesQry(params) {
    return axiosInstance.get("api/countries/", {params})
}

export function getAirportsQry(params) {
    return axiosInstance.get("api/airports/", {params})
}

export function getTermsAndConditionQry() {
    return axiosInstance.get("api/terms-and-conditions/",)

}

function _normalize_zoom(z) {
    /* We can diminish z to get bigger tiles on the map and thus decrease http/server load. */
    return z-2;
}

/* FIXME PUT THIS IN HELPERS ?? */

// From https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29
export function long2tile(lon,zoom) { return (Math.floor((lon+180)/360*Math.pow(2,_normalize_zoom(zoom)))); }

export function lat2tile(lat,zoom)  { return (Math.floor((1-Math.log(Math.tan(lat*Math.PI/180) + 1/Math.cos(lat*Math.PI/180))/Math.PI)/2 *Math.pow(2,_normalize_zoom(zoom)))); }

// These return the NW-corner of the square

export function tile2long(x,z) {
 return (x/Math.pow(2,_normalize_zoom(z))*360-180);
}

export function tile2lat(y,z) {
 var n=Math.PI-2*Math.PI*y/Math.pow(2,_normalize_zoom(z));
 return (180/Math.PI*Math.atan(0.5*(Math.exp(n)-Math.exp(-n))));
}

export function generateCoveredTiles(x, y, width, height, tilesPerAxis) {
    //console.log("WIPTILES generateCoveredTiles()", x, y, width, height, tilesPerAxis)
  var tiles = [];
  for (var xIncr = 0; xIncr < width ; xIncr ++){
    for (var yIncr = 0; yIncr < height ; yIncr ++){
      var newTile = [(x+xIncr) % tilesPerAxis, (y+yIncr) % tilesPerAxis, ]
      tiles.push(newTile)
    }
  }
    //console.log("WIPTILES generateCoveredTiles() result", tiles)
  return tiles;
}

