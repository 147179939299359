<template>
  <AppModal 
    v-model="value.showModal" 
    :header-name="$t('User privileges')"
    :footer-hide="false"
    @click:submit="modalOnSubmit"
    @click:cancel="value.showModal = false"
    :loading="loadingRole" 
    width="350" 
    :closable="true" 
    class="user-modal"
  >
    <div class="mt-5">
      {{value.form.full_name}}
    </div>
    <Row class="mt-5">
      <i-Select v-model="value.form.user_role">
        <i-option v-for="userType in getUserTypes()" :key="userType.value" :value="userType.value">
          {{ userType.display_name }}
        </i-option>
      </i-Select>
    </Row>
  </AppModal>
</template>

<script>
import AppModal from "@/components/common/AppModal";

export default {
  name: "userMangement",

  components: {
    AppModal
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: {
        showModal: false,
        form: {},
        userType: "",
      }
    },
    modalOnSubmit: {
      type: Function,
      required: true,
    },
    loadingRole: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  watch: {
    'value.showModal'(newValue) {
      if (newValue === false) {
        this.$emit("hide")
      }
    }
  },
}
</script>
