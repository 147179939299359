<template>
  <div class="analysis-content">
    <Row
      v-if="!showUpdateForm"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <i-col
        v-if="s2ec.user.is_admin || s2ec.user.is_superuser"
        :sm="24"
        class="text-right"
      >
        <Button
          type="success"
          class="confirm-button"
          v-bind="getTranslatableContentAttributes()"
          @click="triggerAnalysisForm()"
        >
          <Icon
            type="md-add"
            size="18"
          />
          {{ $t('Add') }}
        </Button>
      </i-col>
    </Row>
    <AnalysisForm
      v-if="showUpdateForm"
      ref="analysis"
      :fact-type="factType"
      @triggerCancel="triggerCancel"
      @analysisCreated="analysisCreated"
    />

    <div v-else>
      <draggable
        v-model="countryFacts"
        :disabled="!s2ec.user.is_superuser"
        @change="analysisReposition"
      >
        <div
          v-for="(fact, idx) in countryFacts"
          :key="fact.id"
          class="general-content analysis-item"
        >
          <div>
            <strong> {{ fact.data.title }} </strong>
            <div
              class="content-text"
              style="text-align: justify"
              v-html="fact.data.content"
            />
          </div>
          <div
            v-if="s2ec.user.is_admin || s2ec.user.is_superuser"
            class="analysis-item-action"
          >
            <Icon
              type="md-create"
              size="20"
              :title="$t('Edit')"
              class="pointer-cursor"
              v-bind="getTranslatableContentAttributes()"
              @click.native="triggerAnalysisForm(fact, idx)"
            />
            <Icon
              type="md-trash"
              size="20"
              class="pointer-cursor"
              v-bind="getTranslatableContentAttributes()"
              @click.native="triggerDelete(fact.id, idx)"
            />
          </div>
        </div>
      </draggable>

      <div
        v-if="countryFacts.length === 0"
        class="empty-fact-type"
      >
        <h4 class="text-center">
          {{ $t('No data') }}
        </h4>
      </div>
    </div>
  </div>
</template>


<script>
    import {
        getCountryFactsQry,
        getFactTypeQry,
        deleteCountryFactQry,
        patchCountryFactsQry
    } from "../../../services/countryInfo.service";
    import AnalysisForm from "./AnalysisForm"
    import draggable from 'vuedraggable'
    import AnalysisFilter from "./AnalysisFilter";
    import DateFormat from "@/mixins/dateFormat"

    export default {
        name: "Analysis",
        components: {
            AnalysisForm,
            draggable,
            AnalysisFilter
        },
        mixins: [DateFormat],
        props: ['country', 'factTypeSlug'],
        data() {
            return {
                loading: false,
                showUpdateForm: false,
                countryFacts: [],
                factType: {},
                filterParams: {}
            }
        },
        async mounted() {
            this.loading = true;
            this.getCountryFacts();
            getFactTypeQry(this.factTypeSlug)
                .then(res => {
                    this.factType = res.data;
                })
                .catch(() => {
                    this.$Message.error({content: this.$t('Unable to retrieve country fact structure'), duration: 5})
                });
            this.loading = false;
        },
        methods: {
            analysisReposition(data) {  // We ignore data.moved.element.id and data.moved.newIndex
                const self = this;
                let promises = self.countryFacts.map(
                    (item, index) => patchCountryFactsQry(item.id, {position: index}));

                Promise.all(promises)
                    .then(res => {
                        self.$Message.success(self.$t('Item successfully moved'))
                    })
                    .catch(err => {
                        self.$Message.error(self.$t('There was an error moving an item'))
                    });
            },

            triggerAnalysisForm(data, idx) {
                this.showUpdateForm = true;
                this.$nextTick(() => {
                    if (data) {
                        this.$refs['analysis'].setUpdateData(data, idx);
                    }
                });

            },

            analysisCreated(data, idx) {
                if (idx === undefined) {
                    this.countryFacts.unshift(data)
                } else {
                    this.countryFacts.splice(idx, 1, data);
                }
            },

            triggerDelete(id, idx) {
                this.$AppModal.AppConfirm({
                    method: 'delete',
                    loading: true,
                    extraTitle: this.$t('Delete a section'),
                    message: this.$t('You are about to delete this section.'),
                    onConfirm: (() => {
                        deleteCountryFactQry(id)
                            .then(() => {
                                this.countryFacts.splice(idx, 1);
                                this.$Message.success(this.$t('Successfully removed a section.'));
                                this.$AppModal.remove()

                            })
                            .catch(() => {
                                this.$Message.error({content: this.$t('Error removing a section'), duration: 5});
                                this.$AppModal.remove()
                            })
                    })
                });
            },

            triggerCancel() {
                this.showUpdateForm = false;
            },
            triggerFilter(filters) {
                this.filterParams = filters;
                if (filters.updated) {
                    this.countryFacts = this.countryFacts.filter(a => dateFormat(a.updated) === dateFormat(filters.updated));
                }
                if (filters.title) {
                    this.countryFacts = this.countryFacts.filter(a => a.data.title.toLowerCase().includes(filters.title.toLowerCase()));
                }
                if (!filters.updated && !filters.title)
                    this.getCountryFacts();
            },
            getCountryFacts() {
                getCountryFactsQry({country: this.$route.params.country, country_fact_type: 'analysis'})
                  .then(res => {
                        this.countryFacts = res.data;  // FIXME handle pagination here?
                    })
                    .catch(() => {
                        this.$Message.error({content: this.$t('Cannot retrieve country facts'), duration: 5})
                    });
            }
        },
    }
</script>

<style>
    .analysis-content {
        background: #ffffff;
        padding: 10px 25px 10px 25px;
    }

    .analysis-content .analysis-item {
        margin: 15px 0px 30px 0px;
        padding: 20px;
        background: #fff;
        position: relative;
    }

    .analysis-content .analysis-item > div > div {
        margin-top: 10px;
    }

    .analysis-content .analysis-item-action {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .analysis-content .analysis-item-action .ivu-icon:first-of-type {
        margin-right: 5px;
    }

    .content-text {
        font-size: 12px;
    }

</style>
