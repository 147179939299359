<template>
  <AppModal
    v-model="value"
    :header-name="$t('Add referent')"
    :footer-hide="false"
    @click:submit="addReferents"
    @click:cancel="cancel"
    width="800"
    class="referent-modal"
  >
    <Row class="mt-3">
      <p class="mt-3">{{ this.$t(`Enter the e-mail address of the user you wish to invite.`) }}</p>
      <i-col :md="17" class="mt-3">
        <i-select type="text" :placeholder="$t('Managers')" v-model="tempReferent" clearable value-key="id">
          <i-option v-for="referent in referents" :key="referent.id" :value="referent.id">
            {{ referent.email }}
          </i-option>
        </i-select>
      </i-col>
    </Row>
    <input type="submit" hidden />

    <Spin v-if="loading" fix />
  </AppModal>
</template>

<script>
import AppModal from "@/components/common/AppModal";
import AppPhoneInput from "@/components/common/AppPhoneInput";
import { postInviteReferent } from "@/services/accounts.service";

export default {
  name: "AddReferent",

  components: {
    AppModal,
    AppPhoneInput,
  },
  props: ["value", "profile", "referents"],
  data() {
    return {
      tempReferent: [],
      editing: false,
      loading: false,
    };
  },
  computed: {
    currentProfile() {
      const userIdParams = this.$route.params.userId;
      const internalId = this.userProfileStore.getInternatlId;
      if (userIdParams == internalId) {
        return true;
      }
      return false;
    },
    getTempUpdate() {
      return this.tempUpdate;
    },
  },
  watch: {
    getTempUpdate(value) {
      if (this.cellInfo != null) {
        this.editing = true;
        this.cellInfo = value;
      }
    },
  },
  methods: {
    addReferents() {
      let tempId = [];
      this.profile.security_referents.forEach((referent) => {
        tempId.push(referent.id);
      });
      if (tempId.includes(this.tempReferent)) {
        this.$Message.error({
          content: this.$t("He's already your safety referent"),
          duration: 5,
        });
        return
      }
      this.loading = true;
      postInviteReferent({
        security_referents: this.tempReferent,
        user_id: this.profile.id,
        current_id: this.userProfileStore.getInternatlId
      })
        .then((res) => {
          const referent = res.data;
          if (res.data.cellphones != null) {
            res.data.cellphones.forEach((cellphone) => {
              if (cellphone.priority == 1 || cellphone.priority == "1") {
                referent.cellphone_priority = cellphone.number;
              }
            });
          }
          this.profile.security_referents.push(referent);
          this.$emit("spliceById", referent.id);
          this.$Message.success({
            content: this.$t("The safety referent has been added"),
            duration: 5,
          });
        })
        .catch((err) => {
          this.$Message.error({
            content: this.$t(err.response.data.non_field_errors[0]),
            duration: 8,
          });
        })
        .finally(() => {
          this.cancel();
        });
    },
    cancel() {
      this.$emit("input", false);
      this.loading = false;
      this.tempReferent = [];
    },
  },
};
</script>
