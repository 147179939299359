<template>
  <div class="full-height site-page">
    <Row>
      <i-col :xs="24" :md="24" :lg="24" class="text-center">
        <AdminExtraHeader v-if="s2ec.user.is_admin || s2ec.user.is_superuser" />
      </i-col>
    </Row>
    <Row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <i-col
        :xs="24"
        :md="16"
      >
        <SiteFilter
          @applyFilter="triggerFilter"
        />
      </i-col>
      <i-col :xs="12" :md="6" class="text-right">
        <Button
          v-if="s2ec.user.is_superuser || s2ec.user.is_admin || s2ec.user.is_manager"
          class="action-button"
          @click="triggerCreateSite"
        >
          {{ $t("Create a site") }}
        </Button>
      </i-col>
    </Row>
    <CreateSite
      v-if="s2ec.user.is_superuser || s2ec.user.is_admin || s2ec.user.is_manager"
      ref="createSite"
      v-model="showCreateSite"
      @siteCreated="siteCreated"
    />

    <Row type="flex" :gutter="10" class="site-wrapper">
      <i-col :xs="20" :md="8" :lg="7" :xxl="6" class="full-height">
        <p class="site-list-label">
          {{ $t("Site list") }}
        </p>
        <div class="site-content lavender-shadow">
          <div>
            <div
              v-for="(site, idx) in site_list"
              :key="site.id"
              class="site-item"
              :class="[selectedSite.id === site.id ? 'selected-site' : '']"
              @click="triggerSelectSite(site)"
            >
              <div class="site-item-inner-content">
                <div class="site-item-text-content">
                  <p style="font-size: 14px">
                    <strong>{{ site.name }}</strong>
                  </p>
                  <p v-if="site.head_of_site" class="thin-text">
                    {{ site.head_of_site_value.first_name }}
                    {{ site.head_of_site_value.last_name }}
                  </p>
                  <p class="thin-text">
                    {{ site.city
                    }}<span v-if="site.city && site.country_name">,</span>
                    {{ site.country_name }}
                  </p>
                  <p v-if="s2ec.user.is_superuser" class="thin-text">
                    {{ site.client_name }}
                  </p>
                </div>
                <div
                  v-if="
                    s2ec.user.is_superuser ||
                    s2ec.user.is_admin ||
                    s2ec.user.is_manager
                  "
                  class="site-item-action"
                >
                  <div class="icon-circle update">
                    <Icon
                      size="14"
                      type="md-create"
                      :title="$t('Edit')"
                      color="#FFFFFF"
                      class="pointer-cursor"
                      @click="triggerSiteUpdate(site, idx)"
                    />
                  </div>
                  <div class="icon-circle remove">
                    <Icon
                      size="14"
                      type="md-trash"
                      :title="$t('Delete')"
                      color="#FFFFFF"
                      class="pointer-cursor"
                      @click="triggerSiteDelete(site, idx)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <infinite-loading
              :identifier="identifier"
              @infinite="infiniteSiteScroll"
            >
              <span slot="no-more" />
            </infinite-loading>
          </div>
        </div>
      </i-col>
      <i-col :xs="20" :md="16" :lg="17" :xxl="18" class="full-height">
        <div class="site-detail-content lavender-shadow full-height">
          <SiteDetail
            v-show="selectedSite && selectedSite.id"
            ref="siteDetails"
            :site="selectedSite"
          />
        </div>
      </i-col>
    </Row>
  </div>
</template>

<script>
import CreateSite from "./modals/CreateSite";
import SiteDetail from "./SiteDetail";
import SiteFilter from "./SiteFilter";
import AdminExtraHeader from "../header/AdminExtraHeader";
import { getSitesQry, deleteSiteQry } from "@/services/sites.service";
import { getStringUrlParams } from "@/helpers/common.helper";
import infiniteLoading from "vue-infinite-loading";

export default {
  name: "Sites",
  components: {
    AdminExtraHeader,
    CreateSite,
    SiteDetail,
    SiteFilter,
    infiniteLoading,
  },
  data() {
    return {
      showCreateSite: false,
      selectedSite: { id: null },
      identifier: false,
      sites: {
        count: 1,
        next: null,
        previous: null,
        results: [],
      },
      site_list: [],
      filterParams: {},
      additionalFilters: {},
    };
  },
  created() {
    this.$eventBus.$on("clientContextSelected", this.resetClientSpecificData);
  },
  beforeDestroy() {
    this.$eventBus.$off("clientContextSelected", this.resetClientSpecificData);
  },
  methods: {
    async infiniteSiteScroll($state) {
      if (this.sites.next)
        this.filterParams = Object.assign(
          this.filterParams,
          getStringUrlParams(this.sites.next)
        );
      const params = {
        ...this.filterParams,
        ...this.additionalFilters,
        supervised: true,
      };
      getSitesQry(params)
        .then((res) => {
          this.sites = res.data;
          this.triggerSelectSite(res.data.results[0] || null);
          this.site_list.push(...res.data.results);
          $state.loaded();
          if (!this.sites.next) {
            $state.complete();
          }
        })
        .catch((err) => {
          this.$Message.error({
            content: this.$t("Error retrieving sites"),
            duration: 5,
          });
        });
    },

    triggerFilter(filters) {
      this.filterParams = filters;
      this.resetDataName(this, "sites");
      this.site_list = [];
      this.identifier = !this.identifier;
    },

    siteCreated(data, idx) {
      if (idx !== undefined) {
        this.site_list.splice(idx, 1, data);
      } else {
        this.site_list.unshift(data);
      }
    },

    triggerSiteUpdate(site, idx) {
      this.$refs["createSite"].setUpdateData(site, idx);
      this.showCreateSite = true;
    },

    triggerSiteDelete(site, idx) {
      this.$AppModal.AppConfirm({
        method: "delete",
        loading: true,
        extraTitle: this.$t("Delete a site"),
        message: this.$t("Are you sure you want to delete this site?"),
        cancelText: this.$t("Cancel"),
        onConfirm: () => {
          deleteSiteQry(site.id)
            .then(() => {
              this.sites.count -= 1;
              this.site_list.splice(idx, 1);
              this.$Message.success(
                this.$t("Successfully deleted attached location")
              );
              this.$AppModal.remove();
            })
            .catch(() => {
              this.$Message.error({
                content: this.$t("Error deleting attached location"),
                duration: 5,
              });
              this.$AppModal.remove();
            });
        },
      });
    },

    triggerSelectSite(site) {
      if (this.selectedSite === site) return; // Nothing to do

      if (site) {
        this.selectedSite = site;
        this.$refs.siteDetails.getSelectedSiteDetails(site);
      } else {
        this.selectedSite = { id: null };
        this.$refs.siteDetails.resetSiteDetails();
      }
    },

    triggerCreateSite() {
      if (
        this.userProfileStore._internalUser.selectedCompany ||
        !(this.s2ec.user.is_admin || this.s2ec.user.is_superuser)
      ) {
        this.showCreateSite = true;
      } else {
        this.$eventBus.$emit("allowAdminAction");
      }
    },
    resetClientSpecificData(value) {
      this.additionalFilters["client"] = value;
      this.sites = {};
      this.selectedSite = { id: null };
      this.site_list = [];
      this.identifier = !this.identifier;
    },
  },
};
</script>

<style scoped>
.site-wrapper {
  height: calc(100% - 80px);
  justify-content: center;
}

.site-page .site-list-label {
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
  font-weight: 600;
  height: 38px;
  border: solid 1px var(--border);
  border-bottom: none;
}

.site-page .site-content {
  background: #ffffff;
  overflow: auto;
  border: solid 1px var(--border);
  height: calc(100% - 38px);
}

.site-page .site-detail-content {
  padding: 10px;
  background: #ffffff;
  border: solid 1px var(--border);
  border-radius: 10px;
}

.site-page .site-content > div {
  padding-top: 20px;
}

.site-page .site-item {
  margin: 0 20px 10px 20px;
}

.site-page .site-item .site-item-inner-content {
  position: relative;
  padding: 10px 30px 10px 10px;
  height: 95px;
  background: #fff;
  border-radius: 10px;
  border: solid 1px #3c3c3c23;
}

.site-page .site-item-action {
  position: absolute;
  display: flex;
  top: 10px;
  right: 10px;
}

.site-page .site-item .site-item-inner-content:hover,
.site-page .site-item.selected-site .site-item-inner-content {
  background: #5aaff2;
  color: #ffffff;
}

.icon-circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0 4px;
}

.site-item.selected-site .site-item-action .icon-circle {
  background: white;
}
.site-item.selected-site .site-item-action .icon-circle.update .ivu-icon {
  color: #000747 !important;
}

.site-item.selected-site .site-item-action .icon-circle.remove .ivu-icon {
  color: #ce464a !important;
}

.update {
  background: #000747;
}

.remove {
  background: #ce464a;
}
</style>
