<template>
  <div
    class="general-overview-devices"
    :class="showDeviceContent ? '' : 'close-menu'"
  >
    <div class="full-height">
      <div class="device-header">
        <strong class="text-capitalize"
          >{{ $t("Geolocation") }} ({{ value.results.length }})
        </strong>
        <div class="event-header-action">
          <Icon
            id="show-device-content"
            :type="showDeviceContent ? 'ios-arrow-down' : 'ios-arrow-up'"
            size="20"
            color="#000000"
            @click="showDeviceContent = !showDeviceContent"
          />
        </div>
      </div>
      <SmsSendingPopup
        ref="emergencyPopup"
        v-model="showSmsSendingPopup"
        :recipients="selectedDevices"
      />

      <div v-show="showDeviceContent" class="general-overview-device-content">
        <Row>
          <i-col :xs="21">
            <div style="padding: 10px 20px 10px 20px">
              <i-select
                v-model="searchEmail"
                size="large"
                :placeholder="$t('Search a user')"
                filterable
                clearable
                class="input-rounded"
                style="width: 100%;"
                @on-change="triggerEmailSearch"
              >
                <i-Option
                  v-for="device in devices"
                  :key="device.user_value.id"
                  :value="device.user_value.email"
                >
                  {{ device.user_value | userDisplay }}
                </i-Option>
              </i-select>

              <DeviceFilter
                v-show="showFilter"
                :clientList="clients"
                :countryList="worldInfoStore.countryList"
                @applyFilter="triggerFilter"
                @resetList="resetDeviceList"
              />
            </div>
          </i-col>
          <i-col :xs="3">
            <Button
              @click="showFilter = !showFilter"
              class="btn-filter"
              :class="showFilter ? 'btn-active' : ''"
            >
              <Icon size="22" type="md-funnel" style="margin: -12px" />
            </Button>
          </i-col>
        </Row>
        <div class="hr"></div>
        <Row
          v-if="
            s2ec.user.is_superuser || s2ec.user.is_admin || s2ec.user.is_manager
          "
          type="flex"
          justify="center"
        >
          <i-col :sm="24" align="left" style="padding: 0px 20px 0px 20px">
            <Button
              size="large"
              type="text"
              class="btn-sendsms"
              :class="selectedDevices.length ? 'bounce' : ''"
              :disabled="!selectedDevices.length"
              @click="showSendSmsPopup()"
            >
              <Icon
                type="md-text"
                size="20"
                style="margin: auto"
                :class="selectedDevices.length ? 'btn-sms' : ''"
              />
            </Button>
            <p class="mt-3">{{ $t("Send SMS") }}</p>
          </i-col>
        </Row>
        <Row>
          <div class="hr"></div>
        </Row>
        <div
          class="general-overview-device-items"
          :class="{
            'device-items-with-filter-operator':
              showFilter && isOperatorOrAbove(),
            'device-items-with-filter': showFilter && !isOperatorOrAbove(),
          }"
        >
          <div class="device">
            <Card
              v-for="device in value.results"
              :key="device.id"
              class="general-overview-device-item-card"
              @click.native="deviceClick(device)"
            >
              <div class="general-overview-item-content">
                <i-col
                  v-if="
                    s2ec.user.is_superuser ||
                    s2ec.user.is_admin ||
                    s2ec.user.is_manager
                  "
                  :sm="2"
                  align="left"
                  style="padding: 10px"
                >
                  <input
                    v-if="device.user_value.cellphone !== ''"
                    :id="'device' + device.id"
                    v-model="selectedDevices"
                    type="checkbox"
                    class="device-selection-checkbox"
                    :value="device.user_value.id"
                    @click.stop
                  />
                  <input
                    v-else
                    disabled
                    :id="'device' + device.id"
                    v-model="selectedDevices"
                    type="checkbox"
                    class="device-selection-checkbox"
                    :value="device.user_value.id"
                    @click.stop
                  />
                </i-col>
                <i-col :sm="15" style="padding: 13px">
                  <p class="p1">
                    <span>{{ device.user_value | userDisplay }}</span>
                  </p>
                  <p>
                    {{ device.latest_device_track | location(addressSequence) }}
                  </p>
                  <p>
                    <span>{{ device.user_value.client_name }}</span>
                  </p>
                </i-col>
                <i-col :sm="7" align="center">
                  <!-- ICON PHONE EMERGENCY -->
                  <div v-if="device.has_emergency" class="white-circle">
                    <Icon
                      type="ios-call"
                      size="25"
                      style="margin: auto; color: #d14646"
                    />
                  </div>
                  <!-- ICON POSITION -->
                  <div v-else-if="device.has_alert" class="white-circle">
                    <img
                      src="@/assets/images/icon/device/device_location.svg"
                      style="margin: auto; vertical-align: middle"
                    />
                  </div>
                  <!-- NO SOUCIS -->
                  <div v-else class="white-circle">
                    <Icon
                      type="md-phone-portrait"
                      size="25"
                      style="margin: auto; color: #312f78"
                    />
                  </div>
                  <p v-if="device.latest_device_track" class="text-track">
                    {{ device.latest_device_track.updated_at | fromNow }}
                  </p>
                </i-col>
              </div>
            </Card>
            <infinite-loading
              :identifier="identifier"
              @infinite="onInfiniteSiteScroll"
            >
              <span slot="no-more" />
            </infinite-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import infiniteLoading from "vue-infinite-loading";
import { getDevicesQry } from "@/services/devices.service";
import { getClientsQry } from "@/services/accounts.service";
import { getStringUrlParams } from "@/helpers/common.helper";
import moment from "moment";
import DeviceFilter from "./DeviceFilter";
import SmsSendingPopup from "./SmsSendingPopup";

export default {
  name: "Devices",
  components: {
    DeviceFilter,
    infiniteLoading,
    SmsSendingPopup,
  },
  props: ["value"],
  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    },
    location(data, addressSequence) {
      // FIXME what are these parameters for?
      if (data) {
        return data.country_name + " - " + data.city;
      } else return "";
    },
  },
  data() {
    return {
      showSmsSendingPopup: false,
      showDeviceContent: true,
      showFilter: false,
      identifier: false,
      devices: [],
      addressSequence: ["country_name", "city"], // We may add "address" here
      searchEmail: "",
      filterParams: {},
      selectedDevices: [],
      clients: [],
      selectedClient: null,
    };
  },
  created() {
    if (this.isOperatorOrAbove()) this.fetchClients();
    this.devices = this.value.results;
    this.$eventBus.$on("clientContextSelected", this.resetCompanySpecificData);
  },
  beforeDestroy() {
    this.$eventBus.$off("clientContextSelected", this.resetCompanySpecificData);
  },
  methods: {
    onInfiniteSiteScroll($state) {
      let company = this.userProfileStore.getSelectedCompany;
      let getQueryParams = {
        page_size: 500,
        company: company,
        user__email: this.searchEmail,
        ...this.filterParams,
      };

      if (this.value.next) {
        Object.assign(getQueryParams, getStringUrlParams(this.value.next));
      }

      getDevicesQry(getQueryParams)
        .then((res) => {
          const { results, ...data } = res.data;

          const alertDevice = results.filter(
            (device) =>
              device.has_alert === true && device.has_emergency === false
          );
          const emergencyDevice = results.filter(
            (device) => device.has_emergency === true
          );
          const safeDevice = results.filter(
            (device) =>
              device.has_emergency === false && device.has_alert === false
          );

          Object.assign(this.value, data);
          this.value.results.push(
            ...emergencyDevice,
            ...alertDevice,
            ...safeDevice
          );
          $state.loaded();
          if (!this.value.next) {
            $state.complete();
          }
        })
        .catch(() => {
          this.$Message.error(this.$t("Error retrieving devices"));
        });
    },
    showSendSmsPopup() {
      if (!this.selectedDevices.length) return;
      this.showSmsSendingPopup = true;
    },
    deviceClick(device) {
      this.$emit("deviceClick", device);
    },
    getDeviceIconAndTitle(device) {
      var iconName, title;
      var thresholdDate = moment().subtract(4, "hours"); // CRITERIUM: not green after 4h
      var gpsOnStr = this.$t("GPS turned on");
      var gpsOffStr = this.$t("GPS turned off");
      if (
        device.latest_device_track &&
        moment(device.latest_device_track.updated) > thresholdDate
      ) {
        title = this.$t("Device located less than 4h ago") + " - ";
        if (device.geolocation_activated) {
          iconName = "located-gpson.png";
          title += gpsOnStr;
        } else {
          iconName = "located-gpsoff.png";
          title += gpsOffStr;
        }
      } else {
        title = this.$t("Device not located less than 4h ago") + " - ";
        if (device.geolocation_activated) {
          iconName = "lost-gpson.png";
          title += gpsOnStr;
        } else {
          iconName = "lost-gpsoff.png";
          title += gpsOffStr;
        }
      }
      return [require("@/assets/images/icon/device/" + iconName), title];
    },
    resetDeviceList() {
      this.selectedDevices = [];
      this.$emit("input", {
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
      this.identifier = !this.identifier;
    },
    triggerEmailSearch() {
      // Nothing else to do, search params are already set
      this.resetDeviceList();
    },
    triggerFilter(filters) {
      this.filterParams = filters;
      this.resetDeviceList();
    },
    filterExpanded(isExpanded) {
      this.filterIsExpanded = isExpanded;
      var oldFilterParams = this.filterParams;
      this.filterParams = {};
      if (this.searchEmail) {
        this.searchEmail = ""; // Will trigger reload of data anyway
        return;
      }
      if (Object.keys(oldFilterParams).length) {
        this.resetDeviceList(); // We must force a refresh now
      }
    },
    resetCompanySpecificData() {
      this.$emit("input", {
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
      this.identifier = !this.identifier;
    },
    fetchClients() {
      getClientsQry()
        .then((res) => {
          this.clients = res.data.results;
        })
        .catch(() => {
          this.$Message.error({
            content: this.$t("Error occurred retrieving clients"),
            duration: 5,
          });
        });
    },
  },
};
</script>

<style scoped>
.general-overview-devices {
  z-index: 200;
  position: absolute;
  height: 98%;
  right: 20px;
  top: 10px;
  width: 400px;
  box-shadow: rgb(0 0 0 / 80%) 0px 1px 4px -1px;
  border-radius: 5px;
  overflow: hidden;
}

.general-overview-devices * {
  scrollbar-color: rgba(0255, 255, 255, 0.5) rgba(0, 0, 0, 0.1) !important;
  scrollbar-width: thin !important;
}

.general-overview-devices ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  margin-left: 2px;
  background: rgba(0, 0, 0, 0.1);
}

.general-overview-devices ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}

.btn-active {
  color: var(--secondary-bleu) !important;
}

.ivu-select-dropdown-list {
  font-size: 11px !important;
}

.general-overview-devices .device-header {
  background: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.general-overview-devices .general-overview-device-content {
  height: calc(100% - 40px) !important;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0;
}

.close-menu {
  height: 40px !important;
}

.general-overview-device-items {
  height: calc(100% - 46px - 46px - 35.66px - 5px);
  overflow-y: auto;
}

.device-items-with-filter-operator {
  height: calc(100% - 190px - 46px - 35.66px - 5px) !important;
}

.device-items-with-filter {
  height: calc(100% - 154px - 46px - 35.66px - 5px) !important;
}

.general-overview-devices .general-overview-device-item-card {
  margin-bottom: -3px !important;
  min-height: 50px;
  font-size: xx-small;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background: none !important;
  border: none !important;
  border-radius: 0px !important;
  overflow: hidden;
}
.general-overview-device-item-card:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.07) !important;
}

.general-overview-devices .ivu-card-body {
  padding: 0px !important;
}

.general-overview-devices .ivu-card-body img {
  height: 23px !important;
  width: auto !important;
}

.general-overview-devices
  .ivu-select-large.ivu-select-single
  .ivu-select-selection {
  height: 40px;
  border-radius: 5px;
}

.ivu-input .ivu-input-large {
  border-radius: 5px;
}

.general-overview-devices .device-selection-checkbox {
  margin-top: 23px;
}

p.from-now-text {
  font-size: 12px;
  font-weight: 500;
}

.general-overview-devices p {
  color: black !important;
  font-size: 12px;
}

.p1 {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.btn-sendsms {
  background-color: white;
  border-radius: 5px;
  width: 20px;
  justify-content: center;
  display: flex;
  float: left;
  margin-right: 20px;
}

.hr {
  height: 1px;
  width: 100%;
  background-color: white;
  margin: 10px 0 12px;
}

.white-circle {
  height: 34px;
  width: 34px;
  margin-top: 15px;
  border-radius: 50px;
  display: flex;
  background-color: white;
  margin-bottom: 7px;
}

.text-track {
  margin: -3px -3px 0 0px;
  font-size: 10px !important;
}

.btn-sms {
  color: var(--secondary-bleu) !important;
}

.bounce {
  animation: bounce-in 0.3s;
}
@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.btn-sendsms:hover {
  background-color: #f8f8f8;
}
.ivu-input.ivu-input-large {
  border-radius: 5px;
}

.btn-filter {
  margin: 10px -6px -26px;
  width: 34px;
  border-radius: 5px;
  color: #a6a6a6;
}

.btn-filter:hover {
  color: #a6a6a6;
}

.input-rounded {
  background-color: #ffffffcf;
}
</style>