<template>
  <div>
    <Card class="country-general">
      <div class="general-header">
        <div>
          <Icon
            type="md-pin"
            :size="25"
            style="margin-right: 15px"
          />
          <strong>{{ $t('Points') }}</strong>
        </div>
        <Icon
          v-if="(s2ec.user.is_admin || s2ec.user.is_superuser) && !showPintsForm"
          type="md-add"
          size="25"
          class="pointer-cursor"
          v-bind="getTranslatableContentAttributes()"
          @click.native="showCreate()"
        />
      </div>
      <div v-if="showPintsForm">
        <PointsForm
          v-if="s2ec.user.is_admin || s2ec.user.is_superuser"
          ref="points"
          :fact-type="factType"
          @closeForm="closeForm"
          @pointsCreated="pointsCreated"
        />
      </div>
      <div
        v-else
        class="points-body"
      >
        <div
          v-for="(value, key, index) in structuredPoints"
          :key="index"
        >
          <strong> {{ value[0].point_type_value }} </strong>
          <div
            v-for="(point, idx) in value"
            :key="point.id"
            class="general-content"
          >
            <Row
              type="flex"
              :gutter="15"
              class="points-list"
              align="middle"
            >
              <i-col
                :xs="24"
                :md="8"
                :lg="8"
                :xl="8"
              >
                <p>{{ point.name }}</p>
                <p v-if="point.point_type === 5">
                  {{ $t('Emergency number') }}
                </p><!-- FIXME -->
              </i-col>
              <i-col
                :xs="24"
                :md="4"
                :lg="6"
                :xl="4"
              >
                <p> {{ point.landline }} </p>
                <p> {{ point.emergency_contact }} </p>
              </i-col>
              <i-col
                :xs="24"
                :md="10"
                :lg="8"
                :xl="10"
              >
                <p> {{ point | full_address(addressSequence) }} </p>
              </i-col>
              <i-col :span="2" class="points-update-icon">
                <Icon
                  v-if="s2ec.user.is_admin || s2ec.user.is_superuser"
                  type="md-create"
                  size="20"
                  :title="$t('Edit')"
                  v-bind="getTranslatableContentAttributes()"
                  class="pointer-cursor"
                  @click.native="showCreate(point, idx)"
                />
                <Icon
                  v-if="s2ec.user.is_admin || s2ec.user.is_superuser"
                  type="md-trash"
                  size="20"
                  v-bind="getTranslatableContentAttributes()"
                  class="pointer-cursor"
                  @click.native="triggerDelete(point, idx)"
                />
              </i-col>
            </Row>
          </div>
        </div>
        <div
          v-if="pointsCount === 0"
          class="empty-fact-type"
        >
          <h4 class="text-center">
            {{ $t('No data') }}
          </h4>
        </div>
      </div>
      <Spin
        v-if="loading"
        fix
      />
    </Card>
  </div>
</template>

<script>
    import {
        deletePointOfInterestQry,
        getPointOfInterestQry
    } from "../../../services/countryInfo.service";
    import PointsForm from "./PointsForm"

    export default {
        name: "Points",
        components: {
            PointsForm
        },
        props: ['country'],

        data() {
            return {
                countryFact: [],
                factType: {},
                loading: false,
                showPintsForm: false,
                addressSequence: ['address', 'city', 'country_name'],
                structuredPoints: {},
                pointsCount: 0,
            }
        },
        mounted() {
            this.loading = true;
            const params = {country: this.$route.params.country, page_size: 1000};
            getPointOfInterestQry(params)
                .then(res => {
                    this.loading = false;
                    this.pointsCount = res.data.count;
                    this.structuredPoints = res.data.results.reduce((acc, cur) => {
                        if (cur.point_type_value in acc) {
                            acc[cur.point_type_value].push(cur);
                        } else {
                            acc[cur.point_type_value] = [cur];
                        }
                        return acc

                    }, {});

                })
                .catch(err => {
                    this.loading = false;
                    this.$Message.error(this.$t('Error retrieving points'));
                })

        },
        methods: {
            pointsCreated(data, idx, previousPointType) {
                if (idx !== null) {
                    if (data.point_type_value === previousPointType) {
                        this.structuredPoints[data.point_type_value].splice(idx, 1, data)
                    } else {
                        this.structuredPoints[previousPointType].splice(idx, 1);
                        if (this.structuredPoints[previousPointType].length === 0) {
                            delete this.structuredPoints[previousPointType];
                        }

                        if (data.point_type_value in this.structuredPoints) {
                            this.structuredPoints[data.point_type_value].unshift(data);
                        } else {
                            this.structuredPoints[data.point_type_value] = [data];
                        }
                    }

                } else {
                    this.pointsCount += 1;
                    if (data.point_type_value in this.structuredPoints) {
                        this.structuredPoints[data.point_type_value].unshift(data);
                    } else {
                        this.structuredPoints[data.point_type_value] = [data];
                    }
                }
            },

            triggerDelete(data, idx) {
                this.$AppModal.AppConfirm({
                    method: 'delete',
                    loading: true,
                    extraTitle: this.$t('Delete a point'),
                    message: this.$t('You are about to delete this point. \nContinue?'),
                    onConfirm: (() => {
                        deletePointOfInterestQry(data.id)
                            .then(() => {
                                this.structuredPoints[data.point_type_value].splice(idx, 1);
                                if (this.structuredPoints[data.point_type_value].length === 0) {
                                    delete this.structuredPoints[data.point_type_value];
                                }
                                this.$Message.success(this.$t('Successfully removed a point'));
                                this.$AppModal.remove()

                            })
                            .catch(() => {
                                this.$Message.error({content: this.$t('Error removing a point'), duration: 5});
                                this.$AppModal.remove()
                            })
                    })
                });
            },

            showCreate(data, idx) {
                this.showPintsForm = true;
                if (data) {
                    this.$nextTick(() => {
                        this.$refs['points'].setUpdateData(data, idx, data.point_type_value);
                    })
                }
            },
            closeForm() {
                this.showPintsForm = false;
            }
        }
    }
</script>

<style scoped>
    .points-body {
        padding: 15px 0 20px 20px;
        background: #fff;
    }

    .points-body > div:not(:last-of-type) {
        margin-bottom: 20px;
    }

    .general-content {
        margin-top: 15px;
    }

    .points-body .general-content .points-list {
        margin-bottom: 5px;
    }


    .points-body .points-update-icon {

    }

</style>
