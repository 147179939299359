<template>
  <i-select
    ref="adminCompanySelector"
    v-model="selectedCompany"
    filterable=""
    :placeholder="$t('Select company')"
    class="admin-company-selector"
    :class="[ companySelectorError ? 'error':'' ]"
    clearable
    @on-change="companySelected"
  >
    <Option
      v-for="company in company_list"
      :key="company.id"
      :value="company.id"
      :label="company.client_name"
    />
  </i-select>
</template>

<script>
    import {getClientsQry} from "../../services/accounts.service";
    import {mapState} from "vuex";

    export default {
        name: "AdminExtraHeader",
        data() {
            return {
                companies: {},
                company_list: [],
                companySelectorError: false,
            }
        },
        computed: {
            selectedCompany: {
                get: function () {
                    return this.userProfileStore.getSelectedCompany;
                },
                set: function (value) {
                    this.companySelectorError = false;
                    this.userProfileStore._internalUser.selectedCompany = value;
                }
            }
        },
        created() {
            this.getCompaniesQuery();
            this.$eventBus.$on("allowAdminAction", this.handleAllowAdminAction);
        },
		beforeDestroy() {
		    this.$eventBus.$off("allowAdminAction", this.handleAllowAdminAction);
	    },
        methods: {
            getCompaniesQuery() {
                getClientsQry()
                    .then(res => {
                        this.companies = res.data;
                        this.company_list = res.data.results;
                    })
                    .catch(() => {
                        this.$Message.error({content: this.$t('Error occurred retrieving companies'), duration: 5});
                    })
            },
            companySelected(value) {
                this.$eventBus.$emit('clientContextSelected', value);
            },
			handleAllowAdminAction() {
				this.companySelectorError = true;
				if (this.company_list.length > 0) {
					this.$refs['adminCompanySelector'].toggleMenu();
				}
			}
        }
    }
</script>

<style>
	.admin-company-selector {
		width: 320px;
	}

    .ivu-select-dropdown {
        z-index: 10001;
    }
	.admin-company-selector.error {
		border: 1px solid #ed4014;
	}

	.admin-company-selector.error.ivu-select-visible .ivu-select-selection {
		border: none;
		box-shadow: none;
	}

</style>
