<template>
  <infiniteLoading
    :identifier="identifier"
    @infinite="infinite"
  >
    <div slot="no-more" />
    <div slot="no-results" />
  </infiniteLoading>
</template>

<script>
    // TODO - use this component everywhere instead of <infiniteLoading>? Or remove it instead?
    import infiniteLoading from "vue-infinite-loading"

    export default {
        name: "AppScroller",
        components: {
            infiniteLoading
        },
        props: ['identifier', 'allowTrigger'],
        mounted() {
            this.$emit('infinite', null);
        },
        methods: {
            infinite($state) {
                    if (this.allowTrigger) {
                        this.$emit('infinite', $state);
                    } else {
                        $state.complete();
                    }

            }
        }
    }
</script>

<style scoped>

</style>
