<template>
  <Transition name="slide-fade" appear>
      <div style="padding-top: 10px">
        <ClientSelector
          v-if="isOperatorOrAbove()"
          v-model="params"
          :clients="clientList"
          class="input-rounded"
          @clientSelected="applyFilter"
        />
        <i-Select
          v-model="params.country"
          :placeholder="$t('Country')"
          transfer
          clearable
          filterable
          size="large"
          class="mt-3 input-rounded"
          @on-change="applyFilter"
        >
          <i-Option
            v-for="item in countryList"
            :key="item.value"
            :value="item.value"
            :label="$t(item.display_name)">
              <img :src="item.flag" alt="Drapeau du pays" class="flag-image" style="position: inherit;"/>
              <span class="ml-4">{{ $t(item.display_name) }}</span>
          </i-option>
        </i-Select>
        <Input
          v-model="params.city"
          search
          type="text"
          :placeholder="$t('City')"
          size="large"
          class="mt-3 input-rounded"
          @on-search="applyFilter"
          @on-clear="resetList"
          clearable
        />
      </div>
  </Transition>
</template>

<script>
import ClientSelector from "../../accounts/ClientSelector";

export default {
  name: "DeviceFilter",
  components: {
    ClientSelector,
  },
  props: {
    countryList: {
      required: true,
      type: Array,
    },
    clientList: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      expand: false,
      deviceExpand: {
        height: "50px",
      },
      params: {
        country: null,
        city: null,
        geolocation_activated: null,
        client: null,
      },
    };
  },
  computed: {},
  methods: {
    expandFilter() {
      this.deviceExpand.height = !this.expand ? "100px" : "50px";
      this.expand = !this.expand;
      this.$emit("filterExpanded", this.expand);
    },
    applyFilter() {
      this.$emit("applyFilter", this.params);
    },
    resetList() {
      this.$emit("resetList");
    },
  },
};
</script>

<style>
.general-overview-devices .device-expand {
  font-size: 10pt;
  padding-top: 10px;
  padding-bottom: 5px;
}

.general-overview-devices .device-card {
  background: transparent;
  box-shadow: none;
}

.general-overview-devices input.ivu-select-input:-moz-read-only,
.general-overview-devices input.ivu-select-input:read-only {
  background-color: #f3f3f3 !important;
}

.slide-fade-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  opacity: 0;
}

.slide-fade-leave-active {
  transition: all 0.2s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-40px);
  opacity: 0;
}

.slide-fade-enter-active {
  animation: bounce-in 0.5s;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.input-rounded {
  background-color: #ffffffcf;
}
</style>