import { defineStore } from "pinia";
import {
  getNotificationsQry,
  deleteNotifications,
  deleteNotificationsQry,
  patchNotificationsQry,
  markNotificationsRead,
} from "../services/notification.service";
import { getStringUrlParams } from "@/helpers/common.helper";

export const useNotificationStore = defineStore("notification", {
  //
  // Be aware to update readme of frontend when the notification structure change
  //
  state: () => ({
    _internalNotificationList: {
      results: [],
      count: 0,
      unread: 0,
      next: null,
      previous: null,
      indexOfPageToReload : 0, 
    },
    _notificationsSelected: [], 
    _idsPresent: [], 
    _deleteLoading: false,
    _readLoading: false,
    _isLoading: false,
    recentNotification: {},
  }),
  getters: {
    lastNotification: (state) => state.recentNotification,
    getUnreadNotifications: (state) => state._internalNotificationList.unread,
    getNotifications: (state) => state._internalNotificationList.results
  },
  actions: {
    setLastNotification(notif) {
      this.recentNotification = notif
    },
    decrementUnreadNotification() {
      this._internalNotificationList.unread--
    },
    _resetLastNotification() {
      this.recentNotification = {};
    },
    _infiniteHandler($state) {
      /* It's OK if multiple requests are fired in parallel, the AXIOS cache handles that */
      if (this._isLoading) {
        return;
      }
      this._isLoading = true;
      let params;

      // Adjust page to load
      if (this._internalNotificationList.indexOfPageToReload !== 0) {
        params = this._internalNotificationList.indexOfPageToReload;
        this._internalNotificationList.indexOfPageToReload = 0;
      } else {
        params = this._internalNotificationList.next ? getStringUrlParams(this._internalNotificationList.next) : null;
      }
      getNotificationsQry(params)
        .then((res) => {
          if ($state) {
            $state.loaded();
            if (this._internalNotificationList.results.length > 0 && !this._internalNotificationList.next) {
              $state.complete();
              return;
            }
          }
          this._internalNotificationList.next = res.data.next;
          this._internalNotificationList.count = res.data.count;
          this._internalNotificationList.unread = res.data.unread;
          this._internalNotificationList.previous = res.data.previous;
          if (!this._internalNotificationList.next && $state) {
            $state.complete();
          }

          res.data.results.forEach((notif) => {
            // Skip notifications already load
            if (!this._idsPresent.includes(notif.id)) {
              this._idsPresent.push(notif.id);
              this._internalNotificationList.results.push(notif);
            }
          });
          this._isLoading = false;
        })
        .catch((err) => {
          this._isLoading = false;
          console.log(err);
        });
    },
    _deleteNotificationMultiple() {
      if (this._notificationsSelected.length) {
        let tempIds = [];
        let tempIdx = [];
        this._notificationsSelected.forEach((notif) => {
          tempIds.push(notif.id);
          tempIdx.push(notif.idx);
        });

        this._deleteLoading = true;
        deleteNotifications(tempIds)
          .then(() => {
            this._notificationsSelected.forEach((notif) => {
              if (!notif.read) {
                this._internalNotificationList.unread--;
              }
            });
            tempIdx.sort();
            for (var i = tempIdx.length - 1; i >= 0; i--) {
              this._internalNotificationList.results.splice(tempIdx[i], 1);
            }
            // FIX : I put new page at null to load the new data
            this._internalNotificationList.indexOfPageToReload = this._retunPageToLoad(tempIdx[0]);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this._notificationsSelected = [];
            this._deleteLoading = false;
          });
      }
    },

    _deleteNotification(notification, index) {
      if (!this._deleteLoading) {
        if (!notification.read) {
          this._internalNotificationList.unread--;
        }
        this._internalNotificationList.results.splice(index, 1);
        this._deleteLoading = true;
        deleteNotificationsQry(notification.id.toString())
          .then(() => {
            // FIX : I put new page at null to load the new data
            this._internalNotificationList.indexOfPageToReload = this._retunPageToLoad(index);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this._deleteLoading = false;
          });
      }
    },

    _markMultipleNotificationAsRead() {
      if (this._notificationsSelected.length) {
        this._readLoading = true;

        let tempId = [];
        let tempIdx = [];
        this._notificationsSelected.forEach((notif) => {
          tempId.push(notif.id);
          tempIdx.push(notif.idx);
        });
        markNotificationsRead(tempId, { read: true })
          .then(() => {
            tempIdx.sort();
            this._notificationsSelected.forEach((notif) => {
              if (!notif.read) {
                this._internalNotificationList.unread--;
                this._internalNotificationList.results[notif.idx].read = true;
              }
            });
            // FIX : I put new page at null to load the new data
            this._internalNotificationList.indexOfPageToReload = this._retunPageToLoad(tempIdx[0]);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this._notificationsSelected = [];
            this._readLoading = false;
          });
      }
    },
    _markNotificationAsRead(notification, idx) {
      if (!notification.read) {
        this._internalNotificationList.unread--;
        this._internalNotificationList.results[idx].read = true;
      }
      patchNotificationsQry(notification.id.toString(), { read: true })
        .then(() => {
          // FIX : I put new page at null to load the new data
          this._internalNotificationList.indexOfPageToReload = this._retunPageToLoad(idx);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Refer to pagination.py
    // Returns the page where the index is located
    _retunPageToLoad(idx) {
      let pageToLoad = Math.floor(idx/20); 
      return pageToLoad;
    },
  },
});
