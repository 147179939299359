
<template>
  <!----------- [START] MODAL "TRAVEL INFOS" ----------->
  <AppModal
    v-model="uiSettings.isVisible"
    :header-name="$t('Mission Infos')"
    class-name="mission-infos-modal"
    :closable="true"
    :width="1200"
  >
    <div class="scrolling-container">
      <Row
        :gutters="0"
        class="d-flex mt-5 px-5"
      >
        <i-col
          span="24"
        >
        <h3>{{ $t("Mission") }}</h3>
        <Table
          :columns="moduleComponents.mainInfosTable.headers"
          class="mt-4 table-format"
          :data="getMainInfosTableData"
        />
        </i-col>
      </Row>

      <Row
        :gutters="0"
        class="d-flex mt-5 px-5"
      >
        <i-col
          span="12"
        >
        <h3>{{ $t("Trip Options") }}</h3>
        <Table
          :columns="moduleComponents.tripOptionsTable.headers"
          class="mt-4 table-format"
          :data="getTripOptionsTableData"
        />
        </i-col>
      </Row>

      <Row
        :gutters="0"
        class="d-flex mt-5 mb-5 px-5"
      >
        <i-col
          span="24"
        >
        <h3>{{ $t("Flights") }}</h3>
        <Table
          :columns="moduleComponents.flightsTable.headers"
          class="mt-4 table-format"
          :data="getFlightsTableData"
        />
        </i-col>
      </Row>
    </div>
  </AppModal>
  <!----------- [END] MODAL "TRAVEL INFOS" ----------->
</template>
<script>

import AppModal from "@/components/common/AppModal"
import {capitalizeFirstLetter} from "@/services/utilities";
import DateFormat from "@/mixins/dateFormat"

export default {
  name: "MissionInfosModal",
  components: {
      AppModal,
  },
  mixins: [DateFormat],
  props: ["mission"],
  data() {
    let utc_suffix = " UTC"
    return {
      moduleComponents: {
        mainInfosTable: {
          headers: [
            {
              title: this.$t("Name"),
              key: "userName",
            },
            {
              title: this.$t("Destination"),
              key: "destination",
            },
            {
              title: this.$t("Risk"),
              key: "riskLevel",
              align: "center",
              render: (h, params) => {
                return h(
                  "Tag",
                  {
                    props: {
                      color: this.getIViewTagComponentColorAttrValue(
                        params.row.riskLevel
                      ),
                    },
                  },
                  (params.row.riskLevel != null ? params.row.riskLevel : "?")
                );
              },
            },
            {
              title: this.$t("Departure Date"),
              key: "departureDate",
              align: "right",
            },
            {
              title: this.$t("Return Date"),
              key: "returnDate",
            },
            {
              title: this.$t("Type"),
              key: "type",
            },
            {
              title: this.$t("PNR Number"),
              key: "pnr",
            },
          ],
        },
        tripOptionsTable: {
          headers: [
            {
              title: this.$t("Reception"),
              key: "reception_upon_arrival_name",
            },
            {
              title: this.$t("Accommodation"),
              key: "accommodation_name",
            },
            {
              title: this.$t("Deplacements"),
              key: "vehicle_name",
            },
          ],
        },
        flightsTable: {
          headers: [
            {
              title: this.$t("Date"),
              key: "scheduled_departure_time_local",  // For sorting!
              render: (h, params) => {
                let content = this.dateFormat(params.row.scheduled_departure_time_local);
                return this.makeSpan(h, content);
              },
            },
            {
              title: this.$t("Flight number"),
              key: "flight_number",
            },
            {
              title: this.$t("Departure"),
              key: "departure_airport_value",
              align: "right",
              render: (h, params) => {
                let content = params.row.departure_airport_value.name;
                return this.makeSpan(h, content);
              },
            },
            {
              title: this.$t("Departure time"),
              key: null,
              render: (h, params) => {
                let content = this.timeFormat(params.row.scheduled_departure_time_local)
                let title = params.row.scheduled_departure_time_utc ?
                    this.timeFormat(params.row.scheduled_departure_time_utc) + utc_suffix : "";
                return this.makeSpan(h, content, title);
              },
            },
            {
              title: this.$t("Arrival"),
              key: "arrival_airport_value",
              align: "right",
              render: (h, params) => {
                let content = params.row.arrival_airport_value.name;
                return this.makeSpan(h, content);
              },
            },
            {
              title: this.$t("Arrival time"),
              key: null,
              render: (h, params) => {
                let content = this.timeFormat(params.row.scheduled_arrival_time_local)
                let title = params.row.scheduled_arrival_time_utc ?
                    this.timeFormat(params.row.scheduled_arrival_time_utc) + utc_suffix : "";
                return this.makeSpan(h, content, title);
              },
            },
            {
              title: this.$t("Status"),
              key: null,
              render: (h, params) => {
                let content = params.row.flight_status_title || "";
                let className = "";
                if (["Cancelled", "PastFlight", "Unknown"].includes(params.row.flight_status)) {
                  className = "flight-status-problem";  // See list of OAG status codes in backend
                }
                return this.makeSpan(h, content, "", className);
              },
            },
          ],
        },
      },
      uiSettings: {
        isVisible: false,
      },
    };
  },
  computed: {
    getMainInfosTableData() {
      if (!this.mission) return [];
      return [
        {
          userName: this.mission.user_value.full_name,
          destination: this.mission.destination_country_name,
          riskLevel: this.mission.global_risk,
          // IMPORTANt - we use UTC as fake local timezone for now!
          departureDate: this.dateFormat(this.mission.start_date),
          returnDate: this.dateFormat(this.mission.end_date),
          pnr: this.mission.pnr_code,
          type: this.mission.mission_type_name,
        },
      ];
    },
    getTripOptionsTableData() {
      if (!this.mission) return [];
      return [this.mission];
    },
    getFlightsTableData() {
      if (!this.mission) return [];
      return this.mission.mission_flight_bookings;
    },
  },
  methods: {
    makeSpan(h, content, title, className) {
      return h('span', {
          attrs: {
              class: className || "",
              title: title
          },
          domProps: {
              innerHTML: content
          }
      })
    },
    getIViewTagComponentColorAttrValue(riskLevel) {
      // FIXME deduplicate
      let color = "black";
      switch (riskLevel) {
        case 0:
          color = "#25c285";
          break;
        case 1:
          color = "#25c285";
          break;
        case 2:
          color = "#25c285";
          break;
        case 3:
          color = "#fbb000";
          break;
        case 4:
          color = "#ff6123";
          break;
        case 5:
          color = "#ff6123";
          break;
      }
      return color;
    },
    show() {
      this.uiSettings.isVisible = true;
    },
  },
};
</script>

<style scoped>
  span.flight-status-problem {
    color: var(--risk-4);
  }
</style>
