import {axiosInstance, TILE_TTL} from "./base"
import axios from "axios"

export function postEventsQry(data) {
    return axiosInstance.post(`api/events/`, data, {})
}

export function putEventsQry(id, data) {
    return axiosInstance.put(`api/events/${id}/`, data, {})
}

export function optionsEventsQry() {
    return axiosInstance.options(`api/events/`, {})
}

export function getRiskQry(country) {
    return axiosInstance.get(`api/risks/${country}/`, {})
}
export function getGlobalRisks() {
    return axiosInstance.get(`api/risks/?page_size=500`, {})
}
export function patchRiskQry(country, data) {
    return axiosInstance.patch(`api/risks/${country}/`, data, {})
}

export function getCountryFactsQry(params) {
    return axiosInstance.get(`api/country-facts/`, {params})
}

export function deleteCountryFactQry(id) {
    return axiosInstance.delete(`api/country-facts/${id}/`, {})
}

export function postCountryFactsQry(data) {
    return axiosInstance.post(`api/country-facts/`, data, {})
}

export function putCountryFactsQry(id, data) {
    return axiosInstance.put(`api/country-facts/${id}/`, data, {})
}

export function patchCountryFactsQry(id, data) {
    return axiosInstance.patch(`api/country-facts/${id}/`, data, {})
}

export function getFactTypeQry(slug) {
    return axiosInstance.get(`api/country-facts-types/${slug}/`, {})
}

export function getPointOfInterestQry(params) {
    return axiosInstance.get(`api/points-of-interest/`, {params})
}

export function getPointOfInterestTilesQry(tiles, zoom, params) {
    var requests = tiles.map(coords => {
        return axiosInstance.get(`api/points-of-interest/tiles/${zoom}/${coords[0]}/${coords[1]}/`,
                                {params, ttl: TILE_TTL})
    });
    return axios.all(requests)
}

export function postPointOfInterestQry(data) {
    return axiosInstance.post(`api/points-of-interest/`, data, {})
}

export function putPointOfInterestQry(id, data) {
    return axiosInstance.put(`api/points-of-interest/${id}/`, data, {})
}

export function deletePointOfInterestQry(id) {
    return axiosInstance.delete(`api/points-of-interest/${id}/`, {})
}

export function optionPointOfInterestQry() {
    return axiosInstance.options(`api/points-of-interest/`, {})
}
