import {axiosInstance} from "./base"


export function getDevicesQry(params) {
    return axiosInstance.get(`api/devices/`, {params});
}

export function deleteDeviceQry(id, params) {
    return axiosInstance.delete(`api/devices/${id}/`, {params});
}
